@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		padding: 40px 0;
		&__inner {
			max-width: 100%;
			margin: 0 auto;
			padding: 0 10px;
		}
		&__inner-white {
			background: $white;
			padding: 30px 15px;
			border-radius: 15px;
		}
		&__inner-bluelignt {
			background: $sky;
			padding: 30px 15px;
			border-radius: 15px;
		}
		&__inner-yellowlignt {
			background: $yellowLight;
			padding: 30px 15px;
			border-radius: 15px;
		}
        &__inner-orange {
			background: #FCF2D8;
            padding: 30px 15px;
			border-radius: 15px;
        }
		&__inner-pinklight {
			background: #FFEFF3;
			padding: 30px 15px;
			border-radius: 15px;
		}
	}
	&--column2 {
		.main {
			margin: 0 0 40px;
		}
	}
}

/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.icon-fix-left,
.icon-fix-right {
	@include centering-elements(false, true);
}
.icon-fix-left { left: 10px; }
.icon-fix-right { right: 10px; }

.bg-dotted {
	background: url(/common/img/common/bg_pattern_02.svg) center center repeat;
}
.bg-lattice {
	background: url(/common/img/common/bg_pattern.svg) center center repeat;
}
.bg-gray {
	background: #F6F6F6;
}
.bg-bluelight {
	background: $sky;
}
.bg-yellowlight {
	background: $yellowLight;
}

.bg-white {
	background: $white;
}

.link-scroll {
	padding-top: 90px;
	margin-top: -90px;
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	margin: 0;
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}
.icon-external-link {
	font-size: $s;
	margin: 0 0 0 5px;
}
.icon-file-pdf-o {
	margin: 0 5px 0 0;
}

.block01 {
	margin: 0 0 30px;
}

.movie-link {
	display: block;
	position: relative;
	&::after {
		@include centering-elements(true, true);
		content: "";
		background: url(/common/img/common/ico_play.png) no-repeat;
		background-size: 70px 70px;
		width: 70px;
		height: 70px;
	}
}
/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	background: url(/common/img/common/bg_ttl01.jpg) center center no-repeat;
	background-size: cover;
	font-size: $xxl;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.2;
	margin: 90px 0 0;
	flex-direction: column;
	
	.feature & {background-image: url(/common/img/feature/bg_ttl01.jpg);}
	.admission & {background-image: url(/common/img/admission/bg_ttl01.jpg);}
	.admission_graduate & {background-image: url(/common/img/admission/graduate/bg_ttl01.jpg);}
	.department & {background-image: url(/common/img/department/bg_ttl01.jpg);}
	.department_student & {background-image: url(/common/img/department/student/bg_ttl01.jpg);}
	.guide & {background-image: url(/common/img/guide/bg_ttl01.jpg);}
	.news & {background-image: url(/common/img/news/bg_ttl01.png);}
	.contact & {background-image: url(/common/img/contact/bg_ttl01.jpg);}
	.request & {background-image: url(/common/img/request/bg_ttl01.jpg);}
	.facility & {background-image: url(/common/img/facility/index/bg_ttl01.jpg);}
	.facility_access & {background-image: url(/common/img/facility/access/bg_ttl01.jpg);}
	.opencampus & {background-image: url(/common/img/opencampus/bg_ttl01.jpg);}
	.opencampus_visit & {background-image: url(/common/img/opencampus/visit/bg_ttl01.png);}
	.opencampus_online & {background-image: url(/common/img/opencampus/online/bg_ttl01.png);}
	.opencampus_soudan & {background-image: url(/common/img/opencampus/soudan/bg_ttl01.jpg);}
	.target & {background-image: url(/common/img/target/bg_ttl01.png);}
    .sheet & {background-image: url(/common/img/sheet/bg_ttl01.png);}
	
	[class^="icon-"] {
		display: block;
		font-size: 3.0rem;
		margin: 0 0 10px;
		.department & { font-size: 2.4rem;}
	}
	&__img {
		display: flex;
		justify-content: center;
		align-items: center; 
		height: 150px;
		& > img {
			max-height: 100px;
		}
	}
}

.ttl02 {
	margin: 0 0 20px;
	color: $black;
	font-size: 2.8rem;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		background: url(/common/img/common/ttl-line.svg) no-repeat;
		width: 55px;
		height: 4.8px;
	}
}

.ttl03 {
	margin: 0 0 20px;
	font-size: $xxl;
	font-weight: $font-medium;
	line-height: 1.4;
	color: $black;
	text-align: center;
    &._left_ {
        text-align: left;
    }
}

.ttl04 {
	margin: 0 0 20px;
	color: $black;
	font-size: $xl;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		border-bottom: 2px solid $baseColor;
		width: 47px;
	}
}

.ttl05 {
	margin: 0 0 20px;
	color: $black;
	font-size: $xl;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		border-bottom: 1px solid $baseColor;
		width: 65%;
	}
}

.ttl06 {
	margin: 0 0 15px;
	padding: 0 0 10px;
	border-bottom: 1px solid $baseColor;
	color: $black;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
	&:first-letter {
		color: $baseColor;
	}
}

.ttl07 {
	padding: 3px 0 3px 7px;
	margin: 0 0 15px;
	border-left: 4px solid $baseColor;
	color: $black;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
}

.ttl08 {
	margin: 0 0 15px;
	color: $black;
	font-size: $m;
	font-weight: $font-medium;
}

.ttl09 {
	margin: 0 0 10px;
	padding: 0 0 5px;
	color: $black;
	font-size: $l;
	display: block;
	font-weight: $font-medium;
	border-bottom: 1px solid $baseColor;
}

.ttl10 {
	margin: 0 0 10px;
	color: $baseColor;
	font-weight: $font-medium;
	&._c-black_ {
		color: $textColor;
	}
}

.catch01 {
	font-size: $xl;
	color: $baseColor;
	text-align: center;
}
.lead01 {
	margin: 0 0 40px;
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
	&__hierarchy {
		display: flex;
		&> * {
			width: calc((100% - 21px) / 2);
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--border {
		& > * {
			&::before {
				width: 8px;
				border-bottom: 1px solid $textColor;
				margin: 0 10px 0 0;
				top: 0.5em;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $orange;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
	&--odd {
		& > * {
			padding: 7px 15px;
			&:nth-child(odd) {
				background: #F6F6F6;
			}
		}
	}
}
.link-list {
    &__item {
        position: relative;
        padding: 0 0 0 15px;
        border-bottom: 1px solid $borderColor;
        &::before {
            @include centering-elements(false, true);
            left: 0;
            content: "\f105";
            font-family: 'icomoon';
            color: $baseColor;
        }
        &__link {
            color: $textColor;
            width: 100%;
            padding: 15px 0;
            display: block;
            text-decoration: none;
        }
    }
}

/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #ECECEC;
	}
	&--radius {
		border-radius: 15px;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--bluelight {
		background: $sky;
	}
	&--yellowlight {
		background: $yellowLight;
	}
	&--pinklight {
		background: #FFF5F6;
	}
	&--border-base {
		border: 1px solid $baseColor;
	}
    &--orange {
		background: #FCF2D8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--purple {
		background: #F3EFFB;
	}
    &--border-dashed {
        border: 2px dashed $baseColor;
    }
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;
	margin: 0 0 20px;
	th,
	td {
		padding: 10px;
		background: #fff;
		border-bottom: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
	}
	.bg-pink {
		background: $pink;
		color: $white;
	}
	.bg-pinklight {
		background: #FFF5F6;
	}
    .bg-bluedarken {
		background: #006281;
		border: 1px solid $white;
		color: $white;
	}
	.bg-bluelight {
		background: $sky;
	}
    .bg-yellowlight {
		background: $yellowLight;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
		td {
			border-bottom: 1px solid $borderColor;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list-sp {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	&--list {
		border: none;
		th,
		td {
			border: none;
		}
		tr {
			&:nth-child(odd) {
				th,td {
					background: #F6F6F6;
				}
			}
		}
		&--sp {
			tr {
				&:nth-child(odd) {
					th,td {
						background: none;
					}
				}
				&:nth-child(even) {
					th,td {
						background: #F6F6F6;
					}
				}
			}
		}
	}
}
.table-swipe {
	overflow-x: scroll;
	.table {
		border-top: solid 1px $borderColor;
		margin: 0;
		th,td {
			white-space: nowrap;
		}
		td {
			border:solid 1px $borderColor;
			background: $white;
			&:last-child {
				border-right: none;
			}
		}
		th {
			border-bottom: solid 1px $borderColor;
			&:first-child {
				background: $white;
				position:sticky;
				left:0;
			}
		}
		thead th {
			background: $white;
			border-right:solid 1px $borderColor;
			font-weight: $font-medium;
			font-size: $l;
			&:last-child {
				border-right: none;
			}
		}
	}
}
.table-swipe02 {
	overflow-x: scroll;
	position: relative;
	margin: 0 0 20px;
	.table {
		margin: 0;
		th,td {
			white-space: nowrap;
		}
	}
}
/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 40px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(25);

	&--white {
		background: $white;
	}
	&--base {
		background: $baseColor;
	}
	&--pink {
		background: $pink;
	}
	&--orange {
		background: $orange;
	}
	&--black {
		background: $black;
	}
	
	&--border-pink {
		border: 2px solid $pink;
		color: $pink;
		background: $white;
	}
	&--border-orange {
		border: 2px solid $orange;
		color: $orange;
		background: $white;
	}
	&--border-base {
		border: 2px solid $baseColor;
		color: $baseColor;
		background: $white;
	}
	&--clear {
		background: none;
		border: 1px solid #585858;
		color: #585858;
	}
	&--bluelight {
		background: $blueLight;
	}
	&--lg {
		width: 90%;
		height: 60px;
		font-size: $m;
		@include radius(30);
	}
    &--lg02 {
		width: 100%;
		height: 60px;
		font-size: $m;
		@include radius(30);
	}
	&--md {
		width: 80%;
		height: 50px;
		font-size: $m;
	}
	&--md02 {
		width: 75%;
		height: 50px;
		font-size: $m;
	}
    &--md03 {
		width: 90%;
		height: 50px;
		font-size: $m;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: flex;
			background: $white;
			border: 1px solid $baseColor;
			color: $baseColor;
			text-decoration: none;
			@include radius(20);
			width: 40px;
			height: 40px;
			justify-content: center;
			align-items: center;
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
			}
		}
	}
	&__prev,
	&__next,
	&__list {
		& > * {
			width: auto!important;
			padding: 0 20px;
		}
	}
	&__list {
		& > * {
			background: $baseColor!important;
			border: 1px solid $baseColor!important;
			color: $white!important;
			&:hover {
				background: $white!important;
				border: 1px solid $baseColor!important;
				color: $baseColor!important;
			}
		}
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: #006281;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: $font-medium;
}

.note {
	color: #5E5E5E;
	font-size: $s;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	padding: 0 10px;
	background: $baseColor;
	color: $white;
	font-size: $s;
	&--news {
		background: $baseColor;
	}
	&--oc {
		background: $pink;
	}
	&--class {
		background: #23C569;
	}
	&--graduate {
		background: $orange;
	}
	&--school {
		background: $blue;
	}
	&--other {
		background: #484848;
	}
}
.topic-date {
	color: #9C9C9C;
}

/* --------------------------------------------------- */
/* topic__list */
/* --------------------------------------------------- */
.topic__list {
	margin-left: -1%;
	margin-right: -1%;
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin-left: 1%;
		margin-right: 1%;
		width: 48%;
		text-decoration: none;
		display: block;
		margin-bottom: 30px;
		&__img {
			width: 100%;
			height: 140px;
			overflow: hidden;
			position: relative;
			& > img {
				width: auto;
				height: 100%;
				@include centering-elements(true, true);
			}
		}
		&__box {
			padding: 15px 0 0;
			&__label {
				min-width: 60%;
				height: 28px;
				display: inline-block;
				line-height: 28px;
				margin: 0 0 5px;
			}
			&__date {
				display: block;
			}
			&__title {
				color: $black;
				font-size: $s;
				line-height: 1.6;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	.topic-date {
		margin: 0 0 0 10px;
	}
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 20px;
		&__text {
			font-size: $xxl;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 10px 0 0;
			color: $baseColor;
			width: 100%;
		}
	}
	&__block {
		padding: 0 0 30px;
		img {
			margin: 0 0 25px;
			max-width: 100%;
			height: auto;
			display: block;
			&.aligncenter {
				margin: 0 auto 25px;
			}
			&.alignright {
				margin: 0 0 25px auto;
			}
		}
	}
}

/* ============================================================ */
/* banner */
/* ============================================================ */
.banner {
	border-top: 1px solid #CEE5E8;
	margin: 0 10px;
}

/* ============================================================ */
/* movie */
/* ============================================================ */
.movie {
	&__item {
		position: relative;
		cursor: pointer;
		@include transition;
		margin-bottom: 15px;
		img {
			width: 100%;
		}
		&::after {
			content: '';
			display: block;
			width: 100px;
			height: 100px;
			background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
			background-size: 100px;
			@include centering-elements(true, true);
		}
		&:hover {
			@include opacity;
		}
	}
}

/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	background: #F6F6F6;
	margin: 0 0 60px;
	border-radius: 5px;
	&__item {
		width: 100%;
		&__link {
			padding: 15px 20px;
			display: block;
			text-decoration: none;
			color: $textColor;
			border-bottom: 1px solid #CCCCCC;
			.icon-angle-right,
			.icon-angle-down {
				color: $baseColor;
				margin: 0 5px 0 0;
			}
		}
		&:last-child {
			.lnav__item__link {
				border-bottom: none;
			}
		}
	}
}
.lnav02 {
	&__item {
		width: 100%;
		display: block;
		margin-left: 5px;
		margin-right: 5px;
		background: #ECECEC;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;
		transition: 0.3s all;
		color: $textColor;
		padding: 10px 0;
		margin: 5px 0;
		.icon-angle-right,
		.icon-angle-down {
			color: $baseColor;
			margin: 0 5px 0 0;
		}
		&--active {
			background: #006281;
			color: $white;
			.icon-angle-right,
			.icon-angle-down {
				color: $white;
			}
		}
	}
}

/* --------------------------------------------------- */
/* admission-bnr */
/* --------------------------------------------------- */
.admission-bnr {
	color: $white;
	font-size: $xl;
	font-weight: $font-medium;
	height: 90px;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 1.6;
	text-decoration: none;
	&--ao {
		background-image: url(/common/img/common/bnr_ao_01.jpg);
	}
	&--suisen {
		background-image: url(/common/img/common/bnr_suisen_01.jpg);
	}
	&--ippan {
		background-image: url(/common/img/common/bnr_ippan_01.jpg);
	}
	&--tuition {
		background-image: url(/common/img/admission/bnr_tuition_01.jpg);
	}
	&--support {
		background-image: url(/common/img/admission/bnr_support_01.jpg);
	}
}
/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__inner {
		border: 1px solid $baseColor;
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		width: 50%;
		border-bottom: 1px solid $baseColor;
		position: relative;

		&:nth-child(2n+1) {
			border-right: 1px solid $baseColor;
		}
		&:last-child,
		&:nth-last-child(2) {
			border-bottom: none;
		}
		&__link {
			color: $black;
			text-decoration: none;
			font-weight: $font-medium;
			display: block;
			padding: 15px 0 15px 25px;
			.icon-angle-right {
				color: $baseColor;
				@include centering-elements(false, true);
				left: 10px;
			}
		}
	}
}
