@charset "utf-8";
/* ============================================================ */
/* admission */
/* ============================================================ */
.admission {
	.box__head {
		background: #006281;
		color: $white;
		padding: 7px 15px;
	}
	.ad-block {
		margin: 0 0 30px;
	}
	.ttl09 {
		&__small {
			font-size: 1.5rem;
			padding-left: 10px;
		}
	}
	.ad-flow {
		margin: 50px 0 30px 0;
		&__title {
			margin: 0 0 25px;
			padding: 0 0 10px;
			border-bottom: 1px solid $baseColor;
			color: $black;
			font-size: $l;
			font-weight: $font-medium;
			line-height: 1.4;
			&__num {
				color: $baseColor;
				font-size: $xxl;
				padding: 0 5px 0 0;
			}
		}
		&__btn {
			margin: 30px 0 0 0;
			.btn {
				width: 90%;
			}
		}
	}
	.admission-ttl01 {
		background: #006281;
		color: $white;
		font-size: $l;
		padding: 7px 15px;
		margin: 0 0 20px;
		font-weight: $font-medium;
	}
	.table--tuition {
		thead {
			th {
				background: #006281;
				border: 1px solid $white;
				color: $white;
				text-align: center;
				&.season {
					border-bottom: 1px dashed $white!important;
				}
				&.date {
					border-top: none;
				}
				&.bunkatsu-season {
					border-bottom: none;
				}
			}
		}
		tbody {
			th,td {
				border: 1px solid $borderColor;
				.note {
					margin-left: 5px;
					font-size: $xxs;
				}
				.note02 {
					position: relative;
					&__item {
						position: absolute;
						top: -10px;
						left: 0;
						font-size: $xxs;
					}
				}
			}
			th {
				&.item {
					font-weight: $font-medium;
					text-align: center;
					.item__text {
						writing-mode: vertical-rl;
						width: 30px;
						line-height: 30px;
					}
				}
			}
			td {
				text-align: right;
			}
		}
	}
}

/* ============================================================ */
/* admission_index */
/* ============================================================ */
#admission_index {
    .department-type {
		margin-bottom: 50px;
		&__item {
			&__img {
				position: relative;
				padding: 0 0 50px;
				margin-bottom: 20px;
				&__text {
					padding: 15px 30px;
					width: 85%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $white;
					&__title {
						font-size: 2.5rem;
					}
				}
			}
			.catch01 {
				font-weight: $font-regular;
				margin: 0 0 20px;
			}
			&--kodomo {
				.department-type__item__img__text {
					background: rgba($orange,0.9);
				}
				.catch01 {
					color: $orange;
				}
			}
			&--yoji {
				.department-type__item__img__text {
					background: rgba($pink,0.9);
				}
				.catch01 {
					color: $pink;
				}
			}
		}
	}
	.ttl07 {
		font-size: $xxl;
		&__small {
			font-size: $m;
			padding: 0 0 0 10px;
		}
	}
	.recruitment-idx,
	.type-idx,
	.tuition-idx {
		margin-bottom: 60px;
	}
	.recruitment-idx {
		&__item {
			margin: 0 0 60px;
			.box {
				margin: 0 0 30px;
				.list {
					margin: 0 0 15px;
				}
			}
		}
	}
	.type-idx {
		&__ico {
            &__item {
                display: flex;
                align-items: center;
                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    max-width: 100px;
                    flex: 0 0 100px;
                }
                .btn {
                    margin: 0 0 0 20px;
                    &.btn--md02 {
                        width: 60%;
                    }
                }
            }
        }
        &__pdf {
            border: 1px solid $baseColor;
            border-left: 7px solid $baseColor;
            font-weight: $font-medium;
            font-size: $l;
            padding: 5px 20px;
            line-height: 1.5;
            margin: 25px 0 0 0;
            &__dl {
                display: block;
                width: 100%;
                text-align: right;
                font-size: $m;
                margin: 5px 0 0 0;
                .__ico {
                    color: $baseColor;
                    margin: 0 0 0 5px;
                }
            }
        }
	}
	.tuition-idx {
		&__item {
			margin-bottom: 20px;
			&__box {
                .icon-angle-right {
                    margin: 0 0 0 5px;
                }
			}
		}
	}
	.support-idx {
		&__item {
			margin: 0 0 30px;
			.btn {
				margin: 30px 0 0;
				width: 100%;
			}
			.icon-angle-right {
				margin: 0 0 0 5px;
			}
		}
	}
}

/* ============================================================ */
/* admission_ao,admission_suisen,admission_ippan */
/* ============================================================ */
.admission_ao,
.admission_suisen,
.admission_ippan {
	.policy {
		margin: 0 0 40px;
		.ttl03 {
			font-size: $xxl;
		}
		&__item {
			display: flex;
			&__num {
				flex: 0 0 30px;
				background: $baseColor;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $l;
				font-weight: $font-medium;
				color: $white;
				margin: 0 10px 10px 0;
			}
		}
	}
	.table {
		th {
			font-weight: $font-medium;
		}
	}
	.ad-lnav{
		border-top: 1px solid #CEE5E8;
	}
}

/* ============================================================ */
/* admission_support */
/* ============================================================ */
.admission_support {
    .challenge-flow {
		margin: 0 0 40px;
		&__item {
			margin: 0 0 50px 0;
			width: 100%;
			border: 2px solid $baseColor;
			color: $baseColor;
			display: flex;
			align-items: center;
			padding: 15px;
			font-weight: $font-medium;
			position: relative;
			font-size: 1.5rem;
			&::after {
				@include centering-elements(true, false);
				bottom: -42px;
				content: "\e908";
				font-family: 'icomoon';
				color: $black;
				font-size: $xl;
			}
			&:last-child {
				margin: 0;
				&::after {
					display: none;
				}
			}
			&__label {
				font-size: 3.2rem;
				padding: 0 15px 0 0;
				margin: 0 15px 0 0;
				border-right: 2px solid $baseColor;
			}
		}
	}
	.challenge-price {
        .icon-arrow-circle-right {
            margin: 0 10px;
        }
	}
}

/* ============================================================ */
/* admission_tuition */
/* ============================================================ */
.admission_tuition {
	.admission-bnr {
		width: 270px;
	}
    .kyufu-ex {
        display: flex;
        .__label {
            background: $baseColor;
            color: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            margin: 0 15px 0 0;
        }
    }
    .lone-flow {
		margin: 0 0 40px;
		.box__head {
			background: $baseColor;
			font-size: $l;
			text-align: center;
			font-weight: $font-medium;
		}
		.box--border-base {
			border-width: 2px;
			text-align: center;
		}
		&__body {
			margin: 0 0 20px;
			&__icon {
				display: flex;
				justify-content: center;
				padding: 10px 0;
				img {
					transform: rotate( 90deg );
					padding: 10px 0 0;
				}
			}
		}
	}
}
/* ============================================================ */
/* admission_ao_form,admission_ippan_form,admission_suisen_form */
/* ============================================================ */
.admission_ao_form,
.admission_ippan_form,
.admission_suisen_form {
    .pdf-dl {
        &__item {
            margin: 0 0 5px;   
        }
    }
}

/* ============================================================ */
/* admission_support */
/* ============================================================ */
.admission_support {
	.ad-contact {
		font-weight: $font-medium;
		font-size: $l;
		&__tel {
			color: $baseColor;
		}
		&__num {
			font-size: $xxxl;
			color: $textColor;
		}
	}
	
}

/* ============================================================ */
/* admission_graduate */
/* ============================================================ */
.admission_graduate {
	.student-list {
		&__item {
			border-radius: 15px;
			padding: 20px 25px;
			margin: 0 0 30px;
			background: $sky;
			&__data {
				&__img {
					width: 160px;
					text-align: center;
					margin: 0 auto 10px;
					& > img {
						border-radius: 50%;
						width: 100%;
					}
				}
				&__text {
					&__label {
						color: $white;
						padding: 0 5px;
						height: 25px;
						line-height: 25px;
						font-size: 1.5rem;
						background: $baseColor;
					}
					&__name {
						font-size: $xxl;
						font-weight: $font-medium;
					}
				}
			}
			&__catch {
				font-size: $l;
				font-weight: $font-medium;
				margin: 0 0 20px;
				color: $baseColor;
			}
		}
	}
	.row__inner-white {
		margin-bottom: 30px;
	}
	.ttl01 {
		&__text {
			z-index: -1;
			color: $white;
			height: 1px;
		}
	}
	.student-data {
		margin: 0 0 20px;
		&__img {
			width: 160px;
			text-align: center;
			margin: 0 auto 10px;
			& > img {
				border-radius: 50%;
				width: 100%;
			}
		}
		&__text {
			&__label {
				color: $white;
				padding: 0 5px;
				height: 25px;
				min-width: 110px;
				line-height: 25px;
				font-size: 1.5rem;
				background: $baseColor;
			}
			&__name {
				margin: 0 0 10px;
				font-size: $xl;
				font-weight: $font-medium;
				display: flex;
				align-items: flex-end;
				flex-wrap: wrap;
				&__label {
					font-size: 1.5rem;
					font-weight: $font-regular;
					padding: 0 15px 0 0;
				}
				&__type {
					width: 100%;
					color: #484848;
					font-size: 1.5rem;
					font-weight: $font-regular;
				}
			}
			&__catch {
				font-size: $l;
				font-weight: $font-medium;
				padding: 0 0 10px;
				border-bottom: 1px solid $baseColor;
				color: $baseColor;
			}
			.__school {
				font-size: 1.5rem;
			}
		}
	}
	.box {
		margin: 0 0 40px;
	}
	.student-block {
		margin: 0 0 50px;
		.student-ttl {
			border-bottom: 3px dotted #666666;
			padding: 0 0 15px;
			margin: 0 0 30px;
		}
		.question {
			font-size: $l;
			font-weight: $font-medium;
			display: flex;
			align-items: center;
			margin: 0 0 15px;
			&__label {
				flex: 0 0 40px;
				margin: 0 15px 0 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				font-weight: $font-regular;
				font-size: $m;
				background: $baseColor;
			}
		}
	}
}