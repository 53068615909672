@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	font-family: 'Noto Sans JP', sans-serif;
	color: $textColor;
	font-size: $m;
	line-height: 1.8;
	overflow-x: hidden;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		img {
			transition: 0.3s all;
			opacity: 0.7;
		}
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 12px 0;
	font-size: 1.5rem;
	line-height: 1.6;
	bottom: 0;
	left: 0;
	width: 100%;
	font-weight: $font-regular;
	color: #5E5E5E;
	border-bottom: 1px solid $baseColorLight;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		&__link {
			color: #5E5E5E;
		}
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 20px;
	bottom: 120px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 55px;
		height: 55px;
		background: rgba($orange, 0.9);
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		border-radius: 50%;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}

/* ================================================== */
/* #fix-btn */
/* ================================================== */
.fix-btn {
	position: fixed;
	top: 180px;
	right: 0;
	z-index: 20;
	&__item {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		padding: 20px 0 0;
		width: 70px;
		display: block;
		line-height: 60px;
		color: $white;
		margin: 0 0 5px;
		transition: 0.3s all;
		position: relative;
		left: 10px;
		text-decoration: none;
		&__text {
			font-size: $xl;
			writing-mode: vertical-rl;
			transform: rotate(0.001deg);
		}
		&--oc {
			background: $orange;
		}
		&--request {
			background: $baseColor;
		}
		&--contact {
			background: #006281;
		}
		&:hover {
			left: 0;
		}
	}
}
