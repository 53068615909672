@charset "utf-8";
/* ============================================================ */
/* facility_access */
/* ============================================================ */
.facility_access {
	.school-title {
		margin: 0 0 30px;
		line-height: 1.5;
		font-size: $xl;
		&__label {
			font-weight: $font-regular;
			font-size: $s;
		}
	}
	.root {
		margin: 60px 0 30px 0;
		&__list {
			&__item {
				width: 100%;
				margin: 0 0 50px 0;
				position: relative;
				text-align: center;
				&:last-child {
					&::after {
						display: none;
					}
				}
				&::after {
					@include centering-elements(true, false);
					bottom: -60px;
					content: "\f0d7";
					font-family: 'icomoon';
					color: $baseColor;
					font-size: 4.0rem;
				}
				& > img {
					width: 70%;
					margin: 0 0 10px;
				}
				&__text {
					text-align: left;
				}
				.ttl08 {
					margin: 0 0 5px;
				}
			}
		}
	}
	.row__inner-white {
		border-radius: 0;
	}
}