@charset "utf-8";
/* ============================================================ */
/* opencampus */
/* ============================================================ */
.opencampus {
	.contents {
		a {
			color: $pink;
		}
		.btn {
			color: $white;
			&--clear {
				color: #585858;
			}
			&--border-pink {
				color: $pink;
				&:hover {
					color: $white;
				}
			}
			&--border-sp {
				color: $ocSpText;
				border: 1px solid $ocSpText;
				&:hover {
					background: $ocSpText;
					color: $white;
					opacity: 1.0;
				}
			}
		}
		.ttl02 {
			&::after {
				background: url(/common/img/opencampus/ttl-line.svg) no-repeat;
			}
		}
		.ttl04 {
			&::after {
				border-bottom: 2px solid $pink;
			}
		}
		.ttl06 {
			border-bottom: 1px solid $pink;
			&:first-letter {
				color: $pink;
			}
		}
		.ttl07 {
			border-left: 6px solid $pink;
		}
		.ttl10 {
			color: $pink;
		}
		.catch01 {
			font-size: $xxl;
			font-weight: $font-medium;
			color: $pink;
			text-align: center;
			margin: 0 0 20px;
		}
		.catch02 {
			font-size: $xxl;
			font-weight: $font-medium;
			margin: 0 0 20px;
			&__em {
				color: $baseColor;
			}
		}
        .catch03 {
            position: relative;
            padding: 0;
            color: #000;
            margin: 0;
            font-size: $xxl;
            font-weight: 500;
            text-align: center;
            .__fukidashi {
                margin: 0 20px;
            }
        }
		.bg-oc-gray {
			background: #FBF4F4;
		}
		.table {
			.bg-pinklight {
				color: $pink;
			}
		}
	}

	.tokuten {
		background-color: $pinkLight;
		background-image: url(/common/img/opencampus/pic_tokuten_left_01.svg),url(/common/img/opencampus/pic_tokuten_right_01.svg),url(/common/img/opencampus/bg_tokuten_left_01.svg),url(/common/img/opencampus/bg_tokuten_right_01.svg);
		background-position: left bottom 10%, right bottom 10%, left 10%, right 10%;
		background-size: 12% auto, 12% auto, 26% auto, 26% auto;
		background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
		.row__inner {
			padding: 0 120px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 60px; }
		}
		.box {
			padding: 50px 25px;
			text-align: center;
			font-size: 2.1rem;
			font-weight: $font-medium;
			.ttl04 {
				font-size: 2.8rem;
				&::after {
					border-bottom: 2px solid $pink;
				}
			}
			.tokuten__em {
				margin: 0 0 30px;
				display: inline-block;
				font-size: 3.2rem;
				border-bottom: 3px solid $yellow;
			}
		}
	}
	.schedule-bnr {
		max-width: 940px;
	}
	.banner {
		border-top: 1px solid #ECECEC;
	}
	.topic-path {
		border-bottom: 1px solid $pinkLight;
		&__item {
			&:not(:last-child) {
				&::after {
					color: $pink;
				}
			}
		}
	}
	.oc-type {
		padding: 0;
		display: flex;
		&__item {
			padding: 30px 5px 85px;
			text-align: center;
			position: relative;
			&--visit {
				background: url(/common/img/opencampus/bg_mini_01.png) center center no-repeat;
				background-size: cover;
				.oc-type__item__box__catch {
					color: $blueLight;
				}
				.oc-type__item__box__list li::before,
				.oc-type__item__text {
					color: $blueLight;
				}
			}
			&--online {
				background: url(/common/img/opencampus/bg_sp_01.png) center center no-repeat;
				background-size: cover;
				.oc-type__item__text {
					color: $orange;
				}
				.oc-type__item__box {
					text-align: center;
					&__catch,
					a {
						color: $orange;
					}
				}
			}
			&--soudan {
				background: url(/common/img/opencampus/bg_soudan_01.png) center center no-repeat;
				background-size: cover;
				.oc-type__item__ttl {
					& > * {
						max-width: 90%;
					}
				}
				.oc-type__item__box {
					&__catch,
					.em,
					.oc-type__item__box__list li::before,
					.oc-type__item__text {
						color: $pink;
					}
				}
				.commingsoon__ttl:before {
					color: $pink;
				}
				.oc-type__item__text {
					color: $baseColorDarken;
				}
			}
			&__text {
				font-size: $xl;
				line-height: 1.6;
				padding: 5px 0;
				font-weight: $font-medium;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 20%,rgba(255,255,255,0.6) 80%,rgba(255,255,255,0) 100%);
			}
			&__ttl {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 0 20px;
				min-height: 180px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-height: 160px;}
				& > * {
					max-width: 65%;
				}
			}
			&__box {
				background: $white;
				width: 85%;
				text-align: left;
				margin: 0 auto 30px;
				padding: 30px 15px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {padding: 20px 10px;}
				
				&__catch {
					position: relative;
					font-size: 1.7rem;
					font-weight: $font-medium;
					line-height: 1.6;
					padding: 0 0 10px;
					margin: 0 0 15px;
					text-align: center;
				}
				&__list {
					width: 80%;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 100%; }
					margin: 0 auto;
					
					li {
						position: relative;
						padding: 0 0 0 1.5em;
						&::before {
							content: "\f0c8";
							position: absolute;
							left: 0;
							top: 0;
							 font-family: 'icomoon';
						}
					}
					.icon-square {
						margin-right: 5px;
					}
				}
			}
			.btn {
				min-width: 270px;
				@include centering-elements(true, false);
				bottom: 35px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: inherit;width: 80%; }
			}
		}
		&--special {
			.oc-type__item__box {
				height: 240px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-height: 200px;height: 220px;}
			}
		}
	}
}
/* ============================================================ */
/* opencampus_index */
/* ============================================================ */
#opencampus_index {
	.visual {
		margin: 160px 0 0;
		background: url(/common/img/opencampus/index/pic_visual_01_pc.jpg) center center no-repeat;
		height: 775px;
		position: relative;
		&__inner {
			@include centering-elements(true, true);
			max-width: 1140px;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			&__img {
				width: 75%;
			}
		}
	}
	/*.commingsoon {
		&__inner {
			position: relative;
			top: -155px;
			padding: 55px 45px;
			background: $white;
			margin: 0 0 -30px;
		}
		&__ttl {
			display: flex;
			align-items: flex-end;
			margin: 0 0 35px;
			color: $baseColor;
			font-size: 1.5rem;
			font-weight: $font-regular;
			&:before {
				color: $black;
				content: attr(data-text);
				margin: 0 25px 0 0;
				font-size: 3.6rem;
				font-weight: $font-black;
				line-height: 1.0;
			}
		}
		&__item {
			text-decoration: none;
			&__img {
				margin: 0 0 15px;
			}
			&__text {
				color: $textColor;
				font-size: $l;
				line-height: 1.6;
			}
		}
	}*/
	
	.corona-info {
		background: $white;
		text-align: center;
		padding: 15px 0;
		width: 60%;
		margin: 0 auto;
		color: $pink;
		display: block;
		font-size: $l;
		font-weight: $font-medium;
		text-decoration: none;
		@include transition;
		&:hover {
			background: $pink;
			color: $white;
		}
	}
	
	.oc-type {
		margin-left: -3px;
		margin-right: -3px;
		&__item {
			margin-left: 3px;
			margin-right: 3px;
			width: calc((100% - 19px) / 3);
			display: flex;
			flex-direction: column;
			&__box {
				flex-grow: 1;
				&__catch {
					@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: $m;text-align: left;}
				}
			}
		}
	}
	.point {
		.row__inner {
			padding: 70px 85px;
			background: $white;
		}
		&__block {
			margin: 0 0 85px;
			&:last-child {
				margin: 0;
			}
			&__title {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 0 45px;
				&::before,
				&::after {
					content: "";
					@include centering-elements(false, true);
					background: url(/common/img/opencampus/ttl-line02.svg) repeat-x;
					width: 50%;
					height: 8px;
				}
				&::before {
					left: 0;
					background-position: left center;

				}
				&::after {
					right: 0;
					background-position: right center;
				}
				&__img {
					background: $white;
					width: 164px;
					height: 60px;
					z-index: 1;
					text-align: center;
				}
			}
			.catch01 {
				font-size: 2.7rem;
			}
		}
	}
	.movie {
		margin: 0 auto;
		width: 50%;
	}
}


/* ============================================================ */
/* opencampus_visit,opencampus_online */
/* ============================================================ */
.opencampus_visit,
.opencampus_online {
	.corona-info {
		text-align: center;
		color: $pink;
		display: block;
		font-size: $l;
		font-weight: $font-medium;
		margin: 0 0 40px;
		@include transition;
	}
	.oc-date {
		background: #F6F6F6;
		padding: 5px;
		width: 100%;
		max-width: 600px;
		margin: 0 auto 40px;
		font-size: $xxxl;
		font-weight: $font-bold;
		text-align: center;
		&__inner {
			border: 1px solid $pinkLight;
			padding: 15px;
		}
		&__title {
			margin: 0 0 10px;
			font-size: 1.7rem;
		}
		&__label {
			font-weight: $font-medium;
			line-height: 1.0;
			font-size: 1.7rem;
			background: $pink;
			color: $white;
			padding: 5px 20px;
			border-radius: 20px;
			margin-right: 10px;
		}
	}
	.table--oc-time {
		border-top: none;
		th,td {
			padding: 10px 15px;
		}
		.bg-pinklight {
			color: $textColor;
		}
		thead {
			th {
				border-bottom: none;
			}
		}
		tbody {
			th {
				font-weight: $font-medium;
				text-align: center;
			}
		}
		&__day {
			font-weight: $font-medium;
		}
	}
	.time-text {
		padding-left: 30px;
		&__item {
			margin-bottom: 30px;
			&__img {
				margin: 0 0 15px;
			}
			&__title {
				font-weight: $font-medium;
				font-size: $l;
				margin: 0 0 10x;
			}
		}
	}
	.day-item {
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.oc-type {
		&__item {
			width: calc(100% / 2);
		}
	}
	.banner {
		border-top: 0;
	}
}

/* ============================================================ */
/* opencampus_visit */
/* ============================================================ */
.opencampus_visit {
	.topic-path {
		border-bottom: 1px solid $ocMiniTextLight;
		&__item {
			&:not(:last-child) {
				&::after {
					color: $ocMiniText;
				}
			}
		}
	}
	.contents {
		.oc-date {
			&__inner {
				border: 1px solid $ocMiniText;
			}
			&__label {
				background: $ocMiniText;
			}
		}
		.day-item {
			&__btn-area {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.btn {
					width: calc((100% - 21px) / 2 );
					margin: 0 0 10px;
					font-size: 1.5rem;
				}
			}
		}
		.ttl02 {
			&::after {
				background: url(/common/img/opencampus/visit/ttl-line.svg) no-repeat;
			}
		}
		.ttl04 {
			&::after {
				border-bottom: 2px solid $ocMiniText;
			}
		}
		.ttl06 {
			border-bottom: 1px solid $ocMiniText;
			&:first-letter {
				color: $ocMiniText;
			}
		}
		.ttl07 {
			border-left: 6px solid $ocMiniText;
		}
		.ttl10 {
			color: $ocMiniText;
		}
		.catch01 {
			color: $ocMiniText;
		}
		.btn {
			&--ocmini {
				background: $ocMiniText;
			}
			&--border-ocmini {
				color: $ocMiniText;
				border: 1px solid $ocMiniText;
				&:hover {
					background: $ocMiniText;
					color: $white;
					opacity: 1.0;
				}
			}
			&--oc {
				background: $ocMiniText;
			}
		}
		.table--oc-time {
			.bg-ocmini {
				color: $white;
				background: $ocMiniText;
			}
			.bg-ocmini-light {
				background: $sky;
				color: $ocMiniText;
			}
			&__day {
				color: $ocMiniText;
			}
		}
		.schedule-list {
			&__item {
				border: 1px solid #FF6085;
				background: $white;
				margin-bottom: 40px;
                display: flex;
                &__day {
                    background: #ffacc5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 30px;
                    img {
                        height: 170px;
                        
                    }
                    &._0722_ {
                        img {
                            height: auto;
                            max-width: 171.61px;
                        }
                    }
                    @media screen and (min-width:  767.5px) and (max-width:1024px) {
                        padding: 20px;
                        img {
                            height: 150px;
                        }
                        &._0722_ {
                            img {
                                height: auto;
                                max-width: 151.42px;
                            }
                        }
                    }
                }
                &__wrap {
                    flex: 1 1 240px;
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    @media screen and (min-width:  767.5px) and (max-width:1024px) {
                        flex: 1 1 200px;
                    }
                }
                &__img {
                    max-width: 178px;
                    margin: 0 20px 0 0;
                    img {
                        width: 100%;
                    }
                }
                &__body {
                    flex: 1 1 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn {
                        margin: 0 0 0 20px;
                    }
                    
                    @media screen and (min-width:  767.5px) and (max-width:1024px) {
                        display: block;
                        .btn {
                            margin: 15px 0 0 0;
                        }
                    }
                }
			}
		}
	}
}

/* ============================================================ */
/* opencampus_online */
/* ============================================================ */
.opencampus_online {
	.topic-path {
		border-bottom: 1px solid $ocSpTextLight;
		&__item {
			&:not(:last-child) {
				&::after {
					color: $ocSpText;
				}
			}
		}
	}
	.contents {
		a {
			color: $ocSpText;
		}
		.oc-date {
			&__inner {
				border: 1px solid $ocSpText;
			}
			&__label {
				background: $ocSpText;
			}
		}
		.day-item {
			&__btn-area {
				text-align: center;
				.btn {
					width: 80%;
				}
			}
		}
		.ttl02 {
			&::after {
				background: url(/common/img/opencampus/online/ttl-line.svg) no-repeat;
			}
		}
		.ttl04 {
			&::after {
				border-bottom: 2px solid $ocSpText;
			}
		}
		.ttl06 {
			border-bottom: 1px solid $ocSpText;
			&:first-letter {
				color: $ocSpText;
			}
		}
		.ttl07 {
			border-left: 6px solid $ocSpText;
		}
		.ttl10 {
			color: $ocSpText;
		}
		.catch01 {
			font-size: $xxl;
			font-weight: $font-medium;
			color: $ocSpText;
			text-align: center;
			margin: 0 0 20px;
			.__em {
				font-size: 2.8rem;
			}
		}
		.btn {
			&--ocsp {
				background: $ocSpText;
			}
			&--border-ocsp {
				color: $ocSpText;
				border: 1px solid $ocSpText;
				&:hover {
					background: $ocSpText;
					color: $white;
					opacity: 1.0;
				}
			}
			&--oc {
				background: $ocSpText;
			}
		}
		.table--oc-time {
			.bg-ocsp {
				color: $white;
				background: $ocSpText;
			}
			.bg-ocsp-light {
				background: $yellowLight;
				color: $ocSpText;
			}
			&__day {
				color: $ocSpText;
			}
		}
		.plan {
			margin: 0 0 60px;
			&__item {
				background: $yellowLight;
				border-radius: 20px;
				padding: 30px 20px;
				height: 100%;
				display: flex;
				flex-direction: column;
				
			}
		}
                
        .detail {
            margin: 0 0 60px;
            &__item {
                text-align: center;
                .__ttl {
                    font-size: $xxl;
                    font-weight: $font-medium;
                    margin: 0 auto 15px;
                    &::before,
                    &::after {
                        content: "\e90f";
                        font-family: 'icomoon';
                        padding: 0 10px;
                    }
                }
                img {
                    width: auto;
                }
                &._pink_ {
                    background: #FFEDED;
                    .__ttl {
                       color: #F9405F; 
                    }
                }
                &._blue_ {
                    background: #D7F4F6;
                    .__ttl {
                       color: $ocMiniText; 
                    }
                }
                &._yellow_ {
                    background: #FFF8D8;
                    .__ttl {
                       color: #AF6F00; 
                    }
                }
                &._orange_ {
                    background: #FFEED9;
                    .__ttl {
                       color: #F15A24; 
                    }
                }
                &._green_ {
                    background: #E7F9DE;
                    .__ttl {
                       color: #338D07; 
                    }
                }
            }
        }
	}
}

/* ============================================================ */
/* opencampus_soudan */
/* ============================================================ */
.opencampus_soudan {
	.soudan-flow {
		display: flex;
		border: 1px solid #e2e2e2;
		&__item {
			padding: 30px 0 30px 30px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 20px 0 20px 20px; }
			position: relative;
			font-size: 1.5rem;
			&__img {
				text-align: center;
				margin: 0 0 15px;
			}
			&__title {
				font-size: $l;
				font-weight: $font-medium;
				margin: 0 0 10px;
				text-align: center;
			}
			&:first-child {
				background: $white;
				width: calc((100% / 3) - 30px);
				&::after {
					content: "";
					height: 100%;
					width: 65px;
					background:linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $white 50.5%) no-repeat top left/100% 50%,linear-gradient(to top left, rgba(255,255,255,0) 50%, $white 50.5%) no-repeat bottom right/100% 50.5%;
					position: absolute;
					top: 0;
					right: -65px;
					z-index: 2;
				}
			}
			&:nth-child(2) {
				width: calc((100% / 3) + 10px);
				background: #EDFCFA;
				padding: 30px 0 30px 95px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 20px 0 20px 85px; }
				&::after {
					content: "";
					height: 100%;
					width: 65px;
					background:linear-gradient(to bottom left, rgba(255,255,255,0) 50%, #EDFCFA 50.5%) no-repeat top left/100% 50%,linear-gradient(to top left, rgba(255,255,255,0) 50%, #EDFCFA 50.5%) no-repeat bottom right/100% 50.5%;
					position: absolute;
					top: 0;
					right: -65px;
					z-index: 2;
				}
			}
			&:last-child {
				width: calc((100% / 3) + 20px);
				background: #D0F4F2;
				padding: 30px 30px 30px 95px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 20px 20px 20px 85px; }
			}
		}
	}
	.soudan-time {
		display: flex;
		justify-content: center;
		margin: 0 0 30px;
		&__item {
			display: flex;
			align-items: center;
			font-size: $l;
			color: $black;
			font-weight: $font-medium;
			margin: 0 20px;

			&__label {
				font-weight: $font-medium;
				line-height: 1.0;
				font-size: 1.5rem;
				background: $pink;
				color: $white;
				padding: 5px 20px;
				border-radius: 20px;
				margin-right: 10px;
			}
		}
	}
	.soudan-tel {
		font-size: 2.6rem;
		font-weight: $font-medium;
		text-align: center;
		border-top: 1px solid #DFDFDF;
		border-bottom: 1px solid #DFDFDF;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0 30px;
		
		.icon-phone {
			color: $pink;
			margin-right: 5px;
		}
	}
	.calendar {
		margin: 0 0 60px;
	}
	.oc-type {
		&__item {
			width: calc(100% / 2);
		}
	}
}

/* ============================================================ */
/* opencampus_soudan_form,opencampus_form */
/* ============================================================ */
.opencampus_soudan_form,
.opencampus_form {
	.label-must {
		background: $pink;
	}
	.radio {
		input[type="radio"] + span {
			&::after {
				background: $pink;
			}
		}
	}
	.checkbox {
		input[type="checkbox"] + span {
			&::after {
				color: $pink;
			}
		}
	}
	.ui-widget {
		font-size: 1.35rem !important;
	}
	.ui-datepicker-prev,.ui-datepicker-next {
		box-sizing: content-box;
		transition: all 0s;
	}
	.ui-datepicker-calendar .ui-state-default {
		margin-left: 0 !important;
	}
	.datepicker-saturday span.ui-state-default { /* disabled saturday */
		background-image: none;
		color: dodgerblue !important;
		border: 1px solid transparent;
	}
	.datepicker-sunday span.ui-state-default { /* disabled sunday */
		background-image: none;
		color: red !important;
		border: 1px solid transparent;
	}
	.datepicker-holiday span.ui-state-default { /* disabled holiday */
		background-image: none;
		color: red !important;
		border: 1px solid transparent;
	}
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
		background: $pinkLight;
		border: 1px solid $pink;
	}
}
.opencampus_soudan_form {
	th {
		width: 250px;
		@media screen and (min-width:  800px) and (max-width:860px) { width: 210px; }
		@media screen and (min-width:  767.5px) and (max-width:799.5px) { width: 190px; }
	}
	.ui-widget.ui-widget-content {
		@media screen and (min-width:  767.5px) and (max-width:860px) { width: 150px; }
	}
}