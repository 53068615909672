@charset "utf-8";
/* ============================================================ */
/* facility_access */
/* ============================================================ */
.facility_access {
	.school-title {
		margin: 0 0 30px;
		line-height: 1.7;
		font-size: $xxl;
		&__label {
			font-weight: $font-regular;
			font-size: 1.5rem;
		}
	}
	.root {
		margin: 80px 0 40px 0;
		&__list {
			display: flex;
			flex-wrap: wrap;
			&__item {
				width: calc((100% - 141px) / 3);
				margin: 0 70px 0px 0;
				position: relative;
				&:nth-child(3),
				&:last-child {
					margin: 0;
					&::after {
						display: none;
					}
				}
				&::after {
					@include centering-elements(false, true);
					bottom: -25px;
					right: -40px;
					content: "\f0da";
					font-family: 'icomoon';
					color: $baseColor;
					font-size: 4.0rem;
				}
				& > img {
					width: 100%;
					margin: 0 0 10px;
				}
				&__text {
					height: 130px;
				}
				.ttl08 {
					margin: 0 0 5px;
				}
			}
		}
	}
	.row__inner-white {
		border-radius: 0;
	}
}
