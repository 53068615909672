@charset "utf-8";
/* ============================================================ */
/* fnav */
/* ============================================================ */
.fnav {
	.inner {
		max-width: 100%;
	}
	&__title {
		width: 100%;
		background: #D8EEF1;
		border-bottom: 1px solid $white;
		position: relative;
		margin: 0;
		&__link {
			display: block;
			padding: 10px 15px;
			color: $black;
			text-decoration: none;
		}
		
		&--sp {
			background: #F6FAFB;
			border-bottom: 1px solid #CEE5E8;
			position: relative;
			.fnav__title__link {
				display: block;
				padding: 10px 15px 10px 25px;
				color: #484848;
				text-decoration: none;
			}
			[class^="icon"] {
				@include centering-elements(false, true);
				left: 15px;
				display: block;
			}
		}
		&--accordion {
			color: $black;
			padding: 10px 15px;
			&.active {
				.icon-angle-down::before {
					display: block;
					transform: rotate(180deg);
				}
			}
		}
	}
	&__dropdown {
		display: none;
	}
	&__sub-title {
		background: #F6FAFB;
		border-bottom: 1px solid #CEE5E8;
		position: relative;
		&__link {
			display: block;
			padding: 10px 15px 10px 25px;
			color: #484848;
			text-decoration: none;
			[class^="icon"] {
				@include centering-elements(false, true);
				left: 15px;
				display: block;
			}
		}
		&--under {
			.fnav__sub-title__link {
				&::before {
					content: "-";
					margin: 0 5px 0 0;
				}
			}
		}
	}
}
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	text-align: center;
	line-height: 1.6;
	padding: 0 0 90px;

	.footer-logo {
		margin: 40px 0 25px;
		
		&__link {
			display: block;
			width: 300px;
			margin: 0 auto 15px;
		}
		
		&__sub {
			font-size: $xs;
			color: #717171;
		}
	}
	.footer__address {
		color: #484848;
		margin: 0 0 25px;
		font-size: $s;
	}
	.footer__sns {
		display: flex;
		justify-content: center;
		margin: 0 0 40px;
		&__item {
			margin: 0 5px;
			&__link {
				text-decoration: none;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $baseColor;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $xl;
				transition: 0.3s all;
				.icon-line {
					font-size: $xxl;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.copyright {
		padding: 20px 0;
		font-size: $s;
		color: #777777;
		border-top: 1px solid #CEE5E8;
	}
}