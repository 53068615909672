@charset "utf-8";
/* ============================================================ */
/* admission */
/* ============================================================ */
.admission {
	.box__head {
		background: #006281;
		color: $white;
		padding: 7px 15px;
	}
	.ad-block {
		margin: 0 0 40px;
	}
	.ttl09 {
		&__small {
			font-size: 1.5rem;
			padding-left: 10px;
		}
	}
	.ad-flow {
		margin: 100px 0 50px 0;
		&__title {
			margin: 0 0 25px;
			padding: 0 0 10px;
			border-bottom: 1px solid $baseColor;
			color: $black;
			font-size: $l;
			font-weight: $font-medium;
			line-height: 1.4;
			&__num {
				color: $baseColor;
				font-size: $xxl;
				padding: 0 5px 0 0;
			}
		}
		&__btn {
			margin: 30px 0 0 0;
		}
	}
	.admission-ttl01 {
		background: #006281;
		color: $white;
		font-size: $l;
		padding: 7px 15px;
		margin: 0 0 30px;
		font-weight: $font-medium;
	}
	.table--tuition {
		thead {
			th {
				background: #006281;
				border: 1px solid $white;
				color: $white;
				text-align: center;
				&.season {
					border-bottom: 1px dashed $white!important;
				}
				&.date {
					border-top: none;
				}
				&.bunkatsu-season {
					border-bottom: none;
				}
			}
		}
		tbody {
			th,td {
				border: 1px solid $borderColor;
				
				.note {
					margin-left: 5px;
					font-size: $xxs;
				}
				.note02 {
					position: relative;
					&__item {
						position: absolute;
						top: -10px;
						left: 0;
						font-size: $xxs;
					}
				}
			}
			th {
				&.item {
					font-weight: $font-medium;
					text-align: center;
					.item__text {
						writing-mode: vertical-rl;
					}
				}
			}
			td {
				text-align: right;
			}
		}
	}
}

/* ============================================================ */
/* admission_index */
/* ============================================================ */
#admission_index {
    .department-type {
		margin-bottom: 20px;
		&__item {
			&__img {
				position: relative;
				padding: 0 0 60px;
				margin-bottom: 30px;
				&__text {
					padding: 25px 30px;
					width: 70%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $white;
					&__title {
						font-size: 2.5rem;
					}
				}
			}
			.catch01 {
				font-weight: $font-regular;
				margin: 0 0 15px;
			}
			&--kodomo {
				.department-type__item__img__text {
					background: rgba($orange,0.9);
				}
				.catch01 {
					color: $orange;
				}
			}
			&--yoji {
				.department-type__item__img__text {
					background: rgba($pink,0.9);
				}
				.catch01 {
					color: $pink;
				}
			}
		}
	}
	.ttl07 {
		font-size: $xxl;
		&__small {
			font-size: $m;
			padding: 0 0 0 10px;
		}
	}
	.recruitment-idx,
	.type-idx,
	.tuition-idx {
		margin-bottom: 30px;
	}
	.recruitment-idx {
		&__item {
			margin: 0 0 60px;
			.box {
				margin: 0 0 30px;
				.list {
					margin: 0 0 15px;
				}
			}
		}
	}
	.type-idx {
        &__ico {
            &__item {
                text-align: center;
                img {
                    max-width: 180px;
                    @media screen and (min-width:  767.5px) and (max-width:1024px) {
                        max-width: 150px;
                    }
                }
                .btn {
                    margin: 30px 0 0 0;
                    &.btn--md02 {
                        @media screen and (min-width:  767.5px) and (max-width:1024px) {
                            min-width: inherit;
                            padding-right: 40px;
                        }
                    }
                }
            }
        }
        &__pdf {
            border: 1px solid $baseColor;
            border-left: 7px solid $baseColor;
            font-weight: $font-medium;
            font-size: $xl;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 20px;
            margin: 25px 0 0 0;
            &__dl {
                font-size: $m;
                flex: 0 0 auto;
                white-space: nowrap;
                margin: 0 0 0 30px;
                .__ico {
                    color: $baseColor;
                    margin: 0 0 0 5px;
                }
            }
        }
		
	}
	.tuition-idx {
		&__item {
			margin-bottom: 60px;
			&__box {
				display: flex;
				height: 100%;
				flex-direction: column;
				position: relative;
                padding-bottom: 0;
                .icon-angle-right {
                    margin: 0 0 0 5px;
                }
			}
		}
	}
	.support-idx {
		&__item {
			margin: 0 0 30px;
			.btn {
				margin: 30px 0 0;
			}
			.icon-angle-right {
				margin: 0 0 0 5px;
			}
		}
	}
}

/* ============================================================ */
/* admission_ao,admission_suisen,admission_ippan */
/* ============================================================ */
.admission_ao,
.admission_suisen,
.admission_ippan {
	.policy {
		padding: 40px 70px;
		margin: 0 0 40px;
		.ttl03 {
			font-size: $xxl;
		}
		&__item {
			display: flex;
			&__num {
				flex: 0 0 30px;
				background: $baseColor;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $l;
				font-weight: $font-medium;
				color: $white;
				margin: 0 10px 10px 0;
			}
		}
	}
	.table {
		th {
			text-align: center;
			font-weight: $font-medium;
		}
	}
	.ad-lnav{
		border-top: 1px solid #CEE5E8;
	}
}
/* ============================================================ */
/* admission_ao_form,admission_ippan_form,admission_suisen_form */
/* ============================================================ */
.admission_ao_form,
.admission_ippan_form,
.admission_suisen_form {
	.table {
		th {
			text-align: left;
			font-weight: $font-regular;
		}
	}
    .pdf-dl {
        display: flex;
        justify-content: center;
        align-items: center;
        &__item {
            margin: 0 15px;
        }
    }
}

/* ============================================================ */
/* admission_support */
/* ============================================================ */
.admission_tuition,
.admission_support {
	.lnav {
		padding: 20px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 20px 5px; }
		&__item {
			&:nth-child(3),
			&:nth-child(6) {
				.lnav__item__link__text {
                    &::after {
					    display: none;
                    }
				}
			}
			&__link {
				text-align: left;
				padding: 10px 10px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 10px 0 10px 5px; }
			}
		}
	}
}
.admission_support {
    .challenge-flow {
		margin: 0 0 60px;
		display: flex;
		&__item {
			margin: 0 50px 0 0;
			width: calc((100% - 101px) / 3);
			border: 2px solid $baseColor;
			color: $baseColor;
			display: flex;
			align-items: center;
			padding: 15px;
			font-weight: $font-medium;
			position: relative;
			font-size: 1.5rem;
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				margin: 0 30px 0 0;
				width: calc((100% - 61px) / 3);
				padding: 10px;
			}
			&::after {
				@include centering-elements(false, true);
				right: -37px;
				content: "\e909";
				font-family: 'icomoon';
				color: $black;
				font-size: 3.2rem;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					right: -23px;
					font-size: 2.4rem;
				}
			}
			&:last-child {
				margin: 0;
				&::after {
					display: none;
				}
			}
			&__label {
				font-size: 3.2rem;
				padding: 0 10px 0 0;
				margin: 0 10px 0 0;
				border-right: 2px solid $baseColor;
			}
		}
	}
    .challenge-price {
        .icon-arrow-circle-right {
            margin: 0 15px;
        }
	}
}
/* ============================================================ */
/* admission_tuition */
/* ============================================================ */
.admission_tuition {	
	.admission-bnr {
		width: 270px;
	}
    .kyufu-ex {
        display: flex;
        .__label {
            background: $baseColor;
            color: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            margin: 0 15px 0 0;
        }
    }
    .lone-flow {
		margin: 0 0 40px;
		.box__head {
			background: $baseColor;
			font-size: $l;
			text-align: center;
			font-weight: $font-medium;
		}
		.box--border-base {
			border-width: 2px;
			text-align: center;
		}
		&__body {
			display: flex;
			justify-content: center;
			margin: 0 0 20px;
			&__icon {
				padding: 0 30px;
			}
			& > * {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}

/* ============================================================ */
/* admission_support */
/* ============================================================ */
.admission_support {
	.ad-contact {
		font-weight: $font-medium;
		font-size: $l;
		&__tel {
			color: $baseColor;
		}
		&__num {
			font-size: $xxxl;
		}
	}
	
}

/* ============================================================ */
/* admission_graduate */
/* ============================================================ */
.admission_graduate {
	.student-list {
		justify-content: center;
		&__item {
			border-radius: 15px;
			padding: 40px 25px;
			background: $sky;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 30px 15px; }
			&__data {
				&__img {
					width: 145px;
					text-align: center;
					margin: 0 auto 10px;
					& > img {
						border-radius: 50%;
						width: 100%;
					}
				}
				&__text {
					&__label {
						color: $white;
						padding: 0 5px;
						height: 25px;
						line-height: 25px;
						font-size: 1.5rem;
						background: $baseColor;
					}
					&__name {
						font-size: $xxl;
						font-weight: $font-medium;
					}
				}
			}
			&__catch {
				font-size: $l;
				font-weight: $font-medium;
				margin: 0 0 20px;
				color: $baseColor;
			}
			.btn--md02 {
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 170px; }
			}
		}
	}
	.row__inner-white {
		margin-bottom: 40px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px 30px; }
	}
	.ttl01 {
		&__text {
			z-index: -1;
			color: $white;
			height: 1px;
		}
	}
	.student-data {
		display: flex;
		margin: 0 0 30px;
		align-items: center;
		&__img {
			flex: 0 0 185px;
			margin: 0 30px 0 0;
			& > img {
				border-radius: 50%;
				width: 100%;
			}
		}
		&__text {
			flex: 1 1 auto;
			&__label {
				color: $white;
				padding: 0 5px;
				height: 25px;
				min-width: 110px;
				line-height: 25px;
				font-size: 1.5rem;
				background: $baseColor;
			}
			&__name {
				font-size: $xxl;
				font-weight: $font-medium;
				display: flex;
				align-items: flex-end;
				&__label {
					font-size: 1.5rem;
					font-weight: $font-regular;
					padding: 0 15px 0 0;
				}
				&__type {
					color: #484848;
					font-size: 1.5rem;
					padding: 0 0 0 15px;
					font-weight: $font-regular;
				}
			}
			&__catch {
				font-size: $xxl;
				font-weight: $font-medium;
				padding: 0 0 10px;
				border-bottom: 1px solid $baseColor;
				color: $baseColor;
			}
			.__school {
				font-size: 1.5rem;
			}
		}
	}
	.box {
		margin: 0 0 50px;
	}
	.student-block {
		margin: 0 0 50px;
		.student-ttl {
			border-bottom: 3px dotted #666666;
			padding: 0 0 15px;
			margin: 0 0 30px;
		}
		.question {
			font-size: $l;
			font-weight: $font-medium;
			display: flex;
			align-items: center;
			margin: 0 0 15px;
			&__label {
				flex: 0 0 40px;
				margin: 0 15px 0 0;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				font-weight: $font-regular;
				font-size: $m;
				background: $baseColor;
			}
		}
	}
}