@charset "utf-8";

@import url(//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap);
@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_opencampus";
	@import "pc/_feature";
	@import "pc/_admission";
	@import "pc/_department";
	@import "pc/_guide";
	@import "pc/_facility";
	@import "pc/_target";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_opencampus";
	@import "sp/_feature";
	@import "sp/_admission";
	@import "sp/_department";
	@import "sp/_guide";
	@import "sp/_facility";
	@import "sp/_target";
}
@import "_utility";



