@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual {
		position: relative;
		width: 100%;
		margin: 90px 0 0;
		.index-h1 {
			z-index: -1;
			color: $white;
			height: 1px;
		}
	}
	.index-ttl01 {
		text-align: center;
		margin: 0 0 30px;
		&__en {
			font-size: 3.2rem;
			font-weight: $font-black;
			line-height: 1.0;
			margin: 0 0 10px;
		}
		&__ja {
			display: inline-block;
			color: $baseColor;
			font-size: 1.5rem;
			font-weight: $font-regular;
			border-bottom: 1px dashed $baseColor;
			position: relative;
			padding: 0 5px 0 0;
			.icon-pen {
				position: absolute;
				bottom: 0;
				right: -10px;
			}
		}
	}
	.index-ttl02 {
		text-align: center;
		margin: 0 0 25px;
		&::before {
			background: url(/common/img/common/ttl-icon.svg) no-repeat;
			width: 83px;
			height: 50px;
			content: "";
			display: block;
			margin: 0 auto;
		}
		&__text {
			font-size: $m;
			&::before {
				line-height: 1.3;
				content: attr(data-text);
				display: block;
				font-size: 3.8rem;
				font-weight: $font-black;
			}
		}
	}
	.index-ttl03 {
		font-size: $xxl;
		font-weight: $font-medium;
		line-height: 1.5;
		text-align: center;
		margin: 0 0 30px;
		&__em {
			color: $baseColor;
		}
	}
	.index-inner {
		max-width: 100%;
		margin: 0 auto;
		padding: 0 10px;
	}
	.topic {
		padding-bottom: 40px;
		&__list {
			margin: 0 -2px;
			&__item {
				margin-left: 2px;
				margin-right: 2px;
				text-decoration: none;
				display: block;
				margin-bottom: 0;
				&__img {
					width: 100%;
					height: 280px;
					overflow: hidden;
					position: relative;
					& > img {
						width: auto;
						height: 100%;
						@include centering-elements(true, true);
					}
				}
				&__box {
					background: $white;
					position: relative;
					top: -25px;
					width: 90%;
					margin: 0 auto;
					box-shadow: 0px 0px 4px 0px #e8e8e8;
					padding: 35px 25px 30px;
					height: 135px;
					&__label {
						min-width: 60%;
						position: absolute;
						left: 0;
						top: 0;
						display: block;
						margin: 0;
					}
				}
			}
			.slick-dots {
				position: inherit;
				padding: 0 0 20px;
			}
		}
	}
	.entry {
		background: url(/common/img/common/bg_pattern.svg) center center repeat;
		.index-ttl01 {
			text-align: center;
			margin: 0 0 20px;
			&__ja {
				display: inline-block;
			}
		}
		&__inner {
			max-width: 80%;
			margin: 0 auto;
		}
		&__item {
			padding: 6px;
			text-decoration: none;
			&--ao {
				background: url(/common/img/index/bnr_entry_ao.png) center center no-repeat;
				background-size: cover;
			}
			&--suisen {
				background: url(/common/img/index/bnr_entry_suisen.png) center center no-repeat;
				background-size: cover;
			}
			&--general {
				background: url(/common/img/index/bnr_entry_general.png) center center no-repeat;
				background-size: cover;
			}
			&__box {
				position: relative;
				padding: 0;

				&::before,
				&::after,
				&__inner::before,
				&__inner::after {
					content:'';
					width: 15px;
					height: 15px;
					position: absolute;
					display: inline-block;
				}

				&::before {
					border-left: solid 1px #ccc;
					border-bottom: solid 1px #ccc;
					bottom: -1px;
					left: -1px;
				}

				&::after{
					border-right: solid 1px #ccc;
					border-bottom: solid 1px #ccc;
					bottom: -1px;
					right: -1px;
				}

				&__inner{
					padding: 15px 25px;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					color: $white;
					&::before {
						border-left: solid 1px #ccc;
						border-top: solid 1px #ccc;
						top: -1px;
						left: -1px;
					}

					&::after{
						border-right: solid 1px #ccc;
						border-top: solid 1px #ccc;
						top: -1px;
						right: -1px;
					}
				}
				&__title {
					font-size: $xl;
					line-height: 1.5;
					text-align: center;
					font-weight: $font-medium;
					margin: 0 0 5px;
				}
			}
		}
	}
	.about {
		background: url(/common/img/index/bg_about_01_sp.png) center center repeat-y;
		&__department {
			&__item {
				text-decoration: none;
				position: relative;
				padding: 0 0 110px;
				margin: 0 0 60px;
				&__text {
					padding: 0 15px 15px;
					width: 85%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $white;
					&__en {
						height: 11px;
						width: auto;
					}
					&__title {
						font-size: 2.8rem;
						position: relative;
						padding: 0 0 10px;
						margin: 0 0 15px;
						&::after {
							content: "";
							@include centering-elements(true, false);
							bottom: 0;
							width: 50px;
						}
					}
					&--yoji {
						background: rgba($pink,0.9);
						.about__department__item__text__title::after {
							border-bottom: 1px solid #FFAABD;
						}
					}
					&--kodomo {
						background: rgba($orange,0.9);
						.about__department__item__text__title::after {
							border-bottom: 1px solid #F9C366;
						}
					}
				}
			}
		}
		&__feature {
			padding: 0 10px;
			&__item {
				text-decoration: none;
				position: relative;
				padding: 0 0 40px;
				&__img {
					width: 65%;
				}
				&__text {
					padding: 20px 5px;
					width: 50%;
					margin: 0 auto;
					position: absolute;
					top: 15px;
					right: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $baseColor;
					background: $white;
					height: calc(100% - 55px);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					
					&__title {
						font-size: $xl;
						position: relative;
						padding: 10px 0 0;
						color: $textColor;
						&::before {
							content: "";
							@include centering-elements(true, false);
							top: 0;
							border: 1px solid $baseColor;
							width: 55px;
						}
					}
				}
			}
		}
	}
	.recommended {
		background-color: $baseColorLight;
		background-image: url(/common/img/index/pic_reco_left_01.svg),url(/common/img/index/pic_reco_right_01.svg),url(/common/img/index/bg_reco_left_01.svg),url(/common/img/index/bg_reco_right_01.svg);
		background-position: left 3%, right 3%, left top, right top;
		background-size: 25% auto, 25% auto, 40% auto, 40% auto;
		background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
		padding: 0 20px;
		&__item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: 0 0 60px;
			
			&__img {
				margin: 0 0 25px;
				min-height: 0.1px
			}
			&__text {
				background: $white;
				box-shadow: 6px 6px 0px 3px #FCFF97;
				padding: 15px 15px 30px;
				display: block;
				text-align: center;
				text-decoration: none;
				color: $textColor;
				position: relative;
				flex: 1 1 auto;
				&__label {
					width: auto;
					max-width: inherit;
					margin: 0 0 10px;
				}
				&__title {
					color: $baseColor;
					font-weight: $font-medium;
					font-size: $xxl;
					line-height: 1.0;
					margin: 0 0 15px;
				}
				.icon-chevron-circle-right {
					@include centering-elements(true, false);
					bottom: 10px;
					color: $baseColor;
					font-size: $xxl;
				}
			}
		}
	}
	.access {
		.row__inner {
			position: relative;
			&::after {
				position: absolute;
				left: 10px;
				bottom: -40px;
				content: "";
				background: url(/common/img/index/pic_f-access_01.svg) no-repeat;
				width: 140px;
				height: 73px;
			}
		}
		&__contact {
			padding: 20px 0;
			text-align: center;
			&__sns {
				display: flex;
				justify-content: center;
				&__item {
					margin: 0 10px 0 0;
					&__link {
						text-decoration: none;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background: $baseColor;
						color: $white;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: $xl;
						transition: 0.3s all;
						.icon-line {
							font-size: $xxl;
						}
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
		&__map {
			padding-bottom: 40px;
		}
	}
}