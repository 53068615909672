@charset "utf-8";
/* ============================================================ */
/* department */
/* ============================================================ */
.department {
	.ttl02 {
		&__small {
			display: block;
			font-size: $xxl;
		}
	}
	.banner {
		border-top: none;
	}
	.comment {
		display: flex;
		&__img {
			flex: 0 0 270px;
			padding: 0 0 0 35px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.__img {
			margin: 0 0 -25px;
			max-width: 100%;
		}
	}
	.question {
		font-size: $l;
		font-weight: $font-medium;
		display: flex;
		align-items: center;
		margin: 0 0 15px;
		&__label {
			flex: 0 0 40px;
			margin: 0 15px 0 0;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			font-weight: $font-regular;
			font-size: $m;
		}
		&.kodomo .question__label {
			background: $orange;
		}
	}
}
/* ============================================================ */
/* department_index */
/* ============================================================ */
#department_index {
	.department-type {
		margin-bottom: 20px;
		&__item {
			&__img {
				position: relative;
				padding: 0 0 60px;
				margin-bottom: 30px;
				&__text {
					padding: 25px 30px;
					width: 70%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $white;
					&__title {
						font-size: 2.5rem;
					}
				}
			}
			.catch01 {
				font-weight: $font-regular;
				margin: 0 0 30px;
			}
			&__point {
				box-shadow: 0px 0px 6px 3px #f3f3f3;
				padding: 20px 30px 50px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 10px 20px 40px; }
				&__list {
					margin: 0 0 20px;
					&__item {
						padding: 15px 0;
						border-bottom-style: dashed;
						border-bottom-width: 1px;
						display: flex;
						align-items: center;
						font-size: $l;
						font-weight: $font-medium;
						color: $black;
						&__icon {
							width: 70px;
							height: 70px;
							border-radius: 50%;
							flex: 0 0 70px;
							margin: 0 20px 0 0;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 2.9rem;
							line-height: 1.0;
							color: $white;
							flex-direction: column;
							@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 60px;height: 60px;flex: 0 0 60px;margin: 0 15px 0 0; }
							&__label {
								font-size: 1.0rem;
							}
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
			&--kodomo {
				.department-type__item__img__text {
					background: rgba($orange,0.9);
				}
				.catch01 {
					color: $orange;
				}
				.department-type__item__point__list__item {
					border-bottom-color: $orange;
					&__icon {
						background: $orange;
					}
				}
			}
			&--yoji {
				.department-type__item__img__text {
					background: rgba($pink,0.9);
				}
				.catch01 {
					color: $pink;
				}
				.department-type__item__point__list__item {
					border-bottom-color: $pink;
					&__icon {
						background: $pink;
					}
					a {
						color: $pink;
					}
				}
			}
		}
	}
    .triple,
	.dual {
        margin: 30px 0 0 0;
		padding: 30px;
		&__title {
			font-size: 2.8rem;
			line-height: 1.0;
			font-weight: $font-medium;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 30px;
			.__label {
				background: $pink;
				border-radius: 18px;
				color: $white;
				font-size: 1.7rem;
				position: relative;
				height: 35px;
				padding: 0 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 20px 0 0;
                white-space: nowrap;
				&::before{
					content: "";
					position: absolute;
					bottom: 7px; 
					right: -14px;
					border: 6px solid transparent;
					border-left: 18px solid $pink;
					transform: rotate(-15deg);
				}
			}
		}
		.__text {
			color: $pink;
			.icon-square {
				margin: 0 10px 0 0;
			}
		}
	}
    .triple {
        .__label {
            background: $orange;
            &::before{
                border-left: 18px solid $orange;
            }
        }
        .__text {
			color: $orange;
        }
    }
    .support {
        &__ttl {
            background: #7378B7;
            color: $white;
            font-weight: $font-medium;
            margin: 0 0 30px;
            font-size: $xl;
            padding: 10px 20px;
        }
        &__item {
            margin-bottom: 30px;
            img {
                margin: 0 0 15px;
            }
        }
        &__ttl02 {
            font-weight: $font-medium;
            font-size: $xl;
            color: #7378B7;
            margin: 0 0 15px;
            display: flex;
            align-items: center;
            line-height: 1.5;
            .__label {
                background: #7378B7;
                color: $white;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $xl;
                margin: 0 10px 0 0;
                flex: 0 0 40px;
            }
        }
        &__list {
            border: 1px solid #7378B7;
            border-radius: 20px;
            padding: 20px;
            width: 100%;
            height: 100%;
            &::after {
                display: block;
                content: "";
                clear: both;
            }
            &__img {
                float: right;
                margin: 0 0 15px 15px;
                max-width: 30%;
                img {
                    max-width: 100%;
                }
            }
        }
        .row__inner-white {
            @media screen and (min-width:  767.5px) and (max-width:1024px) {padding: 40px 30px; }
        }
    }
    .ttl04 {
		&--index {
			&::after {
				border-bottom: 2px solid #7378B7;
			}
		}
    }
}

/* ============================================================ */
/* department_kodomo,.department_yoji */
/* ============================================================ */
.department_kodomo,
.department_yoji {
	.catch01 {
		&--kodomo {
			color: $orange;
		}
		&--yoji {
			color: $pink;
		}
	}
	.ttl04 {
		&--kodomo {
			&::after {
				border-bottom: 2px solid $orange;
			}
		}
		&--yoji {
			&::after {
				border-bottom: 2px solid $pink;
			}
		}
	}
	.ttl06 {
		&--kodomo {
			border-bottom: 1px solid $orange;
			&:first-letter {
				color: $orange;
			}
		}
		&--yoji {
			border-bottom: 1px solid $pink;
			&:first-letter {
				color: $pink;
			}
		}
	}
	.ttl07 {
		&--kodomo {
			border-left: 6px solid $orange;
		}
		&--yoji {
			border-left: 6px solid $pink;
		}
	}
	.department-about {
		align-items: center;
		margin: 0 0 40px;
		&__guide {
			margin-left: 40px;
			flex: 1 1 auto;
			&__item {
				border-bottom-width: 1px;
				border-bottom-style: dotted;
				padding: 15px 0;
				display: flex;
				&:first-child {
					border-top-width: 1px;
					border-top-style: dotted;
				}
				&__label {
					border-right-width: 4px;
					border-right-style: solid;
					display: flex;
					align-items: center;
					justify-content: center;
					padding-right: 25px;
					margin-right: 25px;
					flex: 0 0 110px;
					font-weight: $font-medium;
				}
			}
			&--kodomo {
				.department-about__guide__item {
					border-bottom-color: $orange;
					&:first-child {
						border-top-color: $orange;
					}
					&__label {
						border-right-color: $orange;
					}
				}
			}
			&--yoji {
				.department-about__guide__item {
					border-bottom-color: $pink;
					&:first-child {
						border-top-color: $pink;
					}
					&__label {
						border-right-color: $pink;
					}
				}
			}
		}
	}
	.department-point {
		@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-left: -10px;margin-right: -10px; }
		&> * {
			@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-left: 10px;margin-right: 10px;width: calc((100% - 61px) / 3); }
		}
		&__item {
			border-radius: 15px;
			padding: 20px;
			text-align: center;
			font-weight: $font-medium;
			&__title {
				border-bottom-width: 1px;
				border-bottom-style: dotted;
				font-size: $xxl;
				padding: 0 0 5px 0;
				margin: 0 0 10px;
			}
		}
		&--kodomo {
			.department-point__item {
				background: #FFFCEF;
				&__title {
					color: $orange;
					border-bottom-color: $orange;
				}
			}
		}
		&--yoji {
			.department-point__item {
				background: #FFF6F8;
				&__title {
					color: $pink;
					border-bottom-color: $pink;
				}
			}
		}
	}
	.department-shikaku {
		margin: 0 0 50px;
		.department-shikaku__block {
			margin: 0 0 30px;
			.ttl08 {
				margin: 0 0 5px;
			}
			&:last-child {
				margin: 0;
			}
		}
		&:last-child {
			margin: 0;
		}
	}
	.table--department {
		th,td {
			text-align: center;
		}
		thead th {
			color: $white;
		}
		&--kodomo {
			td {
				background: $yellowLight;
			}
			th:first-child {
				background: #FFF5C9;
			}
			thead th {
				background: $orange;
				&:first-child {
					background: $orange;
				}
			}
		}
		&--yoji {
			td {
				background: #FFF9FB;
			}
			th:first-child {
				background: #FFE4EA;
			}
			thead th {
				background: $pink;
				&:first-child {
					background: $pink;
				}
			}
		}
	}
	.department-voice {
		&__title {
			text-align: center;
			margin: 0 0 40px;
		}
		&__question {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $xl;
			font-weight: $font-medium;
			margin: 0 0 30px;
			&__label {
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				width: 55px;
				height: 55px;
				flex: 0 0 55px;
				border-radius: 50%;
				font-size: $xxxl;
				margin: 0 10px 0 0;
				&--kodomo {
					background: $orange;
				}
				&--yoji {
					background: $pink;
				}
			}
		}
		&__img {
			margin: 0 auto 40px;
            max-width: 550px;
			img {
				width: 100%;
			}
		}
		&__item {
			margin-bottom: 40px;
			&--kodomo {
				background: #FFF5EF;
			}
			&--yoji {
				background: #FFF6F8;
			}
			&__img {
				margin: 0 0 15px;
				text-align: center;
				img {
					width: 138px;
				}
			}
			.ttl08 {
				text-align: center;
				&__small {
					font-size: 1.5rem;
					padding: 0 10px 0 0;
				}
			}
		}
	}
	.contents-wrap {
		&__inner {
			margin-top: 30px;
			position: relative;
			padding: 70px 50px 60px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) {padding: 70px 20px 40px; }
			&::before {
				width: 135px;
				height: 167px;
				content: "";
				position: absolute;
				top: -30px;
				left: 120px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {left: 40px;width: 106px;height: 131px; }
			}
			.row__inner-white {
				border-radius: 0;
				padding: 60px 70px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {padding: 40px 30px; }
			}
		}
		&__merit {
			&__item {
				margin: 0 0 30px;
				display: table;
				& > *:first-child {
					display: table-cell;
					white-space: nowrap;
					padding: 0 10px 0 0;
					.__num {
						font-weight: $font-medium;
						color: $white;
						content: attr(data-text);
						background: $pink;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						flex: 0 0 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: $xl;
					}
				}
				.__txt {
					font-weight: $font-medium;
					font-size: 2.6rem;
					line-height: 1.5;
				}
			}
		}
		&.dual {
			.contents-wrap__inner::before {
				background: url(/common/img/department/yoji/ico_dual.svg) no-repeat;
				background-size: contain;
			}
			.contents-wrap__merit {
				.__num {
					background: $pink;
				}
				.__txt {
					 color: $pink;
				}
			}
		}
		&.primaryteacher {
			.contents-wrap__inner::before {
				background: url("/common/img/department/kodomo/pic_primaryteacher_01.svg") no-repeat;
				background-size: contain;
			}
			.contents-wrap__merit {
				.__num {
					background: $orange;
				}
				.__txt {
					 color: $orange;
				}
			}
		}
	}
}

/* ============================================================ */
/* .department_yoji */
/* ============================================================ */
.department_yoji {
	.dual {
		.table--department--yoji {
			th,td {
				font-size: 1.5rem;
			}
			td.bg-white {
				background: $white;
			}
            td.bg-yellowlight {
                background: #FFE98F;
            }
		}
		&__flow {
			margin: 0 0 30px;
			&__item {
				margin-bottom: 40px;
				&:not(:last-child) {
					&::after {
						content: "";
						background: url(/common/img/department/yoji/ico_dual_yajirushi.svg);
						background-size: cover;
						width: 40px;
						height: 32px;
						margin: 0 auto;
					}
				}

			}
		}
        &__voice-box {
            display: flex;
            &__img {
                flex: 0 0 270px;
                padding: 0 0 0 35px;
            }
            .__img {
                margin: 0 0 -25px;
                max-width: 100%;
            }
        }
	}
}

/* ============================================================ */
/* department_kodomo */
/* ============================================================ */
.department_kodomo {
	.primaryteacher {
		&__list {
			display: flex;
			&__item {
				margin: 0 20px 0 0;
				.__label {
					position: relative;
					display: inline-block;
					width: 100px;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 13px 0 0;
					border-radius: 50%;
					background-color: #f7b52c;
					color: $white;
					font-weight: $font-medium;
					font-size: $l;
					&::after {
						content: "";
						position: absolute;
						top: 50%;
						right: 3px;
						border-style: solid;
						border-width: 12.5px 0 12.5px 13px;
						border-color: transparent transparent transparent #f7b52c;
						translate: 100% -50%;
					}
				}
			}
		}
	}
}


/* ============================================================ */
/* department_student */
/* ============================================================ */
.department_student {
	.student-list {
		&__item {
			border-radius: 15px;
			padding: 40px 25px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 30px 15px; }
			&__data {
				display: flex;
				margin: 0 0 20px;
				&__img {
					width: 55%;
					padding: 0 15px 0 0;
					& > img {
						border-radius: 50%;
						width: 100%;
					}
				}
				&__text {
					width: 45%;
					&__label {
						color: $white;
						padding: 0 5px;
						height: 25px;
						line-height: 25px;
						font-size: 1.5rem;
					}
					&__name {
						font-size: $xxl;
						font-weight: $font-medium;
					}
				}
			}
			&__catch {
				font-size: $l;
				font-weight: $font-medium;
				margin: 0 0 20px;
			}
			.btn--md02 {
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 170px; }
			}
		}
		&--kodomo {
			.student-list__item {
				background: #FFFCEF;
				&__data {
					&__text {
						&__label {
							background: $orange;
						}
					}
				}
				&__catch {
					color: $orange;
				}
			}
		}
		&--yoji {
			.student-list__item {
				background: #FFF5F6;
				&__data {
					&__text {
						&__label {
							background: $pink;
						}
					}
				}
				&__catch {
					color: $pink;
				}
			}
		}
	}
}
/* ============================================================ */
/* department_student_student */
/* ============================================================ */
.department_student_student {
	.row__inner-white {
		margin-bottom: 40px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px 30px; }
	}
	.ttl01 {
		&__text {
			z-index: -1;
			color: $white;
			height: 1px;
		}
	}
	.student-data {
		display: flex;
		margin: 0 0 30px;
		align-items: center;
		&__img {
			flex: 0 0 185px;
			margin: 0 30px 0 0;
			& > img {
				border-radius: 50%;
				width: 100%;
			}
		}
		&__text {
			flex: 1 1 auto;
			&__label {
				color: $white;
				padding: 0 5px;
				height: 25px;
				min-width: 110px;
				line-height: 25px;
				font-size: 1.5rem;
			}
			&__name {
				font-size: $xxl;
				font-weight: $font-medium;
				display: flex;
				align-items: flex-end;
				&__school {
					font-size: 1.5rem;
					padding: 0 0 0 15px;
					font-weight: $font-regular;
				}
			}
			&__catch {
				font-size: $xxl;
				font-weight: $font-medium;
				padding: 0 0 10px;
				border-bottom-width: 1px;
				border-bottom-style: solid;
			}
		}
	}
	.box {
		margin: 0 0 50px;
	}
	.student-block {
		margin: 0 0 50px;
		.student-ttl {
			border-bottom: 3px dotted #666666;
			padding: 0 0 15px;
			margin: 0 0 30px;
		}
		.float__item {
			& > img {
				width: 265px;
			}
		}
		.table--list {
			th {
				font-weight: $font-medium;
				width: 170px;
				padding: 15px 0 15px 15px;
				vertical-align: top;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 110px; }
			}
			td {
				vertical-align: top;
			}
		}
	}
	.kodomo {
		.student-data__text {
			&__label {
				background: $orange;
			}
			&__catch {
				color: $orange;
				border-bottom-color: $orange;
			}
		}
		.question__label {
			background: $orange;
		}
	}
	.yoji {
		.student-data__text {
			&__label {
				background: $pink;
			}
			&__catch {
				color: $pink;
				border-bottom-color: $pink;
			}
		}
		.question__label {
			background: $pink;
		}
	}
}