@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	background: $white;
	z-index: 10;
	height: 170px;
	width: 100%;
	top: 0;
	.header-wrap {
		position: relative;
		&::before {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			content: '';
			background: rgba(#000, 0.5); 
			opacity: 0;
			visibility: hidden;
			@include transition;
		}
	}

	.header-body {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 5px;
		max-width: 1140px;
		padding: 20px 20px 0;
		margin: 0 auto;
		height: 62px;
		.header-logo {
			top: 10px;
			&__link {
				text-decoration: none;
				transition: 0.3s all;
				display: flex;
				align-items: center;
				&:hover {
					opacity: 0.7;
				}
				&__icon {
					margin: 0 5px 0 0;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 35px;}
				}
				&__logo {
					@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 180px;}
				}
			}
		}
		.header-target {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			&__item {
				font-size: 1.5rem;
				display: flex;
				align-items: center;
				margin: 0 15px 0 0;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { margin: 0 10px 0 0;}
				&__icon {
					color: $baseColor;
					padding: 0 5px 0 0;
				}
				&__link {
					color: #606060;
					text-decoration: none;
					&:hover {
						color: $baseColor;
					}
				}
			}
		}
		.header-menu {
			display: none;
		}
	}
	
	.header-inner {
		padding: 15px 0 0;
		height: 87px;
		background: $white;
		
		&.is-fixed {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background: rgba($white, 0.8);
			border-bottom: 1px solid $borderColor;
			
			.header-search__inner {
				top: 110px;
			}
		}
	}

	.gnav {
		order: 2;
		display: flex;
		max-width: 1140px;
		padding: 0 20px;
		margin: 0 auto;
		
		&__item {
			padding-bottom: 15px;
			width: 100%;
			&:first-child {
				.gnav__item__link__text {
					&::before {
						content: "";
						@include centering-elements(false, true);
						left: 0;
						border-left: 1px solid #DFDFDF;
						height: 28px;
                        line-height: 1.2;
					}
				}
			}
			&__link {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				position: relative;
				width: 100%;
				cursor: pointer;
				&::before {
					position: absolute;
					left: 50%;
					bottom: -8px;
					z-index: 5;
					content: "";
					width: 0;
					height: 5px;
					background: $baseColorDarken;
					backface-visibility: hidden;
					transition: 0.3s all ease;
					transform: translateX(-50%);
				}
				&__text {
					position: relative;
					color: $baseColor;
					font-size: $m;
					text-align: center;
					flex-direction: column;
					display: flex;
					align-items: center;
					padding: 0 0.5em;
					width: 100%;
					height: 80px;
					color: $black;
                    line-height: 1.2;
					&::after {
						content: "";
						@include centering-elements(false, true);
						right: 0;
						border-right: 1px solid #DFDFDF;
						height: 28px;
					}
					&__icon {
						margin: 0 0 10px;
                        height: 27px;
					}
				}
				[class^="icon-"] {
					display: none;
				}
			}
			&:hover {
				.gnav__item__link {
					color: $baseColor;
					text-decoration: none;
					&:before {
						width: 100%;
					}
					&__text {
						opacity: 0.5;
					}
				}
				.gnav__dropdown {
					transform: translateY(0);
					visibility: visible;
					opacity: 1;
					@include transition;
				}
			}
			& > .current {
				&::before {
					position: absolute;
					left: 50%;
					bottom: -17px;
					z-index: 5;
					content: "";
					width: 100%;
					height: 3px;
					background: $baseColor;
				}
			} 
		}

		&__dropdown {
			position: absolute;
			left: 0;
			right: 0;
			z-index: -1;
			top: 169px;
			padding: 30px 0 40px;
			transform: translateY(-50px);
			visibility: hidden;
			opacity: 0;
			transition: 0.1s all;
			background: rgba($baseColor, 0.9);
			
			&__inner {
				max-width: 1140px;
				margin: 0 auto;
				padding: 0 20px;
				&__midashi {
					padding: 0 0 20px;
					color: $white;
					font-size: $xl;
					font-weight: $font-medium;
					&__icon {
						margin: 0 5px 0 0;
					}
					&:hover {
						text-decoration: none;
					}
					&__link {
						color: $white;
					}
				}
				
				&__body {
					&__item {
						font-size: $m;
						color: $white;
						transition: 0.3s all;
						text-decoration: none;
						border-bottom: 1px solid #54D4DF;
						padding: 0 0 10px;
						
						&:hover {
							opacity: 0.7;
						}
						&__img {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 110px;
							margin: 0 0 10px;
							transition: 0.3s all;
							& > * {
								width: auto!important;
								max-width: inherit;
							}
						}
						&__text {
							position: relative;
							.icon-fix-right {
								right: 0;
							}
						}
					}
				}
			}
			&--feature,
			&--admission {
				.gnav__dropdown__inner__body__item__img {
					background: rgba(255,255,255,0.4);
				}
			}
			&--department,
			&--sns {
				.gnav__dropdown__inner__body__item__img {
					&> img {
						width: 100%!important;
						max-width: 100%;
					}
				}
			}
		}
	}
}