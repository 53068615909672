@charset "utf-8";
/* ============================================================ */
/* guide */
/* ============================================================ */

/* ============================================================ */
/* guide_index */
/* ============================================================ */
#guide_index {
	.greeting {
		padding: 0;
		&__img {
			width: 100%;
			margin: 0 0 15px;
		}
		.ttl07 {
			font-size: $l;
			margin: 0 0 20px;
			&__name {
				font-size: 2.8rem;
			}
		}
	}
	.ability-list {
		&__item {
			text-align: center;
			position: relative;
			border: 2px dashed $baseColor;
			border-radius: 10px;
			padding: 40px 20px 30px;
			text-align: center;
			margin: 0 0 50px;
			&__label {
				@include centering-elements(true, false);
				top: -25px;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				color: $white;
				font-size: 2.5rem;
				font-weight: $font-medium;
				display: flex;
				justify-content: center;
				align-items: center;
				background: $baseColor;
			}
			&__title {
				color: $baseColor;
				font-size: $xl;
				margin: 0 0 15px;
			}
		}
	}
	.charactor-area {
		align-items: center;
		img {
			width: 75%;
		}
	}
	.history {
		&__item {
			&__year {
				display: block;
				font-weight: $font-medium;
			}
		}
	}
}