@charset "utf-8";
/* ============================================================ */
/* guide */
/* ============================================================ */

/* ============================================================ */
/* guide_index */
/* ============================================================ */
#guide_index {
	.greeting {
		background: url(/common/img/guide/index/pic_head-teacher_01.jpg) center center no-repeat;
		background: cover;
		height: 630px;
		padding: 0;
		display: flex;
		align-items: center;
		.row__inner {
			width: 100%;
			.ttl07 {
				font-size: $l;
				margin: 0 0 40px;
				&__name {
					font-size: 3.2rem;
				}
			}
		}
	}
	.ability-list {
		margin: 0 0 50px;
		&__item {
			text-align: center;
			position: relative;
			border: 2px dashed $baseColor;
			border-radius: 10px;
			padding: 60px 20px 30px;
			text-align: center;
			&__label {
				@include centering-elements(true, false);
				top: -25px;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				color: $white;
				font-size: 2.5rem;
				font-weight: $font-medium;
				display: flex;
				justify-content: center;
				align-items: center;
				background: $baseColor;
			}
			&__title {
				color: $baseColor;
				font-size: $xxl;
				margin: 0 0 15px;
			}
		}
	}
    .pic-message {
        max-width: 440px;
    }
	.professional {
		&__item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	.charactor-area {
		align-items: center;
		img {
			width: 75%;
		}
	}
	.history {
		&__item {
			display: flex;
			&__year {
				flex: 0 0 170px;
				display: block;
				font-weight: $font-medium;
				padding: 0 30px 0 20px;
			}
		}
	}
}