@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual {
		position: relative;
		width: 100%;
		margin: 160px 0 0;
		&__slider {
			&__item:hover {
				img {
					opacity: 1.0;
				}
			}
		}
		.index-h1 {
			z-index: -1;
			color: $white;
			height: 1px;
		}
	}
	
	.index-ttl01 {
		&__en {
			font-size: 3.6rem;
			font-weight: $font-black;
			line-height: 1.0;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: 3.2rem; }
		}
		&__ja {
			color: $baseColor;
			font-size: 1.5rem;
			font-weight: $font-regular;
			border-bottom: 1px dashed $baseColor;
			position: relative;
			padding: 0 5px 0 0;
			.icon-pen {
				position: absolute;
				bottom: 0;
				right: -10px;
			}
		}
	}
	.index-ttl02 {
		text-align: center;
		margin: 0 0 25px;
		&::before {
			background: url(/common/img/common/ttl-icon.svg) no-repeat;
			width: 83px;
			height: 50px;
			content: "";
			display: block;
			margin: 0 auto;
		}
		&__text {
			font-size: $m;
			&::before {
				line-height: 1.3;
				content: attr(data-text);
				display: block;
				font-size: 4.5rem;
				font-weight: $font-black;
			}
		}
	}
	.index-ttl03 {
		font-size: 2.8rem;
		font-weight: $font-medium;
		line-height: 1.5;
		text-align: center;
		margin: 0 0 30px;
		&__em {
			color: $baseColor;
		}
	}
	.index-inner {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 20px;
	}
	
	.topic {
		padding-bottom: 60px;
		.index-ttl01 {
			display: flex;
			align-items: flex-end;
			margin: 0 0 35px;
			&__en {
				margin: 0;
			}
			&__ja {
				margin: 0 0 0 20px;
			}
		}
		&__list {
			margin: 0 -2px 30px;
			&__item {
				margin-left: 2px;
				margin-right: 2px;
				width: calc((100% - 16px) / 4 );
				text-decoration: none;
				transition: 0.3s all;
				display: block;
				margin-bottom: 0;
				&__img {
					width: 100%;
					height: 280px;
					overflow: hidden;
					position: relative;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 200px; }
					& > img {
						width: auto;
						height: 100%;
						@include centering-elements(true, true);
					}
				}
				&__box {
					background: $white;
					position: relative;
					top: -25px;
					width: 90%;
					margin: 0 auto;
					box-shadow: 0px 0px 4px 0px #e8e8e8;
					padding: 50px 25px 30px;
					height: 180px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 45px 10px 20px;height: 150px; }
					&__label {
						min-width: 50%;
						position: absolute;
						left: 0;
						top: 0;
						display: block;
						margin: 0;
					}
					&__title {
						font-size: $m;
					}
				}
				&:hover {
					opacity: 0.7;
				}
			}
			.slick-dots {
				bottom: -30px;
				padding: 10px 0 0;
			}
		}
	}
	.entry {
		background: url(/common/img/common/bg_pattern.svg) center center repeat;
		.index-ttl01 {
			text-align: center;
			margin: 0 0 40px;
			&__ja {
				display: inline-block;
			}
		}
		&__item {
			transition: 0.3s all;
			padding: 6px;
			text-decoration: none;
			&--ao {
				background: url(/common/img/index/bnr_entry_ao.png) center center no-repeat;
				background-size: cover;
			}
			&--suisen {
				background: url(/common/img/index/bnr_entry_suisen.png) center center no-repeat;
				background-size: cover;
			}
			&--general {
				background: url(/common/img/index/bnr_entry_general.png) center center no-repeat;
				background-size: cover;
			}
			&:hover {
				opacity: 0.7;
			}
			&__box {
				position: relative;
				padding: 0;
				height: 100%;
				display: flex;
				width: 100%;
				align-items: center;

				&::before,
				&::after,
				&__inner::before,
				&__inner::after {
					content:'';
					width: 15px;
					height: 15px;
					position: absolute;
					display: inline-block;
				}

				&::before {
					border-left: solid 1px #ccc;
					border-bottom: solid 1px #ccc;
					bottom: -1px;
					left: -1px;
				}

				&::after{
					border-right: solid 1px #ccc;
					border-bottom: solid 1px #ccc;
					bottom: -1px;
					right: -1px;
				}

				&__inner{
					padding: 10px 15px;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					color: $white;
					height: 100%;
					width: 100%;
					&::before {
						border-left: solid 1px #ccc;
						border-top: solid 1px #ccc;
						top: -1px;
						left: -1px;
					}

					&::after{
						border-right: solid 1px #ccc;
						border-top: solid 1px #ccc;
						top: -1px;
						right: -1px;
					}
				}
				&__title {
					font-size: $xl;
					text-align: center;
					line-height: 1.5;
					font-weight: $font-medium;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: $l; }
				}
			}
		}
	}
	.about {
		background: url(/common/img/index/bg_about_01.png) center center repeat;
		background-size: cover;
		&__department {
			margin-bottom: 90px;
			&__item {
				text-decoration: none;
				transition: 0.3s all;
				position: relative;
				padding: 0 0 90px;
				&__text {
					padding: 25px 30px;
					width: 70%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $white;
					&__en {
						height: 18px;
						width: auto;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 10px; }
					}
					&__title {
						font-size: 3.5rem;
						position: relative;
						padding: 0 0 10px;
						margin: 0 0 15px;
						&::after {
							content: "";
							@include centering-elements(true, false);
							bottom: 0;
							width: 50px;
						}
					}
					&--yoji {
						background: rgba($pink,0.9);
						.about__department__item__text__title::after {
							border-bottom: 1px solid #FFAABD;
						}
					}
					&--kodomo {
						background: rgba($orange,0.9);
						.about__department__item__text__title::after {
							border-bottom: 1px solid #F9C366;
						}
					}
				}
				&:hover {
					.about__department__item__text {
						opacity: 0.7;
					}
				}
			}
		}
		&__feature {
			&__item {
				text-decoration: none;
				transition: 0.3s all;
				position: relative;
				padding: 0 0 110px;
				
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					width: 48%;
					padding: 0 0 90px;
				}
				&__text {
					padding: 20px 5px;
					width: 85%;
					margin: 0 auto;
					@include centering-elements(true, false);
					bottom: 0;
					font-weight: $font-medium;
					text-align: center;
					color: $baseColor;
					background: $white;
					height: 200px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) {
						height: inherit;
					}
					
					&__title {
						font-size: $xxl;
						position: relative;
						padding: 10px 0 0;
						color: $textColor;
						&::before {
							content: "";
							@include centering-elements(true, false);
							top: 0;
							border: 1px solid $baseColor;
							width: 55px;
						}
					}
				}
				&:hover {
					.about__feature__item__text {
						opacity: 0.7;
					}
				}
			}
		}
	}
	.recommended {
		background-color: $baseColorLight;
		background-image: url(/common/img/index/pic_reco_left_01.svg),url(/common/img/index/pic_reco_right_01.svg),url(/common/img/index/bg_reco_left_01.svg),url(/common/img/index/bg_reco_right_01.svg);
		background-position: left bottom 10%, right bottom 10%, left 10%, right 10%;
		background-size: 16% auto, 16% auto, 30% auto, 30% auto;
		background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
		&__item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			&__img {
				margin: 0 0 25px;
				min-height: 0.1px
			}
			&__text {
				background: $white;
				box-shadow: 6px 6px 0px 3px #FCFF97;
				padding: 20px 25px 50px;
				display: block;
				text-align: center;
				text-decoration: none;
				color: $textColor;
				position: relative;
				transition: 0.3s all;
				flex: 1 1 auto;
				&__label {
					width: auto;
					max-width: inherit;
					margin: 0 0 15px;
				}
				&__title {
					color: $baseColor;
					font-weight: $font-medium;
					font-size: $xxxl;
					line-height: 1.0;
					margin: 0 0 15px;
				}
				.icon-chevron-circle-right {
					@include centering-elements(true, false);
					bottom: 20px;
					color: $baseColor;
					font-size: $xxl;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	.access {
		.row__inner {
			position: relative;
			&::after {
				position: absolute;
				bottom: -80px;
				left: 0;
				content: "";
				background: url(/common/img/index/pic_f-access_01.svg) no-repeat;
				width: 212px;
				height: 110px;
			}
		}
		&__contact {
			padding: 30px 20px 0 0;
			&__sns {
				display: flex;
				&__item {
					margin: 0 10px 0 0;
					&__link {
						text-decoration: none;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background: $baseColor;
						color: $white;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: $xl;
						transition: 0.3s all;
						.icon-line {
							font-size: $xxl;
						}
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
}