@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?j6mcs2');
  src:  url('/common/icon/fonts/icomoon.eot?j6mcs2#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?j6mcs2') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?j6mcs2') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?j6mcs2') format('woff'),
    url('/common/icon/fonts/icomoon.svg?j6mcs2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-x:before {
  content: "\e910";
}
.icon-kira:before {
  content: "\e90f";
}
.icon-ico_tiktok:before {
  content: "\e90e";
}
.icon-ico-sns:before {
  content: "\e90d";
}
.icon-facility:before {
  content: "\e90c";
}
.icon-access:before {
  content: "\e90b";
}
.icon-guide:before {
  content: "\e90a";
}
.icon-department:before {
  content: "\e903";
}
.icon-contact:before {
  content: "\e905";
}
.icon-opencampus:before {
  content: "\e906";
}
.icon-request:before {
  content: "\e907";
}
.icon-yajirushi-down:before {
  content: "\e908";
}
.icon-yajirushi-right:before {
  content: "\e909";
}
.icon-admission:before {
  content: "\e902";
}
.icon-feature:before {
  content: "\e904";
}
.icon-line:before {
  content: "\e900";
}
.icon-pen:before {
  content: "\e901";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-home:before {
  content: "\f015";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-phone:before {
  content: "\f095";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-square:before {
  content: "\f0c8";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
.icon-chevron-circle-right:before {
  content: "\f138";
}
.icon-chevron-circle-up:before {
  content: "\f139";
}
.icon-chevron-circle-down:before {
  content: "\f13a";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}