@charset "utf-8";
/* ============================================================ */
/* feature */
/* ============================================================ */
.feature {
	.banner {
		border-top: none;
	}
	&__label {
		margin: 0 0 0 15px;
		font-size: 1.5rem;
		width: 100px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: $white;
		border-radius: 3px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 65px; }
		&--green {
			background: $green;
		}
		&--purple {
			background: $purple;
		}
		&--pink {
			background: $pink;
		}
		&--black {
			background: #484848;
		}
		&--base {
			background: $baseColor;
		}
	}
	.more-support {
		background: $white;
		padding: 15px;
		border-radius: 15px;
		&__inner {
			border: 1px dashed $baseColor;
			border-radius: 8px;
			&__head {
				border-bottom: 1px dashed $baseColor;
				padding: 25px 0;
				&__title {
					font-size: 3.0rem;
					line-height: 1.0;
					font-weight: $font-medium;
					text-align: center;
				}
			}
			&__body {
				padding: 30px 70px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 30px 40px; }
			}
		}
	}
	.student-iv {
		&__item {
			background: $baseColorLight;
			border-radius: 15px;
			padding: 30px 25px;
			.feature-name {
				color: $baseColor;
				text-align: center;
			}
		}
	}
	.feature-name {
		font-size: 2.0rem;
		margin: 0 0 15px;
		font-weight: $font-medium;
		&__label {
			font-size: 1.5rem;
			margin: 0 10px 0 0;
		}
	}
	&__voice {
		display: flex;
		height: 100%;
		&__img {
			flex: 0 0 230px;
			padding: 0 0 0 35px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.__img {
			margin: 0 0 -25px;
			max-width: 100%;
		}
		&._piano_ {
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
			}
			.feature__voice__img {
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					padding: 0;
					max-width: 195px;
				}
			}
		}
	}
}

/* ============================================================ */
/* feature_index */
/* ============================================================ */
#feature_index {
	.catch01 {
		color: $black;
		line-height: 2.5;
		margin: 0 0 50px;
		& > img {
			margin: 0 5px 13px;
		}
	}
	.feature-item {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        &__title {
            display: flex;
            font-size: 2.5rem;
            line-height: 1.4;
            color: $orange;
            align-items: flex-end;
            font-weight: $font-medium;
            margin: 0 0 15px;
            img {
                width: auto;
                max-width: none;
                margin: 0 20px 0 0;
            }
            & > img:not([class*="wd-a"]) {
                @media screen and (min-width:  767.5px) and (max-width:1024px) { width: 40px; }
            }
        }
        &__text {
            display: flex;
            justify-content: space-between;
            margin: 0 0 20px;
            .__img {
                max-width: 155px;
                margin: 0 0 0 15px;
                @media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 120px; }
            }
        }
        &__btn {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: center;
        }
        &._sub_ {
            display: block;
            .feature-item__head {
                display: flex;
                align-items: center;
                margin: 0 0 15px;
                .feature-item__img {
                    width: 37%;
                    display: flex;
                    padding: 20px;
                    align-items: center;
                    justify-content: center;
                    background: url(/common/img/feature/index/bg_feature.svg) center center repeat;
                    border-radius: 10px;
                    height: 154px;
                    .__img {
                        max-width: 100%;
                    }
                }
                .feature-item__title {
                    flex: 1 1 37%;
                    margin: 0 0 0 20px;
                    color: $baseColor;
                    align-items: center;
                }
            }
        }
    }
}

/* ============================================================ */
/* feature_hoiku */
/* ============================================================ */
.feature_hoiku {
	.hoiku-lnav {
		padding: 35px 0;
		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			transition: 0.3s all;
			&__img {
				margin: 0 10px 0 0;
				height: 150px;
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { height: auto; }
				& > * {
					max-width: 140px;
					width: 100%;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 100px; }
				}
			}
			&:hover {
				opacity: 0.7;
				img {
					opacity: 1.0;
					width: 110%;
					max-width: 150px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 100px;width: 100%; }
				}
			}
			.ttl08 {
				margin: 0 0 5px;
				.icon-angle-right {
					color: $baseColor;
					margin: 0 5px 0 0;
				}
			}
			&__teach {
				padding: 0 0 0 20px;
				color: $textColor;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 0 0 10px; }
			}
		}
	}
	.ttl04 {
		&__label {
			font-size: $l;
			display: block;
		}
	}
	.hoiku-group {
		&__item {
			margin-bottom: 50px;
			&__teacher {
				display: flex;
				margin: 0 0 25px;
				&__img {
					margin: 0 20px 0 0;
					& > * {
						max-width: 170px;
						width: 100%;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 130px;margin: 0 10px 0 0; }
					}
				}
				.feature__label {
					width: auto;
					padding: 0 15px;
					margin: 0 0 15px 0;
					display: inline-block;
				}
				.ttl07 {
					font-size: $xl;
					line-height: 1.8;
					&__small {
						font-size: 1.5rem;
						display: flex;
					}
				}
			}
		}
		&__info {
			margin: 0 0 50px;
			padding: 45px 50px 30px;
			.catch01 {
				text-align: left;
				margin: 0 0 10px;
			}
			.icon-envelope-square {
				color:  $baseColor;
				font-size: $l;
				margin: 0 5px 0 0;
			}
		}
	}
}

/* ============================================================ */
/* feature_child-academy */
/* ============================================================ */
.feature_child-academy {
	.banner {
		border-top: 1px solid #CEE5E8;
	}
    .group {
        margin: 50px 0 0 0;
        &__item {
            margin: 20px 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
            &__box {
                margin: 0 0 20px;
                padding: 20px;
            }
            .ttl07 {
                display: flex;
                align-items: center;
            }
            &--niji {
                margin-bottom: 60px;
                .ttl07 {
                    display: block;
                }
                .feature__label {
                    margin: 0 0 10px;
                }
            }
            &__comment {
                background: #FBF5DA;
                /* &__img {
                    flex: 0 0 145px;
                    margin-right: 30px;
                    & > * {
                        width: 100%;
                        border-radius: 50%;
                    }
                }*/
                &__text {
                    &__title {
                        font-size: $xl;
                        font-weight: $font-medium;
                        &__label {
                            font-size: 1.5rem;
                            margin: 0 15px 0 0;
                        }
                    }
                }
            }
        }
    }
}
/* ============================================================ */
/* feature_piano */
/* ============================================================ */
.feature_piano {
    &__questionnaire {
        text-align: center;
        font-weight: $font-medium;
        &__ttl {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $l;
            .__label {
                background: $orange;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: $xxl;
            }
        }
        .row__inner-white {
            padding: 40px 30px;
            border-radius: 0;
            @media screen and (min-width:  767.5px) and (max-width:1024px) {padding: 30px 20px; }
        }
        &__point {
            .__ttl {
                color: $baseColor;
                font-size: $xl;
                font-weight: $font-medium;
            }
            &__list {
                margin: 20px 0 0 0;
                &__item {
                    padding: 0 0 15px;
                    border-bottom-style: dashed;
                    border-bottom-width: 1px;
                    display: flex;
                    text-align: left;
                    align-items: center;
                    font-size: $l;
                    font-weight: $font-medium;
                    color: $black;
                    border-bottom-color: $baseColor;
                    &__icon {
                        background: $baseColor;
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        flex: 0 0 70px;
                        margin: 0 20px 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 2.9rem;
                        line-height: 1.0;
                        color: $white;
                        flex-direction: column;
                        @media screen and (min-width:  767.5px) and (max-width:1024px) { width: 60px;height: 60px;flex: 0 0 60px;margin: 0 15px 0 0; }
                        .__label {
                            font-size: 1.0rem;
                        }
                    }
                    &:last-child,
                    &:nth-child(5) {
                        border-bottom: none;
                    }
                }
            }
        }
    }
	.student-list {
		&__item {
			margin-bottom: 40px;
			img {
				margin: 0 0 15px;
			}
			&__title {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.feature__label {
					margin: 0 15px 0 0;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { margin: 0 10px 0 0; }
				}
			}
		}
	}
}
/* ============================================================ */
/* feature_education */
/* ============================================================ */
.feature_education {
	.catch01 {
		border-bottom: 1px solid $baseColor;
		padding: 0 0 10px;
		text-align: left;
	}
	.ningengaku {
		border-top: 1px solid #CEE5E8;
	}
}
/* ============================================================ */
/* feature_recruit */
/* ============================================================ */
.feature_recruit {
	.recruit-icon {
		display: flex;
		justify-content: center;
        margin: 0 0 30px;
		&__item {
			margin: 0 10px;
		}
	}
	.catch01 {
		padding: 0 0 30px;
	}
}
/* ============================================================ */
/* feature_school-life */
/* ============================================================ */
.feature_school-life {
	.event-title {
		text-align: center;
		margin: 0 0 20px;
		display: inline-block;
		& > * {
			max-width: 50%;
		}
	}
	.event-list {
		margin: 0 0 60px 0;
		&__item {
			margin-bottom: 40px;
			&__img {
				border-radius: 10px;
				margin: 0 0 10px;
			}
			&__title {
				display: flex;
				align-items: center;
				line-height: 1.0;
				padding: 5px 0;
				font-size: $xl;
				font-weight: $font-medium;
			}
		}
	}
	.movie {
		margin: 0 auto;
		width: 50%;
	}
	.instagram {
		&__catch {
			color: #006281;
			font-weight: $font-medium;
			font-size: $xxxl;
		}
	}
}

/* ============================================================ */
/* feature_piano */
/* ============================================================ */
.feature_piano_pianolesson {
	.banner {
		border-top: 1px solid #CEE5E8;
	}
	.dailylife-ttl {
		background: url(/common/img/feature/piano/pianolesson/bg_special01.png) 0 0 repeat;
		text-align: center;
		padding: 0;
	}
	.catch01 {
		margin: 0 0 30px;
	}
	.flow {
		.box {
			margin: 0 0 50px;
			position: relative;
			.catch01 {
				text-align: left;
				margin-bottom: 5px;
			}
			.feature__label {
				width: auto;
				padding: 0 20px;
				margin: 0;
			}
			&::after {
				content: "";
				background: url(/common/img/feature/piano/pianolesson/icon_yajirushi01.svg) no-repeat;
				width: 35px;
				height: 32px;
				bottom: -40px;
				@include centering-elements(true, false);
			}
			.float__item {
				margin-bottom: 0;
				&__img {
					width: 210px;
				}
			}
			&:last-child {
				background: #f9edb5;
				margin-bottom: 0;
				.catch01 {
					color: $textColor;
				}
				&::after {
					display: none;
				}
			}
		}
	}
	.question {
		background: url(/common/img/feature/piano/pianolesson/ico_pianolesson01_pc.png) no-repeat;
		background-position: 0 10px;
		font-size: 2.8rem;
		text-align: left;
		padding-left: 90px;
		margin: 0 auto;
		width: 650px;
		font-weight: $font-bold;
		span {
			font-size: 1.9rem;
		}
	}	
	.type {
		background: #d0eef7;
		border-radius: 5px;
		padding: 20px 20px 0 20px;
		margin: 20px 0;
		
		&__student {
			background: $white;
			padding: 20px;
			text-align: left;
			display: flex;
			&__img {
				flex: 0 0 25%;
				padding: 0 10px 0 0;
				img {
					max-width: 100%;
				}
			}
			&__skill {
				flex: 0 0 35%;
				background: url(/common/img/feature/piano/pianolesson/ico_pianolesson02_pc.png) center right 10px no-repeat;
				padding-right: 55px;
			}
			&__comment {
				flex: 1 1 auto;
			}
			.level {
				font-size: 2.2rem;
				font-weight: $font-medium;
				margin: 0 0 5px;
				span {
					font-size: 1.3rem;
					padding: 5px 10px;
					border-radius: 20px;
					margin-right: 10px;
					color: $black;
				}
				&--beginner {
					color: #f7931e;
					span {
						background: #f9ed81;
					}
				}
				&--blank {
					color: #47b6d9;
					span {
						background: #74ccd9;
					}
				}
				&--senior {
					color: #fb728b;
					span {
						background: #fb728b;
					}
				}
			}
			.ttl07 {
				font-size: $xxl;
				color: #47b6d9;
				font-weight: $font-medium;
				
				&:before {
					content: url(/common/img/feature/piano/pianolesson/ico_pianolesson03_pc.png);
					margin-right: 5px;
					top: 3px;
					position: relative;
				}
			}
		}

		.type__teacher {
			padding: 0 20px;
			.ttl08 {
				position: relative;
				text-align: center;
				margin: 20px 0 10px;
				span {
					position: relative;
					z-index: 2;
					display: inline-block;
					margin: 0 2.5em;
					padding: 0 1em;
					background-color: #d0eef7;
					text-align: left;
					font-size: 1.8rem;
					font-weight: bold;
				}
				&:before {
					position: absolute;
					top: 50%;
					z-index: 1;
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background-color: #fff;
				}
			}
			.float__item {
				margin-bottom: 0;
			}
		}
	}
	
	.messeage {
		&__item {
			padding: 10px 20px 20px;
			&:nth-child(odd) {
				background: #e8f4f7;
				.messeage__item__text {
					border-bottom: 2px solid $white;
				}
			}
			&:nth-child(even) {
				background: $white;
				.messeage__item__text {
					border-bottom: 2px solid #e8f4f7;
				}
			}
			&__text {
				padding-bottom: 5px;
				line-height: 250%;
				display:inline;
				font-size: $s;
				font-weight: $font-medium;
			}
		}
	}
}

/* ============================================================ */
/* feature_recruit_conection */
/* ============================================================ */
.feature_recruit_conection {
	.more-support {
		background: $yellowLight;
		&__inner__body {
			padding: 30px 40px;
			&__img {
				flex: 0 0 157px;
				margin: 0 20px 0 0;
			}
		}
	}
    .banner {
        border-top: 1px solid #CEE5E8;
    }
}