@charset "utf-8";
/* ============================================================ */
/* target.html */
/* ============================================================ */
.target {
	.target-main {
		margin: 0 0 60px;
		&__visual {
			border-radius: 15px;
		}
		&__ttl {
			font-size: $xxl;
			color: $black;
			font-weight: $font-medium;
			height: 140px;
			display: flex;
			align-items: center;
			background: url(/common/img/target/bg_text.png) left center no-repeat;
			background-size: contain;
		}
	}
	.box {
		margin-bottom: 30px;
		.ttl09 {
			&__text {
				padding-left: 10px;
                display: block;
				border-left: 5px solid $baseColor;
			}
		}
	}
}

/* ============================================================ */
/* restart */
/* ============================================================ */
.restart {
	.benefit {
		margin-bottom: 40px;
		&__item {
			padding: 10px 10px 20px;
			text-align: center;
			background: $white;
			&__title {
				background: $baseColorDarken;
				padding: 5px 0;
				margin: 0 0 20px;
				color: $white;
				font-size: $l;
				font-weight: $font-medium;
			}
			&__text {
				font-size: $xl;
				&__em {
					color: $pink;
					font-weight: $font-medium; 
				}
			}
		}
	}
	.benefit-flow {
		&__item {
			display: flex;
			padding: 10px;
			&:nth-child(odd) {
				background: $sky;
			}
			&__label {
				display: block;
				background: $baseColorDarken;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				margin-right: 10px;
				flex: 0 0 30px;
			}
			&__em {
				background: $pink;
				color: $white;
				padding: 3px 10px;
				margin-bottom: 10px;
				font-size: 1.5rem;
			}
		}
	}
    .support {
        &__point03-box {
            display: flex;
            .__label {
                background: $baseColor;
                color: $white;
                border-radius: 50%;
                width: 90px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 90px;
                margin: 0 25px 0 0;
                font-size: $xl;
                font-weight: $font-medium; 
            }
        }
        &__voice-box {
            display: flex;
            &__img {
                flex: 0 0 230px;
                padding: 0 0 0 35px;
            }
            .__img {
                margin: 0 0 -25px;
                max-width: 100%;
            }
        }
    }
}

/* ============================================================ */
/* recruitmentofficer */
/* ============================================================ */
.recruitmentofficer {
	.target-main__ttl {
		background-position: center center;
		justify-content: center;
	}
	.box .target-main__ttl,
	.btn-area-ttl {
		height: auto;
		background: none;
		justify-content: center;
		margin: 0 0 20px;
	}
	.btn--md {
		min-width: 100%;
	}
}