@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	html {
		font-size: calc(100vw / 37.5);
	}
}

body {
	background: $white;
}

.wrap {
	font-family: 'Noto Sans JP', sans-serif;
	color: $textColor;
	font-size: 1.5rem;
	line-height: 1.8;
	overflow-x: hidden;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 10px 0;
	font-size: $xs;
	line-height: 1.6;
	width: 100%;
	font-weight: $font-regular;
	color: #5E5E5E;
	border-bottom: 1px solid $baseColorLight;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		&__link {
			color: #5E5E5E;
		}
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 100px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($orange, 0.9);
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		border-radius: 50%;
	}
}