@charset "utf-8";
/* ============================================================ */
/* fnav */
/* ============================================================ */
.fnav {
	background: #F6FAFB;
    padding: 60px 0;
	.inner {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
	}
	&__target {
		border-right: 1px solid #CEE5E8;
		width: 17%;
	}
	&__contents {
		flex: 1 1 auto;
		display: flex;
		justify-content: space-between;
		padding-left: 20px;
        .col:not(last-child) {
            margin: 0 15px 0 0;
        }
	}
	&__title {
		margin: 0 0 15px 0;
		font-size: $m;
		font-weight: $font-medium;
		&__link {
			color: $black;
			transition: 0.3s all;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__block {
		margin: 0 0 25px;
		.fnav__title {
			margin: 0 0 5px;
		}
	}
	&__sub-title {
		font-size: $s;
		line-height: 2.0;
		margin: 0 5px 0 0;
		&__link {
			color: #484848;
			transition: 0.3s all;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&--under {
			&::before {
				content: "-";
				margin: 0 5px 0 0;
			}
		}
	}
	.col {
		.fnav__block {
			&:last-child {
				margin: 0;
			}
		}
	}
}

/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	text-align: center;
	line-height: 1.6;

	.footer-logo {
		margin: 40px 0 25px;
		
		&__link {
			display: block;
			width: 300px;
			margin: 0 auto 15px;
		}
		
		&__sub {
			font-size: $xs;
			color: #717171;
		}
	}
	.footer__address {
		color: #484848;
		margin: 0 0 25px;
		font-size: $s;
	}
	.footer__sns {
		display: flex;
		justify-content: center;
		margin: 0 0 40px;
		&__item {
			margin: 0 5px;
			&__link {
				text-decoration: none;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $baseColor;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $xl;
				transition: 0.3s all;
				.icon-line {
					font-size: $xxl;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.copyright {
		padding: 20px 0;
		font-size: $s;
		color: #777777;
		border-top: 1px solid #CEE5E8;
	}
}