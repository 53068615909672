@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		padding: 80px 0;
		&__inner {
			max-width: 1140px;
			margin: 0 auto;
			padding: 0 20px;
			&--narrow {
				max-width: 860px;
				margin: 0 auto;
			}
		}
		&__inner-white {
			background: $white;
			padding: 60px 85px;
			border-radius: 25px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px 60px; }
		}
		&__inner-bluelignt {
			background: $sky;
			padding: 60px 85px;
			border-radius: 25px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px; }
            &._narrow_ {
                padding: 40px 50px;
			    @media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 30px 40px; }
            }
		}
		&__inner-yellowlignt {
			background: $yellowLight;
			padding: 60px 85px;
			border-radius: 25px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px; }
		}
        &__inner-orange {
			background: #FCF2D8;
            padding: 60px 85px;
			border-radius: 25px;
            @media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px; }
        }
		&__inner-pinklight {
			background: #FFEFF3;
			padding: 60px 85px;
			border-radius: 25px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px; }
		}
	}
	
	&__inner {
		display: flex;
		justify-content: space-between;
	}
	&--column2 {
		.main {
			flex: 0 1 auto;
			width: 800px;
			margin: 0 60px 0 0;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 73vw;margin: 0 20px 0 0; }
		}
		.side {
			flex: 0 0 auto;
			width: 240px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 24vw; }
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.icon-fix-left,
.icon-fix-right {
	@include centering-elements(false, true);
}
.icon-fix-left { left: 15px; }
.icon-fix-right { right: 15px; }

.bg-dotted {
	background: url(/common/img/common/bg_pattern_02.svg) center center repeat;
}
.bg-lattice {
	background: url(/common/img/common/bg_pattern.svg) center center repeat;
}
.bg-gray {
	background: #F6F6F6;
}
.bg-bluelight {
	background: $sky;
}
.bg-yellowlight {
	background: $yellowLight;
}

.link-scroll {
	padding-top: 170px;
	margin-top: -170px;
}

.block01 {
	margin: 0 0 40px;
}

.movie-link {
	display: block;
	position: relative;
	&::after {
		@include centering-elements(true, true);
		content: "";
		background: url(/common/img/common/ico_play.png) no-repeat;
		background-size: 70px 70px;
		width: 70px;
		height: 70px;
	}
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	margin: 0;
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

a[href^="tel:"] {
	cursor: default;
	text-decoration: none;
	color: $textColor;
	&:hover {
		opacity: 1.0;
	}
}

.icon-external-link {
	font-size: $s;
	margin: 0 0 0 5px;
}
.icon-file-pdf-o {
	margin: 0 5px 0 0;
}

.icon-link {
	color: $baseColor;
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 350px;
	background: url(/common/img/common/bg_ttl01.jpg) center center no-repeat;
	background-size: cover;
	font-size: 3.6rem;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.2;
	margin: 170px 0 0;
	flex-direction: column;
	
	@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 33vh; }
	
	.feature & {background-image: url(/common/img/feature/bg_ttl01.jpg);}
	.admission & {background-image: url(/common/img/admission/bg_ttl01.jpg);}
	.admission_graduate & {background-image: url(/common/img/admission/graduate/bg_ttl01.jpg);}
	.department & {background-image: url(/common/img/department/bg_ttl01.jpg);}
	.department_student & {background-image: url(/common/img/department/student/bg_ttl01.jpg);}
	.guide & {background-image: url(/common/img/guide/bg_ttl01.jpg);}
	.news & {background-image: url(/common/img/news/bg_ttl01.png);}
	.contact & {background-image: url(/common/img/contact/bg_ttl01.jpg);}
	.request & {background-image: url(/common/img/request/bg_ttl01.jpg);}
	.facility & {background-image: url(/common/img/facility/index/bg_ttl01.jpg);}
	.facility_access & {background-image: url(/common/img/facility/access/bg_ttl01.jpg);}
	.opencampus & {background-image: url(/common/img/opencampus/bg_ttl01.jpg);}
	.opencampus_visit & {background-image: url(/common/img/opencampus/visit/bg_ttl01.png);}
	.opencampus_online & {background-image: url(/common/img/opencampus/online/bg_ttl01.png);}
	.opencampus_soudan & {background-image: url(/common/img/opencampus/soudan/bg_ttl01.jpg);}
	.target & {background-image: url(/common/img/target/bg_ttl01.png);}
    .sheet & {background-image: url(/common/img/sheet/bg_ttl01.png);}
	
	[class^="icon-"] {
		display: block;
		font-size: 4.5rem;
		margin: 0 0 15px;
		.department & { font-size: 3.5rem;}
	}
	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 350px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { 
			height: 33vh;
		}
	}
}

.ttl02 {
	margin: 0 0 20px;
	color: $black;
	font-size: 3.1rem;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		background: url(/common/img/common/ttl-line.svg) no-repeat;
		width: 55px;
		height: 4.8px;
	}
}

.ttl03 {
	margin: 0 0 20px;
	font-size: 2.6rem;
	font-weight: $font-medium;
	line-height: 1.4;
	color: $black;
	text-align: center;
    &._left_ {
        text-align: left;
    }
}

.ttl04 {
	margin: 0 0 20px;
	color: $black;
	font-size: 2.6rem;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		border-bottom: 2px solid $baseColor;
		width: 47px;
	}
}

.ttl05 {
	margin: 0 0 20px;
	color: $black;
	font-size: $xxl;
	text-align: center;
	font-weight: $font-medium;
	position: relative;
	padding: 0 0 15px;
	&::after {
		@include centering-elements(true, false);
		bottom: 0;
		content: "";
		border-bottom: 1px solid $baseColor;
		width: 65%;
	}
}

.ttl06 {
	margin: 0 0 25px;
	padding: 0 0 10px;
	border-bottom: 1px solid $baseColor;
	color: $black;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
	&:first-letter {
		color: $baseColor;
	}
}

.ttl07 {
	padding: 5px 0 5px 10px;
	margin: 0 0 15px;
	border-left: 6px solid $baseColor;
	color: $black;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
}

.ttl08 {
	margin: 0 0 15px;
	color: $black;
	font-size: $l;
	font-weight: $font-medium;
}

.ttl09 {
	margin: 0 0 15px;
	padding: 0 0 5px;
	color: $black;
	font-size: $l;
	display: block;
	font-weight: $font-medium;
	border-bottom: 1px solid $baseColor;
}

.ttl10 {
	margin: 0 0 10px;
	color: $baseColor;
	font-weight: $font-medium;
	&._c-black_ {
		color: $textColor;
	}
}

.catch01 {
	font-size: $xxl;
	color: $baseColor;
	font-weight: $font-medium;
	text-align: center;
}
.lead01 {
	text-align: center;
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 40px;
	}
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -20px;
	margin-right: -20px;

	&> * {
	 margin-left: 20px;
	 margin-right: 20px;
	 width: calc((100% - 81px) / 2 );
	}
	&--narrow {
		margin-left: -10px;
		margin-right: -10px;

		&> * {
			margin-left: 10px;
			margin-right: 10px;
			width: calc((100% - 41px) / 2);
		}
	}
	&--md {
		margin-left: -30px;
		margin-right: -30px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-left: -20px;margin-right: -20px; }

		&> * {
			margin-left: 30px;
			margin-right: 30px;
			width: calc((100% - 121px) / 2);
			@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-left: 20px;margin-right: 20px;width: calc((100% - 81px) / 2); }
		}
	}
	&__hierarchy {
		display: flex;
		&> * {
			width: calc((100% - 21px) / 2);
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
	
	&--md {
		margin-left: -20px;
		margin-right: -20px;

		&> * {
			margin-left: 20px;
			margin-right: 20px;
			width: calc((100% - 121px) / 3);
		}
	}
	&--narrow {
		margin-left: -5px;
		margin-right: -5px;

		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 31px) / 3);
		}
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--border {
		& > * {
			&::before {
				width: 8px;
				border-bottom: 1px solid $textColor;
				margin: 0 10px 0 0;
				top: 0.5em;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $orange;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
	&--odd {
		& > * {
			padding: 7px 15px;
			&:nth-child(odd) {
				background: #F6F6F6;
			}
		}
	}
}
.link-list {
    &__item {
        position: relative;
        margin-bottom: 7px;
        padding-left: 15px;
        &::before {
            position: absolute;
            left: 0;
            content: "\f105";
            font-family: 'icomoon';
            color: $baseColor;
        }
        &__link {
            color: $textColor;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--radius {
		border-radius: 15px;
	}
	&--gray {
		background: #ECECEC;
	}
	&--bluelight {
		background: $sky;
	}
	&--yellowlight {
		background: $yellowLight;
	}
	&--pinklight {
		background: #FFF5F6;
	}
    &--orange {
		background: #FCF2D8;
	}
	&--purple {
		background: #F3EFFB;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--border-base {
		border: 1px solid $baseColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
    &--border-dashed {
        border: 2px dashed $baseColor;
    }
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	margin: 0 0 20px;
	border-top: 1px solid $borderColor;
	th,
	td {
		padding: 15px;
		background: $white;
		border-bottom: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-pink {
		background: $pink;
		color: $white;
	}
	.bg-pinklight {
		background: #FFF5F6;
	}
	.bg-bluedarken {
		background: #006281;
		border: 1px solid $white;
		color: $white;
	}
	.bg-bluelight {
		background: $sky;
	}
	.bg-yellowlight {
		background: $yellowLight;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}
.table--list {
	border: none;
	th,
	td {
		border: none;
	}
	tr {
		&:nth-child(odd) {
			th,td {
				background: #F6F6F6;
			}
		}
	}
}
.table-swipe {
	.table {
		border-top: solid 1px $borderColor;
		width: 100%;
		td {
			border:solid 1px $borderColor;
			&:last-child {
				border-right: none;
			}
		}
		th {
			border-bottom: solid 1px $borderColor;
			white-space: nowrap;
		}
		thead th {
			border-right:solid 1px $borderColor;
			font-weight: $font-medium;
			font-size: $l;
			&:last-child {
				border-right: none;
			}
		}
	}
}
/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 50px;
	margin: 0 5px;
	padding: 0 20px;
	color: $white;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	@include radius(25);

	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--white {
		background: $white;
	}
	
	&--base {
		background: $baseColor;
	}
	
	&--black {
		background: $black;
	}
	
	&--pink {
		background: $pink;
	}
	
	&--orange {
		background: $orange;
	}
	&--clear {
		background: none;
		border: 1px solid #585858;
		color: #585858;
	}
	
	&--border-pink {
		border: 2px solid $pink;
		color: $pink;
		background: $white;
		&:hover {
			background: $pink;
			color: $white;
			opacity: 1.0;
			border: 2px solid $pink;
		}
	}
	
	&--border-orange {
		border: 2px solid $orange;
		color: $orange;
		background: $white;
		&:hover {
			background: $orange;
			color: $white;
			opacity: 1.0;
			border: 2px solid $orange;
		}
	}
	
	&--border-base {
		border: 2px solid $baseColor;
		color: $baseColor;
		background: $white;
		&:hover {
			background: $baseColor;
			color: $white;
			opacity: 1.0;
			border: 2px solid $baseColor;
		}
	}
	
	&--bluelight {
		background: $blueLight;
	}
	
	&--lg {
		min-width: 380px;
		height: 70px;
		font-size: $l;
		@include radius(35);
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 300px; }
	}
    &--lg02 {
		min-width: 450px;
		height: 70px;
		font-size: $l;
		@include radius(35);
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 400px; }
	}
	&--md {
		min-width: 300px;
		height: 60px;
		font-size: $m;
		@include radius(30);
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 250px; }
	}
	&--md02 {
		min-width: 250px;
		height: 55px;
		font-size: $m;
		@include radius(30);
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 200px; }
	}
    &--md03 {
		min-width: 350px;
		height: 60px;
		font-size: $m;
		@include radius(30);
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 300px; }
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: flex;
			background: $white;
			border: 1px solid $baseColor;
			color: $baseColor;
			text-decoration: none;
			@include radius(20);
			width: 40px;
			height: 40px;
			justify-content: center;
			align-items: center;
			transition: 0.3s all;
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev,
	&__next,
	&__list {
		& > * {
			width: auto!important;
			padding: 0 20px;
		}
	}
	&__list {
		& > * {
			background: $baseColor!important;
			border: 1px solid $baseColor!important;
			color: $white!important;
			&:hover {
				background: $white!important;
				border: 1px solid $baseColor!important;
				color: $baseColor!important;
			}
		}
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: #006281;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: $font-medium;
}

.note {
	color: #5E5E5E;
	font-size: $s;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}

textarea:-ms-input-placeholder {
	color:#555;
}

/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	height: 27px;
	background: $baseColor;
	color: $white;
	font-size: $s;
	line-height: 27px;
	padding: 0 10px;
	&--news {
		background: $baseColor;
	}
	&--oc {
		background: $pink;
	}
	&--class {
		background: $green;
	}
	&--graduate {
		background: $orange;
	}
	&--school {
		background: $blue;
	}
	&--other {
		background: #484848;
	}
}
.topic-date {
	color: #9C9C9C;
}

/* --------------------------------------------------- */
/* topic__list */
/* --------------------------------------------------- */
.topic__list {
	margin-bottom: 40px;
	margin-left: -1%;
	margin-right: -1%;
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
		text-decoration: none;
		transition: 0.3s all;
		display: block;
		margin-bottom: 45px;
		&__img {
			width: 100%;
			height: 195px;
			overflow: hidden;
			position: relative;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 150px; }
			& > img {
				width: auto;
				height: 100%;
				@include centering-elements(true, true);
			}
		}
		&__box {
			padding: 15px 0 0;
			&__label {
				min-width: 60%;
				display: inline-block;
				margin: 0 0 5px;
			}
			&__date {
				display: block;
			}
			&__title {
				color: $black;
				font-size: 1.5rem;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	.topic-date {
		margin: 0 0 0 10px;
	}
	&__ttl {
		margin: 0 0 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		&__text {
			font-size: 2.5rem;
			font-weight: $font-medium;
			margin: 10px 0 0;
			color: $baseColor;
			width: 100%;
		}
	}
	&__block {
		padding: 0 0 50px;
		img {
			margin: 0 0 25px;
			max-width: 100%;
			height: auto;
			display: block;
			&.aligncenter {
				margin: 0 auto 25px;
			}
			&.alignright {
				margin: 0 0 25px auto;
			}
		}
	}
}

/* ============================================================ */
/* banner */
/* ============================================================ */
.banner {
	border-top: 1px solid #CEE5E8;
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&__item {
			display: block;
			width: calc((100% - 60px) / 3);
			max-width: 285px;
			margin: 0 10px 20px;
		}
	}
}
/* ============================================================ */
/* movie */
/* ============================================================ */
.movie {
	&__item {
		position: relative;
		cursor: pointer;
		@include transition;
		margin-bottom: 15px;
		img {
			width: 100%;
		}
		&::after {
			content: '';
			display: block;
			width: 100px;
			height: 100px;
			background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
			background-size: 100px;
			@include centering-elements(true, true);
		}
		&:hover {
			@include opacity;
		}
	}
}

/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	background: #F6F6F6;
	display: flex;
	margin: 0 0 60px;
	border-radius: 5px;
	flex-wrap: wrap;
	&__item {
		&__link {
			padding: 20px 0;
			display: block;
			text-decoration: none;
			line-height: 1.2;
			color: $textColor;
			text-align: center;
			&__text {
				width: 100%;
                position: relative;
				&::after {
                    @include centering-elements(false, true);
                    right: 0;
                    border-right: 1px solid #CCCCCC;
                    width: 1px;
                    height: 20px;
                    content: "";
                }
			}
			.icon-angle-right,
			.icon-angle-down {
				color: $baseColor;
				margin: 0 5px 0 0;
			}
			&:hover {
				color: $baseColor;
			}
		}
		&:last-child {
			.lnav__item__link__text {
                &::after {
                    display: none;
                }
			}
		}
	}
    &--column2 {
		.lnav__item {
			width: calc((100% - 2px) / 2 );
            &:nth-child(2) {
                .lnav__item__link__text {
                    &::after {
                        display: none;
                    }
                }
            }
		}
	}
	&--column3 {
		.lnav__item {
			width: calc((100% - 2px) / 3 );
		}
	}
	&--column4 {
		.lnav__item {
			width: calc(100% / 4);
		}
	}
    &--column5,
    &--column6,
    &--column7,
    &--column8 {
        .lnav__item {
            width: calc(100% / 4);
            &:nth-child(4) {
                .lnav__item__link__text {
                    &::after {
                        display: none;
                    }
                }
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                .lnav__item__link {
                    padding: 20px 0 0 0;
                }
            }
        }
    }
    &--column5,
    &--column6,
    &--column7 {
        .lnav__item {
            &:last-child {
                .lnav__item__link__text {
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }
}
.lnav02 {
	display: flex;
	border-radius: 5px;
	margin-right: -5px;
	margin-left: -5px;
	justify-content: center;
	&__item {
		width: calc((100% - 31px) / 3);
		margin-left: 5px;
		margin-right: 5px;
		background: #ECECEC;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;
		transition: 0.3s all;
		color: $textColor;
		padding: 15px 0;
		margin-bottom: 0;
		.icon-angle-right,
		.icon-angle-down {
			color: $baseColor;
			margin: 0 5px 0 0;
		}
		&:hover,
		&--active {
			background: #006281;
			color: $white;
			.icon-angle-right,
			.icon-angle-down {
				color: $white;
			}
		}
	}
}

/* --------------------------------------------------- */
/* admission-bnr */
/* --------------------------------------------------- */
.admission-bnr {
	color: $white;
	font-size: $xl;
	font-weight: $font-medium;
	height: 90px;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: 0.3s all;
	text-align: center;
	line-height: 1.6;
	&:hover {
		opacity: 0.7;
	}
	&--ao {
		background-image: url(/common/img/common/bnr_ao_02_pc.png);
		position: relative;
	}
	&--suisen {
		background-image: url(/common/img/common/bnr_suisen_01.jpg);
		position: relative;
	}
	&--ippan {
		background-image: url(/common/img/common/bnr_ippan_01.jpg);
		position: relative;
	}
	&--tuition {
		background-image: url(/common/img/admission/bnr_tuition_01.jpg);
	}
	&--support {
		background-image: url(/common/img/admission/bnr_support_01.jpg);
	}
}
/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__inner {
		border: 1px solid $baseColor;
	}
	&__item {
		position: relative;
		&__link {
			display: block;
			padding: 15px 0 15px 30px;
			border-bottom: 1px solid $baseColor;
			color: $black;
			text-decoration: none;
			font-weight: $font-medium;
			transition: 0.3s all;

			.icon-angle-right {
				color: $baseColor;
				@include centering-elements(false, true);
				left: 15px;
			}
			&:hover {
				background: $baseColor;
				color: $white;
				.icon-angle-right {
					color: $white;
				}
			}
		}
		&:last-child .snav__item__link {
			border-bottom: none;
		}
	}
}
