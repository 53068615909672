@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	
	&::after {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		content: '';
		width: 100vw;
		height: 100vh;
		background: rgba(#000, 0.7);
		opacity: 0;
		visibility: hidden;
		@include transition;
		
		.show & {
			opacity: 1;
			visibility: visible;
		}
	}
	.header-body {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 90px;
		max-width: 100%;
		margin: 0 auto;
		background: $white;
		z-index: 60;
		.header-logo {
			top: 10px;
			&__link {
				text-decoration: none;
				display: flex;
				align-items: center;
				margin: 0 0 0 10px;
				&__icon {
					margin: 0 5px 0 0;
					max-width: 35px;
				}
				&__logo {
					max-width: 180px;
				}
			}
		}
		.sp-header {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-right: 10px;
			height: 50px;
			.sp-contact {
				font-size: 2.8rem;
				color: $baseColor;
				margin: 5px 20px 0 0;
				text-decoration: none;
			}
		}

		.header-menu {
			display: block;
			position: relative;
			width: 30px;
			height: 20px;
			z-index: 40;
			cursor: pointer;

			&__item {
				display: inline-block;
				position: absolute;
				left: 0;
				width: 100%;
				height: 2px;
				background: $baseColor;
				@include radius(3);

				&:nth-of-type(1) { top: 0; }
				&:nth-of-type(2) { top: 9px;}
				&:nth-of-type(3) { bottom: 0; }
				
			}
			&.active {
				.header-menu__item {
					&:nth-of-type(1) { 
						transform: translateY(10px) rotate(-315deg);
					}
					&:nth-of-type(2) { opacity: 0; }
					&:nth-of-type(3) { 
						transform: translateY(-8px) rotate(315deg); 
					} 
				}
			}
		}
		.sp-fix-menu {
			width: 100%;
			height: 40px;
			background: $white;
			overflow: none;
			transition: all 0.4s ease-in-out;
			&__inner {
				display: flex;
				margin: 0 0 10px;
			}

			&__item {
				height: 38px;
				display: block;
				color: $white;
				margin: 0 2px 0 0;
				width: calc( (100% - 2px ) / 3);
				text-decoration: none;
				border-top: 2px solid $white;
				&__text {
					font-size: $xs;
					display: flex;
					height: 100%;
					align-items: center;
					justify-content: center;
					line-height: 1.3;
					text-align: center;
				}
				&--oc {
					background: $orange;
					width: 45%;
				}
				&--request {
					background: $baseColor;
				}
				&--contact {
					background: #006281;
					margin: 0;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	.header-inner {
		height: 0;
	}
	.sp-menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 90px 0;
		line-height: 1.5;
		overflow-y: auto;
		transform: translateY(-100%);
		@include transition;
		
		.show & {
			transform: translateY(0);
			background: $baseColor;
		}
	}
	.gnav {
		background: rgba($baseColor, 0.95);
		&__item {
			border-bottom: 1px solid #A3EAF1;
			&__link {
				position: relative;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				padding: 15px 20px;
				cursor: pointer;
				text-decoration: none;
				margin: 0;
				&__text {
					color: $white;
					text-align: center;
					display: flex;
					align-items: center;
					flex: 1 1 auto;
					
					&::before {
						padding-right: 10px;
						display: inline;
						font-size: $m;
						content: attr(data-text);
						color: $white;
						font-weight: $font-medium;
					}
				}
				[class^="icon"] {
					@include centering-elements(false, true);
					right: 10px;
					display: block;
					color: $white;
				}
				&.active {
					.icon-angle-down::before {
						display: block;
						transform: rotate(180deg);
					}
				}
			}
		}
		&__dropdown {
			display: none;
			&__inner {
				background: #0999A7;
				padding: 0 15px;
				&__midashi {
					border-bottom: 1px solid #58DCE8;
					&__link {
						width: 100%;
						display: block;
						padding: 15px 0;
						color: $white;
						text-decoration: none;
						[class^="icon-angle-"] {
							margin: 0 10px 0 0;
						}
					}
				}
				&__body {
					display: block;
					&__item {
						width: 100%;
						margin: 0;
						padding: 15px 0;
						display: block;
						color: $white;
						text-decoration: none;
						position: relative;
						border-bottom: 1px solid #58DCE8;
						&__text {
							padding: 0 0 0 18px;
							[class^="icon-angle-"] {
								margin: 0 10px 0 0;
								@include centering-elements(false, true);
								left: 5px;
							}
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
			&--sns {
				display: block;
				.gnav__dropdown__inner {
					padding: 0 25px;
					background: $baseColor;
					&__body {
						display: flex;
						&__item {
							width: 47%;
							margin-right: 1.5%;
							margin-left: 1.5%;
							margin-bottom: 15px;
							padding: 15px 0 5px;
							&__text {
								padding: 5px 0 0;
							}
							&:last-child {
								border-bottom: 1px solid #58DCE8;
							}
						}
					}
				}
			}
		}
	}
	.header-target {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid #A3EAF1;
		margin: 0 0 60px;
		&__item {
			width: calc((100% - 1px) / 2 );
			font-size: 1.5rem;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #A3EAF1;
			&__link {
				padding: 15px;
				width: 100%;
				display: block;
				color: $white;
				text-decoration: none;
			}
			&:last-child {
				border-bottom: none;
			}
			&:nth-child(odd) {
				border-right: 1px solid #A3EAF1;
			}
		}
	}
	.menu-close {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		background: $white;
		color: $baseColor;
		cursor: pointer;
		font-size: 2.0rem;
		@include circle(40);
	}
}