@charset "utf-8";
/* ============================================================ */
/* feature */
/* ============================================================ */
.feature {
	.banner {
		border-top: none;
	}
	&__label {
		margin: 0 0 0 15px;
		font-size: 1.5rem;
		width: 80px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: $white;
		border-radius: 3px;
		&--green {
			background: $green;
		}
		&--purple {
			background: $purple;
		}
		&--pink {
			background: $pink;
		}
		&--black {
			background: #484848;
		}
		&--base {
			background: $baseColor;
		}
	}
	.more-support {
		background: $white;
		padding: 15px;
		border-radius: 15px;
		&__inner {
			border: 1px dashed $baseColor;
			border-radius: 8px;
			&__head {
				border-bottom: 1px dashed $baseColor;
				padding: 15px 0;
				&__title {
					font-size: 3.0rem;
					line-height: 1.0;
					font-weight: $font-medium;
					text-align: center;
				}
			}
			&__body {
				padding: 30px 15px;
			}
		}
	}
	.feature-name {
		font-size: 2.0rem;
		margin: 0 0 15px;
		font-weight: $font-medium;
		&__label {
			font-size: 1.5rem;
			margin: 0 10px 0 0;
		}
	}
	.student-iv {
		&__item {
			background: $baseColorLight;
			border-radius: 15px;
			padding: 30px 20px;
			.feature-name {
				color: $baseColor;
				text-align: center;
			}
		}
	}
	&__voice {
		&__img {
			flex: 100%;
			padding: 0;
			text-align: center;
		}
		.__img {
			margin: 15px 0 -15px;
			width: 50%!important;
			max-width: 195px;
		}
	}
}

/* ============================================================ */
/* feature_index */
/* ============================================================ */
#feature_index {
	.catch01 {
		color: $black;
		line-height: 2.0;
		margin: 0 0 30px;
		font-size: $l;
		& > img {
			margin: 0 5px 5px;
			width: 40px;
		}
	}
	.feature-item {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
			
        &__title {
            display: flex;
            font-size: $xxl;
            line-height: 1.4;
            color: $orange;
            align-items: center;
            font-weight: $font-medium;
            margin: 0 0 15px;
            img {
                width: auto;
                max-width: none;
                margin: 0 15px 0 0;
                width: 40px;
            }
            & > img:not([class*="wd-a"]) {
                width: 30px;
            }
        }
        &__text {
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .__img {
                margin: 15px auto;
                max-width: 60%;
            }
        }
        &__btn {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: center;
        }
        &._sub_ {
            .feature-item__head {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                margin: 0 0 15px;
                width: 100%;
                .feature-item__img {
                    width: 80%;
                    display: flex;
                    padding: 20px 30px;
                    align-items: center;
                    justify-content: center;
                    background: url(/common/img/feature/index/bg_feature.svg) center center repeat;
                    border-radius: 10px;
                    margin: 0 0 15px;
                    .__img {
                        max-width: 100%;
                    }
                }
                .feature-item__title {
                    flex: 1 1 37%;
                    color: $baseColor;
                    align-items: center;
                }
            }
        }
	}
}

/* ============================================================ */
/* feature_hoiku */
/* ============================================================ */
.feature_hoiku {
	.hoiku-lnav {
		padding: 35px 0;
		&__item {
			border: 1px solid $borderColor;
			border-top: none;
			display: flex;
			align-items: center;
			text-decoration: none;
			position: relative;
			padding: 15px 20px;
			margin: 0 20px;
			&:first-child {
				border-top: 1px solid $borderColor;
			}
			&__img {
				margin: 0 10px 0 0;
				& > * {
					max-width: 100px;
					width: 100%;
				}
			}
			.ttl08 {
				margin: 0 0 5px;
			}
			.icon-angle-down {
				color: $baseColor;
				margin: 0 5px 0 0;
				@include centering-elements(false, true);
				right: 10px;
				font-size: $xl;
			}
			&__teach {
				padding: 0 0 0 20px;
				color: $textColor;
			}
		}
	}
	.ttl04 {
		&__label {
			font-size: $l;
			display: block;
		}
	}
	.hoiku-group {
		&__item {
			margin-bottom: 30px;
			&__teacher {
				display: flex;
				margin: 0 0 15px;
				&__img {
					margin: 0 10px 0 0;
					& > * {
						max-width: 110px;
						width: 100%;
					}
				}
				.feature__label {
					width: auto;
					padding: 0 15px;
					margin: 0 0 15px 0;
					display: inline-block;
				}
				.ttl07 {
					font-size: $xl;
					line-height: 1.8;
					&__small {
						font-size: $s;
						display: flex;
					}
				}
			}
		}
		&__info {
			margin: 0 0 40px;
			.catch01 {
				text-align: left;
				margin: 0 0 10px;
			}
			.icon-envelope-square {
				color:  $baseColor;
				font-size: $l;
				margin: 0 5px 0 0;
			}
		}
	}
}

/* ============================================================ */
/* feature_child-academy */
/* ============================================================ */
.feature_child-academy {
	.banner {
		border-top: 1px solid #CEE5E8;
	}
	.group {
        margin: 40px 0 0 0;
        &__item {
		    margin: 20px 0 0;
            &:last-child {
                margin-bottom: 0;
            }
            &__box {
                margin: 0 0 20px;
                padding: 20px;
            }
            .ttl07 {
                display: flex;
                align-items: center;
            }

            .btn--md {
                width: 90%;
            }
            &--niji {
                margin-bottom: 60px;
                .ttl07 {
                    display: block;
                }
                .feature__label {
                    margin: 0 0 10px;
                }
            }
            &__comment {
                background: #FBF5DA;
                /* &__img {
                    width: 130px;
                    margin: 0 auto;
                    & > * {
                        width: 100%;
                        border-radius: 50%;
                    }
                }*/
                &__text {
                    &__title {
                        font-size: $xl;
                        font-weight: $font-medium;
                        &__label {
                            font-size: 1.5rem;
                            margin: 0 15px 0 0;
                        }
                    }
                }
            }
        }
	}
}
/* ============================================================ */
/* feature_piano */
/* ============================================================ */
.feature_piano {
    &__questionnaire {
        font-weight: $font-medium;
        &__ttl {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $l;
            .__label {
                background: $orange;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: $xxl;
                flex: 0 0 40px;
            }
        }
        .row__inner-white {
            padding: 30px 15px;
            border-radius: 0;
        }
        &__point {
            .__ttl {
                color: $baseColor;
                font-size: $xl;
                font-weight: $font-medium;
            }
            &__list {
                margin: 20px 0 0 0;
                &__item {
                    padding: 0 0 15px;
                    border-bottom-style: dashed;
                    border-bottom-width: 1px;
                    display: flex;
                    text-align: left;
                    align-items: center;
                    font-size: $l;
                    font-weight: $font-medium;
                    line-height: 1.5;
                    color: $black;
                    border-bottom-color: $baseColor;
                    &__icon {
                        background: $baseColor;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        flex: 0 0 60px;
                        margin: 0 20px 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 2.9rem;
                        line-height: 1.0;
                        color: $white;
                        flex-direction: column;
                        .__label {
                            font-size: 1.0rem;
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
	.student-list {
		&__item {
			margin-bottom: 40px;
			img {
				margin: 0 0 15px;
			}
			&__title {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.feature__label {
					margin: 0 15px 0 0;
				}
			}
		}
	}
}
/* ============================================================ */
/* feature_education */
/* ============================================================ */
.feature_education {
	.catch01 {
		border-bottom: 1px solid $baseColor;
		padding: 0 0 10px;
		text-align: left;
		font-size: $l;
		margin-bottom: 15px;
	}
	.ningengaku {
		border-top: 1px solid #CEE5E8;
	}
}
/* ============================================================ */
/* feature_recruit */
/* ============================================================ */
.feature_recruit {
	.recruit-icon {
		display: flex;
		justify-content: center;
        margin: 0 0 15px;
		&__item {
			margin: 0 10px;
			& >img {
				width: 100%;
			}
		}
	}
	.catch01 {
		padding: 0 0 20px;
	}
}
/* ============================================================ */
/* feature_school-life */
/* ============================================================ */
.feature_school-life {
	.event-title {
		text-align: center;
		margin: 0 0 20px;
		display: inline-block;
		& > * {
			max-width: 70%;
		}
	}
	.event-list {
		&__item {
			margin-bottom: 30px;
			&__img {
				border-radius: 10px;
				margin: 0 0 5px;
			}
			&__title {
				display: flex;
				align-items: center;
				line-height: 1.0;
				padding: 5px 0;
				font-weight: $font-medium;
			}
		}
	}
	.instagram {
		&__catch {
			color: #006281;
			font-weight: $font-medium;
			font-size: $xxl;
		}
	}
}
/* ============================================================ */
/* feature_piano */
/* ============================================================ */
.feature_piano_pianolesson {
	.banner {
		border-top: 1px solid #CEE5E8;
	}
	.dailylife-ttl {
		background: url(/common/img/feature/piano/pianolesson/bg_special01.png) 0 0 repeat;
		padding: 0;
	}
	.catch01 {
		margin: 0 0 30px;
	}
	.flow {
		.box {
			margin: 0 0 50px;
			position: relative;
			.catch01 {
				text-align: left;
				margin-bottom: 5px;
			}
			.feature__label {
				width: auto;
				padding: 0 20px;
				margin: 0;
			}
			&::after {
				content: "";
				background: url(/common/img/feature/piano/pianolesson/icon_yajirushi01.svg) no-repeat;
				width: 35px;
				height: 32px;
				bottom: -40px;
				@include centering-elements(true, false);
			}
			&:last-child {
				background: #f9edb5;
				margin-bottom: 0;
				.catch01 {
					color: $textColor;
				}
				&::after {
					display: none;
				}
			}
		}
	}
	.question {
		background: url(/common/img/feature/piano/pianolesson/ico_pianolesson01_pc.png) no-repeat;
		background-position: 0 10px;
		font-size: 2.0rem;
		text-align: left;
		padding-left: 90px;
		margin: 0 auto 20px;
		line-height: 150%;
		font-weight: $font-bold;
		span {
			line-height: 130%;
			font-size: $m;
		}
	}	
	.type {
		margin-bottom: 40px;
		.accordion {
			border: none;
			position: relative;
			&::after {
				@include centering-elements(false, true);
				right: 10px;
				content: "\f055";
				font-size: $xxl;
				font-family: 'icomoon';
				color: $textColor;
			}
			&.active {
				&::after {
					content: "\f056";
					font-family: 'icomoon';
				}
			}
		}
		.accordion-hide {
			background: #d0eef7;
			border-radius: 10px;
			text-align: left;
			padding: 10px;
			margin: 10px 0 0;
			
			.type__student {
				background: #fff;
				padding: 10px 15px;
				
				.ttl07 {
					font-size: 1.8rem;
					color: #47b6d9;
					font-weight: bold;
					
					&:before {
						content: url(/common/img/special/pianolesson/ico_pianolesson03_sp.png);
						margin-right: 5px;
						top: 2px;
						position: relative;
					}
				}
			}
			.type__teacher {
				.float__item {
					width: 17%;
					margin-bottom: 0px
				}
				
			}
		}
	}
	.beginner {
		.accordion {
			background: #f9ed81;
		}
	}
	.blank {
		.accordion {
			background: #74ccd9;
		}
	}
	.senior {
		.accordion {
			background: #ffc4ca;
		}
	}
	.messeage {
		&__item {
			padding: 10px 20px 20px;
			&:nth-child(odd) {
				background: #e8f4f7;
				.messeage__item__text {
					border-bottom: 2px solid $white;
				}
			}
			&:nth-child(even) {
				background: $white;
				.messeage__item__text {
					border-bottom: 2px solid #e8f4f7;
				}
			}
			&__text {
				padding-bottom: 5px;
				line-height: 250%;
				display:inline;
				font-size: $s;
				font-weight: $font-medium;
			}
		}
	}
}

/* ============================================================ */
/* feature_recruit_conection */
/* ============================================================ */
.feature_recruit_conection {
	.more-support {
		background: $yellowLight;
		&__inner__body {
			padding: 30px 20px;
			&__img {
				text-align: center;
			}
		}
	}
    .banner {
        border-top: 1px solid #CEE5E8;
    }
}