@charset "UTF-8";
@import url(//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap);
/*
OZ RESET CSS
Author: ONZE
Author URI: http://on-ze.com
Version: 1.1
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
* {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
  font-size: 10px;
  font-size: 1.0rem;
  line-height: 1;
  position: relative;
  text-size-adjust: 100%;
  font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

article,
aside,
dialog,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

hr {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

input,
select {
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

img {
  vertical-align: bottom;
}

/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src: url("/common/icon/fonts/icomoon.eot?j6mcs2");
  src: url("/common/icon/fonts/icomoon.eot?j6mcs2#iefix") format("embedded-opentype"), url("/common/icon/fonts/icomoon.woff2?j6mcs2") format("woff2"), url("/common/icon/fonts/icomoon.ttf?j6mcs2") format("truetype"), url("/common/icon/fonts/icomoon.woff?j6mcs2") format("woff"), url("/common/icon/fonts/icomoon.svg?j6mcs2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-x:before {
  content: "\e910";
}

.icon-kira:before {
  content: "\e90f";
}

.icon-ico_tiktok:before {
  content: "\e90e";
}

.icon-ico-sns:before {
  content: "\e90d";
}

.icon-facility:before {
  content: "\e90c";
}

.icon-access:before {
  content: "\e90b";
}

.icon-guide:before {
  content: "\e90a";
}

.icon-department:before {
  content: "\e903";
}

.icon-contact:before {
  content: "\e905";
}

.icon-opencampus:before {
  content: "\e906";
}

.icon-request:before {
  content: "\e907";
}

.icon-yajirushi-down:before {
  content: "\e908";
}

.icon-yajirushi-right:before {
  content: "\e909";
}

.icon-admission:before {
  content: "\e902";
}

.icon-feature:before {
  content: "\e904";
}

.icon-line:before {
  content: "\e900";
}

.icon-pen:before {
  content: "\e901";
}

.icon-check:before {
  content: "\f00c";
}

.icon-close:before {
  content: "\f00d";
}

.icon-remove:before {
  content: "\f00d";
}

.icon-times:before {
  content: "\f00d";
}

.icon-home:before {
  content: "\f015";
}

.icon-plus-circle:before {
  content: "\f055";
}

.icon-minus-circle:before {
  content: "\f056";
}

.icon-external-link:before {
  content: "\f08e";
}

.icon-phone:before {
  content: "\f095";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-facebook-f:before {
  content: "\f09a";
}

.icon-arrow-circle-left:before {
  content: "\f0a8";
}

.icon-arrow-circle-right:before {
  content: "\f0a9";
}

.icon-arrow-circle-up:before {
  content: "\f0aa";
}

.icon-arrow-circle-down:before {
  content: "\f0ab";
}

.icon-square:before {
  content: "\f0c8";
}

.icon-caret-down:before {
  content: "\f0d7";
}

.icon-caret-up:before {
  content: "\f0d8";
}

.icon-caret-left:before {
  content: "\f0d9";
}

.icon-caret-right:before {
  content: "\f0da";
}

.icon-envelope:before {
  content: "\f0e0";
}

.icon-angle-left:before {
  content: "\f104";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-angle-up:before {
  content: "\f106";
}

.icon-angle-down:before {
  content: "\f107";
}

.icon-chevron-circle-left:before {
  content: "\f137";
}

.icon-chevron-circle-right:before {
  content: "\f138";
}

.icon-chevron-circle-up:before {
  content: "\f139";
}

.icon-chevron-circle-down:before {
  content: "\f13a";
}

.icon-youtube-play:before {
  content: "\f16a";
}

.icon-instagram:before {
  content: "\f16d";
}

.icon-envelope-square:before {
  content: "\f199";
}

.icon-file-pdf-o:before {
  content: "\f1c1";
}

/* font-weight
-------------------------------------------------- */
/* font-size
-------------------------------------------------- */
@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* 基本設定 */
  /* ============================================================ */
  html {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  html {
    font-size: calc(100vw / 102.4);
  }
}

@media print, screen and (min-width: 767.5px) {
  .wrap {
    font-family: 'Noto Sans JP', sans-serif;
    color: #303030;
    font-size: 1.6rem;
    line-height: 1.8;
    overflow-x: hidden;
  }
  .sp-view {
    display: none !important;
  }
  a {
    color: #23B8C5;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  a:hover img {
    transition: 0.3s all;
    opacity: 0.7;
  }
  /* --------------------------------------------------- */
  /* topic-path */
  /* --------------------------------------------------- */
  .topic-path {
    padding: 12px 0;
    font-size: 1.5rem;
    line-height: 1.6;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: 400;
    color: #5E5E5E;
    border-bottom: 1px solid #e8f8f9;
  }
  .topic-path__inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .topic-path__item__link {
    color: #5E5E5E;
  }
  .topic-path__item:not(:last-child)::after {
    margin: 0 10px;
    content: "\f105";
    font-family: 'icomoon';
    color: #23B8C5;
  }
  /* --------------------------------------------------- */
  /* pagetop */
  /* --------------------------------------------------- */
  .pagetop {
    position: fixed;
    right: 20px;
    bottom: 120px;
  }
  .pagetop__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    background: rgba(255, 136, 67, 0.9);
    color: #fff;
    font-size: 2.4rem;
    text-decoration: none;
    border-radius: 50%;
    transition: 0.3s all;
  }
  .pagetop__link:hover {
    opacity: 0.7;
  }
  /* ================================================== */
  /* #fix-btn */
  /* ================================================== */
  .fix-btn {
    position: fixed;
    top: 180px;
    right: 0;
    z-index: 20;
  }
  .fix-btn__item {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 20px 0 0;
    width: 70px;
    display: block;
    line-height: 60px;
    color: #fff;
    margin: 0 0 5px;
    transition: 0.3s all;
    position: relative;
    left: 10px;
    text-decoration: none;
  }
  .fix-btn__item__text {
    font-size: 2rem;
    writing-mode: vertical-rl;
    transform: rotate(0.001deg);
  }
  .fix-btn__item--oc {
    background: #FF8843;
  }
  .fix-btn__item--request {
    background: #23B8C5;
  }
  .fix-btn__item--contact {
    background: #006281;
  }
  .fix-btn__item:hover {
    left: 0;
  }
  /* ============================================================ */
  /* header */
  /* ============================================================ */
  header {
    position: fixed;
    background: #fff;
    z-index: 10;
    height: 170px;
    width: 100%;
    top: 0;
  }
  header .header-wrap {
    position: relative;
  }
  header .header-wrap::before {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
  }
  header .header-body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5px;
    max-width: 1140px;
    padding: 20px 20px 0;
    margin: 0 auto;
    height: 62px;
  }
  header .header-body .header-logo {
    top: 10px;
  }
  header .header-body .header-logo__link {
    text-decoration: none;
    transition: 0.3s all;
    display: flex;
    align-items: center;
  }
  header .header-body .header-logo__link:hover {
    opacity: 0.7;
  }
  header .header-body .header-logo__link__icon {
    margin: 0 5px 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  header .header-body .header-logo__link__icon {
    max-width: 35px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  header .header-body .header-logo__link__logo {
    max-width: 180px;
  }
}

@media print, screen and (min-width: 767.5px) {
  header .header-body .header-target {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  header .header-body .header-target__item {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  header .header-body .header-target__item {
    margin: 0 10px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  header .header-body .header-target__item__icon {
    color: #23B8C5;
    padding: 0 5px 0 0;
  }
  header .header-body .header-target__item__link {
    color: #606060;
    text-decoration: none;
  }
  header .header-body .header-target__item__link:hover {
    color: #23B8C5;
  }
  header .header-body .header-menu {
    display: none;
  }
  header .header-inner {
    padding: 15px 0 0;
    height: 87px;
    background: #fff;
  }
  header .header-inner.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid #DFDFDF;
  }
  header .header-inner.is-fixed .header-search__inner {
    top: 110px;
  }
  header .gnav {
    order: 2;
    display: flex;
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
  }
  header .gnav__item {
    padding-bottom: 15px;
    width: 100%;
  }
  header .gnav__item:first-child .gnav__item__link__text::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    border-left: 1px solid #DFDFDF;
    height: 28px;
    line-height: 1.2;
  }
  header .gnav__item__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
    width: 100%;
    cursor: pointer;
  }
  header .gnav__item__link::before {
    position: absolute;
    left: 50%;
    bottom: -8px;
    z-index: 5;
    content: "";
    width: 0;
    height: 5px;
    background: #008C98;
    backface-visibility: hidden;
    transition: 0.3s all ease;
    transform: translateX(-50%);
  }
  header .gnav__item__link__text {
    position: relative;
    color: #23B8C5;
    font-size: 1.6rem;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 0 0.5em;
    width: 100%;
    height: 80px;
    color: #000;
    line-height: 1.2;
  }
  header .gnav__item__link__text::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    border-right: 1px solid #DFDFDF;
    height: 28px;
  }
  header .gnav__item__link__text__icon {
    margin: 0 0 10px;
    height: 27px;
  }
  header .gnav__item__link [class^="icon-"] {
    display: none;
  }
  header .gnav__item:hover .gnav__item__link {
    color: #23B8C5;
    text-decoration: none;
  }
  header .gnav__item:hover .gnav__item__link:before {
    width: 100%;
  }
  header .gnav__item:hover .gnav__item__link__text {
    opacity: 0.5;
  }
  header .gnav__item:hover .gnav__dropdown {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    transition: 0.3s all;
  }
  header .gnav__item > .current::before {
    position: absolute;
    left: 50%;
    bottom: -17px;
    z-index: 5;
    content: "";
    width: 100%;
    height: 3px;
    background: #23B8C5;
  }
  header .gnav__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    top: 169px;
    padding: 30px 0 40px;
    transform: translateY(-50px);
    visibility: hidden;
    opacity: 0;
    transition: 0.1s all;
    background: rgba(35, 184, 197, 0.9);
  }
  header .gnav__dropdown__inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
  }
  header .gnav__dropdown__inner__midashi {
    padding: 0 0 20px;
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
  }
  header .gnav__dropdown__inner__midashi__icon {
    margin: 0 5px 0 0;
  }
  header .gnav__dropdown__inner__midashi:hover {
    text-decoration: none;
  }
  header .gnav__dropdown__inner__midashi__link {
    color: #fff;
  }
  header .gnav__dropdown__inner__body__item {
    font-size: 1.6rem;
    color: #fff;
    transition: 0.3s all;
    text-decoration: none;
    border-bottom: 1px solid #54D4DF;
    padding: 0 0 10px;
  }
  header .gnav__dropdown__inner__body__item:hover {
    opacity: 0.7;
  }
  header .gnav__dropdown__inner__body__item__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    margin: 0 0 10px;
    transition: 0.3s all;
  }
  header .gnav__dropdown__inner__body__item__img > * {
    width: auto !important;
    max-width: inherit;
  }
  header .gnav__dropdown__inner__body__item__text {
    position: relative;
  }
  header .gnav__dropdown__inner__body__item__text .icon-fix-right {
    right: 0;
  }
  header .gnav__dropdown--feature .gnav__dropdown__inner__body__item__img, header .gnav__dropdown--admission .gnav__dropdown__inner__body__item__img {
    background: rgba(255, 255, 255, 0.4);
  }
  header .gnav__dropdown--department .gnav__dropdown__inner__body__item__img > img, header .gnav__dropdown--sns .gnav__dropdown__inner__body__item__img > img {
    width: 100% !important;
    max-width: 100%;
  }
  /* ============================================================ */
  /* .contents */
  /* ============================================================ */
  .contents .row {
    padding: 80px 0;
  }
  .contents .row__inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .contents .row__inner--narrow {
    max-width: 860px;
    margin: 0 auto;
  }
  .contents .row__inner-white {
    background: #fff;
    padding: 60px 85px;
    border-radius: 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-white {
    padding: 40px 60px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents .row__inner-bluelignt {
    background: #EFF9FB;
    padding: 60px 85px;
    border-radius: 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-bluelignt {
    padding: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents .row__inner-bluelignt._narrow_ {
    padding: 40px 50px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-bluelignt._narrow_ {
    padding: 30px 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents .row__inner-yellowlignt {
    background: #FFFCEF;
    padding: 60px 85px;
    border-radius: 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-yellowlignt {
    padding: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents .row__inner-orange {
    background: #FCF2D8;
    padding: 60px 85px;
    border-radius: 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-orange {
    padding: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents .row__inner-pinklight {
    background: #FFEFF3;
    padding: 60px 85px;
    border-radius: 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents .row__inner-pinklight {
    padding: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents__inner {
    display: flex;
    justify-content: space-between;
  }
  .contents--column2 .main {
    flex: 0 1 auto;
    width: 800px;
    margin: 0 60px 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents--column2 .main {
    width: 73vw;
    margin: 0 20px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  .contents--column2 .side {
    flex: 0 0 auto;
    width: 240px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .contents--column2 .side {
    width: 24vw;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* 汎用スタイル */
  /* ============================================================ */
  .icon-fix-left,
  .icon-fix-right {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .icon-fix-left {
    left: 15px;
  }
  .icon-fix-right {
    right: 15px;
  }
  .bg-dotted {
    background: url(/common/img/common/bg_pattern_02.svg) center center repeat;
  }
  .bg-lattice {
    background: url(/common/img/common/bg_pattern.svg) center center repeat;
  }
  .bg-gray {
    background: #F6F6F6;
  }
  .bg-bluelight {
    background: #EFF9FB;
  }
  .bg-yellowlight {
    background: #FFFCEF;
  }
  .link-scroll {
    padding-top: 170px;
    margin-top: -170px;
  }
  .block01 {
    margin: 0 0 40px;
  }
  .movie-link {
    display: block;
    position: relative;
  }
  .movie-link::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background: url(/common/img/common/ico_play.png) no-repeat;
    background-size: 70px 70px;
    width: 70px;
    height: 70px;
  }
  /* --------------------------------------------------- */
  /* テキスト */
  /* --------------------------------------------------- */
  p {
    margin-bottom: 15px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .indent {
    display: table;
    margin: 0;
  }
  .indent > *:first-child {
    display: table-cell;
    white-space: nowrap;
  }
  a[href^="tel:"] {
    cursor: default;
    text-decoration: none;
    color: #303030;
  }
  a[href^="tel:"]:hover {
    opacity: 1.0;
  }
  .icon-external-link {
    font-size: 1.4rem;
    margin: 0 0 0 5px;
  }
  .icon-file-pdf-o {
    margin: 0 5px 0 0;
  }
  .icon-link {
    color: #23B8C5;
  }
  /* --------------------------------------------------- */
  /* 見出し */
  /* --------------------------------------------------- */
  .ttl01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    background: url(/common/img/common/bg_ttl01.jpg) center center no-repeat;
    background-size: cover;
    font-size: 3.6rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    margin: 170px 0 0;
    flex-direction: column;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .ttl01 {
    height: 33vh;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature .ttl01 {
    background-image: url(/common/img/feature/bg_ttl01.jpg);
  }
  .admission .ttl01 {
    background-image: url(/common/img/admission/bg_ttl01.jpg);
  }
  .admission_graduate .ttl01 {
    background-image: url(/common/img/admission/graduate/bg_ttl01.jpg);
  }
  .department .ttl01 {
    background-image: url(/common/img/department/bg_ttl01.jpg);
  }
  .department_student .ttl01 {
    background-image: url(/common/img/department/student/bg_ttl01.jpg);
  }
  .guide .ttl01 {
    background-image: url(/common/img/guide/bg_ttl01.jpg);
  }
  .news .ttl01 {
    background-image: url(/common/img/news/bg_ttl01.png);
  }
  .contact .ttl01 {
    background-image: url(/common/img/contact/bg_ttl01.jpg);
  }
  .request .ttl01 {
    background-image: url(/common/img/request/bg_ttl01.jpg);
  }
  .facility .ttl01 {
    background-image: url(/common/img/facility/index/bg_ttl01.jpg);
  }
  .facility_access .ttl01 {
    background-image: url(/common/img/facility/access/bg_ttl01.jpg);
  }
  .opencampus .ttl01 {
    background-image: url(/common/img/opencampus/bg_ttl01.jpg);
  }
  .opencampus_visit .ttl01 {
    background-image: url(/common/img/opencampus/visit/bg_ttl01.png);
  }
  .opencampus_online .ttl01 {
    background-image: url(/common/img/opencampus/online/bg_ttl01.png);
  }
  .opencampus_soudan .ttl01 {
    background-image: url(/common/img/opencampus/soudan/bg_ttl01.jpg);
  }
  .target .ttl01 {
    background-image: url(/common/img/target/bg_ttl01.png);
  }
  .sheet .ttl01 {
    background-image: url(/common/img/sheet/bg_ttl01.png);
  }
  .ttl01 [class^="icon-"] {
    display: block;
    font-size: 4.5rem;
    margin: 0 0 15px;
  }
  .department .ttl01 [class^="icon-"] {
    font-size: 3.5rem;
  }
  .ttl01__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .ttl01__img {
    height: 33vh;
  }
}

@media print, screen and (min-width: 767.5px) {
  .ttl02 {
    margin: 0 0 20px;
    color: #000;
    font-size: 3.1rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl02::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    background: url(/common/img/common/ttl-line.svg) no-repeat;
    width: 55px;
    height: 4.8px;
  }
  .ttl03 {
    margin: 0 0 20px;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
    text-align: center;
  }
  .ttl03._left_ {
    text-align: left;
  }
  .ttl04 {
    margin: 0 0 20px;
    color: #000;
    font-size: 2.6rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl04::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    border-bottom: 2px solid #23B8C5;
    width: 47px;
  }
  .ttl05 {
    margin: 0 0 20px;
    color: #000;
    font-size: 2.2rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl05::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    border-bottom: 1px solid #23B8C5;
    width: 65%;
  }
  .ttl06 {
    margin: 0 0 25px;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .ttl06:first-letter {
    color: #23B8C5;
  }
  .ttl07 {
    padding: 5px 0 5px 10px;
    margin: 0 0 15px;
    border-left: 6px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .ttl08 {
    margin: 0 0 15px;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .ttl09 {
    margin: 0 0 15px;
    padding: 0 0 5px;
    color: #000;
    font-size: 1.8rem;
    display: block;
    font-weight: 500;
    border-bottom: 1px solid #23B8C5;
  }
  .ttl10 {
    margin: 0 0 10px;
    color: #23B8C5;
    font-weight: 500;
  }
  .ttl10._c-black_ {
    color: #303030;
  }
  .catch01 {
    font-size: 2.2rem;
    color: #23B8C5;
    font-weight: 500;
    text-align: center;
  }
  .lead01 {
    text-align: center;
    margin-bottom: 40px;
  }
  .lead01:last-child {
    margin-bottom: 40px;
  }
  /* --------------------------------------------------- */
  /* カラム制御 */
  /* --------------------------------------------------- */
  /* 複数カラム
/* --------------------------------------------------- */
  .column {
    display: flex;
    flex-wrap: wrap;
  }
  .column > * {
    margin-bottom: 15px;
  }
  .column > * * {
    max-width: 100%;
  }
  .column > * img {
    outline: 1px solid transparent;
  }
  .column > * img:not([class*="wd-a"]) {
    width: 100%;
  }
  .column1 > * {
    width: 100%;
  }
  .column2 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .column2 > * {
    margin-left: 20px;
    margin-right: 20px;
    width: calc((100% - 81px) / 2);
  }
  .column2--narrow {
    margin-left: -10px;
    margin-right: -10px;
  }
  .column2--narrow > * {
    margin-left: 10px;
    margin-right: 10px;
    width: calc((100% - 41px) / 2);
  }
  .column2--md {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .column2--md {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .column2--md > * {
    margin-left: 30px;
    margin-right: 30px;
    width: calc((100% - 121px) / 2);
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .column2--md > * {
    margin-left: 20px;
    margin-right: 20px;
    width: calc((100% - 81px) / 2);
  }
}

@media print, screen and (min-width: 767.5px) {
  .column2__hierarchy {
    display: flex;
  }
  .column2__hierarchy > * {
    width: calc((100% - 21px) / 2);
  }
  .column2__hierarchy > *:first-child {
    margin-right: 10px;
  }
  .column2__hierarchy > *:last-child {
    margin-left: 10px;
  }
  .column3 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .column3 > * {
    margin-left: 10px;
    margin-right: 10px;
    width: calc((100% - 61px) / 3);
  }
  .column3--md {
    margin-left: -20px;
    margin-right: -20px;
  }
  .column3--md > * {
    margin-left: 20px;
    margin-right: 20px;
    width: calc((100% - 121px) / 3);
  }
  .column3--narrow {
    margin-left: -5px;
    margin-right: -5px;
  }
  .column3--narrow > * {
    margin-left: 5px;
    margin-right: 5px;
    width: calc((100% - 31px) / 3);
  }
  .column4 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column4 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
  }
  .column5 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column5 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 18%;
  }
  .column6 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column6 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 14.6%;
  }
  /* 幅指定カラム
------------------------------------------------------------ */
  .flex-column {
    display: flex;
    justify-content: space-between;
  }
  .flex-column > * {
    flex: 0 0 auto;
  }
  .flex-column > * * {
    max-width: 100%;
  }
  /* 画像回り込みカラム
------------------------------------------------------------ */
  .float::after {
    display: block;
    content: "";
    clear: both;
  }
  .float--left .float__item {
    float: left;
    margin: 0 20px 20px 0;
    max-width: 50%;
  }
  .float--left .float__item * {
    width: 100%;
  }
  .float--right .float__item {
    float: right;
    margin: 0 0 20px 20px;
    max-width: 50%;
  }
  .float--right .float__item * {
    width: 100%;
  }
  /* --------------------------------------------------- */
  /* リスト */
  /* --------------------------------------------------- */
  .list {
    list-style-type: none;
  }
  .list > * {
    position: relative;
    padding-left: 15px;
    margin-bottom: 5px;
  }
  .list > *::before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    position: absolute;
    top: 0.7em;
    left: 0;
  }
  .list--circle > *::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  .list--border > *::before {
    width: 8px;
    border-bottom: 1px solid #303030;
    margin: 0 10px 0 0;
    top: 0.5em;
  }
  .list--black > *::before {
    background: #303030;
  }
  .list--orange > *::before {
    background: #FF8843;
  }
  .list--decimal {
    list-style: decimal outside;
    margin: 0 0 15px 20px;
  }
  .list--decimal > * {
    padding-left: 0;
  }
  .list--odd > * {
    padding: 7px 15px;
  }
  .list--odd > *:nth-child(odd) {
    background: #F6F6F6;
  }
  .link-list__item {
    position: relative;
    margin-bottom: 7px;
    padding-left: 15px;
  }
  .link-list__item::before {
    position: absolute;
    left: 0;
    content: "\f105";
    font-family: 'icomoon';
    color: #23B8C5;
  }
  .link-list__item__link {
    color: #303030;
    text-decoration: none;
  }
  .link-list__item__link:hover {
    text-decoration: underline;
  }
  /* --------------------------------------------------- */
  /* box */
  /* --------------------------------------------------- */
  .box {
    padding: 25px;
    box-sizing: border-box;
    background: #fff;
  }
  .box--radius {
    border-radius: 15px;
  }
  .box--gray {
    background: #ECECEC;
  }
  .box--bluelight {
    background: #EFF9FB;
  }
  .box--yellowlight {
    background: #FFFCEF;
  }
  .box--pinklight {
    background: #FFF5F6;
  }
  .box--orange {
    background: #FCF2D8;
  }
  .box--purple {
    background: #F3EFFB;
  }
  .box--border-gray {
    border: 1px solid #DFDFDF;
  }
  .box--border-base {
    border: 1px solid #23B8C5;
  }
  .box--red {
    background: #fde9e9;
    border: 1px solid #f8cfd4;
  }
  .box--border-dashed {
    border: 2px dashed #23B8C5;
  }
  /* --------------------------------------------------- */
  /* iframe */
  /* --------------------------------------------------- */
  .iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
  .iframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /* --------------------------------------------------- */
  /* table */
  /* --------------------------------------------------- */
  .table {
    width: 100%;
    margin: 0 0 20px;
    border-top: 1px solid #DFDFDF;
  }
  .table th,
  .table td {
    padding: 15px;
    background: #fff;
    border-bottom: 1px solid #DFDFDF;
    text-align: left;
    vertical-align: middle;
    line-height: 1.6;
  }
  .table .bg-pink {
    background: #FF6085;
    color: #fff;
  }
  .table .bg-pinklight {
    background: #FFF5F6;
  }
  .table .bg-bluedarken {
    background: #006281;
    border: 1px solid #fff;
    color: #fff;
  }
  .table .bg-bluelight {
    background: #EFF9FB;
  }
  .table .bg-yellowlight {
    background: #FFFCEF;
  }
  .table .bg-gray {
    background: #f3f3f3;
  }
  .table .error {
    background: #fde9e9;
    color: #dd0000;
  }
  .table--center th,
  .table--center td {
    text-align: center;
  }
  .table--thin th,
  .table--thin td {
    padding: 5px 10px;
    line-height: 1.4;
  }
  .table--list {
    border: none;
  }
  .table--list th,
  .table--list td {
    border: none;
  }
  .table--list tr:nth-child(odd) th, .table--list tr:nth-child(odd) td {
    background: #F6F6F6;
  }
  .table-swipe .table {
    border-top: solid 1px #DFDFDF;
    width: 100%;
  }
  .table-swipe .table td {
    border: solid 1px #DFDFDF;
  }
  .table-swipe .table td:last-child {
    border-right: none;
  }
  .table-swipe .table th {
    border-bottom: solid 1px #DFDFDF;
    white-space: nowrap;
  }
  .table-swipe .table thead th {
    border-right: solid 1px #DFDFDF;
    font-weight: 500;
    font-size: 1.8rem;
  }
  .table-swipe .table thead th:last-child {
    border-right: none;
  }
  /* --------------------------------------------------- */
  /* btn */
  /* --------------------------------------------------- */
  .btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 50px;
    margin: 0 5px;
    padding: 0 20px;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.4;
    cursor: pointer;
    transition: 0.3s all;
    border-radius: 25px;
  }
  .btn:hover {
    opacity: 0.7;
    text-decoration: none;
  }
  .btn--white {
    background: #fff;
  }
  .btn--base {
    background: #23B8C5;
  }
  .btn--black {
    background: #000;
  }
  .btn--pink {
    background: #FF6085;
  }
  .btn--orange {
    background: #FF8843;
  }
  .btn--clear {
    background: none;
    border: 1px solid #585858;
    color: #585858;
  }
  .btn--border-pink {
    border: 2px solid #FF6085;
    color: #FF6085;
    background: #fff;
  }
  .btn--border-pink:hover {
    background: #FF6085;
    color: #fff;
    opacity: 1.0;
    border: 2px solid #FF6085;
  }
  .btn--border-orange {
    border: 2px solid #FF8843;
    color: #FF8843;
    background: #fff;
  }
  .btn--border-orange:hover {
    background: #FF8843;
    color: #fff;
    opacity: 1.0;
    border: 2px solid #FF8843;
  }
  .btn--border-base {
    border: 2px solid #23B8C5;
    color: #23B8C5;
    background: #fff;
  }
  .btn--border-base:hover {
    background: #23B8C5;
    color: #fff;
    opacity: 1.0;
    border: 2px solid #23B8C5;
  }
  .btn--bluelight {
    background: #1290C8;
  }
  .btn--lg {
    min-width: 380px;
    height: 70px;
    font-size: 1.8rem;
    border-radius: 35px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .btn--lg {
    min-width: 300px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .btn--lg02 {
    min-width: 450px;
    height: 70px;
    font-size: 1.8rem;
    border-radius: 35px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .btn--lg02 {
    min-width: 400px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .btn--md {
    min-width: 300px;
    height: 60px;
    font-size: 1.6rem;
    border-radius: 30px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .btn--md {
    min-width: 250px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .btn--md02 {
    min-width: 250px;
    height: 55px;
    font-size: 1.6rem;
    border-radius: 30px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .btn--md02 {
    min-width: 200px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .btn--md03 {
    min-width: 350px;
    height: 60px;
    font-size: 1.6rem;
    border-radius: 30px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .btn--md03 {
    min-width: 300px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .btn--sm {
    min-width: inherit;
    height: 40px;
  }
  button,
  input[type="button"].btn,
  input[type="submit"].btn {
    appearance: none;
  }
  /* --------------------------------------------------- */
  /* pager */
  /* --------------------------------------------------- */
  .pager {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0 0;
  }
  .pager li {
    margin: 0 3px 5px;
  }
  .pager li > * {
    display: flex;
    background: #fff;
    border: 1px solid #23B8C5;
    color: #23B8C5;
    text-decoration: none;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
  }
  .pager li > *.current, .pager li > *:hover {
    background: #23B8C5;
    border: 1px solid #23B8C5;
    color: #fff;
    opacity: 1;
    text-decoration: none;
  }
  .pager__prev > *, .pager__next > *, .pager__list > * {
    width: auto !important;
    padding: 0 20px;
  }
  .pager__list > * {
    background: #23B8C5 !important;
    border: 1px solid #23B8C5 !important;
    color: #fff !important;
  }
  .pager__list > *:hover {
    background: #fff !important;
    border: 1px solid #23B8C5 !important;
    color: #23B8C5 !important;
  }
  /* --------------------------------------------------- */
  /* accordion */
  /* --------------------------------------------------- */
  .accordion {
    position: relative;
    padding: 10px 30px 10px 10px;
    background: #f3f3f3;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    line-height: 1.6;
    border-radius: 3px;
  }
  .accordion__icon {
    right: 10px;
    color: #23B8C5;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .accordion.active .accordion__icon::before {
    display: block;
    transform: rotate(180deg);
  }
  .accordion-hide {
    display: none;
    padding: 15px 0 0;
  }
  /* --------------------------------------------------- */
  /* form */
  /* --------------------------------------------------- */
  .label-must {
    right: 0;
    padding: 2px 10px;
    background: #006281;
    color: #fff;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .error-block {
    margin-top: 5px;
    font-weight: 500;
  }
  .note {
    color: #5E5E5E;
    font-size: 1.4rem;
  }
  input[type="text"] {
    appearance: none;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d2d2d2;
    color: #303030;
    font-size: 1.6rem;
    border-radius: 0px;
  }
  textarea {
    appearance: none;
    padding: 5px 10px;
    width: 100%;
    height: 200px;
    border: 1px solid #d2d2d2;
    color: #303030;
    border-radius: 0px;
  }
  select {
    height: 40px;
    padding: 0 0 0 10px;
    background: #fff;
    border: 1px solid #d2d2d2;
  }
  textarea:-ms-input-placeholder {
    color: #555;
  }
  /* radio
----------------------------------------------------------- */
  .radio {
    cursor: pointer;
  }
  .radio input[type="radio"] {
    display: none;
  }
  .radio input[type="radio"] + span {
    position: relative;
    display: block;
    padding: 0 0 0 25px;
    color: #303030;
  }
  .radio input[type="radio"] + span::before, .radio input[type="radio"] + span::after {
    position: absolute;
    top: 50%;
    content: '';
    transform: translateY(-50%);
  }
  .radio input[type="radio"] + span::before {
    left: 0;
    background: #fff;
    border: 2px solid #d2d2d2;
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  .radio input[type="radio"] + span::after {
    left: 5px;
    background: #23B8C5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0;
  }
  .radio input[type="radio"]:checked + span::after {
    opacity: 1;
  }
  /* checkbox
----------------------------------------------------------- */
  .checkbox input[type="checkbox"] {
    display: none;
  }
  .checkbox input[type="checkbox"] + span {
    position: relative;
    padding: 0 0 0 22px;
    color: #303030;
    cursor: pointer;
  }
  .checkbox input[type="checkbox"] + span::before, .checkbox input[type="checkbox"] + span::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .checkbox input[type="checkbox"] + span::before {
    left: 0;
    content: '';
    width: 12px;
    height: 12px;
    background: #fff;
    border: 2px solid #d2d2d2;
  }
  .checkbox input[type="checkbox"] + span::after {
    left: 1px;
    content: "\f00c";
    color: #23B8C5;
    font-family: 'icomoon';
    font-size: 1.6rem;
    opacity: 0;
  }
  .checkbox input[type="checkbox"]:checked + span::after {
    opacity: 1;
  }
  /* --------------------------------------------------- */
  /* label */
  /* --------------------------------------------------- */
  .label {
    height: 27px;
    background: #23B8C5;
    color: #fff;
    font-size: 1.4rem;
    line-height: 27px;
    padding: 0 10px;
  }
  .label--news {
    background: #23B8C5;
  }
  .label--oc {
    background: #FF6085;
  }
  .label--class {
    background: #23C569;
  }
  .label--graduate {
    background: #FF8843;
  }
  .label--school {
    background: #3F91E4;
  }
  .label--other {
    background: #484848;
  }
  .topic-date {
    color: #9C9C9C;
  }
  /* --------------------------------------------------- */
  /* topic__list */
  /* --------------------------------------------------- */
  .topic__list {
    margin-bottom: 40px;
    margin-left: -1%;
    margin-right: -1%;
    display: flex;
    flex-wrap: wrap;
  }
  .topic__list__item {
    margin-left: 1%;
    margin-right: 1%;
    width: 31%;
    text-decoration: none;
    transition: 0.3s all;
    display: block;
    margin-bottom: 45px;
  }
  .topic__list__item__img {
    width: 100%;
    height: 195px;
    overflow: hidden;
    position: relative;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .topic__list__item__img {
    height: 150px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .topic__list__item__img > img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .topic__list__item__box {
    padding: 15px 0 0;
  }
  .topic__list__item__box__label {
    min-width: 60%;
    display: inline-block;
    margin: 0 0 5px;
  }
  .topic__list__item__box__date {
    display: block;
  }
  .topic__list__item__box__title {
    color: #000;
    font-size: 1.5rem;
  }
  /* --------------------------------------------------- */
  /* entry */
  /* --------------------------------------------------- */
  .entry .topic-date {
    margin: 0 0 0 10px;
  }
  .entry__ttl {
    margin: 0 0 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .entry__ttl__text {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 10px 0 0;
    color: #23B8C5;
    width: 100%;
  }
  .entry__block {
    padding: 0 0 50px;
  }
  .entry__block img {
    margin: 0 0 25px;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .entry__block img.aligncenter {
    margin: 0 auto 25px;
  }
  .entry__block img.alignright {
    margin: 0 0 25px auto;
  }
  /* ============================================================ */
  /* banner */
  /* ============================================================ */
  .banner {
    border-top: 1px solid #CEE5E8;
  }
  .banner__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner__list__item {
    display: block;
    width: calc((100% - 60px) / 3);
    max-width: 285px;
    margin: 0 10px 20px;
  }
  /* ============================================================ */
  /* movie */
  /* ============================================================ */
  .movie__item {
    position: relative;
    cursor: pointer;
    transition: 0.3s all;
    margin-bottom: 15px;
  }
  .movie__item img {
    width: 100%;
  }
  .movie__item::after {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
    background-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .movie__item:hover {
    opacity: 0.7;
  }
  /* --------------------------------------------------- */
  /* lnav */
  /* --------------------------------------------------- */
  .lnav {
    background: #F6F6F6;
    display: flex;
    margin: 0 0 60px;
    border-radius: 5px;
    flex-wrap: wrap;
  }
  .lnav__item__link {
    padding: 20px 0;
    display: block;
    text-decoration: none;
    line-height: 1.2;
    color: #303030;
    text-align: center;
  }
  .lnav__item__link__text {
    width: 100%;
    position: relative;
  }
  .lnav__item__link__text::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    border-right: 1px solid #CCCCCC;
    width: 1px;
    height: 20px;
    content: "";
  }
  .lnav__item__link .icon-angle-right,
  .lnav__item__link .icon-angle-down {
    color: #23B8C5;
    margin: 0 5px 0 0;
  }
  .lnav__item__link:hover {
    color: #23B8C5;
  }
  .lnav__item:last-child .lnav__item__link__text::after {
    display: none;
  }
  .lnav--column2 .lnav__item {
    width: calc((100% - 2px) / 2);
  }
  .lnav--column2 .lnav__item:nth-child(2) .lnav__item__link__text::after {
    display: none;
  }
  .lnav--column3 .lnav__item {
    width: calc((100% - 2px) / 3);
  }
  .lnav--column4 .lnav__item {
    width: calc(100% / 4);
  }
  .lnav--column5 .lnav__item, .lnav--column6 .lnav__item, .lnav--column7 .lnav__item, .lnav--column8 .lnav__item {
    width: calc(100% / 4);
  }
  .lnav--column5 .lnav__item:nth-child(4) .lnav__item__link__text::after, .lnav--column6 .lnav__item:nth-child(4) .lnav__item__link__text::after, .lnav--column7 .lnav__item:nth-child(4) .lnav__item__link__text::after, .lnav--column8 .lnav__item:nth-child(4) .lnav__item__link__text::after {
    display: none;
  }
  .lnav--column5 .lnav__item:nth-child(1) .lnav__item__link, .lnav--column5 .lnav__item:nth-child(2) .lnav__item__link, .lnav--column5 .lnav__item:nth-child(3) .lnav__item__link, .lnav--column5 .lnav__item:nth-child(4) .lnav__item__link, .lnav--column6 .lnav__item:nth-child(1) .lnav__item__link, .lnav--column6 .lnav__item:nth-child(2) .lnav__item__link, .lnav--column6 .lnav__item:nth-child(3) .lnav__item__link, .lnav--column6 .lnav__item:nth-child(4) .lnav__item__link, .lnav--column7 .lnav__item:nth-child(1) .lnav__item__link, .lnav--column7 .lnav__item:nth-child(2) .lnav__item__link, .lnav--column7 .lnav__item:nth-child(3) .lnav__item__link, .lnav--column7 .lnav__item:nth-child(4) .lnav__item__link, .lnav--column8 .lnav__item:nth-child(1) .lnav__item__link, .lnav--column8 .lnav__item:nth-child(2) .lnav__item__link, .lnav--column8 .lnav__item:nth-child(3) .lnav__item__link, .lnav--column8 .lnav__item:nth-child(4) .lnav__item__link {
    padding: 20px 0 0 0;
  }
  .lnav--column5 .lnav__item:last-child .lnav__item__link__text::after, .lnav--column6 .lnav__item:last-child .lnav__item__link__text::after, .lnav--column7 .lnav__item:last-child .lnav__item__link__text::after {
    display: block;
  }
  .lnav02 {
    display: flex;
    border-radius: 5px;
    margin-right: -5px;
    margin-left: -5px;
    justify-content: center;
  }
  .lnav02__item {
    width: calc((100% - 31px) / 3);
    margin-left: 5px;
    margin-right: 5px;
    background: #ECECEC;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all;
    color: #303030;
    padding: 15px 0;
    margin-bottom: 0;
  }
  .lnav02__item .icon-angle-right,
  .lnav02__item .icon-angle-down {
    color: #23B8C5;
    margin: 0 5px 0 0;
  }
  .lnav02__item:hover, .lnav02__item--active {
    background: #006281;
    color: #fff;
  }
  .lnav02__item:hover .icon-angle-right,
  .lnav02__item:hover .icon-angle-down, .lnav02__item--active .icon-angle-right,
  .lnav02__item--active .icon-angle-down {
    color: #fff;
  }
  /* --------------------------------------------------- */
  /* admission-bnr */
  /* --------------------------------------------------- */
  .admission-bnr {
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    height: 90px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s all;
    text-align: center;
    line-height: 1.6;
  }
  .admission-bnr:hover {
    opacity: 0.7;
  }
  .admission-bnr--ao {
    background-image: url(/common/img/common/bnr_ao_02_pc.png);
    position: relative;
  }
  .admission-bnr--suisen {
    background-image: url(/common/img/common/bnr_suisen_01.jpg);
    position: relative;
  }
  .admission-bnr--ippan {
    background-image: url(/common/img/common/bnr_ippan_01.jpg);
    position: relative;
  }
  .admission-bnr--tuition {
    background-image: url(/common/img/admission/bnr_tuition_01.jpg);
  }
  .admission-bnr--support {
    background-image: url(/common/img/admission/bnr_support_01.jpg);
  }
  /* ============================================================ */
  /* .side */
  /* ============================================================ */
  /* --------------------------------------------------- */
  /* snav */
  /* --------------------------------------------------- */
  .snav__inner {
    border: 1px solid #23B8C5;
  }
  .snav__item {
    position: relative;
  }
  .snav__item__link {
    display: block;
    padding: 15px 0 15px 30px;
    border-bottom: 1px solid #23B8C5;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s all;
  }
  .snav__item__link .icon-angle-right {
    color: #23B8C5;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
  }
  .snav__item__link:hover {
    background: #23B8C5;
    color: #fff;
  }
  .snav__item__link:hover .icon-angle-right {
    color: #fff;
  }
  .snav__item:last-child .snav__item__link {
    border-bottom: none;
  }
  /* ============================================================ */
  /* fnav */
  /* ============================================================ */
  .fnav {
    background: #F6FAFB;
    padding: 60px 0;
  }
  .fnav .inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
  }
  .fnav__target {
    border-right: 1px solid #CEE5E8;
    width: 17%;
  }
  .fnav__contents {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
  }
  .fnav__contents .col:not(last-child) {
    margin: 0 15px 0 0;
  }
  .fnav__title {
    margin: 0 0 15px 0;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .fnav__title__link {
    color: #000;
    transition: 0.3s all;
    text-decoration: none;
  }
  .fnav__title__link:hover {
    text-decoration: underline;
  }
  .fnav__block {
    margin: 0 0 25px;
  }
  .fnav__block .fnav__title {
    margin: 0 0 5px;
  }
  .fnav__sub-title {
    font-size: 1.4rem;
    line-height: 2.0;
    margin: 0 5px 0 0;
  }
  .fnav__sub-title__link {
    color: #484848;
    transition: 0.3s all;
    text-decoration: none;
  }
  .fnav__sub-title__link:hover {
    text-decoration: underline;
  }
  .fnav__sub-title--under::before {
    content: "-";
    margin: 0 5px 0 0;
  }
  .fnav .col .fnav__block:last-child {
    margin: 0;
  }
  /* ============================================================ */
  /* footer */
  /* ============================================================ */
  footer {
    text-align: center;
    line-height: 1.6;
  }
  footer .footer-logo {
    margin: 40px 0 25px;
  }
  footer .footer-logo__link {
    display: block;
    width: 300px;
    margin: 0 auto 15px;
  }
  footer .footer-logo__sub {
    font-size: 1.3rem;
    color: #717171;
  }
  footer .footer__address {
    color: #484848;
    margin: 0 0 25px;
    font-size: 1.4rem;
  }
  footer .footer__sns {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
  }
  footer .footer__sns__item {
    margin: 0 5px;
  }
  footer .footer__sns__item__link {
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #23B8C5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s all;
  }
  footer .footer__sns__item__link .icon-line {
    font-size: 2.2rem;
  }
  footer .footer__sns__item__link:hover {
    opacity: 0.7;
  }
  footer .copyright {
    padding: 20px 0;
    font-size: 1.4rem;
    color: #777777;
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* index.html */
  /* ============================================================ */
  #index .visual {
    position: relative;
    width: 100%;
    margin: 160px 0 0;
  }
  #index .visual__slider__item:hover img {
    opacity: 1.0;
  }
  #index .visual .index-h1 {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  #index .index-ttl01__en {
    font-size: 3.6rem;
    font-weight: 900;
    line-height: 1.0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .index-ttl01__en {
    font-size: 3.2rem;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .index-ttl01__ja {
    color: #23B8C5;
    font-size: 1.5rem;
    font-weight: 400;
    border-bottom: 1px dashed #23B8C5;
    position: relative;
    padding: 0 5px 0 0;
  }
  #index .index-ttl01__ja .icon-pen {
    position: absolute;
    bottom: 0;
    right: -10px;
  }
  #index .index-ttl02 {
    text-align: center;
    margin: 0 0 25px;
  }
  #index .index-ttl02::before {
    background: url(/common/img/common/ttl-icon.svg) no-repeat;
    width: 83px;
    height: 50px;
    content: "";
    display: block;
    margin: 0 auto;
  }
  #index .index-ttl02__text {
    font-size: 1.6rem;
  }
  #index .index-ttl02__text::before {
    line-height: 1.3;
    content: attr(data-text);
    display: block;
    font-size: 4.5rem;
    font-weight: 900;
  }
  #index .index-ttl03 {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 30px;
  }
  #index .index-ttl03__em {
    color: #23B8C5;
  }
  #index .index-inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
  }
  #index .topic {
    padding-bottom: 60px;
  }
  #index .topic .index-ttl01 {
    display: flex;
    align-items: flex-end;
    margin: 0 0 35px;
  }
  #index .topic .index-ttl01__en {
    margin: 0;
  }
  #index .topic .index-ttl01__ja {
    margin: 0 0 0 20px;
  }
  #index .topic__list {
    margin: 0 -2px 30px;
  }
  #index .topic__list__item {
    margin-left: 2px;
    margin-right: 2px;
    width: calc((100% - 16px) / 4);
    text-decoration: none;
    transition: 0.3s all;
    display: block;
    margin-bottom: 0;
  }
  #index .topic__list__item__img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .topic__list__item__img {
    height: 200px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .topic__list__item__img > img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #index .topic__list__item__box {
    background: #fff;
    position: relative;
    top: -25px;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0px 0px 4px 0px #e8e8e8;
    padding: 50px 25px 30px;
    height: 180px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .topic__list__item__box {
    padding: 45px 10px 20px;
    height: 150px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .topic__list__item__box__label {
    min-width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
  }
  #index .topic__list__item__box__title {
    font-size: 1.6rem;
  }
  #index .topic__list__item:hover {
    opacity: 0.7;
  }
  #index .topic__list .slick-dots {
    bottom: -30px;
    padding: 10px 0 0;
  }
  #index .entry {
    background: url(/common/img/common/bg_pattern.svg) center center repeat;
  }
  #index .entry .index-ttl01 {
    text-align: center;
    margin: 0 0 40px;
  }
  #index .entry .index-ttl01__ja {
    display: inline-block;
  }
  #index .entry__item {
    transition: 0.3s all;
    padding: 6px;
    text-decoration: none;
  }
  #index .entry__item--ao {
    background: url(/common/img/index/bnr_entry_ao.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item--suisen {
    background: url(/common/img/index/bnr_entry_suisen.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item--general {
    background: url(/common/img/index/bnr_entry_general.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item:hover {
    opacity: 0.7;
  }
  #index .entry__item__box {
    position: relative;
    padding: 0;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
  }
  #index .entry__item__box::before, #index .entry__item__box::after, #index .entry__item__box__inner::before, #index .entry__item__box__inner::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    display: inline-block;
  }
  #index .entry__item__box::before {
    border-left: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    bottom: -1px;
    left: -1px;
  }
  #index .entry__item__box::after {
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    bottom: -1px;
    right: -1px;
  }
  #index .entry__item__box__inner {
    padding: 10px 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    height: 100%;
    width: 100%;
  }
  #index .entry__item__box__inner::before {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
    top: -1px;
    left: -1px;
  }
  #index .entry__item__box__inner::after {
    border-right: solid 1px #ccc;
    border-top: solid 1px #ccc;
    top: -1px;
    right: -1px;
  }
  #index .entry__item__box__title {
    font-size: 2rem;
    text-align: center;
    line-height: 1.5;
    font-weight: 500;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .entry__item__box__title {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .about {
    background: url(/common/img/index/bg_about_01.png) center center repeat;
    background-size: cover;
  }
  #index .about__department {
    margin-bottom: 90px;
  }
  #index .about__department__item {
    text-decoration: none;
    transition: 0.3s all;
    position: relative;
    padding: 0 0 90px;
  }
  #index .about__department__item__text {
    padding: 25px 30px;
    width: 70%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #index .about__department__item__text__en {
    height: 18px;
    width: auto;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .about__department__item__text__en {
    height: 10px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .about__department__item__text__title {
    font-size: 3.5rem;
    position: relative;
    padding: 0 0 10px;
    margin: 0 0 15px;
  }
  #index .about__department__item__text__title::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    width: 50px;
  }
  #index .about__department__item__text--yoji {
    background: rgba(255, 96, 133, 0.9);
  }
  #index .about__department__item__text--yoji .about__department__item__text__title::after {
    border-bottom: 1px solid #FFAABD;
  }
  #index .about__department__item__text--kodomo {
    background: rgba(255, 136, 67, 0.9);
  }
  #index .about__department__item__text--kodomo .about__department__item__text__title::after {
    border-bottom: 1px solid #F9C366;
  }
  #index .about__department__item:hover .about__department__item__text {
    opacity: 0.7;
  }
  #index .about__feature__item {
    text-decoration: none;
    transition: 0.3s all;
    position: relative;
    padding: 0 0 110px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .about__feature__item {
    width: 48%;
    padding: 0 0 90px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .about__feature__item__text {
    padding: 20px 5px;
    width: 85%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #23B8C5;
    background: #fff;
    height: 200px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #index .about__feature__item__text {
    height: inherit;
  }
}

@media print, screen and (min-width: 767.5px) {
  #index .about__feature__item__text__title {
    font-size: 2.2rem;
    position: relative;
    padding: 10px 0 0;
    color: #303030;
  }
  #index .about__feature__item__text__title::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    border: 1px solid #23B8C5;
    width: 55px;
  }
  #index .about__feature__item:hover .about__feature__item__text {
    opacity: 0.7;
  }
  #index .recommended {
    background-color: #e8f8f9;
    background-image: url(/common/img/index/pic_reco_left_01.svg), url(/common/img/index/pic_reco_right_01.svg), url(/common/img/index/bg_reco_left_01.svg), url(/common/img/index/bg_reco_right_01.svg);
    background-position: left bottom 10%, right bottom 10%, left 10%, right 10%;
    background-size: 16% auto, 16% auto, 30% auto, 30% auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  }
  #index .recommended__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #index .recommended__item__img {
    margin: 0 0 25px;
    min-height: 0.1px;
  }
  #index .recommended__item__text {
    background: #fff;
    box-shadow: 6px 6px 0px 3px #FCFF97;
    padding: 20px 25px 50px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #303030;
    position: relative;
    transition: 0.3s all;
    flex: 1 1 auto;
  }
  #index .recommended__item__text__label {
    width: auto;
    max-width: inherit;
    margin: 0 0 15px;
  }
  #index .recommended__item__text__title {
    color: #23B8C5;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.0;
    margin: 0 0 15px;
  }
  #index .recommended__item__text .icon-chevron-circle-right {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    color: #23B8C5;
    font-size: 2.2rem;
  }
  #index .recommended__item__text:hover {
    opacity: 0.7;
  }
  #index .access .row__inner {
    position: relative;
  }
  #index .access .row__inner::after {
    position: absolute;
    bottom: -80px;
    left: 0;
    content: "";
    background: url(/common/img/index/pic_f-access_01.svg) no-repeat;
    width: 212px;
    height: 110px;
  }
  #index .access__contact {
    padding: 30px 20px 0 0;
  }
  #index .access__contact__sns {
    display: flex;
  }
  #index .access__contact__sns__item {
    margin: 0 10px 0 0;
  }
  #index .access__contact__sns__item__link {
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #23B8C5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s all;
  }
  #index .access__contact__sns__item__link .icon-line {
    font-size: 2.2rem;
  }
  #index .access__contact__sns__item__link:hover {
    opacity: 0.7;
  }
  /* ============================================================ */
  /* opencampus */
  /* ============================================================ */
  .opencampus .contents a {
    color: #FF6085;
  }
  .opencampus .contents .btn {
    color: #fff;
  }
  .opencampus .contents .btn--clear {
    color: #585858;
  }
  .opencampus .contents .btn--border-pink {
    color: #FF6085;
  }
  .opencampus .contents .btn--border-pink:hover {
    color: #fff;
  }
  .opencampus .contents .btn--border-sp {
    color: #FF8843;
    border: 1px solid #FF8843;
  }
  .opencampus .contents .btn--border-sp:hover {
    background: #FF8843;
    color: #fff;
    opacity: 1.0;
  }
  .opencampus .contents .ttl02::after {
    background: url(/common/img/opencampus/ttl-line.svg) no-repeat;
  }
  .opencampus .contents .ttl04::after {
    border-bottom: 2px solid #FF6085;
  }
  .opencampus .contents .ttl06 {
    border-bottom: 1px solid #FF6085;
  }
  .opencampus .contents .ttl06:first-letter {
    color: #FF6085;
  }
  .opencampus .contents .ttl07 {
    border-left: 6px solid #FF6085;
  }
  .opencampus .contents .ttl10 {
    color: #FF6085;
  }
  .opencampus .contents .catch01 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #FF6085;
    text-align: center;
    margin: 0 0 20px;
  }
  .opencampus .contents .catch02 {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0 0 20px;
  }
  .opencampus .contents .catch02__em {
    color: #23B8C5;
  }
  .opencampus .contents .catch03 {
    position: relative;
    padding: 0;
    color: #000;
    margin: 0;
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
  }
  .opencampus .contents .catch03 .__fukidashi {
    margin: 0 20px;
  }
  .opencampus .contents .bg-oc-gray {
    background: #FBF4F4;
  }
  .opencampus .contents .table .bg-pinklight {
    color: #FF6085;
  }
  .opencampus .tokuten {
    background-color: #FFBBC3;
    background-image: url(/common/img/opencampus/pic_tokuten_left_01.svg), url(/common/img/opencampus/pic_tokuten_right_01.svg), url(/common/img/opencampus/bg_tokuten_left_01.svg), url(/common/img/opencampus/bg_tokuten_right_01.svg);
    background-position: left bottom 10%, right bottom 10%, left 10%, right 10%;
    background-size: 12% auto, 12% auto, 26% auto, 26% auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  }
  .opencampus .tokuten .row__inner {
    padding: 0 120px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .tokuten .row__inner {
    padding: 0 60px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus .tokuten .box {
    padding: 50px 25px;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 500;
  }
  .opencampus .tokuten .box .ttl04 {
    font-size: 2.8rem;
  }
  .opencampus .tokuten .box .ttl04::after {
    border-bottom: 2px solid #FF6085;
  }
  .opencampus .tokuten .box .tokuten__em {
    margin: 0 0 30px;
    display: inline-block;
    font-size: 3.2rem;
    border-bottom: 3px solid #FFF461;
  }
  .opencampus .schedule-bnr {
    max-width: 940px;
  }
  .opencampus .banner {
    border-top: 1px solid #ECECEC;
  }
  .opencampus .topic-path {
    border-bottom: 1px solid #FFBBC3;
  }
  .opencampus .topic-path__item:not(:last-child)::after {
    color: #FF6085;
  }
  .opencampus .oc-type {
    padding: 0;
    display: flex;
  }
  .opencampus .oc-type__item {
    padding: 30px 5px 85px;
    text-align: center;
    position: relative;
  }
  .opencampus .oc-type__item--visit {
    background: url(/common/img/opencampus/bg_mini_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--visit .oc-type__item__box__catch {
    color: #1290C8;
  }
  .opencampus .oc-type__item--visit .oc-type__item__box__list li::before,
  .opencampus .oc-type__item--visit .oc-type__item__text {
    color: #1290C8;
  }
  .opencampus .oc-type__item--online {
    background: url(/common/img/opencampus/bg_sp_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--online .oc-type__item__text {
    color: #FF8843;
  }
  .opencampus .oc-type__item--online .oc-type__item__box {
    text-align: center;
  }
  .opencampus .oc-type__item--online .oc-type__item__box__catch,
  .opencampus .oc-type__item--online .oc-type__item__box a {
    color: #FF8843;
  }
  .opencampus .oc-type__item--soudan {
    background: url(/common/img/opencampus/bg_soudan_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__ttl > * {
    max-width: 90%;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__box__catch,
  .opencampus .oc-type__item--soudan .oc-type__item__box .em,
  .opencampus .oc-type__item--soudan .oc-type__item__box .oc-type__item__box__list li::before,
  .opencampus .oc-type__item--soudan .oc-type__item__box .oc-type__item__text {
    color: #FF6085;
  }
  .opencampus .oc-type__item--soudan .commingsoon__ttl:before {
    color: #FF6085;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__text {
    color: #008C98;
  }
  .opencampus .oc-type__item__text {
    font-size: 2rem;
    line-height: 1.6;
    padding: 5px 0;
    font-weight: 500;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 20%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0) 100%);
  }
  .opencampus .oc-type__item__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
    min-height: 180px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .oc-type__item__ttl {
    min-height: 160px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus .oc-type__item__ttl > * {
    max-width: 65%;
  }
  .opencampus .oc-type__item__box {
    background: #fff;
    width: 85%;
    text-align: left;
    margin: 0 auto 30px;
    padding: 30px 15px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .oc-type__item__box {
    padding: 20px 10px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus .oc-type__item__box__catch {
    position: relative;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.6;
    padding: 0 0 10px;
    margin: 0 0 15px;
    text-align: center;
  }
  .opencampus .oc-type__item__box__list {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .oc-type__item__box__list {
    width: 100%;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus .oc-type__item__box__list li {
    position: relative;
    padding: 0 0 0 1.5em;
  }
  .opencampus .oc-type__item__box__list li::before {
    content: "\f0c8";
    position: absolute;
    left: 0;
    top: 0;
    font-family: 'icomoon';
  }
  .opencampus .oc-type__item__box__list .icon-square {
    margin-right: 5px;
  }
  .opencampus .oc-type__item .btn {
    min-width: 270px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 35px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .oc-type__item .btn {
    min-width: inherit;
    width: 80%;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus .oc-type--special .oc-type__item__box {
    height: 240px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus .oc-type--special .oc-type__item__box {
    min-height: 200px;
    height: 220px;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* opencampus_index */
  /* ============================================================ */
  #opencampus_index {
    /*.commingsoon {
		&__inner {
			position: relative;
			top: -155px;
			padding: 55px 45px;
			background: $white;
			margin: 0 0 -30px;
		}
		&__ttl {
			display: flex;
			align-items: flex-end;
			margin: 0 0 35px;
			color: $baseColor;
			font-size: 1.5rem;
			font-weight: $font-regular;
			&:before {
				color: $black;
				content: attr(data-text);
				margin: 0 25px 0 0;
				font-size: 3.6rem;
				font-weight: $font-black;
				line-height: 1.0;
			}
		}
		&__item {
			text-decoration: none;
			&__img {
				margin: 0 0 15px;
			}
			&__text {
				color: $textColor;
				font-size: $l;
				line-height: 1.6;
			}
		}
	}*/
  }
  #opencampus_index .visual {
    margin: 160px 0 0;
    background: url(/common/img/opencampus/index/pic_visual_01_pc.jpg) center center no-repeat;
    height: 775px;
    position: relative;
  }
  #opencampus_index .visual__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1140px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  #opencampus_index .visual__inner__img {
    width: 75%;
  }
  #opencampus_index .corona-info {
    background: #fff;
    text-align: center;
    padding: 15px 0;
    width: 60%;
    margin: 0 auto;
    color: #FF6085;
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s all;
  }
  #opencampus_index .corona-info:hover {
    background: #FF6085;
    color: #fff;
  }
  #opencampus_index .oc-type {
    margin-left: -3px;
    margin-right: -3px;
  }
  #opencampus_index .oc-type__item {
    margin-left: 3px;
    margin-right: 3px;
    width: calc((100% - 19px) / 3);
    display: flex;
    flex-direction: column;
  }
  #opencampus_index .oc-type__item__box {
    flex-grow: 1;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #opencampus_index .oc-type__item__box__catch {
    font-size: 1.6rem;
    text-align: left;
  }
}

@media print, screen and (min-width: 767.5px) {
  #opencampus_index .point .row__inner {
    padding: 70px 85px;
    background: #fff;
  }
  #opencampus_index .point__block {
    margin: 0 0 85px;
  }
  #opencampus_index .point__block:last-child {
    margin: 0;
  }
  #opencampus_index .point__block__title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 45px;
  }
  #opencampus_index .point__block__title::before, #opencampus_index .point__block__title::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: url(/common/img/opencampus/ttl-line02.svg) repeat-x;
    width: 50%;
    height: 8px;
  }
  #opencampus_index .point__block__title::before {
    left: 0;
    background-position: left center;
  }
  #opencampus_index .point__block__title::after {
    right: 0;
    background-position: right center;
  }
  #opencampus_index .point__block__title__img {
    background: #fff;
    width: 164px;
    height: 60px;
    z-index: 1;
    text-align: center;
  }
  #opencampus_index .point__block .catch01 {
    font-size: 2.7rem;
  }
  #opencampus_index .movie {
    margin: 0 auto;
    width: 50%;
  }
  /* ============================================================ */
  /* opencampus_visit,opencampus_online */
  /* ============================================================ */
  .opencampus_visit .corona-info,
  .opencampus_online .corona-info {
    text-align: center;
    color: #FF6085;
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 40px;
    transition: 0.3s all;
  }
  .opencampus_visit .oc-date,
  .opencampus_online .oc-date {
    background: #F6F6F6;
    padding: 5px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 40px;
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
  }
  .opencampus_visit .oc-date__inner,
  .opencampus_online .oc-date__inner {
    border: 1px solid #FFBBC3;
    padding: 15px;
  }
  .opencampus_visit .oc-date__title,
  .opencampus_online .oc-date__title {
    margin: 0 0 10px;
    font-size: 1.7rem;
  }
  .opencampus_visit .oc-date__label,
  .opencampus_online .oc-date__label {
    font-weight: 500;
    line-height: 1.0;
    font-size: 1.7rem;
    background: #FF6085;
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  .opencampus_visit .table--oc-time,
  .opencampus_online .table--oc-time {
    border-top: none;
  }
  .opencampus_visit .table--oc-time th, .opencampus_visit .table--oc-time td,
  .opencampus_online .table--oc-time th,
  .opencampus_online .table--oc-time td {
    padding: 10px 15px;
  }
  .opencampus_visit .table--oc-time .bg-pinklight,
  .opencampus_online .table--oc-time .bg-pinklight {
    color: #303030;
  }
  .opencampus_visit .table--oc-time thead th,
  .opencampus_online .table--oc-time thead th {
    border-bottom: none;
  }
  .opencampus_visit .table--oc-time tbody th,
  .opencampus_online .table--oc-time tbody th {
    font-weight: 500;
    text-align: center;
  }
  .opencampus_visit .table--oc-time__day,
  .opencampus_online .table--oc-time__day {
    font-weight: 500;
  }
  .opencampus_visit .time-text,
  .opencampus_online .time-text {
    padding-left: 30px;
  }
  .opencampus_visit .time-text__item,
  .opencampus_online .time-text__item {
    margin-bottom: 30px;
  }
  .opencampus_visit .time-text__item__img,
  .opencampus_online .time-text__item__img {
    margin: 0 0 15px;
  }
  .opencampus_visit .time-text__item__title,
  .opencampus_online .time-text__item__title {
    font-weight: 500;
    font-size: 1.8rem;
    margin: 0 0 10x;
  }
  .opencampus_visit .day-item,
  .opencampus_online .day-item {
    margin-bottom: 30px;
  }
  .opencampus_visit .day-item:last-child,
  .opencampus_online .day-item:last-child {
    margin-bottom: 0;
  }
  .opencampus_visit .oc-type__item,
  .opencampus_online .oc-type__item {
    width: calc(100% / 2);
  }
  .opencampus_visit .banner,
  .opencampus_online .banner {
    border-top: 0;
  }
  /* ============================================================ */
  /* opencampus_visit */
  /* ============================================================ */
  .opencampus_visit .topic-path {
    border-bottom: 1px solid #80d3ff;
  }
  .opencampus_visit .topic-path__item:not(:last-child)::after {
    color: #1290C8;
  }
  .opencampus_visit .contents .oc-date__inner {
    border: 1px solid #1290C8;
  }
  .opencampus_visit .contents .oc-date__label {
    background: #1290C8;
  }
  .opencampus_visit .contents .day-item__btn-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .opencampus_visit .contents .day-item__btn-area .btn {
    width: calc((100% - 21px) / 2);
    margin: 0 0 10px;
    font-size: 1.5rem;
  }
  .opencampus_visit .contents .ttl02::after {
    background: url(/common/img/opencampus/visit/ttl-line.svg) no-repeat;
  }
  .opencampus_visit .contents .ttl04::after {
    border-bottom: 2px solid #1290C8;
  }
  .opencampus_visit .contents .ttl06 {
    border-bottom: 1px solid #1290C8;
  }
  .opencampus_visit .contents .ttl06:first-letter {
    color: #1290C8;
  }
  .opencampus_visit .contents .ttl07 {
    border-left: 6px solid #1290C8;
  }
  .opencampus_visit .contents .ttl10 {
    color: #1290C8;
  }
  .opencampus_visit .contents .catch01 {
    color: #1290C8;
  }
  .opencampus_visit .contents .btn--ocmini {
    background: #1290C8;
  }
  .opencampus_visit .contents .btn--border-ocmini {
    color: #1290C8;
    border: 1px solid #1290C8;
  }
  .opencampus_visit .contents .btn--border-ocmini:hover {
    background: #1290C8;
    color: #fff;
    opacity: 1.0;
  }
  .opencampus_visit .contents .btn--oc {
    background: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time .bg-ocmini {
    color: #fff;
    background: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time .bg-ocmini-light {
    background: #EFF9FB;
    color: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time__day {
    color: #1290C8;
  }
  .opencampus_visit .contents .schedule-list__item {
    border: 1px solid #FF6085;
    background: #fff;
    margin-bottom: 40px;
    display: flex;
  }
  .opencampus_visit .contents .schedule-list__item__day {
    background: #ffacc5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .opencampus_visit .contents .schedule-list__item__day img {
    height: 170px;
  }
  .opencampus_visit .contents .schedule-list__item__day._0722_ img {
    height: auto;
    max-width: 171.61px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_visit .contents .schedule-list__item__day {
    padding: 20px;
  }
  .opencampus_visit .contents .schedule-list__item__day img {
    height: 150px;
  }
  .opencampus_visit .contents .schedule-list__item__day._0722_ img {
    height: auto;
    max-width: 151.42px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus_visit .contents .schedule-list__item__wrap {
    flex: 1 1 240px;
    display: flex;
    align-items: center;
    padding: 20px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_visit .contents .schedule-list__item__wrap {
    flex: 1 1 200px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus_visit .contents .schedule-list__item__img {
    max-width: 178px;
    margin: 0 20px 0 0;
  }
  .opencampus_visit .contents .schedule-list__item__img img {
    width: 100%;
  }
  .opencampus_visit .contents .schedule-list__item__body {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .opencampus_visit .contents .schedule-list__item__body .btn {
    margin: 0 0 0 20px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_visit .contents .schedule-list__item__body {
    display: block;
  }
  .opencampus_visit .contents .schedule-list__item__body .btn {
    margin: 15px 0 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* opencampus_online */
  /* ============================================================ */
  .opencampus_online .topic-path {
    border-bottom: 1px solid #FFBB00;
  }
  .opencampus_online .topic-path__item:not(:last-child)::after {
    color: #FF8843;
  }
  .opencampus_online .contents a {
    color: #FF8843;
  }
  .opencampus_online .contents .oc-date__inner {
    border: 1px solid #FF8843;
  }
  .opencampus_online .contents .oc-date__label {
    background: #FF8843;
  }
  .opencampus_online .contents .day-item__btn-area {
    text-align: center;
  }
  .opencampus_online .contents .day-item__btn-area .btn {
    width: 80%;
  }
  .opencampus_online .contents .ttl02::after {
    background: url(/common/img/opencampus/online/ttl-line.svg) no-repeat;
  }
  .opencampus_online .contents .ttl04::after {
    border-bottom: 2px solid #FF8843;
  }
  .opencampus_online .contents .ttl06 {
    border-bottom: 1px solid #FF8843;
  }
  .opencampus_online .contents .ttl06:first-letter {
    color: #FF8843;
  }
  .opencampus_online .contents .ttl07 {
    border-left: 6px solid #FF8843;
  }
  .opencampus_online .contents .ttl10 {
    color: #FF8843;
  }
  .opencampus_online .contents .catch01 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #FF8843;
    text-align: center;
    margin: 0 0 20px;
  }
  .opencampus_online .contents .catch01 .__em {
    font-size: 2.8rem;
  }
  .opencampus_online .contents .btn--ocsp {
    background: #FF8843;
  }
  .opencampus_online .contents .btn--border-ocsp {
    color: #FF8843;
    border: 1px solid #FF8843;
  }
  .opencampus_online .contents .btn--border-ocsp:hover {
    background: #FF8843;
    color: #fff;
    opacity: 1.0;
  }
  .opencampus_online .contents .btn--oc {
    background: #FF8843;
  }
  .opencampus_online .contents .table--oc-time .bg-ocsp {
    color: #fff;
    background: #FF8843;
  }
  .opencampus_online .contents .table--oc-time .bg-ocsp-light {
    background: #FFFCEF;
    color: #FF8843;
  }
  .opencampus_online .contents .table--oc-time__day {
    color: #FF8843;
  }
  .opencampus_online .contents .plan {
    margin: 0 0 60px;
  }
  .opencampus_online .contents .plan__item {
    background: #FFFCEF;
    border-radius: 20px;
    padding: 30px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .opencampus_online .contents .detail {
    margin: 0 0 60px;
  }
  .opencampus_online .contents .detail__item {
    text-align: center;
  }
  .opencampus_online .contents .detail__item .__ttl {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0 auto 15px;
  }
  .opencampus_online .contents .detail__item .__ttl::before, .opencampus_online .contents .detail__item .__ttl::after {
    content: "\e90f";
    font-family: 'icomoon';
    padding: 0 10px;
  }
  .opencampus_online .contents .detail__item img {
    width: auto;
  }
  .opencampus_online .contents .detail__item._pink_ {
    background: #FFEDED;
  }
  .opencampus_online .contents .detail__item._pink_ .__ttl {
    color: #F9405F;
  }
  .opencampus_online .contents .detail__item._blue_ {
    background: #D7F4F6;
  }
  .opencampus_online .contents .detail__item._blue_ .__ttl {
    color: #1290C8;
  }
  .opencampus_online .contents .detail__item._yellow_ {
    background: #FFF8D8;
  }
  .opencampus_online .contents .detail__item._yellow_ .__ttl {
    color: #AF6F00;
  }
  .opencampus_online .contents .detail__item._orange_ {
    background: #FFEED9;
  }
  .opencampus_online .contents .detail__item._orange_ .__ttl {
    color: #F15A24;
  }
  .opencampus_online .contents .detail__item._green_ {
    background: #E7F9DE;
  }
  .opencampus_online .contents .detail__item._green_ .__ttl {
    color: #338D07;
  }
  /* ============================================================ */
  /* opencampus_soudan */
  /* ============================================================ */
  .opencampus_soudan .soudan-flow {
    display: flex;
    border: 1px solid #e2e2e2;
  }
  .opencampus_soudan .soudan-flow__item {
    padding: 30px 0 30px 30px;
    position: relative;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_soudan .soudan-flow__item {
    padding: 20px 0 20px 20px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus_soudan .soudan-flow__item__img {
    text-align: center;
    margin: 0 0 15px;
  }
  .opencampus_soudan .soudan-flow__item__title {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 10px;
    text-align: center;
  }
  .opencampus_soudan .soudan-flow__item:first-child {
    background: #fff;
    width: calc((100% / 3) - 30px);
  }
  .opencampus_soudan .soudan-flow__item:first-child::after {
    content: "";
    height: 100%;
    width: 65px;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #fff 50.5%) no-repeat top left/100% 50%, linear-gradient(to top left, rgba(255, 255, 255, 0) 50%, #fff 50.5%) no-repeat bottom right/100% 50.5%;
    position: absolute;
    top: 0;
    right: -65px;
    z-index: 2;
  }
  .opencampus_soudan .soudan-flow__item:nth-child(2) {
    width: calc((100% / 3) + 10px);
    background: #EDFCFA;
    padding: 30px 0 30px 95px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_soudan .soudan-flow__item:nth-child(2) {
    padding: 20px 0 20px 85px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus_soudan .soudan-flow__item:nth-child(2)::after {
    content: "";
    height: 100%;
    width: 65px;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #EDFCFA 50.5%) no-repeat top left/100% 50%, linear-gradient(to top left, rgba(255, 255, 255, 0) 50%, #EDFCFA 50.5%) no-repeat bottom right/100% 50.5%;
    position: absolute;
    top: 0;
    right: -65px;
    z-index: 2;
  }
  .opencampus_soudan .soudan-flow__item:last-child {
    width: calc((100% / 3) + 20px);
    background: #D0F4F2;
    padding: 30px 30px 30px 95px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .opencampus_soudan .soudan-flow__item:last-child {
    padding: 20px 20px 20px 85px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .opencampus_soudan .soudan-time {
    display: flex;
    justify-content: center;
    margin: 0 0 30px;
  }
  .opencampus_soudan .soudan-time__item {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: #000;
    font-weight: 500;
    margin: 0 20px;
  }
  .opencampus_soudan .soudan-time__item__label {
    font-weight: 500;
    line-height: 1.0;
    font-size: 1.5rem;
    background: #FF6085;
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  .opencampus_soudan .soudan-tel {
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 30px;
  }
  .opencampus_soudan .soudan-tel .icon-phone {
    color: #FF6085;
    margin-right: 5px;
  }
  .opencampus_soudan .calendar {
    margin: 0 0 60px;
  }
  .opencampus_soudan .oc-type__item {
    width: calc(100% / 2);
  }
  /* ============================================================ */
  /* opencampus_soudan_form,opencampus_form */
  /* ============================================================ */
  .opencampus_soudan_form .label-must,
  .opencampus_form .label-must {
    background: #FF6085;
  }
  .opencampus_soudan_form .radio input[type="radio"] + span::after,
  .opencampus_form .radio input[type="radio"] + span::after {
    background: #FF6085;
  }
  .opencampus_soudan_form .checkbox input[type="checkbox"] + span::after,
  .opencampus_form .checkbox input[type="checkbox"] + span::after {
    color: #FF6085;
  }
  .opencampus_soudan_form .ui-widget,
  .opencampus_form .ui-widget {
    font-size: 1.35rem !important;
  }
  .opencampus_soudan_form .ui-datepicker-prev, .opencampus_soudan_form .ui-datepicker-next,
  .opencampus_form .ui-datepicker-prev,
  .opencampus_form .ui-datepicker-next {
    box-sizing: content-box;
    transition: all 0s;
  }
  .opencampus_soudan_form .ui-datepicker-calendar .ui-state-default,
  .opencampus_form .ui-datepicker-calendar .ui-state-default {
    margin-left: 0 !important;
  }
  .opencampus_soudan_form .datepicker-saturday span.ui-state-default,
  .opencampus_form .datepicker-saturday span.ui-state-default {
    /* disabled saturday */
    background-image: none;
    color: dodgerblue !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form .datepicker-sunday span.ui-state-default,
  .opencampus_form .datepicker-sunday span.ui-state-default {
    /* disabled sunday */
    background-image: none;
    color: red !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form .datepicker-holiday span.ui-state-default,
  .opencampus_form .datepicker-holiday span.ui-state-default {
    /* disabled holiday */
    background-image: none;
    color: red !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form .ui-state-active, .opencampus_soudan_form .ui-widget-content .ui-state-active, .opencampus_soudan_form .ui-widget-header .ui-state-active,
  .opencampus_form .ui-state-active,
  .opencampus_form .ui-widget-content .ui-state-active,
  .opencampus_form .ui-widget-header .ui-state-active {
    background: #FFBBC3;
    border: 1px solid #FF6085;
  }
  .opencampus_soudan_form th {
    width: 250px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 800px) and (max-width: 860px) {
  .opencampus_soudan_form th {
    width: 210px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 799.5px) {
  .opencampus_soudan_form th {
    width: 190px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 860px) {
  .opencampus_soudan_form .ui-widget.ui-widget-content {
    width: 150px;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* feature */
  /* ============================================================ */
  .feature .banner {
    border-top: none;
  }
  .feature__label {
    margin: 0 0 0 15px;
    font-size: 1.5rem;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature__label {
    width: 65px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature__label--green {
    background: #23C569;
  }
  .feature__label--purple {
    background: #7F4AAE;
  }
  .feature__label--pink {
    background: #FF6085;
  }
  .feature__label--black {
    background: #484848;
  }
  .feature__label--base {
    background: #23B8C5;
  }
  .feature .more-support {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
  }
  .feature .more-support__inner {
    border: 1px dashed #23B8C5;
    border-radius: 8px;
  }
  .feature .more-support__inner__head {
    border-bottom: 1px dashed #23B8C5;
    padding: 25px 0;
  }
  .feature .more-support__inner__head__title {
    font-size: 3.0rem;
    line-height: 1.0;
    font-weight: 500;
    text-align: center;
  }
  .feature .more-support__inner__body {
    padding: 30px 70px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature .more-support__inner__body {
    padding: 30px 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature .student-iv__item {
    background: #e8f8f9;
    border-radius: 15px;
    padding: 30px 25px;
  }
  .feature .student-iv__item .feature-name {
    color: #23B8C5;
    text-align: center;
  }
  .feature .feature-name {
    font-size: 2.0rem;
    margin: 0 0 15px;
    font-weight: 500;
  }
  .feature .feature-name__label {
    font-size: 1.5rem;
    margin: 0 10px 0 0;
  }
  .feature__voice {
    display: flex;
    height: 100%;
  }
  .feature__voice__img {
    flex: 0 0 230px;
    padding: 0 0 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .feature__voice .__img {
    margin: 0 0 -25px;
    max-width: 100%;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature__voice._piano_ {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature__voice._piano_ .feature__voice__img {
    padding: 0;
    max-width: 195px;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* feature_index */
  /* ============================================================ */
  #feature_index .catch01 {
    color: #000;
    line-height: 2.5;
    margin: 0 0 50px;
  }
  #feature_index .catch01 > img {
    margin: 0 5px 13px;
  }
  #feature_index .feature-item {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  #feature_index .feature-item__title {
    display: flex;
    font-size: 2.5rem;
    line-height: 1.4;
    color: #FF8843;
    align-items: flex-end;
    font-weight: 500;
    margin: 0 0 15px;
  }
  #feature_index .feature-item__title img {
    width: auto;
    max-width: none;
    margin: 0 20px 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #feature_index .feature-item__title > img:not([class*="wd-a"]) {
    width: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #feature_index .feature-item__text {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
  }
  #feature_index .feature-item__text .__img {
    max-width: 155px;
    margin: 0 0 0 15px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #feature_index .feature-item__text .__img {
    max-width: 120px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #feature_index .feature-item__btn {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
  }
  #feature_index .feature-item._sub_ {
    display: block;
  }
  #feature_index .feature-item._sub_ .feature-item__head {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__img {
    width: 37%;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    background: url(/common/img/feature/index/bg_feature.svg) center center repeat;
    border-radius: 10px;
    height: 154px;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__img .__img {
    max-width: 100%;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__title {
    flex: 1 1 37%;
    margin: 0 0 0 20px;
    color: #23B8C5;
    align-items: center;
  }
  /* ============================================================ */
  /* feature_hoiku */
  /* ============================================================ */
  .feature_hoiku .hoiku-lnav {
    padding: 35px 0;
  }
  .feature_hoiku .hoiku-lnav__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s all;
  }
  .feature_hoiku .hoiku-lnav__item__img {
    margin: 0 10px 0 0;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_hoiku .hoiku-lnav__item__img {
    height: auto;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_hoiku .hoiku-lnav__item__img > * {
    max-width: 140px;
    width: 100%;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_hoiku .hoiku-lnav__item__img > * {
    max-width: 100px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_hoiku .hoiku-lnav__item:hover {
    opacity: 0.7;
  }
  .feature_hoiku .hoiku-lnav__item:hover img {
    opacity: 1.0;
    width: 110%;
    max-width: 150px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_hoiku .hoiku-lnav__item:hover img {
    max-width: 100px;
    width: 100%;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_hoiku .hoiku-lnav__item .ttl08 {
    margin: 0 0 5px;
  }
  .feature_hoiku .hoiku-lnav__item .ttl08 .icon-angle-right {
    color: #23B8C5;
    margin: 0 5px 0 0;
  }
  .feature_hoiku .hoiku-lnav__item__teach {
    padding: 0 0 0 20px;
    color: #303030;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_hoiku .hoiku-lnav__item__teach {
    padding: 0 0 0 10px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_hoiku .ttl04__label {
    font-size: 1.8rem;
    display: block;
  }
  .feature_hoiku .hoiku-group__item {
    margin-bottom: 50px;
  }
  .feature_hoiku .hoiku-group__item__teacher {
    display: flex;
    margin: 0 0 25px;
  }
  .feature_hoiku .hoiku-group__item__teacher__img {
    margin: 0 20px 0 0;
  }
  .feature_hoiku .hoiku-group__item__teacher__img > * {
    max-width: 170px;
    width: 100%;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_hoiku .hoiku-group__item__teacher__img > * {
    max-width: 130px;
    margin: 0 10px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_hoiku .hoiku-group__item__teacher .feature__label {
    width: auto;
    padding: 0 15px;
    margin: 0 0 15px 0;
    display: inline-block;
  }
  .feature_hoiku .hoiku-group__item__teacher .ttl07 {
    font-size: 2rem;
    line-height: 1.8;
  }
  .feature_hoiku .hoiku-group__item__teacher .ttl07__small {
    font-size: 1.5rem;
    display: flex;
  }
  .feature_hoiku .hoiku-group__info {
    margin: 0 0 50px;
    padding: 45px 50px 30px;
  }
  .feature_hoiku .hoiku-group__info .catch01 {
    text-align: left;
    margin: 0 0 10px;
  }
  .feature_hoiku .hoiku-group__info .icon-envelope-square {
    color: #23B8C5;
    font-size: 1.8rem;
    margin: 0 5px 0 0;
  }
  /* ============================================================ */
  /* feature_child-academy */
  /* ============================================================ */
  .feature_child-academy .banner {
    border-top: 1px solid #CEE5E8;
  }
  .feature_child-academy .group {
    margin: 50px 0 0 0;
  }
  .feature_child-academy .group__item {
    margin: 20px 0 10px;
  }
  .feature_child-academy .group__item:last-child {
    margin-bottom: 0;
  }
  .feature_child-academy .group__item__box {
    margin: 0 0 20px;
    padding: 20px;
  }
  .feature_child-academy .group__item .ttl07 {
    display: flex;
    align-items: center;
  }
  .feature_child-academy .group__item--niji {
    margin-bottom: 60px;
  }
  .feature_child-academy .group__item--niji .ttl07 {
    display: block;
  }
  .feature_child-academy .group__item--niji .feature__label {
    margin: 0 0 10px;
  }
  .feature_child-academy .group__item__comment {
    background: #FBF5DA;
    /* &__img {
                    flex: 0 0 145px;
                    margin-right: 30px;
                    & > * {
                        width: 100%;
                        border-radius: 50%;
                    }
                }*/
  }
  .feature_child-academy .group__item__comment__text__title {
    font-size: 2rem;
    font-weight: 500;
  }
  .feature_child-academy .group__item__comment__text__title__label {
    font-size: 1.5rem;
    margin: 0 15px 0 0;
  }
  /* ============================================================ */
  /* feature_piano */
  /* ============================================================ */
  .feature_piano__questionnaire {
    text-align: center;
    font-weight: 500;
  }
  .feature_piano__questionnaire__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
  .feature_piano__questionnaire__ttl .__label {
    background: #FF8843;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2.2rem;
  }
  .feature_piano__questionnaire .row__inner-white {
    padding: 40px 30px;
    border-radius: 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_piano__questionnaire .row__inner-white {
    padding: 30px 20px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_piano__questionnaire__point .__ttl {
    color: #23B8C5;
    font-size: 2rem;
    font-weight: 500;
  }
  .feature_piano__questionnaire__point__list {
    margin: 20px 0 0 0;
  }
  .feature_piano__questionnaire__point__list__item {
    padding: 0 0 15px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
    border-bottom-color: #23B8C5;
  }
  .feature_piano__questionnaire__point__list__item__icon {
    background: #23B8C5;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex: 0 0 70px;
    margin: 0 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.9rem;
    line-height: 1.0;
    color: #fff;
    flex-direction: column;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_piano__questionnaire__point__list__item__icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin: 0 15px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  .feature_piano__questionnaire__point__list__item__icon .__label {
    font-size: 1.0rem;
  }
  .feature_piano__questionnaire__point__list__item:last-child, .feature_piano__questionnaire__point__list__item:nth-child(5) {
    border-bottom: none;
  }
  .feature_piano .student-list__item {
    margin-bottom: 40px;
  }
  .feature_piano .student-list__item img {
    margin: 0 0 15px;
  }
  .feature_piano .student-list__item__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .feature_piano .student-list__item__title .feature__label {
    margin: 0 15px 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .feature_piano .student-list__item__title .feature__label {
    margin: 0 10px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  /* ============================================================ */
  /* feature_education */
  /* ============================================================ */
  .feature_education .catch01 {
    border-bottom: 1px solid #23B8C5;
    padding: 0 0 10px;
    text-align: left;
  }
  .feature_education .ningengaku {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* feature_recruit */
  /* ============================================================ */
  .feature_recruit .recruit-icon {
    display: flex;
    justify-content: center;
    margin: 0 0 30px;
  }
  .feature_recruit .recruit-icon__item {
    margin: 0 10px;
  }
  .feature_recruit .catch01 {
    padding: 0 0 30px;
  }
  /* ============================================================ */
  /* feature_school-life */
  /* ============================================================ */
  .feature_school-life .event-title {
    text-align: center;
    margin: 0 0 20px;
    display: inline-block;
  }
  .feature_school-life .event-title > * {
    max-width: 50%;
  }
  .feature_school-life .event-list {
    margin: 0 0 60px 0;
  }
  .feature_school-life .event-list__item {
    margin-bottom: 40px;
  }
  .feature_school-life .event-list__item__img {
    border-radius: 10px;
    margin: 0 0 10px;
  }
  .feature_school-life .event-list__item__title {
    display: flex;
    align-items: center;
    line-height: 1.0;
    padding: 5px 0;
    font-size: 2rem;
    font-weight: 500;
  }
  .feature_school-life .movie {
    margin: 0 auto;
    width: 50%;
  }
  .feature_school-life .instagram__catch {
    color: #006281;
    font-weight: 500;
    font-size: 2.4rem;
  }
  /* ============================================================ */
  /* feature_piano */
  /* ============================================================ */
  .feature_piano_pianolesson .banner {
    border-top: 1px solid #CEE5E8;
  }
  .feature_piano_pianolesson .dailylife-ttl {
    background: url(/common/img/feature/piano/pianolesson/bg_special01.png) 0 0 repeat;
    text-align: center;
    padding: 0;
  }
  .feature_piano_pianolesson .catch01 {
    margin: 0 0 30px;
  }
  .feature_piano_pianolesson .flow .box {
    margin: 0 0 50px;
    position: relative;
  }
  .feature_piano_pianolesson .flow .box .catch01 {
    text-align: left;
    margin-bottom: 5px;
  }
  .feature_piano_pianolesson .flow .box .feature__label {
    width: auto;
    padding: 0 20px;
    margin: 0;
  }
  .feature_piano_pianolesson .flow .box::after {
    content: "";
    background: url(/common/img/feature/piano/pianolesson/icon_yajirushi01.svg) no-repeat;
    width: 35px;
    height: 32px;
    bottom: -40px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .feature_piano_pianolesson .flow .box .float__item {
    margin-bottom: 0;
  }
  .feature_piano_pianolesson .flow .box .float__item__img {
    width: 210px;
  }
  .feature_piano_pianolesson .flow .box:last-child {
    background: #f9edb5;
    margin-bottom: 0;
  }
  .feature_piano_pianolesson .flow .box:last-child .catch01 {
    color: #303030;
  }
  .feature_piano_pianolesson .flow .box:last-child::after {
    display: none;
  }
  .feature_piano_pianolesson .question {
    background: url(/common/img/feature/piano/pianolesson/ico_pianolesson01_pc.png) no-repeat;
    background-position: 0 10px;
    font-size: 2.8rem;
    text-align: left;
    padding-left: 90px;
    margin: 0 auto;
    width: 650px;
    font-weight: 700;
  }
  .feature_piano_pianolesson .question span {
    font-size: 1.9rem;
  }
  .feature_piano_pianolesson .type {
    background: #d0eef7;
    border-radius: 5px;
    padding: 20px 20px 0 20px;
    margin: 20px 0;
  }
  .feature_piano_pianolesson .type__student {
    background: #fff;
    padding: 20px;
    text-align: left;
    display: flex;
  }
  .feature_piano_pianolesson .type__student__img {
    flex: 0 0 25%;
    padding: 0 10px 0 0;
  }
  .feature_piano_pianolesson .type__student__img img {
    max-width: 100%;
  }
  .feature_piano_pianolesson .type__student__skill {
    flex: 0 0 35%;
    background: url(/common/img/feature/piano/pianolesson/ico_pianolesson02_pc.png) center right 10px no-repeat;
    padding-right: 55px;
  }
  .feature_piano_pianolesson .type__student__comment {
    flex: 1 1 auto;
  }
  .feature_piano_pianolesson .type__student .level {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0 0 5px;
  }
  .feature_piano_pianolesson .type__student .level span {
    font-size: 1.3rem;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 10px;
    color: #000;
  }
  .feature_piano_pianolesson .type__student .level--beginner {
    color: #f7931e;
  }
  .feature_piano_pianolesson .type__student .level--beginner span {
    background: #f9ed81;
  }
  .feature_piano_pianolesson .type__student .level--blank {
    color: #47b6d9;
  }
  .feature_piano_pianolesson .type__student .level--blank span {
    background: #74ccd9;
  }
  .feature_piano_pianolesson .type__student .level--senior {
    color: #fb728b;
  }
  .feature_piano_pianolesson .type__student .level--senior span {
    background: #fb728b;
  }
  .feature_piano_pianolesson .type__student .ttl07 {
    font-size: 2.2rem;
    color: #47b6d9;
    font-weight: 500;
  }
  .feature_piano_pianolesson .type__student .ttl07:before {
    content: url(/common/img/feature/piano/pianolesson/ico_pianolesson03_pc.png);
    margin-right: 5px;
    top: 3px;
    position: relative;
  }
  .feature_piano_pianolesson .type .type__teacher {
    padding: 0 20px;
  }
  .feature_piano_pianolesson .type .type__teacher .ttl08 {
    position: relative;
    text-align: center;
    margin: 20px 0 10px;
  }
  .feature_piano_pianolesson .type .type__teacher .ttl08 span {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin: 0 2.5em;
    padding: 0 1em;
    background-color: #d0eef7;
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .feature_piano_pianolesson .type .type__teacher .ttl08:before {
    position: absolute;
    top: 50%;
    z-index: 1;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .feature_piano_pianolesson .type .type__teacher .float__item {
    margin-bottom: 0;
  }
  .feature_piano_pianolesson .messeage__item {
    padding: 10px 20px 20px;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(odd) {
    background: #e8f4f7;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(odd) .messeage__item__text {
    border-bottom: 2px solid #fff;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(even) {
    background: #fff;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(even) .messeage__item__text {
    border-bottom: 2px solid #e8f4f7;
  }
  .feature_piano_pianolesson .messeage__item__text {
    padding-bottom: 5px;
    line-height: 250%;
    display: inline;
    font-size: 1.4rem;
    font-weight: 500;
  }
  /* ============================================================ */
  /* feature_recruit_conection */
  /* ============================================================ */
  .feature_recruit_conection .more-support {
    background: #FFFCEF;
  }
  .feature_recruit_conection .more-support__inner__body {
    padding: 30px 40px;
  }
  .feature_recruit_conection .more-support__inner__body__img {
    flex: 0 0 157px;
    margin: 0 20px 0 0;
  }
  .feature_recruit_conection .banner {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* admission */
  /* ============================================================ */
  .admission .box__head {
    background: #006281;
    color: #fff;
    padding: 7px 15px;
  }
  .admission .ad-block {
    margin: 0 0 40px;
  }
  .admission .ttl09__small {
    font-size: 1.5rem;
    padding-left: 10px;
  }
  .admission .ad-flow {
    margin: 100px 0 50px 0;
  }
  .admission .ad-flow__title {
    margin: 0 0 25px;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .admission .ad-flow__title__num {
    color: #23B8C5;
    font-size: 2.2rem;
    padding: 0 5px 0 0;
  }
  .admission .ad-flow__btn {
    margin: 30px 0 0 0;
  }
  .admission .admission-ttl01 {
    background: #006281;
    color: #fff;
    font-size: 1.8rem;
    padding: 7px 15px;
    margin: 0 0 30px;
    font-weight: 500;
  }
  .admission .table--tuition thead th {
    background: #006281;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
  }
  .admission .table--tuition thead th.season {
    border-bottom: 1px dashed #fff !important;
  }
  .admission .table--tuition thead th.date {
    border-top: none;
  }
  .admission .table--tuition thead th.bunkatsu-season {
    border-bottom: none;
  }
  .admission .table--tuition tbody th, .admission .table--tuition tbody td {
    border: 1px solid #DFDFDF;
  }
  .admission .table--tuition tbody th .note, .admission .table--tuition tbody td .note {
    margin-left: 5px;
    font-size: 1.2rem;
  }
  .admission .table--tuition tbody th .note02, .admission .table--tuition tbody td .note02 {
    position: relative;
  }
  .admission .table--tuition tbody th .note02__item, .admission .table--tuition tbody td .note02__item {
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 1.2rem;
  }
  .admission .table--tuition tbody th.item {
    font-weight: 500;
    text-align: center;
  }
  .admission .table--tuition tbody th.item .item__text {
    writing-mode: vertical-rl;
  }
  .admission .table--tuition tbody td {
    text-align: right;
  }
  /* ============================================================ */
  /* admission_index */
  /* ============================================================ */
  #admission_index .department-type {
    margin-bottom: 20px;
  }
  #admission_index .department-type__item__img {
    position: relative;
    padding: 0 0 60px;
    margin-bottom: 30px;
  }
  #admission_index .department-type__item__img__text {
    padding: 25px 30px;
    width: 70%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #admission_index .department-type__item__img__text__title {
    font-size: 2.5rem;
  }
  #admission_index .department-type__item .catch01 {
    font-weight: 400;
    margin: 0 0 15px;
  }
  #admission_index .department-type__item--kodomo .department-type__item__img__text {
    background: rgba(255, 136, 67, 0.9);
  }
  #admission_index .department-type__item--kodomo .catch01 {
    color: #FF8843;
  }
  #admission_index .department-type__item--yoji .department-type__item__img__text {
    background: rgba(255, 96, 133, 0.9);
  }
  #admission_index .department-type__item--yoji .catch01 {
    color: #FF6085;
  }
  #admission_index .ttl07 {
    font-size: 2.2rem;
  }
  #admission_index .ttl07__small {
    font-size: 1.6rem;
    padding: 0 0 0 10px;
  }
  #admission_index .recruitment-idx,
  #admission_index .type-idx,
  #admission_index .tuition-idx {
    margin-bottom: 30px;
  }
  #admission_index .recruitment-idx__item {
    margin: 0 0 60px;
  }
  #admission_index .recruitment-idx__item .box {
    margin: 0 0 30px;
  }
  #admission_index .recruitment-idx__item .box .list {
    margin: 0 0 15px;
  }
  #admission_index .type-idx__ico__item {
    text-align: center;
  }
  #admission_index .type-idx__ico__item img {
    max-width: 180px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #admission_index .type-idx__ico__item img {
    max-width: 150px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #admission_index .type-idx__ico__item .btn {
    margin: 30px 0 0 0;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #admission_index .type-idx__ico__item .btn.btn--md02 {
    min-width: inherit;
    padding-right: 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #admission_index .type-idx__pdf {
    border: 1px solid #23B8C5;
    border-left: 7px solid #23B8C5;
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    margin: 25px 0 0 0;
  }
  #admission_index .type-idx__pdf__dl {
    font-size: 1.6rem;
    flex: 0 0 auto;
    white-space: nowrap;
    margin: 0 0 0 30px;
  }
  #admission_index .type-idx__pdf__dl .__ico {
    color: #23B8C5;
    margin: 0 0 0 5px;
  }
  #admission_index .tuition-idx__item {
    margin-bottom: 60px;
  }
  #admission_index .tuition-idx__item__box {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
    padding-bottom: 0;
  }
  #admission_index .tuition-idx__item__box .icon-angle-right {
    margin: 0 0 0 5px;
  }
  #admission_index .support-idx__item {
    margin: 0 0 30px;
  }
  #admission_index .support-idx__item .btn {
    margin: 30px 0 0;
  }
  #admission_index .support-idx__item .icon-angle-right {
    margin: 0 0 0 5px;
  }
  /* ============================================================ */
  /* admission_ao,admission_suisen,admission_ippan */
  /* ============================================================ */
  .admission_ao .policy,
  .admission_suisen .policy,
  .admission_ippan .policy {
    padding: 40px 70px;
    margin: 0 0 40px;
  }
  .admission_ao .policy .ttl03,
  .admission_suisen .policy .ttl03,
  .admission_ippan .policy .ttl03 {
    font-size: 2.2rem;
  }
  .admission_ao .policy__item,
  .admission_suisen .policy__item,
  .admission_ippan .policy__item {
    display: flex;
  }
  .admission_ao .policy__item__num,
  .admission_suisen .policy__item__num,
  .admission_ippan .policy__item__num {
    flex: 0 0 30px;
    background: #23B8C5;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    margin: 0 10px 10px 0;
  }
  .admission_ao .table th,
  .admission_suisen .table th,
  .admission_ippan .table th {
    text-align: center;
    font-weight: 500;
  }
  .admission_ao .ad-lnav,
  .admission_suisen .ad-lnav,
  .admission_ippan .ad-lnav {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* admission_ao_form,admission_ippan_form,admission_suisen_form */
  /* ============================================================ */
  .admission_ao_form .table th,
  .admission_ippan_form .table th,
  .admission_suisen_form .table th {
    text-align: left;
    font-weight: 400;
  }
  .admission_ao_form .pdf-dl,
  .admission_ippan_form .pdf-dl,
  .admission_suisen_form .pdf-dl {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .admission_ao_form .pdf-dl__item,
  .admission_ippan_form .pdf-dl__item,
  .admission_suisen_form .pdf-dl__item {
    margin: 0 15px;
  }
  /* ============================================================ */
  /* admission_support */
  /* ============================================================ */
  .admission_tuition .lnav,
  .admission_support .lnav {
    padding: 20px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_tuition .lnav,
  .admission_support .lnav {
    padding: 20px 5px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_tuition .lnav__item:nth-child(3) .lnav__item__link__text::after, .admission_tuition .lnav__item:nth-child(6) .lnav__item__link__text::after,
  .admission_support .lnav__item:nth-child(3) .lnav__item__link__text::after,
  .admission_support .lnav__item:nth-child(6) .lnav__item__link__text::after {
    display: none;
  }
  .admission_tuition .lnav__item__link,
  .admission_support .lnav__item__link {
    text-align: left;
    padding: 10px 10px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_tuition .lnav__item__link,
  .admission_support .lnav__item__link {
    padding: 10px 0 10px 5px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_support .challenge-flow {
    margin: 0 0 60px;
    display: flex;
  }
  .admission_support .challenge-flow__item {
    margin: 0 50px 0 0;
    width: calc((100% - 101px) / 3);
    border: 2px solid #23B8C5;
    color: #23B8C5;
    display: flex;
    align-items: center;
    padding: 15px;
    font-weight: 500;
    position: relative;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_support .challenge-flow__item {
    margin: 0 30px 0 0;
    width: calc((100% - 61px) / 3);
    padding: 10px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_support .challenge-flow__item::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -37px;
    content: "\e909";
    font-family: 'icomoon';
    color: #000;
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_support .challenge-flow__item::after {
    right: -23px;
    font-size: 2.4rem;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_support .challenge-flow__item:last-child {
    margin: 0;
  }
  .admission_support .challenge-flow__item:last-child::after {
    display: none;
  }
  .admission_support .challenge-flow__item__label {
    font-size: 3.2rem;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    border-right: 2px solid #23B8C5;
  }
  .admission_support .challenge-price .icon-arrow-circle-right {
    margin: 0 15px;
  }
  /* ============================================================ */
  /* admission_tuition */
  /* ============================================================ */
  .admission_tuition .admission-bnr {
    width: 270px;
  }
  .admission_tuition .kyufu-ex {
    display: flex;
  }
  .admission_tuition .kyufu-ex .__label {
    background: #23B8C5;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin: 0 15px 0 0;
  }
  .admission_tuition .lone-flow {
    margin: 0 0 40px;
  }
  .admission_tuition .lone-flow .box__head {
    background: #23B8C5;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 500;
  }
  .admission_tuition .lone-flow .box--border-base {
    border-width: 2px;
    text-align: center;
  }
  .admission_tuition .lone-flow__body {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
  }
  .admission_tuition .lone-flow__body__icon {
    padding: 0 30px;
  }
  .admission_tuition .lone-flow__body > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  /* ============================================================ */
  /* admission_support */
  /* ============================================================ */
  .admission_support .ad-contact {
    font-weight: 500;
    font-size: 1.8rem;
  }
  .admission_support .ad-contact__tel {
    color: #23B8C5;
  }
  .admission_support .ad-contact__num {
    font-size: 2.4rem;
  }
  /* ============================================================ */
  /* admission_graduate */
  /* ============================================================ */
  .admission_graduate .student-list {
    justify-content: center;
  }
  .admission_graduate .student-list__item {
    border-radius: 15px;
    padding: 40px 25px;
    background: #EFF9FB;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_graduate .student-list__item {
    padding: 30px 15px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_graduate .student-list__item__data__img {
    width: 145px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .admission_graduate .student-list__item__data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .admission_graduate .student-list__item__data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    font-size: 1.5rem;
    background: #23B8C5;
  }
  .admission_graduate .student-list__item__data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
  }
  .admission_graduate .student-list__item__catch {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 20px;
    color: #23B8C5;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_graduate .student-list__item .btn--md02 {
    min-width: 170px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_graduate .row__inner-white {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .admission_graduate .row__inner-white {
    padding: 40px 30px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .admission_graduate .ttl01__text {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  .admission_graduate .student-data {
    display: flex;
    margin: 0 0 30px;
    align-items: center;
  }
  .admission_graduate .student-data__img {
    flex: 0 0 185px;
    margin: 0 30px 0 0;
  }
  .admission_graduate .student-data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .admission_graduate .student-data__text {
    flex: 1 1 auto;
  }
  .admission_graduate .student-data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    min-width: 110px;
    line-height: 25px;
    font-size: 1.5rem;
    background: #23B8C5;
  }
  .admission_graduate .student-data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }
  .admission_graduate .student-data__text__name__label {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0 15px 0 0;
  }
  .admission_graduate .student-data__text__name__type {
    color: #484848;
    font-size: 1.5rem;
    padding: 0 0 0 15px;
    font-weight: 400;
  }
  .admission_graduate .student-data__text__catch {
    font-size: 2.2rem;
    font-weight: 500;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #23B8C5;
  }
  .admission_graduate .student-data__text .__school {
    font-size: 1.5rem;
  }
  .admission_graduate .box {
    margin: 0 0 50px;
  }
  .admission_graduate .student-block {
    margin: 0 0 50px;
  }
  .admission_graduate .student-block .student-ttl {
    border-bottom: 3px dotted #666666;
    padding: 0 0 15px;
    margin: 0 0 30px;
  }
  .admission_graduate .student-block .question {
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  .admission_graduate .student-block .question__label {
    flex: 0 0 40px;
    margin: 0 15px 0 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.6rem;
    background: #23B8C5;
  }
  /* ============================================================ */
  /* department */
  /* ============================================================ */
  .department .ttl02__small {
    display: block;
    font-size: 2.2rem;
  }
  .department .banner {
    border-top: none;
  }
  .department .comment {
    display: flex;
  }
  .department .comment__img {
    flex: 0 0 270px;
    padding: 0 0 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .department .comment .__img {
    margin: 0 0 -25px;
    max-width: 100%;
  }
  .department .question {
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  .department .question__label {
    flex: 0 0 40px;
    margin: 0 15px 0 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.6rem;
  }
  .department .question.kodomo .question__label {
    background: #FF8843;
  }
  /* ============================================================ */
  /* department_index */
  /* ============================================================ */
  #department_index .department-type {
    margin-bottom: 20px;
  }
  #department_index .department-type__item__img {
    position: relative;
    padding: 0 0 60px;
    margin-bottom: 30px;
  }
  #department_index .department-type__item__img__text {
    padding: 25px 30px;
    width: 70%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #department_index .department-type__item__img__text__title {
    font-size: 2.5rem;
  }
  #department_index .department-type__item .catch01 {
    font-weight: 400;
    margin: 0 0 30px;
  }
  #department_index .department-type__item__point {
    box-shadow: 0px 0px 6px 3px #f3f3f3;
    padding: 20px 30px 50px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #department_index .department-type__item__point {
    padding: 10px 20px 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #department_index .department-type__item__point__list {
    margin: 0 0 20px;
  }
  #department_index .department-type__item__point__list__item {
    padding: 15px 0;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
  }
  #department_index .department-type__item__point__list__item__icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex: 0 0 70px;
    margin: 0 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.9rem;
    line-height: 1.0;
    color: #fff;
    flex-direction: column;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #department_index .department-type__item__point__list__item__icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin: 0 15px 0 0;
  }
}

@media print, screen and (min-width: 767.5px) {
  #department_index .department-type__item__point__list__item__icon__label {
    font-size: 1.0rem;
  }
  #department_index .department-type__item__point__list__item:last-child {
    border-bottom: none;
  }
  #department_index .department-type__item--kodomo .department-type__item__img__text {
    background: rgba(255, 136, 67, 0.9);
  }
  #department_index .department-type__item--kodomo .catch01 {
    color: #FF8843;
  }
  #department_index .department-type__item--kodomo .department-type__item__point__list__item {
    border-bottom-color: #FF8843;
  }
  #department_index .department-type__item--kodomo .department-type__item__point__list__item__icon {
    background: #FF8843;
  }
  #department_index .department-type__item--yoji .department-type__item__img__text {
    background: rgba(255, 96, 133, 0.9);
  }
  #department_index .department-type__item--yoji .catch01 {
    color: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item {
    border-bottom-color: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item__icon {
    background: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item a {
    color: #FF6085;
  }
  #department_index .triple,
  #department_index .dual {
    margin: 30px 0 0 0;
    padding: 30px;
  }
  #department_index .triple__title,
  #department_index .dual__title {
    font-size: 2.8rem;
    line-height: 1.0;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 30px;
  }
  #department_index .triple__title .__label,
  #department_index .dual__title .__label {
    background: #FF6085;
    border-radius: 18px;
    color: #fff;
    font-size: 1.7rem;
    position: relative;
    height: 35px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    white-space: nowrap;
  }
  #department_index .triple__title .__label::before,
  #department_index .dual__title .__label::before {
    content: "";
    position: absolute;
    bottom: 7px;
    right: -14px;
    border: 6px solid transparent;
    border-left: 18px solid #FF6085;
    transform: rotate(-15deg);
  }
  #department_index .triple .__text,
  #department_index .dual .__text {
    color: #FF6085;
  }
  #department_index .triple .__text .icon-square,
  #department_index .dual .__text .icon-square {
    margin: 0 10px 0 0;
  }
  #department_index .triple .__label {
    background: #FF8843;
  }
  #department_index .triple .__label::before {
    border-left: 18px solid #FF8843;
  }
  #department_index .triple .__text {
    color: #FF8843;
  }
  #department_index .support__ttl {
    background: #7378B7;
    color: #fff;
    font-weight: 500;
    margin: 0 0 30px;
    font-size: 2rem;
    padding: 10px 20px;
  }
  #department_index .support__item {
    margin-bottom: 30px;
  }
  #department_index .support__item img {
    margin: 0 0 15px;
  }
  #department_index .support__ttl02 {
    font-weight: 500;
    font-size: 2rem;
    color: #7378B7;
    margin: 0 0 15px;
    display: flex;
    align-items: center;
    line-height: 1.5;
  }
  #department_index .support__ttl02 .__label {
    background: #7378B7;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 0 10px 0 0;
    flex: 0 0 40px;
  }
  #department_index .support__list {
    border: 1px solid #7378B7;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
  }
  #department_index .support__list::after {
    display: block;
    content: "";
    clear: both;
  }
  #department_index .support__list__img {
    float: right;
    margin: 0 0 15px 15px;
    max-width: 30%;
  }
  #department_index .support__list__img img {
    max-width: 100%;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  #department_index .support .row__inner-white {
    padding: 40px 30px;
  }
}

@media print, screen and (min-width: 767.5px) {
  #department_index .ttl04--index::after {
    border-bottom: 2px solid #7378B7;
  }
  /* ============================================================ */
  /* department_kodomo,.department_yoji */
  /* ============================================================ */
  .department_kodomo .catch01--kodomo,
  .department_yoji .catch01--kodomo {
    color: #FF8843;
  }
  .department_kodomo .catch01--yoji,
  .department_yoji .catch01--yoji {
    color: #FF6085;
  }
  .department_kodomo .ttl04--kodomo::after,
  .department_yoji .ttl04--kodomo::after {
    border-bottom: 2px solid #FF8843;
  }
  .department_kodomo .ttl04--yoji::after,
  .department_yoji .ttl04--yoji::after {
    border-bottom: 2px solid #FF6085;
  }
  .department_kodomo .ttl06--kodomo,
  .department_yoji .ttl06--kodomo {
    border-bottom: 1px solid #FF8843;
  }
  .department_kodomo .ttl06--kodomo:first-letter,
  .department_yoji .ttl06--kodomo:first-letter {
    color: #FF8843;
  }
  .department_kodomo .ttl06--yoji,
  .department_yoji .ttl06--yoji {
    border-bottom: 1px solid #FF6085;
  }
  .department_kodomo .ttl06--yoji:first-letter,
  .department_yoji .ttl06--yoji:first-letter {
    color: #FF6085;
  }
  .department_kodomo .ttl07--kodomo,
  .department_yoji .ttl07--kodomo {
    border-left: 6px solid #FF8843;
  }
  .department_kodomo .ttl07--yoji,
  .department_yoji .ttl07--yoji {
    border-left: 6px solid #FF6085;
  }
  .department_kodomo .department-about,
  .department_yoji .department-about {
    align-items: center;
    margin: 0 0 40px;
  }
  .department_kodomo .department-about__guide,
  .department_yoji .department-about__guide {
    margin-left: 40px;
    flex: 1 1 auto;
  }
  .department_kodomo .department-about__guide__item,
  .department_yoji .department-about__guide__item {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    padding: 15px 0;
    display: flex;
  }
  .department_kodomo .department-about__guide__item:first-child,
  .department_yoji .department-about__guide__item:first-child {
    border-top-width: 1px;
    border-top-style: dotted;
  }
  .department_kodomo .department-about__guide__item__label,
  .department_yoji .department-about__guide__item__label {
    border-right-width: 4px;
    border-right-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
    margin-right: 25px;
    flex: 0 0 110px;
    font-weight: 500;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item {
    border-bottom-color: #FF8843;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item:first-child,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item:first-child {
    border-top-color: #FF8843;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item__label,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item__label {
    border-right-color: #FF8843;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item,
  .department_yoji .department-about__guide--yoji .department-about__guide__item {
    border-bottom-color: #FF6085;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item:first-child,
  .department_yoji .department-about__guide--yoji .department-about__guide__item:first-child {
    border-top-color: #FF6085;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item__label,
  .department_yoji .department-about__guide--yoji .department-about__guide__item__label {
    border-right-color: #FF6085;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_kodomo .department-point,
  .department_yoji .department-point {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_kodomo .department-point > *,
  .department_yoji .department-point > * {
    margin-left: 10px;
    margin-right: 10px;
    width: calc((100% - 61px) / 3);
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_kodomo .department-point__item,
  .department_yoji .department-point__item {
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    font-weight: 500;
  }
  .department_kodomo .department-point__item__title,
  .department_yoji .department-point__item__title {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    font-size: 2.2rem;
    padding: 0 0 5px 0;
    margin: 0 0 10px;
  }
  .department_kodomo .department-point--kodomo .department-point__item,
  .department_yoji .department-point--kodomo .department-point__item {
    background: #FFFCEF;
  }
  .department_kodomo .department-point--kodomo .department-point__item__title,
  .department_yoji .department-point--kodomo .department-point__item__title {
    color: #FF8843;
    border-bottom-color: #FF8843;
  }
  .department_kodomo .department-point--yoji .department-point__item,
  .department_yoji .department-point--yoji .department-point__item {
    background: #FFF6F8;
  }
  .department_kodomo .department-point--yoji .department-point__item__title,
  .department_yoji .department-point--yoji .department-point__item__title {
    color: #FF6085;
    border-bottom-color: #FF6085;
  }
  .department_kodomo .department-shikaku,
  .department_yoji .department-shikaku {
    margin: 0 0 50px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block,
  .department_yoji .department-shikaku .department-shikaku__block {
    margin: 0 0 30px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block .ttl08,
  .department_yoji .department-shikaku .department-shikaku__block .ttl08 {
    margin: 0 0 5px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block:last-child,
  .department_yoji .department-shikaku .department-shikaku__block:last-child {
    margin: 0;
  }
  .department_kodomo .department-shikaku:last-child,
  .department_yoji .department-shikaku:last-child {
    margin: 0;
  }
  .department_kodomo .table--department th, .department_kodomo .table--department td,
  .department_yoji .table--department th,
  .department_yoji .table--department td {
    text-align: center;
  }
  .department_kodomo .table--department thead th,
  .department_yoji .table--department thead th {
    color: #fff;
  }
  .department_kodomo .table--department--kodomo td,
  .department_yoji .table--department--kodomo td {
    background: #FFFCEF;
  }
  .department_kodomo .table--department--kodomo th:first-child,
  .department_yoji .table--department--kodomo th:first-child {
    background: #FFF5C9;
  }
  .department_kodomo .table--department--kodomo thead th,
  .department_yoji .table--department--kodomo thead th {
    background: #FF8843;
  }
  .department_kodomo .table--department--kodomo thead th:first-child,
  .department_yoji .table--department--kodomo thead th:first-child {
    background: #FF8843;
  }
  .department_kodomo .table--department--yoji td,
  .department_yoji .table--department--yoji td {
    background: #FFF9FB;
  }
  .department_kodomo .table--department--yoji th:first-child,
  .department_yoji .table--department--yoji th:first-child {
    background: #FFE4EA;
  }
  .department_kodomo .table--department--yoji thead th,
  .department_yoji .table--department--yoji thead th {
    background: #FF6085;
  }
  .department_kodomo .table--department--yoji thead th:first-child,
  .department_yoji .table--department--yoji thead th:first-child {
    background: #FF6085;
  }
  .department_kodomo .department-voice__title,
  .department_yoji .department-voice__title {
    text-align: center;
    margin: 0 0 40px;
  }
  .department_kodomo .department-voice__question,
  .department_yoji .department-voice__question {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 500;
    margin: 0 0 30px;
  }
  .department_kodomo .department-voice__question__label,
  .department_yoji .department-voice__question__label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 55px;
    height: 55px;
    flex: 0 0 55px;
    border-radius: 50%;
    font-size: 2.4rem;
    margin: 0 10px 0 0;
  }
  .department_kodomo .department-voice__question__label--kodomo,
  .department_yoji .department-voice__question__label--kodomo {
    background: #FF8843;
  }
  .department_kodomo .department-voice__question__label--yoji,
  .department_yoji .department-voice__question__label--yoji {
    background: #FF6085;
  }
  .department_kodomo .department-voice__img,
  .department_yoji .department-voice__img {
    margin: 0 auto 40px;
    max-width: 550px;
  }
  .department_kodomo .department-voice__img img,
  .department_yoji .department-voice__img img {
    width: 100%;
  }
  .department_kodomo .department-voice__item,
  .department_yoji .department-voice__item {
    margin-bottom: 40px;
  }
  .department_kodomo .department-voice__item--kodomo,
  .department_yoji .department-voice__item--kodomo {
    background: #FFF5EF;
  }
  .department_kodomo .department-voice__item--yoji,
  .department_yoji .department-voice__item--yoji {
    background: #FFF6F8;
  }
  .department_kodomo .department-voice__item__img,
  .department_yoji .department-voice__item__img {
    margin: 0 0 15px;
    text-align: center;
  }
  .department_kodomo .department-voice__item__img img,
  .department_yoji .department-voice__item__img img {
    width: 138px;
  }
  .department_kodomo .department-voice__item .ttl08,
  .department_yoji .department-voice__item .ttl08 {
    text-align: center;
  }
  .department_kodomo .department-voice__item .ttl08__small,
  .department_yoji .department-voice__item .ttl08__small {
    font-size: 1.5rem;
    padding: 0 10px 0 0;
  }
  .department_kodomo .contents-wrap__inner,
  .department_yoji .contents-wrap__inner {
    margin-top: 30px;
    position: relative;
    padding: 70px 50px 60px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_kodomo .contents-wrap__inner,
  .department_yoji .contents-wrap__inner {
    padding: 70px 20px 40px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_kodomo .contents-wrap__inner::before,
  .department_yoji .contents-wrap__inner::before {
    width: 135px;
    height: 167px;
    content: "";
    position: absolute;
    top: -30px;
    left: 120px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_kodomo .contents-wrap__inner::before,
  .department_yoji .contents-wrap__inner::before {
    left: 40px;
    width: 106px;
    height: 131px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_kodomo .contents-wrap__inner .row__inner-white,
  .department_yoji .contents-wrap__inner .row__inner-white {
    border-radius: 0;
    padding: 60px 70px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_kodomo .contents-wrap__inner .row__inner-white,
  .department_yoji .contents-wrap__inner .row__inner-white {
    padding: 40px 30px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_kodomo .contents-wrap__merit__item,
  .department_yoji .contents-wrap__merit__item {
    margin: 0 0 30px;
    display: table;
  }
  .department_kodomo .contents-wrap__merit__item > *:first-child,
  .department_yoji .contents-wrap__merit__item > *:first-child {
    display: table-cell;
    white-space: nowrap;
    padding: 0 10px 0 0;
  }
  .department_kodomo .contents-wrap__merit__item > *:first-child .__num,
  .department_yoji .contents-wrap__merit__item > *:first-child .__num {
    font-weight: 500;
    color: #fff;
    content: attr(data-text);
    background: #FF6085;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  .department_kodomo .contents-wrap__merit__item .__txt,
  .department_yoji .contents-wrap__merit__item .__txt {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 1.5;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__inner::before,
  .department_yoji .contents-wrap.dual .contents-wrap__inner::before {
    background: url(/common/img/department/yoji/ico_dual.svg) no-repeat;
    background-size: contain;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__merit .__num,
  .department_yoji .contents-wrap.dual .contents-wrap__merit .__num {
    background: #FF6085;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__merit .__txt,
  .department_yoji .contents-wrap.dual .contents-wrap__merit .__txt {
    color: #FF6085;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__inner::before,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__inner::before {
    background: url("/common/img/department/kodomo/pic_primaryteacher_01.svg") no-repeat;
    background-size: contain;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__merit .__num,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__merit .__num {
    background: #FF8843;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__merit .__txt,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__merit .__txt {
    color: #FF8843;
  }
  /* ============================================================ */
  /* .department_yoji */
  /* ============================================================ */
  .department_yoji .dual .table--department--yoji th, .department_yoji .dual .table--department--yoji td {
    font-size: 1.5rem;
  }
  .department_yoji .dual .table--department--yoji td.bg-white {
    background: #fff;
  }
  .department_yoji .dual .table--department--yoji td.bg-yellowlight {
    background: #FFE98F;
  }
  .department_yoji .dual__flow {
    margin: 0 0 30px;
  }
  .department_yoji .dual__flow__item {
    margin-bottom: 40px;
  }
  .department_yoji .dual__flow__item:not(:last-child)::after {
    content: "";
    background: url(/common/img/department/yoji/ico_dual_yajirushi.svg);
    background-size: cover;
    width: 40px;
    height: 32px;
    margin: 0 auto;
  }
  .department_yoji .dual__voice-box {
    display: flex;
  }
  .department_yoji .dual__voice-box__img {
    flex: 0 0 270px;
    padding: 0 0 0 35px;
  }
  .department_yoji .dual__voice-box .__img {
    margin: 0 0 -25px;
    max-width: 100%;
  }
  /* ============================================================ */
  /* department_kodomo */
  /* ============================================================ */
  .department_kodomo .primaryteacher__list {
    display: flex;
  }
  .department_kodomo .primaryteacher__list__item {
    margin: 0 20px 0 0;
  }
  .department_kodomo .primaryteacher__list__item .__label {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    border-radius: 50%;
    background-color: #f7b52c;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
  }
  .department_kodomo .primaryteacher__list__item .__label::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 3px;
    border-style: solid;
    border-width: 12.5px 0 12.5px 13px;
    border-color: transparent transparent transparent #f7b52c;
    translate: 100% -50%;
  }
  /* ============================================================ */
  /* department_student */
  /* ============================================================ */
  .department_student .student-list__item {
    border-radius: 15px;
    padding: 40px 25px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_student .student-list__item {
    padding: 30px 15px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_student .student-list__item__data {
    display: flex;
    margin: 0 0 20px;
  }
  .department_student .student-list__item__data__img {
    width: 55%;
    padding: 0 15px 0 0;
  }
  .department_student .student-list__item__data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .department_student .student-list__item__data__text {
    width: 45%;
  }
  .department_student .student-list__item__data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    font-size: 1.5rem;
  }
  .department_student .student-list__item__data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
  }
  .department_student .student-list__item__catch {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 20px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_student .student-list__item .btn--md02 {
    min-width: 170px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_student .student-list--kodomo .student-list__item {
    background: #FFFCEF;
  }
  .department_student .student-list--kodomo .student-list__item__data__text__label {
    background: #FF8843;
  }
  .department_student .student-list--kodomo .student-list__item__catch {
    color: #FF8843;
  }
  .department_student .student-list--yoji .student-list__item {
    background: #FFF5F6;
  }
  .department_student .student-list--yoji .student-list__item__data__text__label {
    background: #FF6085;
  }
  .department_student .student-list--yoji .student-list__item__catch {
    color: #FF6085;
  }
  /* ============================================================ */
  /* department_student_student */
  /* ============================================================ */
  .department_student_student .row__inner-white {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_student_student .row__inner-white {
    padding: 40px 30px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_student_student .ttl01__text {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  .department_student_student .student-data {
    display: flex;
    margin: 0 0 30px;
    align-items: center;
  }
  .department_student_student .student-data__img {
    flex: 0 0 185px;
    margin: 0 30px 0 0;
  }
  .department_student_student .student-data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .department_student_student .student-data__text {
    flex: 1 1 auto;
  }
  .department_student_student .student-data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    min-width: 110px;
    line-height: 25px;
    font-size: 1.5rem;
  }
  .department_student_student .student-data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }
  .department_student_student .student-data__text__name__school {
    font-size: 1.5rem;
    padding: 0 0 0 15px;
    font-weight: 400;
  }
  .department_student_student .student-data__text__catch {
    font-size: 2.2rem;
    font-weight: 500;
    padding: 0 0 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .department_student_student .box {
    margin: 0 0 50px;
  }
  .department_student_student .student-block {
    margin: 0 0 50px;
  }
  .department_student_student .student-block .student-ttl {
    border-bottom: 3px dotted #666666;
    padding: 0 0 15px;
    margin: 0 0 30px;
  }
  .department_student_student .student-block .float__item > img {
    width: 265px;
  }
  .department_student_student .student-block .table--list th {
    font-weight: 500;
    width: 170px;
    padding: 15px 0 15px 15px;
    vertical-align: top;
  }
}

@media screen and (min-width: 767.5px) and (min-width: 767.5px) and (max-width: 1024px) {
  .department_student_student .student-block .table--list th {
    width: 110px;
  }
}

@media print, screen and (min-width: 767.5px) {
  .department_student_student .student-block .table--list td {
    vertical-align: top;
  }
  .department_student_student .kodomo .student-data__text__label {
    background: #FF8843;
  }
  .department_student_student .kodomo .student-data__text__catch {
    color: #FF8843;
    border-bottom-color: #FF8843;
  }
  .department_student_student .kodomo .question__label {
    background: #FF8843;
  }
  .department_student_student .yoji .student-data__text__label {
    background: #FF6085;
  }
  .department_student_student .yoji .student-data__text__catch {
    color: #FF6085;
    border-bottom-color: #FF6085;
  }
  .department_student_student .yoji .question__label {
    background: #FF6085;
  }
  /* ============================================================ */
  /* guide */
  /* ============================================================ */
  /* ============================================================ */
  /* guide_index */
  /* ============================================================ */
  #guide_index .greeting {
    background: url(/common/img/guide/index/pic_head-teacher_01.jpg) center center no-repeat;
    background: cover;
    height: 630px;
    padding: 0;
    display: flex;
    align-items: center;
  }
  #guide_index .greeting .row__inner {
    width: 100%;
  }
  #guide_index .greeting .row__inner .ttl07 {
    font-size: 1.8rem;
    margin: 0 0 40px;
  }
  #guide_index .greeting .row__inner .ttl07__name {
    font-size: 3.2rem;
  }
  #guide_index .ability-list {
    margin: 0 0 50px;
  }
  #guide_index .ability-list__item {
    text-align: center;
    position: relative;
    border: 2px dashed #23B8C5;
    border-radius: 10px;
    padding: 60px 20px 30px;
    text-align: center;
  }
  #guide_index .ability-list__item__label {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -25px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23B8C5;
  }
  #guide_index .ability-list__item__title {
    color: #23B8C5;
    font-size: 2.2rem;
    margin: 0 0 15px;
  }
  #guide_index .pic-message {
    max-width: 440px;
  }
  #guide_index .professional__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #guide_index .charactor-area {
    align-items: center;
  }
  #guide_index .charactor-area img {
    width: 75%;
  }
  #guide_index .history__item {
    display: flex;
  }
  #guide_index .history__item__year {
    flex: 0 0 170px;
    display: block;
    font-weight: 500;
    padding: 0 30px 0 20px;
  }
  /* ============================================================ */
  /* facility_access */
  /* ============================================================ */
  .facility_access .school-title {
    margin: 0 0 30px;
    line-height: 1.7;
    font-size: 2.2rem;
  }
  .facility_access .school-title__label {
    font-weight: 400;
    font-size: 1.5rem;
  }
  .facility_access .root {
    margin: 80px 0 40px 0;
  }
  .facility_access .root__list {
    display: flex;
    flex-wrap: wrap;
  }
  .facility_access .root__list__item {
    width: calc((100% - 141px) / 3);
    margin: 0 70px 0px 0;
    position: relative;
  }
  .facility_access .root__list__item:nth-child(3), .facility_access .root__list__item:last-child {
    margin: 0;
  }
  .facility_access .root__list__item:nth-child(3)::after, .facility_access .root__list__item:last-child::after {
    display: none;
  }
  .facility_access .root__list__item::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    bottom: -25px;
    right: -40px;
    content: "\f0da";
    font-family: 'icomoon';
    color: #23B8C5;
    font-size: 4.0rem;
  }
  .facility_access .root__list__item > img {
    width: 100%;
    margin: 0 0 10px;
  }
  .facility_access .root__list__item__text {
    height: 130px;
  }
  .facility_access .root__list__item .ttl08 {
    margin: 0 0 5px;
  }
  .facility_access .row__inner-white {
    border-radius: 0;
  }
  /* ============================================================ */
  /* target.html */
  /* ============================================================ */
  .target .target-main {
    margin: 0 0 60px;
  }
  .target .target-main__visual {
    border-radius: 15px;
  }
  .target .target-main__ttl {
    font-size: 2.2rem;
    color: #000;
    font-weight: 500;
    height: 140px;
    display: flex;
    align-items: center;
    background: url(/common/img/target/bg_text.png) left center no-repeat;
    background-size: contain;
  }
  .target .box {
    margin-bottom: 30px;
  }
  .target .box .ttl09__text {
    padding-left: 10px;
    display: block;
    border-left: 5px solid #23B8C5;
  }
  /* ============================================================ */
  /* restart */
  /* ============================================================ */
  .restart .benefit {
    margin-bottom: 40px;
  }
  .restart .benefit__item {
    padding: 10px 10px 20px;
    text-align: center;
    background: #fff;
  }
  .restart .benefit__item__title {
    background: #008C98;
    padding: 5px 0;
    margin: 0 0 20px;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .restart .benefit__item__text {
    font-size: 2rem;
  }
  .restart .benefit__item__text__em {
    color: #FF6085;
    font-weight: 500;
  }
  .restart .benefit-flow__item {
    display: flex;
    padding: 10px;
  }
  .restart .benefit-flow__item:nth-child(odd) {
    background: #EFF9FB;
  }
  .restart .benefit-flow__item__label {
    display: block;
    background: #008C98;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    flex: 0 0 30px;
  }
  .restart .benefit-flow__item__em {
    background: #FF6085;
    color: #fff;
    padding: 3px 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  .restart .support__point03-box {
    display: flex;
  }
  .restart .support__point03-box .__label {
    background: #23B8C5;
    color: #fff;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 90px;
    margin: 0 25px 0 0;
    font-size: 2rem;
    font-weight: 500;
  }
  .restart .support__voice-box {
    display: flex;
  }
  .restart .support__voice-box__img {
    flex: 0 0 230px;
    padding: 0 0 0 35px;
  }
  .restart .support__voice-box .__img {
    margin: 0 0 -25px;
    max-width: 100%;
  }
  /* ============================================================ */
  /* recruitmentofficer */
  /* ============================================================ */
  .recruitmentofficer .target-main__ttl {
    background-position: center center;
    justify-content: center;
  }
  .recruitmentofficer .box .target-main__ttl,
  .recruitmentofficer .btn-area-ttl {
    height: auto;
    background: none;
    justify-content: center;
    margin: 0 0 20px;
  }
  .recruitmentofficer .btn--md {
    min-width: 100%;
  }
}

@media screen and (max-width: 767.499px) {
  /* ============================================================ */
  /* 基本設定 */
  /* ============================================================ */
  html html {
    font-size: calc(100vw / 37.5);
  }
  body {
    background: #fff;
  }
  .wrap {
    font-family: 'Noto Sans JP', sans-serif;
    color: #303030;
    font-size: 1.5rem;
    line-height: 1.8;
    overflow-x: hidden;
  }
  .pc-view {
    display: none !important;
  }
  a {
    color: #23B8C5;
    text-decoration: underline;
  }
  /* --------------------------------------------------- */
  /* topic-path */
  /* --------------------------------------------------- */
  .topic-path {
    padding: 10px 0;
    font-size: 1.3rem;
    line-height: 1.6;
    width: 100%;
    font-weight: 400;
    color: #5E5E5E;
    border-bottom: 1px solid #e8f8f9;
  }
  .topic-path__inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .topic-path__item__link {
    color: #5E5E5E;
  }
  .topic-path__item:not(:last-child)::after {
    margin: 0 10px;
    content: "\f105";
    font-family: 'icomoon';
    color: #23B8C5;
  }
  /* --------------------------------------------------- */
  /* pagetop */
  /* --------------------------------------------------- */
  .pagetop {
    position: fixed;
    right: 15px;
    bottom: 100px;
  }
  .pagetop__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: rgba(255, 136, 67, 0.9);
    color: #fff;
    font-size: 2.4rem;
    text-decoration: none;
    border-radius: 50%;
  }
  /* ============================================================ */
  /* header */
  /* ============================================================ */
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  header::after {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
  }
  .show header::after {
    opacity: 1;
    visibility: visible;
  }
  header .header-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 90px;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    z-index: 60;
  }
  header .header-body .header-logo {
    top: 10px;
  }
  header .header-body .header-logo__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
  }
  header .header-body .header-logo__link__icon {
    margin: 0 5px 0 0;
    max-width: 35px;
  }
  header .header-body .header-logo__link__logo {
    max-width: 180px;
  }
  header .header-body .sp-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    height: 50px;
  }
  header .header-body .sp-header .sp-contact {
    font-size: 2.8rem;
    color: #23B8C5;
    margin: 5px 20px 0 0;
    text-decoration: none;
  }
  header .header-body .header-menu {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 40;
    cursor: pointer;
  }
  header .header-body .header-menu__item {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #23B8C5;
    border-radius: 3px;
  }
  header .header-body .header-menu__item:nth-of-type(1) {
    top: 0;
  }
  header .header-body .header-menu__item:nth-of-type(2) {
    top: 9px;
  }
  header .header-body .header-menu__item:nth-of-type(3) {
    bottom: 0;
  }
  header .header-body .header-menu.active .header-menu__item:nth-of-type(1) {
    transform: translateY(10px) rotate(-315deg);
  }
  header .header-body .header-menu.active .header-menu__item:nth-of-type(2) {
    opacity: 0;
  }
  header .header-body .header-menu.active .header-menu__item:nth-of-type(3) {
    transform: translateY(-8px) rotate(315deg);
  }
  header .header-body .sp-fix-menu {
    width: 100%;
    height: 40px;
    background: #fff;
    overflow: none;
    transition: all 0.4s ease-in-out;
  }
  header .header-body .sp-fix-menu__inner {
    display: flex;
    margin: 0 0 10px;
  }
  header .header-body .sp-fix-menu__item {
    height: 38px;
    display: block;
    color: #fff;
    margin: 0 2px 0 0;
    width: calc( (100% - 2px ) / 3);
    text-decoration: none;
    border-top: 2px solid #fff;
  }
  header .header-body .sp-fix-menu__item__text {
    font-size: 1.3rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
    text-align: center;
  }
  header .header-body .sp-fix-menu__item--oc {
    background: #FF8843;
    width: 45%;
  }
  header .header-body .sp-fix-menu__item--request {
    background: #23B8C5;
  }
  header .header-body .sp-fix-menu__item--contact {
    background: #006281;
    margin: 0;
  }
  header .header-body .sp-fix-menu__item:hover {
    opacity: 0.7;
  }
  header .header-inner {
    height: 0;
  }
  header .sp-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 0;
    line-height: 1.5;
    overflow-y: auto;
    transform: translateY(-100%);
    transition: 0.3s all;
  }
  .show header .sp-menu {
    transform: translateY(0);
    background: #23B8C5;
  }
  header .gnav {
    background: rgba(35, 184, 197, 0.95);
  }
  header .gnav__item {
    border-bottom: 1px solid #A3EAF1;
  }
  header .gnav__item__link {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
  }
  header .gnav__item__link__text {
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }
  header .gnav__item__link__text::before {
    padding-right: 10px;
    display: inline;
    font-size: 1.6rem;
    content: attr(data-text);
    color: #fff;
    font-weight: 500;
  }
  header .gnav__item__link [class^="icon"] {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    display: block;
    color: #fff;
  }
  header .gnav__item__link.active .icon-angle-down::before {
    display: block;
    transform: rotate(180deg);
  }
  header .gnav__dropdown {
    display: none;
  }
  header .gnav__dropdown__inner {
    background: #0999A7;
    padding: 0 15px;
  }
  header .gnav__dropdown__inner__midashi {
    border-bottom: 1px solid #58DCE8;
  }
  header .gnav__dropdown__inner__midashi__link {
    width: 100%;
    display: block;
    padding: 15px 0;
    color: #fff;
    text-decoration: none;
  }
  header .gnav__dropdown__inner__midashi__link [class^="icon-angle-"] {
    margin: 0 10px 0 0;
  }
  header .gnav__dropdown__inner__body {
    display: block;
  }
  header .gnav__dropdown__inner__body__item {
    width: 100%;
    margin: 0;
    padding: 15px 0;
    display: block;
    color: #fff;
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid #58DCE8;
  }
  header .gnav__dropdown__inner__body__item__text {
    padding: 0 0 0 18px;
  }
  header .gnav__dropdown__inner__body__item__text [class^="icon-angle-"] {
    margin: 0 10px 0 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 5px;
  }
  header .gnav__dropdown__inner__body__item:last-child {
    border-bottom: none;
  }
  header .gnav__dropdown--sns {
    display: block;
  }
  header .gnav__dropdown--sns .gnav__dropdown__inner {
    padding: 0 25px;
    background: #23B8C5;
  }
  header .gnav__dropdown--sns .gnav__dropdown__inner__body {
    display: flex;
  }
  header .gnav__dropdown--sns .gnav__dropdown__inner__body__item {
    width: 47%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    margin-bottom: 15px;
    padding: 15px 0 5px;
  }
  header .gnav__dropdown--sns .gnav__dropdown__inner__body__item__text {
    padding: 5px 0 0;
  }
  header .gnav__dropdown--sns .gnav__dropdown__inner__body__item:last-child {
    border-bottom: 1px solid #58DCE8;
  }
  header .header-target {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #A3EAF1;
    margin: 0 0 60px;
  }
  header .header-target__item {
    width: calc((100% - 1px) / 2);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #A3EAF1;
  }
  header .header-target__item__link {
    padding: 15px;
    width: 100%;
    display: block;
    color: #fff;
    text-decoration: none;
  }
  header .header-target__item:last-child {
    border-bottom: none;
  }
  header .header-target__item:nth-child(odd) {
    border-right: 1px solid #A3EAF1;
  }
  header .menu-close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: #fff;
    color: #23B8C5;
    cursor: pointer;
    font-size: 2.0rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  /* ============================================================ */
  /* .contents */
  /* ============================================================ */
  .contents .row {
    padding: 40px 0;
  }
  .contents .row__inner {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .contents .row__inner-white {
    background: #fff;
    padding: 30px 15px;
    border-radius: 15px;
  }
  .contents .row__inner-bluelignt {
    background: #EFF9FB;
    padding: 30px 15px;
    border-radius: 15px;
  }
  .contents .row__inner-yellowlignt {
    background: #FFFCEF;
    padding: 30px 15px;
    border-radius: 15px;
  }
  .contents .row__inner-orange {
    background: #FCF2D8;
    padding: 30px 15px;
    border-radius: 15px;
  }
  .contents .row__inner-pinklight {
    background: #FFEFF3;
    padding: 30px 15px;
    border-radius: 15px;
  }
  .contents--column2 .main {
    margin: 0 0 40px;
  }
  /* ============================================================ */
  /* 汎用スタイル */
  /* ============================================================ */
  .icon-fix-left,
  .icon-fix-right {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .icon-fix-left {
    left: 10px;
  }
  .icon-fix-right {
    right: 10px;
  }
  .bg-dotted {
    background: url(/common/img/common/bg_pattern_02.svg) center center repeat;
  }
  .bg-lattice {
    background: url(/common/img/common/bg_pattern.svg) center center repeat;
  }
  .bg-gray {
    background: #F6F6F6;
  }
  .bg-bluelight {
    background: #EFF9FB;
  }
  .bg-yellowlight {
    background: #FFFCEF;
  }
  .bg-white {
    background: #fff;
  }
  .link-scroll {
    padding-top: 90px;
    margin-top: -90px;
  }
  /* --------------------------------------------------- */
  /* テキスト */
  /* --------------------------------------------------- */
  p {
    margin-bottom: 20px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .indent {
    display: table;
    margin: 0;
  }
  .indent > *:first-child {
    display: table-cell;
    white-space: nowrap;
  }
  .icon-external-link {
    font-size: 1.4rem;
    margin: 0 0 0 5px;
  }
  .icon-file-pdf-o {
    margin: 0 5px 0 0;
  }
  .block01 {
    margin: 0 0 30px;
  }
  .movie-link {
    display: block;
    position: relative;
  }
  .movie-link::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background: url(/common/img/common/ico_play.png) no-repeat;
    background-size: 70px 70px;
    width: 70px;
    height: 70px;
  }
  /* --------------------------------------------------- */
  /* 見出し */
  /* --------------------------------------------------- */
  .ttl01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background: url(/common/img/common/bg_ttl01.jpg) center center no-repeat;
    background-size: cover;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    margin: 90px 0 0;
    flex-direction: column;
  }
  .feature .ttl01 {
    background-image: url(/common/img/feature/bg_ttl01.jpg);
  }
  .admission .ttl01 {
    background-image: url(/common/img/admission/bg_ttl01.jpg);
  }
  .admission_graduate .ttl01 {
    background-image: url(/common/img/admission/graduate/bg_ttl01.jpg);
  }
  .department .ttl01 {
    background-image: url(/common/img/department/bg_ttl01.jpg);
  }
  .department_student .ttl01 {
    background-image: url(/common/img/department/student/bg_ttl01.jpg);
  }
  .guide .ttl01 {
    background-image: url(/common/img/guide/bg_ttl01.jpg);
  }
  .news .ttl01 {
    background-image: url(/common/img/news/bg_ttl01.png);
  }
  .contact .ttl01 {
    background-image: url(/common/img/contact/bg_ttl01.jpg);
  }
  .request .ttl01 {
    background-image: url(/common/img/request/bg_ttl01.jpg);
  }
  .facility .ttl01 {
    background-image: url(/common/img/facility/index/bg_ttl01.jpg);
  }
  .facility_access .ttl01 {
    background-image: url(/common/img/facility/access/bg_ttl01.jpg);
  }
  .opencampus .ttl01 {
    background-image: url(/common/img/opencampus/bg_ttl01.jpg);
  }
  .opencampus_visit .ttl01 {
    background-image: url(/common/img/opencampus/visit/bg_ttl01.png);
  }
  .opencampus_online .ttl01 {
    background-image: url(/common/img/opencampus/online/bg_ttl01.png);
  }
  .opencampus_soudan .ttl01 {
    background-image: url(/common/img/opencampus/soudan/bg_ttl01.jpg);
  }
  .target .ttl01 {
    background-image: url(/common/img/target/bg_ttl01.png);
  }
  .sheet .ttl01 {
    background-image: url(/common/img/sheet/bg_ttl01.png);
  }
  .ttl01 [class^="icon-"] {
    display: block;
    font-size: 3.0rem;
    margin: 0 0 10px;
  }
  .department .ttl01 [class^="icon-"] {
    font-size: 2.4rem;
  }
  .ttl01__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  .ttl01__img > img {
    max-height: 100px;
  }
  .ttl02 {
    margin: 0 0 20px;
    color: #000;
    font-size: 2.8rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl02::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    background: url(/common/img/common/ttl-line.svg) no-repeat;
    width: 55px;
    height: 4.8px;
  }
  .ttl03 {
    margin: 0 0 20px;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
    text-align: center;
  }
  .ttl03._left_ {
    text-align: left;
  }
  .ttl04 {
    margin: 0 0 20px;
    color: #000;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl04::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    border-bottom: 2px solid #23B8C5;
    width: 47px;
  }
  .ttl05 {
    margin: 0 0 20px;
    color: #000;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    position: relative;
    padding: 0 0 15px;
  }
  .ttl05::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    content: "";
    border-bottom: 1px solid #23B8C5;
    width: 65%;
  }
  .ttl06 {
    margin: 0 0 15px;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .ttl06:first-letter {
    color: #23B8C5;
  }
  .ttl07 {
    padding: 3px 0 3px 7px;
    margin: 0 0 15px;
    border-left: 4px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .ttl08 {
    margin: 0 0 15px;
    color: #000;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .ttl09 {
    margin: 0 0 10px;
    padding: 0 0 5px;
    color: #000;
    font-size: 1.8rem;
    display: block;
    font-weight: 500;
    border-bottom: 1px solid #23B8C5;
  }
  .ttl10 {
    margin: 0 0 10px;
    color: #23B8C5;
    font-weight: 500;
  }
  .ttl10._c-black_ {
    color: #303030;
  }
  .catch01 {
    font-size: 2rem;
    color: #23B8C5;
    text-align: center;
  }
  .lead01 {
    margin: 0 0 40px;
  }
  /* --------------------------------------------------- */
  /* カラム制御 */
  /* --------------------------------------------------- */
  /* 複数カラム
/* --------------------------------------------------- */
  .column {
    display: flex;
    flex-wrap: wrap;
  }
  .column > * {
    margin-bottom: 15px;
  }
  .column > * * {
    max-width: 100%;
  }
  .column > * img {
    outline: 1px solid transparent;
  }
  .column > * img:not([class*="wd-a"]) {
    width: 100%;
  }
  .column1 > * {
    width: 100%;
  }
  .column2 {
    margin-left: -1.5%;
    margin-right: -1.5%;
  }
  .column2 > * {
    margin-left: 1.5%;
    margin-right: 1.5%;
    width: 47%;
  }
  .column2__hierarchy {
    display: flex;
  }
  .column2__hierarchy > * {
    width: calc((100% - 21px) / 2);
  }
  .column2__hierarchy > *:first-child {
    margin-right: 10px;
  }
  .column2__hierarchy > *:last-child {
    margin-left: 10px;
  }
  .column3,
  .column6 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column3 > *,
  .column6 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 31%;
  }
  .column4 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column4 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 23%;
  }
  .column5 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .column5 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 18%;
  }
  .sp-column1 {
    margin-left: 0;
    margin-right: 0;
  }
  .sp-column1 > * {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .sp-column2 {
    margin-left: -1.5%;
    margin-right: -1.5%;
  }
  .sp-column2 > * {
    margin-left: 1.5%;
    margin-right: 1.5%;
    width: 47%;
  }
  .sp-column3 {
    margin-left: -1%;
    margin-right: -1%;
  }
  .sp-column3 > * {
    margin-left: 1%;
    margin-right: 1%;
    width: 31%;
  }
  /* 幅指定カラム
------------------------------------------------------------ */
  .flex-column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex-column > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .flex-column > *:not(:last-child) {
    margin-bottom: 20px;
  }
  .flex-column > * img {
    display: block;
    margin: 0 auto;
  }
  .flex-column > * * {
    max-width: 100%;
  }
  /* 画像回り込みカラム
------------------------------------------------------------ */
  .float::after {
    display: block;
    content: "";
    clear: both;
  }
  .float--left .float__item {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin: 0 0 10px;
  }
  .float--left .float__item * {
    max-width: 100%;
  }
  .float--right .float__item {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin: 0 0 10px;
  }
  .float--right .float__item * {
    max-width: 100%;
  }
  .sp-float.float::after {
    display: block;
    content: "";
    clear: both;
  }
  .sp-float.float--left .float__item {
    float: left;
    margin: 0 20px 20px 0;
    text-align: center;
    max-width: 50%;
  }
  .sp-float.float--left .float__item * {
    max-width: 100%;
  }
  .sp-float.float--right .float__item {
    float: right;
    margin: 0 0 20px 20px;
    text-align: center;
    max-width: 50%;
  }
  .sp-float.float--right .float__item * {
    max-width: 100%;
  }
  /* --------------------------------------------------- */
  /* リスト */
  /* --------------------------------------------------- */
  .list {
    list-style-type: none;
  }
  .list > * {
    position: relative;
    padding-left: 20px;
    margin-bottom: 5px;
  }
  .list > *::before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    position: absolute;
    top: 0.7em;
    left: 5px;
  }
  .list--circle > *::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  .list--border > *::before {
    width: 8px;
    border-bottom: 1px solid #303030;
    margin: 0 10px 0 0;
    top: 0.5em;
  }
  .list--black > *::before {
    background: #303030;
  }
  .list--orange > *::before {
    background: #FF8843;
  }
  .list--decimal {
    list-style: decimal outside;
    margin: 0 0 15px 20px;
  }
  .list--decimal > * {
    padding-left: 0;
  }
  .list--odd > * {
    padding: 7px 15px;
  }
  .list--odd > *:nth-child(odd) {
    background: #F6F6F6;
  }
  .link-list__item {
    position: relative;
    padding: 0 0 0 15px;
    border-bottom: 1px solid #DFDFDF;
  }
  .link-list__item::before {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    content: "\f105";
    font-family: 'icomoon';
    color: #23B8C5;
  }
  .link-list__item__link {
    color: #303030;
    width: 100%;
    padding: 15px 0;
    display: block;
    text-decoration: none;
  }
  /* --------------------------------------------------- */
  /* box */
  /* --------------------------------------------------- */
  .box {
    padding: 15px;
    background: #fff;
  }
  .box--gray {
    background: #ECECEC;
  }
  .box--radius {
    border-radius: 15px;
  }
  .box--border-gray {
    border: 1px solid #DFDFDF;
  }
  .box--bluelight {
    background: #EFF9FB;
  }
  .box--yellowlight {
    background: #FFFCEF;
  }
  .box--pinklight {
    background: #FFF5F6;
  }
  .box--border-base {
    border: 1px solid #23B8C5;
  }
  .box--orange {
    background: #FCF2D8;
  }
  .box--red {
    background: #fde9e9;
    border: 1px solid #f8cfd4;
  }
  .box--purple {
    background: #F3EFFB;
  }
  .box--border-dashed {
    border: 2px dashed #23B8C5;
  }
  /* --------------------------------------------------- */
  /* iframe */
  /* --------------------------------------------------- */
  .iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
  .iframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /* --------------------------------------------------- */
  /* table */
  /* --------------------------------------------------- */
  .table {
    width: 100%;
    line-height: 1.6;
    word-break: break-all;
    word-wrap: break-word;
    margin: 0 0 20px;
  }
  .table th,
  .table td {
    padding: 10px;
    background: #fff;
    border-bottom: 1px solid #DFDFDF;
    text-align: left;
    vertical-align: middle;
  }
  .table .bg-pink {
    background: #FF6085;
    color: #fff;
  }
  .table .bg-pinklight {
    background: #FFF5F6;
  }
  .table .bg-bluedarken {
    background: #006281;
    border: 1px solid #fff;
    color: #fff;
  }
  .table .bg-bluelight {
    background: #EFF9FB;
  }
  .table .bg-yellowlight {
    background: #FFFCEF;
  }
  .table .bg-gray {
    background: #f3f3f3;
  }
  .table .error {
    background: #fde9e9;
    color: #dd0000;
  }
  .table--sp-column1 {
    display: block;
  }
  .table--sp-column1 thead,
  .table--sp-column1 tbody {
    display: block;
  }
  .table--sp-column1 tr {
    display: block;
  }
  .table--sp-column1 tr:first-child th {
    border-top: none;
  }
  .table--sp-column1 th,
  .table--sp-column1 td {
    display: block;
    padding: 15px;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .table--sp-column1 td {
    border-bottom: 1px solid #DFDFDF;
  }
  .table--wide {
    border: none;
    display: block;
  }
  .table--wide th,
  .table--wide td {
    display: block;
    padding: 5px 8px;
    border-bottom-width: 0;
    border-right-width: 0;
    text-align: center;
  }
  .table--wide th {
    width: auto;
    text-align: left;
  }
  .table--wide thead {
    display: block;
    float: left;
    overflow-x: scroll;
  }
  .table--wide tbody {
    display: block;
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table--wide tbody tr {
    display: inline-block;
    margin: 0 -3px;
  }
  .table--wide tbody tr:last-child th,
  .table--wide tbody tr:last-child td {
    border-right-width: 1px;
  }
  .table--wide thead th:last-child,
  .table--wide tbody td:last-child {
    border-bottom-width: 1px;
  }
  .table--list-sp {
    border: none;
  }
  .table--list-sp tr {
    display: block;
    margin: 0 0 20px;
  }
  .table--list-sp th {
    display: block;
  }
  .table--list-sp td {
    display: list-item;
    margin: 0 0 0 20px;
    padding: 5px 0 0;
    border: none;
    background: none;
  }
  .table--list {
    border: none;
  }
  .table--list th,
  .table--list td {
    border: none;
  }
  .table--list tr:nth-child(odd) th, .table--list tr:nth-child(odd) td {
    background: #F6F6F6;
  }
  .table--list--sp tr:nth-child(odd) th, .table--list--sp tr:nth-child(odd) td {
    background: none;
  }
  .table--list--sp tr:nth-child(even) th, .table--list--sp tr:nth-child(even) td {
    background: #F6F6F6;
  }
  .table-swipe {
    overflow-x: scroll;
  }
  .table-swipe .table {
    border-top: solid 1px #DFDFDF;
    margin: 0;
  }
  .table-swipe .table th, .table-swipe .table td {
    white-space: nowrap;
  }
  .table-swipe .table td {
    border: solid 1px #DFDFDF;
    background: #fff;
  }
  .table-swipe .table td:last-child {
    border-right: none;
  }
  .table-swipe .table th {
    border-bottom: solid 1px #DFDFDF;
  }
  .table-swipe .table th:first-child {
    background: #fff;
    position: sticky;
    left: 0;
  }
  .table-swipe .table thead th {
    background: #fff;
    border-right: solid 1px #DFDFDF;
    font-weight: 500;
    font-size: 1.8rem;
  }
  .table-swipe .table thead th:last-child {
    border-right: none;
  }
  .table-swipe02 {
    overflow-x: scroll;
    position: relative;
    margin: 0 0 20px;
  }
  .table-swipe02 .table {
    margin: 0;
  }
  .table-swipe02 .table th, .table-swipe02 .table td {
    white-space: nowrap;
  }
  /* --------------------------------------------------- */
  /* btn */
  /* --------------------------------------------------- */
  .btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 40px;
    margin: 5px 0;
    padding: 0 30px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    line-height: 1.4;
    border-radius: 25px;
  }
  .btn--white {
    background: #fff;
  }
  .btn--base {
    background: #23B8C5;
  }
  .btn--pink {
    background: #FF6085;
  }
  .btn--orange {
    background: #FF8843;
  }
  .btn--black {
    background: #000;
  }
  .btn--border-pink {
    border: 2px solid #FF6085;
    color: #FF6085;
    background: #fff;
  }
  .btn--border-orange {
    border: 2px solid #FF8843;
    color: #FF8843;
    background: #fff;
  }
  .btn--border-base {
    border: 2px solid #23B8C5;
    color: #23B8C5;
    background: #fff;
  }
  .btn--clear {
    background: none;
    border: 1px solid #585858;
    color: #585858;
  }
  .btn--bluelight {
    background: #1290C8;
  }
  .btn--lg {
    width: 90%;
    height: 60px;
    font-size: 1.6rem;
    border-radius: 30px;
  }
  .btn--lg02 {
    width: 100%;
    height: 60px;
    font-size: 1.6rem;
    border-radius: 30px;
  }
  .btn--md {
    width: 80%;
    height: 50px;
    font-size: 1.6rem;
  }
  .btn--md02 {
    width: 75%;
    height: 50px;
    font-size: 1.6rem;
  }
  .btn--md03 {
    width: 90%;
    height: 50px;
    font-size: 1.6rem;
  }
  .btn--sm {
    display: inline-flex;
    min-width: 0;
    min-height: 35px;
    padding: 0 20px;
    font-size: 1.2rem;
  }
  button,
  input[type="button"].btn,
  input[type="submit"].btn {
    appearance: none;
  }
  /* --------------------------------------------------- */
  /* pager */
  /* --------------------------------------------------- */
  .pager {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0 0;
  }
  .pager li {
    margin: 0 3px 10px;
  }
  .pager li > * {
    display: flex;
    background: #fff;
    border: 1px solid #23B8C5;
    color: #23B8C5;
    text-decoration: none;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  .pager li > *.current {
    background: #23B8C5;
    border: 1px solid #23B8C5;
    color: #fff;
    opacity: 1;
  }
  .pager__prev > *, .pager__next > *, .pager__list > * {
    width: auto !important;
    padding: 0 20px;
  }
  .pager__list > * {
    background: #23B8C5 !important;
    border: 1px solid #23B8C5 !important;
    color: #fff !important;
  }
  .pager__list > *:hover {
    background: #fff !important;
    border: 1px solid #23B8C5 !important;
    color: #23B8C5 !important;
  }
  /* --------------------------------------------------- */
  /* accordion */
  /* --------------------------------------------------- */
  .accordion,
  .sp-accordion {
    position: relative;
    padding: 10px 30px 10px 10px;
    background: #f3f3f3;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    line-height: 1.6;
    border-radius: 3px;
  }
  .accordion__icon,
  .sp-accordion__icon {
    right: 10px;
    color: #23B8C5;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .accordion.active .accordion__icon::before,
  .sp-accordion.active .accordion__icon::before {
    display: block;
    transform: rotate(180deg);
  }
  .accordion-hide {
    display: none;
    padding: 15px 0 0;
  }
  /* --------------------------------------------------- */
  /* form */
  /* --------------------------------------------------- */
  .label-must {
    right: 0;
    padding: 2px 10px;
    background: #006281;
    color: #fff;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .error-block {
    margin-top: 5px;
    font-weight: 500;
  }
  .note {
    color: #5E5E5E;
    font-size: 1.4rem;
  }
  input[type="text"],
  textarea,
  select {
    color: #303030;
    font-size: 16px;
  }
  input[type="text"] {
    appearance: none;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d2d2d2;
    border-radius: 0px;
  }
  textarea {
    appearance: none;
    padding: 5px 10px;
    width: 100%;
    height: 200px;
    border: 1px solid #d2d2d2;
    border-radius: 0px;
  }
  select {
    height: 40px;
    padding: 0 0 0 10px;
    background: #fff;
    border: 1px solid #d2d2d2;
  }
  /* radio
----------------------------------------------------------- */
  .radio {
    cursor: pointer;
  }
  .radio input[type="radio"] {
    display: none;
  }
  .radio input[type="radio"] + span {
    position: relative;
    display: block;
    padding: 0 0 0 25px;
    color: #303030;
  }
  .radio input[type="radio"] + span::before, .radio input[type="radio"] + span::after {
    position: absolute;
    top: 50%;
    content: '';
    transform: translateY(-50%);
  }
  .radio input[type="radio"] + span::before {
    left: 0;
    background: #fff;
    border: 2px solid #d2d2d2;
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  .radio input[type="radio"] + span::after {
    left: 5px;
    background: #23B8C5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0;
  }
  .radio input[type="radio"]:checked + span::after {
    opacity: 1;
  }
  /* checkbox
----------------------------------------------------------- */
  .checkbox input[type="checkbox"] {
    display: none;
  }
  .checkbox input[type="checkbox"] + span {
    position: relative;
    padding: 0 0 0 22px;
    color: #303030;
    cursor: pointer;
  }
  .checkbox input[type="checkbox"] + span::before, .checkbox input[type="checkbox"] + span::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .checkbox input[type="checkbox"] + span::before {
    left: 0;
    content: '';
    width: 12px;
    height: 12px;
    background: #fff;
    border: 2px solid #d2d2d2;
  }
  .checkbox input[type="checkbox"] + span::after {
    left: 1px;
    content: "\f00c";
    color: #23B8C5;
    font-family: 'icomoon';
    font-size: 1.6rem;
    opacity: 0;
  }
  .checkbox input[type="checkbox"]:checked + span::after {
    opacity: 1;
  }
  /* --------------------------------------------------- */
  /* label */
  /* --------------------------------------------------- */
  .label {
    padding: 0 10px;
    background: #23B8C5;
    color: #fff;
    font-size: 1.4rem;
  }
  .label--news {
    background: #23B8C5;
  }
  .label--oc {
    background: #FF6085;
  }
  .label--class {
    background: #23C569;
  }
  .label--graduate {
    background: #FF8843;
  }
  .label--school {
    background: #3F91E4;
  }
  .label--other {
    background: #484848;
  }
  .topic-date {
    color: #9C9C9C;
  }
  /* --------------------------------------------------- */
  /* topic__list */
  /* --------------------------------------------------- */
  .topic__list {
    margin-left: -1%;
    margin-right: -1%;
    display: flex;
    flex-wrap: wrap;
  }
  .topic__list__item {
    margin-left: 1%;
    margin-right: 1%;
    width: 48%;
    text-decoration: none;
    display: block;
    margin-bottom: 30px;
  }
  .topic__list__item__img {
    width: 100%;
    height: 140px;
    overflow: hidden;
    position: relative;
  }
  .topic__list__item__img > img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .topic__list__item__box {
    padding: 15px 0 0;
  }
  .topic__list__item__box__label {
    min-width: 60%;
    height: 28px;
    display: inline-block;
    line-height: 28px;
    margin: 0 0 5px;
  }
  .topic__list__item__box__date {
    display: block;
  }
  .topic__list__item__box__title {
    color: #000;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  /* --------------------------------------------------- */
  /* entry */
  /* --------------------------------------------------- */
  .entry .topic-date {
    margin: 0 0 0 10px;
  }
  .entry__ttl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 20px;
  }
  .entry__ttl__text {
    font-size: 2.2rem;
    line-height: 1.6;
    font-weight: 500;
    margin: 10px 0 0;
    color: #23B8C5;
    width: 100%;
  }
  .entry__block {
    padding: 0 0 30px;
  }
  .entry__block img {
    margin: 0 0 25px;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .entry__block img.aligncenter {
    margin: 0 auto 25px;
  }
  .entry__block img.alignright {
    margin: 0 0 25px auto;
  }
  /* ============================================================ */
  /* banner */
  /* ============================================================ */
  .banner {
    border-top: 1px solid #CEE5E8;
    margin: 0 10px;
  }
  /* ============================================================ */
  /* movie */
  /* ============================================================ */
  .movie__item {
    position: relative;
    cursor: pointer;
    transition: 0.3s all;
    margin-bottom: 15px;
  }
  .movie__item img {
    width: 100%;
  }
  .movie__item::after {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    background: url(/common/img/common/ico_play.png) 0 0 no-repeat;
    background-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .movie__item:hover {
    opacity: 0.7;
  }
  /* --------------------------------------------------- */
  /* lnav */
  /* --------------------------------------------------- */
  .lnav {
    background: #F6F6F6;
    margin: 0 0 60px;
    border-radius: 5px;
  }
  .lnav__item {
    width: 100%;
  }
  .lnav__item__link {
    padding: 15px 20px;
    display: block;
    text-decoration: none;
    color: #303030;
    border-bottom: 1px solid #CCCCCC;
  }
  .lnav__item__link .icon-angle-right,
  .lnav__item__link .icon-angle-down {
    color: #23B8C5;
    margin: 0 5px 0 0;
  }
  .lnav__item:last-child .lnav__item__link {
    border-bottom: none;
  }
  .lnav02__item {
    width: 100%;
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    background: #ECECEC;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all;
    color: #303030;
    padding: 10px 0;
    margin: 5px 0;
  }
  .lnav02__item .icon-angle-right,
  .lnav02__item .icon-angle-down {
    color: #23B8C5;
    margin: 0 5px 0 0;
  }
  .lnav02__item--active {
    background: #006281;
    color: #fff;
  }
  .lnav02__item--active .icon-angle-right,
  .lnav02__item--active .icon-angle-down {
    color: #fff;
  }
  /* --------------------------------------------------- */
  /* admission-bnr */
  /* --------------------------------------------------- */
  .admission-bnr {
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    height: 90px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.6;
    text-decoration: none;
  }
  .admission-bnr--ao {
    background-image: url(/common/img/common/bnr_ao_01.jpg);
  }
  .admission-bnr--suisen {
    background-image: url(/common/img/common/bnr_suisen_01.jpg);
  }
  .admission-bnr--ippan {
    background-image: url(/common/img/common/bnr_ippan_01.jpg);
  }
  .admission-bnr--tuition {
    background-image: url(/common/img/admission/bnr_tuition_01.jpg);
  }
  .admission-bnr--support {
    background-image: url(/common/img/admission/bnr_support_01.jpg);
  }
  /* ============================================================ */
  /* .side */
  /* ============================================================ */
  /* --------------------------------------------------- */
  /* snav */
  /* --------------------------------------------------- */
  .snav__inner {
    border: 1px solid #23B8C5;
    display: flex;
    flex-wrap: wrap;
  }
  .snav__item {
    width: 50%;
    border-bottom: 1px solid #23B8C5;
    position: relative;
  }
  .snav__item:nth-child(2n+1) {
    border-right: 1px solid #23B8C5;
  }
  .snav__item:last-child, .snav__item:nth-last-child(2) {
    border-bottom: none;
  }
  .snav__item__link {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    display: block;
    padding: 15px 0 15px 25px;
  }
  .snav__item__link .icon-angle-right {
    color: #23B8C5;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
  }
  /* ============================================================ */
  /* fnav */
  /* ============================================================ */
  .fnav .inner {
    max-width: 100%;
  }
  .fnav__title {
    width: 100%;
    background: #D8EEF1;
    border-bottom: 1px solid #fff;
    position: relative;
    margin: 0;
  }
  .fnav__title__link {
    display: block;
    padding: 10px 15px;
    color: #000;
    text-decoration: none;
  }
  .fnav__title--sp {
    background: #F6FAFB;
    border-bottom: 1px solid #CEE5E8;
    position: relative;
  }
  .fnav__title--sp .fnav__title__link {
    display: block;
    padding: 10px 15px 10px 25px;
    color: #484848;
    text-decoration: none;
  }
  .fnav__title--sp [class^="icon"] {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
    display: block;
  }
  .fnav__title--accordion {
    color: #000;
    padding: 10px 15px;
  }
  .fnav__title--accordion.active .icon-angle-down::before {
    display: block;
    transform: rotate(180deg);
  }
  .fnav__dropdown {
    display: none;
  }
  .fnav__sub-title {
    background: #F6FAFB;
    border-bottom: 1px solid #CEE5E8;
    position: relative;
  }
  .fnav__sub-title__link {
    display: block;
    padding: 10px 15px 10px 25px;
    color: #484848;
    text-decoration: none;
  }
  .fnav__sub-title__link [class^="icon"] {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
    display: block;
  }
  .fnav__sub-title--under .fnav__sub-title__link::before {
    content: "-";
    margin: 0 5px 0 0;
  }
  /* ============================================================ */
  /* footer */
  /* ============================================================ */
  footer {
    text-align: center;
    line-height: 1.6;
    padding: 0 0 90px;
  }
  footer .footer-logo {
    margin: 40px 0 25px;
  }
  footer .footer-logo__link {
    display: block;
    width: 300px;
    margin: 0 auto 15px;
  }
  footer .footer-logo__sub {
    font-size: 1.3rem;
    color: #717171;
  }
  footer .footer__address {
    color: #484848;
    margin: 0 0 25px;
    font-size: 1.4rem;
  }
  footer .footer__sns {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
  }
  footer .footer__sns__item {
    margin: 0 5px;
  }
  footer .footer__sns__item__link {
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #23B8C5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s all;
  }
  footer .footer__sns__item__link .icon-line {
    font-size: 2.2rem;
  }
  footer .footer__sns__item__link:hover {
    opacity: 0.7;
  }
  footer .copyright {
    padding: 20px 0;
    font-size: 1.4rem;
    color: #777777;
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* index.html */
  /* ============================================================ */
  #index .visual {
    position: relative;
    width: 100%;
    margin: 90px 0 0;
  }
  #index .visual .index-h1 {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  #index .index-ttl01 {
    text-align: center;
    margin: 0 0 30px;
  }
  #index .index-ttl01__en {
    font-size: 3.2rem;
    font-weight: 900;
    line-height: 1.0;
    margin: 0 0 10px;
  }
  #index .index-ttl01__ja {
    display: inline-block;
    color: #23B8C5;
    font-size: 1.5rem;
    font-weight: 400;
    border-bottom: 1px dashed #23B8C5;
    position: relative;
    padding: 0 5px 0 0;
  }
  #index .index-ttl01__ja .icon-pen {
    position: absolute;
    bottom: 0;
    right: -10px;
  }
  #index .index-ttl02 {
    text-align: center;
    margin: 0 0 25px;
  }
  #index .index-ttl02::before {
    background: url(/common/img/common/ttl-icon.svg) no-repeat;
    width: 83px;
    height: 50px;
    content: "";
    display: block;
    margin: 0 auto;
  }
  #index .index-ttl02__text {
    font-size: 1.6rem;
  }
  #index .index-ttl02__text::before {
    line-height: 1.3;
    content: attr(data-text);
    display: block;
    font-size: 3.8rem;
    font-weight: 900;
  }
  #index .index-ttl03 {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 30px;
  }
  #index .index-ttl03__em {
    color: #23B8C5;
  }
  #index .index-inner {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  #index .topic {
    padding-bottom: 40px;
  }
  #index .topic__list {
    margin: 0 -2px;
  }
  #index .topic__list__item {
    margin-left: 2px;
    margin-right: 2px;
    text-decoration: none;
    display: block;
    margin-bottom: 0;
  }
  #index .topic__list__item__img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
  }
  #index .topic__list__item__img > img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #index .topic__list__item__box {
    background: #fff;
    position: relative;
    top: -25px;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0px 0px 4px 0px #e8e8e8;
    padding: 35px 25px 30px;
    height: 135px;
  }
  #index .topic__list__item__box__label {
    min-width: 60%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
  }
  #index .topic__list .slick-dots {
    position: inherit;
    padding: 0 0 20px;
  }
  #index .entry {
    background: url(/common/img/common/bg_pattern.svg) center center repeat;
  }
  #index .entry .index-ttl01 {
    text-align: center;
    margin: 0 0 20px;
  }
  #index .entry .index-ttl01__ja {
    display: inline-block;
  }
  #index .entry__inner {
    max-width: 80%;
    margin: 0 auto;
  }
  #index .entry__item {
    padding: 6px;
    text-decoration: none;
  }
  #index .entry__item--ao {
    background: url(/common/img/index/bnr_entry_ao.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item--suisen {
    background: url(/common/img/index/bnr_entry_suisen.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item--general {
    background: url(/common/img/index/bnr_entry_general.png) center center no-repeat;
    background-size: cover;
  }
  #index .entry__item__box {
    position: relative;
    padding: 0;
  }
  #index .entry__item__box::before, #index .entry__item__box::after, #index .entry__item__box__inner::before, #index .entry__item__box__inner::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    display: inline-block;
  }
  #index .entry__item__box::before {
    border-left: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    bottom: -1px;
    left: -1px;
  }
  #index .entry__item__box::after {
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    bottom: -1px;
    right: -1px;
  }
  #index .entry__item__box__inner {
    padding: 15px 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
  }
  #index .entry__item__box__inner::before {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
    top: -1px;
    left: -1px;
  }
  #index .entry__item__box__inner::after {
    border-right: solid 1px #ccc;
    border-top: solid 1px #ccc;
    top: -1px;
    right: -1px;
  }
  #index .entry__item__box__title {
    font-size: 2rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 500;
    margin: 0 0 5px;
  }
  #index .about {
    background: url(/common/img/index/bg_about_01_sp.png) center center repeat-y;
  }
  #index .about__department__item {
    text-decoration: none;
    position: relative;
    padding: 0 0 110px;
    margin: 0 0 60px;
  }
  #index .about__department__item__text {
    padding: 0 15px 15px;
    width: 85%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #index .about__department__item__text__en {
    height: 11px;
    width: auto;
  }
  #index .about__department__item__text__title {
    font-size: 2.8rem;
    position: relative;
    padding: 0 0 10px;
    margin: 0 0 15px;
  }
  #index .about__department__item__text__title::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    width: 50px;
  }
  #index .about__department__item__text--yoji {
    background: rgba(255, 96, 133, 0.9);
  }
  #index .about__department__item__text--yoji .about__department__item__text__title::after {
    border-bottom: 1px solid #FFAABD;
  }
  #index .about__department__item__text--kodomo {
    background: rgba(255, 136, 67, 0.9);
  }
  #index .about__department__item__text--kodomo .about__department__item__text__title::after {
    border-bottom: 1px solid #F9C366;
  }
  #index .about__feature {
    padding: 0 10px;
  }
  #index .about__feature__item {
    text-decoration: none;
    position: relative;
    padding: 0 0 40px;
  }
  #index .about__feature__item__img {
    width: 65%;
  }
  #index .about__feature__item__text {
    padding: 20px 5px;
    width: 50%;
    margin: 0 auto;
    position: absolute;
    top: 15px;
    right: 0;
    font-weight: 500;
    text-align: center;
    color: #23B8C5;
    background: #fff;
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #index .about__feature__item__text__title {
    font-size: 2rem;
    position: relative;
    padding: 10px 0 0;
    color: #303030;
  }
  #index .about__feature__item__text__title::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    border: 1px solid #23B8C5;
    width: 55px;
  }
  #index .recommended {
    background-color: #e8f8f9;
    background-image: url(/common/img/index/pic_reco_left_01.svg), url(/common/img/index/pic_reco_right_01.svg), url(/common/img/index/bg_reco_left_01.svg), url(/common/img/index/bg_reco_right_01.svg);
    background-position: left 3%, right 3%, left top, right top;
    background-size: 25% auto, 25% auto, 40% auto, 40% auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    padding: 0 20px;
  }
  #index .recommended__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 60px;
  }
  #index .recommended__item__img {
    margin: 0 0 25px;
    min-height: 0.1px;
  }
  #index .recommended__item__text {
    background: #fff;
    box-shadow: 6px 6px 0px 3px #FCFF97;
    padding: 15px 15px 30px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #303030;
    position: relative;
    flex: 1 1 auto;
  }
  #index .recommended__item__text__label {
    width: auto;
    max-width: inherit;
    margin: 0 0 10px;
  }
  #index .recommended__item__text__title {
    color: #23B8C5;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1.0;
    margin: 0 0 15px;
  }
  #index .recommended__item__text .icon-chevron-circle-right {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 10px;
    color: #23B8C5;
    font-size: 2.2rem;
  }
  #index .access .row__inner {
    position: relative;
  }
  #index .access .row__inner::after {
    position: absolute;
    left: 10px;
    bottom: -40px;
    content: "";
    background: url(/common/img/index/pic_f-access_01.svg) no-repeat;
    width: 140px;
    height: 73px;
  }
  #index .access__contact {
    padding: 20px 0;
    text-align: center;
  }
  #index .access__contact__sns {
    display: flex;
    justify-content: center;
  }
  #index .access__contact__sns__item {
    margin: 0 10px 0 0;
  }
  #index .access__contact__sns__item__link {
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #23B8C5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s all;
  }
  #index .access__contact__sns__item__link .icon-line {
    font-size: 2.2rem;
  }
  #index .access__contact__sns__item__link:hover {
    opacity: 0.7;
  }
  #index .access__map {
    padding-bottom: 40px;
  }
  /* ============================================================ */
  /* opencampus */
  /* ============================================================ */
  .opencampus .contents a {
    color: #FF6085;
  }
  .opencampus .contents .btn {
    color: #fff;
  }
  .opencampus .contents .btn--clear {
    color: #585858;
  }
  .opencampus .contents .btn--border-pink {
    color: #FF6085;
  }
  .opencampus .contents .ttl02::after {
    background: url(/common/img/opencampus/ttl-line.svg) no-repeat;
  }
  .opencampus .contents .ttl04::after {
    border-bottom: 2px solid #FF6085;
  }
  .opencampus .contents .ttl06 {
    border-bottom: 1px solid #FF6085;
  }
  .opencampus .contents .ttl06:first-letter {
    color: #FF6085;
  }
  .opencampus .contents .ttl07 {
    border-left: 6px solid #FF6085;
  }
  .opencampus .contents .ttl10 {
    color: #FF6085;
  }
  .opencampus .contents .catch01 {
    font-size: 1.8rem;
    font-weight: 500;
    color: #FF6085;
    text-align: center;
    margin: 0 0 20px;
    line-height: 1.6;
  }
  .opencampus .contents .catch02 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 20px;
    text-align: center;
  }
  .opencampus .contents .catch02__em {
    color: #23B8C5;
  }
  .opencampus .contents .catch03 {
    position: relative;
    padding: 0;
    color: #000;
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .opencampus .contents .catch03 .__fukidashi {
    margin: 0 10px;
  }
  .opencampus .contents .bg-oc-gray {
    background: #FBF4F4;
  }
  .opencampus .contents .table .bg-pinklight {
    color: #FF6085;
  }
  .opencampus .tokuten {
    background-color: #FFBBC3;
    background-image: url(/common/img/opencampus/bg_tokuten_left_01.svg), url(/common/img/opencampus/bg_tokuten_right_01.svg);
    background-position: left top, right top;
    background-size: 45% auto, 45% auto;
    background-repeat: no-repeat, no-repeat;
  }
  .opencampus .tokuten .box {
    padding: 30px 15px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .opencampus .tokuten .box .ttl04 {
    font-size: 2.4rem;
  }
  .opencampus .tokuten .box .ttl04::after {
    border-bottom: 2px solid #FF6085;
  }
  .opencampus .tokuten .box .tokuten__em {
    margin: 0 0 30px;
    display: inline-block;
    font-size: 2.2rem;
    border-bottom: 3px solid #FFF461;
  }
  .opencampus .banner {
    border-top: 1px solid #ECECEC;
  }
  .opencampus .topic-path {
    border-bottom: 1px solid #FFBBC3;
  }
  .opencampus .topic-path__item:not(:last-child)::after {
    color: #FF6085;
  }
  .opencampus .oc-type {
    padding: 0;
    margin: 0 0 40px;
  }
  .opencampus .oc-type__item {
    padding: 25px 5px;
    text-align: center;
    margin: 0 0 2px;
  }
  .opencampus .oc-type__item--visit {
    background: url(/common/img/opencampus/bg_mini_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--visit .oc-type__item__box__catch {
    color: #1290C8;
  }
  .opencampus .oc-type__item--visit .oc-type__item__box__list li::before,
  .opencampus .oc-type__item--visit .oc-type__item__text {
    color: #1290C8;
  }
  .opencampus .oc-type__item--online {
    background: url(/common/img/opencampus/bg_sp_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--online .oc-type__item__box__catch {
    color: #FF8843;
  }
  .opencampus .oc-type__item--online .oc-type__item__box__list li::before,
  .opencampus .oc-type__item--online .oc-type__item__text {
    color: #FF8843;
  }
  .opencampus .oc-type__item--soudan {
    background: url(/common/img/opencampus/bg_soudan_01.png) center center no-repeat;
    background-size: cover;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__box__catch,
  .opencampus .oc-type__item--soudan .oc-type__item__box .em,
  .opencampus .oc-type__item--soudan .oc-type__item__box .oc-type__item__box__list li::before,
  .opencampus .oc-type__item--soudan .oc-type__item__box .oc-type__item__text {
    color: #FF6085;
  }
  .opencampus .oc-type__item--soudan .commingsoon__ttl:before {
    color: #FF6085;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__text {
    color: #008C98;
  }
  .opencampus .oc-type__item--soudan .oc-type__item__ttl > * {
    max-width: 90%;
  }
  .opencampus .oc-type__item__text {
    font-size: 2rem;
    line-height: 1.6;
    padding: 5px 0;
    font-weight: 500;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 20%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0) 100%);
  }
  .opencampus .oc-type__item__ttl {
    margin: 0 0 20px;
  }
  .opencampus .oc-type__item__ttl > * {
    max-width: 70%;
  }
  .opencampus .oc-type__item__box {
    background: #fff;
    width: 85%;
    text-align: left;
    margin: 0 auto 30px;
    padding: 30px 15px;
  }
  .opencampus .oc-type__item__box__catch {
    position: relative;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    padding: 0 0 10px;
    margin: 0 0 15px;
    text-align: center;
  }
  .opencampus .oc-type__item__box__catch::after {
    content: "";
    border-bottom: 1px solid #9C9C9C;
    width: 48px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
  }
  .opencampus .oc-type__item__box__list {
    margin: 0 auto;
  }
  .opencampus .oc-type__item__box__list li {
    position: relative;
    padding: 0 0 0 1.5em;
  }
  .opencampus .oc-type__item__box__list li::before {
    content: "\f0c8";
    position: absolute;
    left: 0;
    top: 0;
    font-family: 'icomoon';
  }
  .opencampus .oc-type__item__box__list .icon-square {
    margin-right: 5px;
  }
  .opencampus .oc-type__item .btn {
    min-width: 270px;
    height: 50px;
  }
  /* ============================================================ */
  /* opencampus_index */
  /* ============================================================ */
  #opencampus_index {
    /*.commingsoon {
		background: $white;
		&__inner {
			margin: 0 0 40px;
		}
		&__ttl {
			margin: 0 0 35px;
			text-align: center;
			color: $baseColor;
			font-size: 1.5rem;
			font-weight: $font-regular;
			&:before {
				margin: 0 0 5px;
				color: $black;
				content: attr(data-text);
				font-size: 3.2rem;
				font-weight: $font-black;
				line-height: 1.0;
				display: block;
			}
		}
		&__item {
			display: flex;
			text-decoration: none;
			&__img {
				padding: 0 0 20px;
				padding: 0 10px 0 0;
				width: 45%;
			}
			&__text {
				width: 55%;
				color: $textColor;
				font-size: $s;
				line-height: 1.6;
			}
		}
	}*/
  }
  #opencampus_index .visual {
    margin: 90px 0 0;
    background: url(/common/img/opencampus/index/pic_visual_01_sp.jpg) center center no-repeat;
    background-size: contain;
    padding-bottom: 106.67%;
    height: 0;
    position: relative;
  }
  #opencampus_index .visual__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
  #opencampus_index .visual__inner__img {
    width: 75%;
  }
  #opencampus_index .corona-info {
    background: #fff;
    text-align: center;
    padding: 15px 0;
    width: 80%;
    margin: 0 auto;
    color: #FF6085;
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
  }
  #opencampus_index .point .row__inner {
    padding: 30px 10px;
    margin: 0 10px;
    background: #fff;
  }
  #opencampus_index .point__block {
    margin: 0 0 40px;
  }
  #opencampus_index .point__block:last-child {
    margin: 0;
  }
  #opencampus_index .point__block__title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 30px;
  }
  #opencampus_index .point__block__title::before, #opencampus_index .point__block__title::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: url(/common/img/opencampus/ttl-line02.svg) repeat-x;
    width: 50%;
    height: 8px;
  }
  #opencampus_index .point__block__title::before {
    left: 0;
    background-position: left center;
  }
  #opencampus_index .point__block__title::after {
    right: 0;
    background-position: right center;
  }
  #opencampus_index .point__block__title__img {
    background: #fff;
    width: 164px;
    height: 60px;
    z-index: 1;
    text-align: center;
  }
  #opencampus_index .point .catch01 {
    font-size: 2.2rem;
  }
  /* ============================================================ */
  /* opencampus_visit,opencampus_online */
  /* ============================================================ */
  .opencampus_visit .corona-info,
  .opencampus_online .corona-info {
    text-align: center;
    color: #FF6085;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0 40px;
    transition: 0.3s all;
  }
  .opencampus_visit .oc-date,
  .opencampus_online .oc-date {
    background: #F6F6F6;
    padding: 5px;
    width: 100%;
    margin: 0 auto 40px;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
  }
  .opencampus_visit .oc-date__inner,
  .opencampus_online .oc-date__inner {
    border: 1px solid #FFBBC3;
    padding: 15px 10px;
  }
  .opencampus_visit .oc-date__title,
  .opencampus_online .oc-date__title {
    font-size: 1.7rem;
    margin: 0 0 10px;
  }
  .opencampus_visit .oc-date__label,
  .opencampus_online .oc-date__label {
    font-weight: 500;
    line-height: 1.0;
    font-size: 1.7rem;
    background: #FF6085;
    color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
    margin-right: 10px;
  }
  .opencampus_visit .table--oc-time,
  .opencampus_online .table--oc-time {
    border-top: none;
  }
  .opencampus_visit .table--oc-time th, .opencampus_visit .table--oc-time td,
  .opencampus_online .table--oc-time th,
  .opencampus_online .table--oc-time td {
    padding: 10px 15px;
  }
  .opencampus_visit .table--oc-time .bg-pinklight,
  .opencampus_online .table--oc-time .bg-pinklight {
    color: #303030;
  }
  .opencampus_visit .table--oc-time thead th,
  .opencampus_online .table--oc-time thead th {
    border-bottom: none;
  }
  .opencampus_visit .table--oc-time tbody th,
  .opencampus_online .table--oc-time tbody th {
    font-weight: 500;
    text-align: center;
  }
  .opencampus_visit .table--oc-time__day,
  .opencampus_online .table--oc-time__day {
    font-weight: 500;
    color: #FF6085;
  }
  .opencampus_visit .time-text__item,
  .opencampus_online .time-text__item {
    margin-bottom: 30px;
  }
  .opencampus_visit .time-text__item:last-child,
  .opencampus_online .time-text__item:last-child {
    margin-bottom: 0;
  }
  .opencampus_visit .time-text__item__img,
  .opencampus_online .time-text__item__img {
    margin: 0 0 15px;
  }
  .opencampus_visit .time-text__item__title,
  .opencampus_online .time-text__item__title {
    font-weight: 500;
    font-size: 1.8rem;
    margin: 0 0 10x;
  }
  .opencampus_visit .day-item,
  .opencampus_online .day-item {
    margin: 0 0 30px;
  }
  .opencampus_visit .day-item .catch02,
  .opencampus_online .day-item .catch02 {
    text-align: left;
  }
  .opencampus_visit .day-item__btn-area,
  .opencampus_online .day-item__btn-area {
    text-align: center;
  }
  .opencampus_visit .day-item__btn-area .btn,
  .opencampus_online .day-item__btn-area .btn {
    font-size: 1.5rem;
  }
  .opencampus_visit .day-item:last-child,
  .opencampus_online .day-item:last-child {
    margin: 0;
  }
  .opencampus_visit .oc-type__item,
  .opencampus_online .oc-type__item {
    margin: 0;
  }
  .opencampus_visit .banner,
  .opencampus_online .banner {
    border-top: 0;
    padding: 0 0 40px;
  }
  /* ============================================================ */
  /* opencampus_visit */
  /* ============================================================ */
  .opencampus_visit .topic-path {
    border-bottom: 1px solid #80d3ff;
  }
  .opencampus_visit .topic-path__item:not(:last-child)::after {
    color: #1290C8;
  }
  .opencampus_visit .contents .oc-date__inner {
    border: 1px solid #1290C8;
  }
  .opencampus_visit .contents .oc-date__label {
    background: #1290C8;
  }
  .opencampus_visit .contents .day-item__btn-area .btn {
    width: 80%;
    margin: 0 0 10px;
  }
  .opencampus_visit .contents .ttl02::after {
    background: url(/common/img/opencampus/visit/ttl-line.svg) no-repeat;
  }
  .opencampus_visit .contents .ttl04::after {
    border-bottom: 2px solid #1290C8;
  }
  .opencampus_visit .contents .ttl06 {
    border-bottom: 1px solid #1290C8;
  }
  .opencampus_visit .contents .ttl06:first-letter {
    color: #1290C8;
  }
  .opencampus_visit .contents .ttl07 {
    border-left: 6px solid #1290C8;
  }
  .opencampus_visit .contents .ttl10 {
    color: #1290C8;
  }
  .opencampus_visit .contents .catch01 {
    color: #1290C8;
  }
  .opencampus_visit .contents .btn--ocmini {
    background: #1290C8;
  }
  .opencampus_visit .contents .btn--border-ocmini {
    color: #1290C8;
    border: 1px solid #1290C8;
  }
  .opencampus_visit .contents .btn--border-ocmini:hover {
    background: #1290C8;
    color: #fff;
    opacity: 1.0;
  }
  .opencampus_visit .contents .btn--oc {
    background: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time .bg-ocmini {
    color: #fff;
    background: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time .bg-ocmini-light {
    background: #EFF9FB;
    color: #1290C8;
  }
  .opencampus_visit .contents .table--oc-time__day {
    color: #1290C8;
  }
  .opencampus_visit .schedule-list__item {
    border: 1px solid #FF6085;
    background: #fff;
    margin-bottom: 40px;
  }
  .opencampus_visit .schedule-list__item__day {
    background: #ffacc5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
  }
  .opencampus_visit .schedule-list__item__day img {
    max-width: 100%;
    max-height: 35px;
  }
  .opencampus_visit .schedule-list__item__wrap {
    padding: 20px 15px;
  }
  .opencampus_visit .schedule-list__item__wrap::after {
    display: block;
    content: "";
    clear: both;
  }
  .opencampus_visit .schedule-list__item__img {
    max-width: 100px;
    margin: 0 15px 0 0;
    float: left;
  }
  .opencampus_visit .schedule-list__item__img img {
    width: 100%;
  }
  .opencampus_visit .schedule-list__item__body {
    text-align: center;
  }
  .opencampus_visit .schedule-list__item__body .ttl03 {
    min-height: 100px;
  }
  .opencampus_visit .schedule-list__item__body .__txt {
    text-align: left;
  }
  .opencampus_visit .schedule-list__item__body .btn {
    margin: 15px auto 0;
  }
  /* ============================================================ */
  /* opencampus_online */
  /* ============================================================ */
  .opencampus_online .topic-path {
    border-bottom: 1px solid #FFBB00;
  }
  .opencampus_online .topic-path__item:not(:last-child)::after {
    color: #FF8843;
  }
  .opencampus_online .contents .oc-date__inner {
    border: 1px solid #FF8843;
  }
  .opencampus_online .contents .oc-date__label {
    background: #FF8843;
  }
  .opencampus_online .contents .day-item__btn-area .btn {
    width: 90%;
  }
  .opencampus_online .contents .ttl02::after {
    background: url(/common/img/opencampus/online/ttl-line.svg) no-repeat;
  }
  .opencampus_online .contents .ttl04::after {
    border-bottom: 2px solid #FF8843;
  }
  .opencampus_online .contents .ttl06 {
    border-bottom: 1px solid #FF8843;
  }
  .opencampus_online .contents .ttl06:first-letter {
    color: #FF8843;
  }
  .opencampus_online .contents .ttl07 {
    border-left: 6px solid #FF8843;
  }
  .opencampus_online .contents .ttl10 {
    color: #FF8843;
  }
  .opencampus_online .contents .catch01 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #FF8843;
    text-align: center;
    margin: 0 0 20px;
  }
  .opencampus_online .contents .btn--ocsp {
    background: #FF8843;
  }
  .opencampus_online .contents .btn--border-ocsp {
    color: #FF8843;
    border: 1px solid #FF8843;
  }
  .opencampus_online .contents .btn--border-ocsp:hover {
    background: #FF8843;
    color: #fff;
    opacity: 1.0;
  }
  .opencampus_online .contents .btn--oc {
    background: #FF8843;
  }
  .opencampus_online .contents .table--oc-time .bg-ocsp {
    color: #fff;
    background: #FF8843;
  }
  .opencampus_online .contents .table--oc-time .bg-ocsp-light {
    background: #FFFCEF;
    color: #FF8843;
  }
  .opencampus_online .contents .table--oc-time__day {
    color: #FF8843;
  }
  .opencampus_online .contents .plan {
    margin: 0 0 40px;
  }
  .opencampus_online .contents .plan__item {
    background: #FFFCEF;
    border-radius: 20px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .opencampus_online .contents .detail {
    margin: 0 0 60px;
  }
  .opencampus_online .contents .detail__item {
    text-align: center;
  }
  .opencampus_online .contents .detail__item .__ttl {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0 auto 15px;
  }
  .opencampus_online .contents .detail__item .__ttl::before, .opencampus_online .contents .detail__item .__ttl::after {
    content: "\e90f";
    font-family: 'icomoon';
    padding: 0 10px;
  }
  .opencampus_online .contents .detail__item img {
    width: auto;
  }
  .opencampus_online .contents .detail__item._pink_ {
    background: #FFEDED;
  }
  .opencampus_online .contents .detail__item._pink_ .__ttl {
    color: #F9405F;
  }
  .opencampus_online .contents .detail__item._blue_ {
    background: #D7F4F6;
  }
  .opencampus_online .contents .detail__item._blue_ .__ttl {
    color: #1290C8;
  }
  .opencampus_online .contents .detail__item._yellow_ {
    background: #FFF8D8;
  }
  .opencampus_online .contents .detail__item._yellow_ .__ttl {
    color: #AF6F00;
  }
  .opencampus_online .contents .detail__item._orange_ {
    background: #FFEED9;
  }
  .opencampus_online .contents .detail__item._orange_ .__ttl {
    color: #F15A24;
  }
  .opencampus_online .contents .detail__item._green_ {
    background: #E7F9DE;
  }
  .opencampus_online .contents .detail__item._green_ .__ttl {
    color: #338D07;
  }
  /* ============================================================ */
  /* opencampus_soudan */
  /* ============================================================ */
  .opencampus_soudan .soudan-flow {
    border: 1px solid #e2e2e2;
  }
  .opencampus_soudan .soudan-flow__item {
    text-align: center;
    padding: 30px 20px 20px;
    position: relative;
    display: flex;
  }
  .opencampus_soudan .soudan-flow__item__img {
    flex: 0 0 50px;
    margin: 0 20px 0 0;
  }
  .opencampus_soudan .soudan-flow__item__img > img {
    width: 100%;
  }
  .opencampus_soudan .soudan-flow__item__title {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 5px;
  }
  .opencampus_soudan .soudan-flow__item:first-child {
    background: #fff;
  }
  .opencampus_soudan .soudan-flow__item:first-child::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    z-index: 10;
  }
  .opencampus_soudan .soudan-flow__item:nth-child(2) {
    background: #EDFCFA;
  }
  .opencampus_soudan .soudan-flow__item:nth-child(2)::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #EDFCFA transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    z-index: 10;
  }
  .opencampus_soudan .soudan-flow__item:last-child {
    background: #D0F4F2;
  }
  .opencampus_soudan .soudan-time {
    margin: 0 0 20px;
  }
  .opencampus_soudan .soudan-time__item {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: #000;
    font-weight: 500;
    margin: 0 20px;
  }
  .opencampus_soudan .soudan-time__item__label {
    font-weight: 500;
    line-height: 1.0;
    font-size: 1.5rem;
    background: #FF6085;
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  .opencampus_soudan .soudan-tel {
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 30px;
  }
  .opencampus_soudan .soudan-tel .icon-phone {
    color: #FF6085;
    margin-right: 5px;
  }
  .opencampus_soudan .soudan-tel__link {
    text-decoration: none;
    color: #000;
  }
  .opencampus_soudan .calendar {
    margin: 0 0 40px;
  }
  .opencampus_soudan .oc-type__item {
    margin: 0;
  }
  /* ============================================================ */
  /* opencampus_soudan_form,opencampus_form */
  /* ============================================================ */
  .opencampus_soudan_form .label-must,
  .opencampus_form .label-must {
    background: #FF6085;
  }
  .opencampus_soudan_form .radio input[type="radio"] + span::after,
  .opencampus_form .radio input[type="radio"] + span::after {
    background: #FF6085;
  }
  .opencampus_soudan_form .checkbox input[type="checkbox"] + span::after,
  .opencampus_form .checkbox input[type="checkbox"] + span::after {
    color: #FF6085;
  }
  .opencampus_soudan_form .ui-state-active, .opencampus_soudan_form .ui-widget-content .ui-state-active, .opencampus_soudan_form .ui-widget-header .ui-state-active,
  .opencampus_form .ui-state-active,
  .opencampus_form .ui-widget-content .ui-state-active,
  .opencampus_form .ui-widget-header .ui-state-active {
    background: #FFBBC3;
    border: 1px solid #FF6085;
  }
  .opencampus_soudan_form .ui-datepicker-calendar .ui-state-default,
  .opencampus_form .ui-datepicker-calendar .ui-state-default {
    margin-left: 0 !important;
  }
  .opencampus_soudan_form .datepicker-saturday span.ui-state-default,
  .opencampus_form .datepicker-saturday span.ui-state-default {
    /* disabled saturday */
    background-image: none;
    color: dodgerblue !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form .datepicker-sunday span.ui-state-default,
  .opencampus_form .datepicker-sunday span.ui-state-default {
    /* disabled sunday */
    background-image: none;
    color: red !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form .datepicker-holiday span.ui-state-default,
  .opencampus_form .datepicker-holiday span.ui-state-default {
    /* disabled holiday */
    background-image: none;
    color: red !important;
    border: 1px solid transparent;
  }
  .opencampus_soudan_form #formBd01 th, .opencampus_soudan_form #formBd01 td, .opencampus_soudan_form #formBd02 th, .opencampus_soudan_form #formBd02 td, .opencampus_soudan_form #formBd03 th, .opencampus_soudan_form #formBd03 td,
  .opencampus_form #formBd01 th,
  .opencampus_form #formBd01 td,
  .opencampus_form #formBd02 th,
  .opencampus_form #formBd02 td,
  .opencampus_form #formBd03 th,
  .opencampus_form #formBd03 td {
    display: table-cell !important;
  }
  .opencampus_soudan_form .ui-datepicker,
  .opencampus_form .ui-datepicker {
    width: 100% !important;
  }
  .opencampus_soudan_form .ui-datepicker-calendar,
  .opencampus_form .ui-datepicker-calendar {
    display: table;
  }
  .opencampus_soudan_form .ui-datepicker-calendar thead,
  .opencampus_soudan_form .ui-datepicker-calendar tbody,
  .opencampus_form .ui-datepicker-calendar thead,
  .opencampus_form .ui-datepicker-calendar tbody {
    display: table-header-group;
  }
  .opencampus_soudan_form .ui-datepicker-calendar tr,
  .opencampus_form .ui-datepicker-calendar tr {
    display: table-row;
  }
  /* ============================================================ */
  /* feature */
  /* ============================================================ */
  .feature .banner {
    border-top: none;
  }
  .feature__label {
    margin: 0 0 0 15px;
    font-size: 1.5rem;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
  }
  .feature__label--green {
    background: #23C569;
  }
  .feature__label--purple {
    background: #7F4AAE;
  }
  .feature__label--pink {
    background: #FF6085;
  }
  .feature__label--black {
    background: #484848;
  }
  .feature__label--base {
    background: #23B8C5;
  }
  .feature .more-support {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
  }
  .feature .more-support__inner {
    border: 1px dashed #23B8C5;
    border-radius: 8px;
  }
  .feature .more-support__inner__head {
    border-bottom: 1px dashed #23B8C5;
    padding: 15px 0;
  }
  .feature .more-support__inner__head__title {
    font-size: 3.0rem;
    line-height: 1.0;
    font-weight: 500;
    text-align: center;
  }
  .feature .more-support__inner__body {
    padding: 30px 15px;
  }
  .feature .feature-name {
    font-size: 2.0rem;
    margin: 0 0 15px;
    font-weight: 500;
  }
  .feature .feature-name__label {
    font-size: 1.5rem;
    margin: 0 10px 0 0;
  }
  .feature .student-iv__item {
    background: #e8f8f9;
    border-radius: 15px;
    padding: 30px 20px;
  }
  .feature .student-iv__item .feature-name {
    color: #23B8C5;
    text-align: center;
  }
  .feature__voice__img {
    flex: 100%;
    padding: 0;
    text-align: center;
  }
  .feature__voice .__img {
    margin: 15px 0 -15px;
    width: 50% !important;
    max-width: 195px;
  }
  /* ============================================================ */
  /* feature_index */
  /* ============================================================ */
  #feature_index .catch01 {
    color: #000;
    line-height: 2.0;
    margin: 0 0 30px;
    font-size: 1.8rem;
  }
  #feature_index .catch01 > img {
    margin: 0 5px 5px;
    width: 40px;
  }
  #feature_index .feature-item {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  #feature_index .feature-item:last-child {
    margin-bottom: 0;
  }
  #feature_index .feature-item__title {
    display: flex;
    font-size: 2.2rem;
    line-height: 1.4;
    color: #FF8843;
    align-items: center;
    font-weight: 500;
    margin: 0 0 15px;
  }
  #feature_index .feature-item__title img {
    width: auto;
    max-width: none;
    margin: 0 15px 0 0;
    width: 40px;
  }
  #feature_index .feature-item__title > img:not([class*="wd-a"]) {
    width: 30px;
  }
  #feature_index .feature-item__text {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #feature_index .feature-item__text .__img {
    margin: 15px auto;
    max-width: 60%;
  }
  #feature_index .feature-item__btn {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
  }
  #feature_index .feature-item._sub_ .feature-item__head {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 15px;
    width: 100%;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__img {
    width: 80%;
    display: flex;
    padding: 20px 30px;
    align-items: center;
    justify-content: center;
    background: url(/common/img/feature/index/bg_feature.svg) center center repeat;
    border-radius: 10px;
    margin: 0 0 15px;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__img .__img {
    max-width: 100%;
  }
  #feature_index .feature-item._sub_ .feature-item__head .feature-item__title {
    flex: 1 1 37%;
    color: #23B8C5;
    align-items: center;
  }
  /* ============================================================ */
  /* feature_hoiku */
  /* ============================================================ */
  .feature_hoiku .hoiku-lnav {
    padding: 35px 0;
  }
  .feature_hoiku .hoiku-lnav__item {
    border: 1px solid #DFDFDF;
    border-top: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    padding: 15px 20px;
    margin: 0 20px;
  }
  .feature_hoiku .hoiku-lnav__item:first-child {
    border-top: 1px solid #DFDFDF;
  }
  .feature_hoiku .hoiku-lnav__item__img {
    margin: 0 10px 0 0;
  }
  .feature_hoiku .hoiku-lnav__item__img > * {
    max-width: 100px;
    width: 100%;
  }
  .feature_hoiku .hoiku-lnav__item .ttl08 {
    margin: 0 0 5px;
  }
  .feature_hoiku .hoiku-lnav__item .icon-angle-down {
    color: #23B8C5;
    margin: 0 5px 0 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    font-size: 2rem;
  }
  .feature_hoiku .hoiku-lnav__item__teach {
    padding: 0 0 0 20px;
    color: #303030;
  }
  .feature_hoiku .ttl04__label {
    font-size: 1.8rem;
    display: block;
  }
  .feature_hoiku .hoiku-group__item {
    margin-bottom: 30px;
  }
  .feature_hoiku .hoiku-group__item__teacher {
    display: flex;
    margin: 0 0 15px;
  }
  .feature_hoiku .hoiku-group__item__teacher__img {
    margin: 0 10px 0 0;
  }
  .feature_hoiku .hoiku-group__item__teacher__img > * {
    max-width: 110px;
    width: 100%;
  }
  .feature_hoiku .hoiku-group__item__teacher .feature__label {
    width: auto;
    padding: 0 15px;
    margin: 0 0 15px 0;
    display: inline-block;
  }
  .feature_hoiku .hoiku-group__item__teacher .ttl07 {
    font-size: 2rem;
    line-height: 1.8;
  }
  .feature_hoiku .hoiku-group__item__teacher .ttl07__small {
    font-size: 1.4rem;
    display: flex;
  }
  .feature_hoiku .hoiku-group__info {
    margin: 0 0 40px;
  }
  .feature_hoiku .hoiku-group__info .catch01 {
    text-align: left;
    margin: 0 0 10px;
  }
  .feature_hoiku .hoiku-group__info .icon-envelope-square {
    color: #23B8C5;
    font-size: 1.8rem;
    margin: 0 5px 0 0;
  }
  /* ============================================================ */
  /* feature_child-academy */
  /* ============================================================ */
  .feature_child-academy .banner {
    border-top: 1px solid #CEE5E8;
  }
  .feature_child-academy .group {
    margin: 40px 0 0 0;
  }
  .feature_child-academy .group__item {
    margin: 20px 0 0;
  }
  .feature_child-academy .group__item:last-child {
    margin-bottom: 0;
  }
  .feature_child-academy .group__item__box {
    margin: 0 0 20px;
    padding: 20px;
  }
  .feature_child-academy .group__item .ttl07 {
    display: flex;
    align-items: center;
  }
  .feature_child-academy .group__item .btn--md {
    width: 90%;
  }
  .feature_child-academy .group__item--niji {
    margin-bottom: 60px;
  }
  .feature_child-academy .group__item--niji .ttl07 {
    display: block;
  }
  .feature_child-academy .group__item--niji .feature__label {
    margin: 0 0 10px;
  }
  .feature_child-academy .group__item__comment {
    background: #FBF5DA;
    /* &__img {
                    width: 130px;
                    margin: 0 auto;
                    & > * {
                        width: 100%;
                        border-radius: 50%;
                    }
                }*/
  }
  .feature_child-academy .group__item__comment__text__title {
    font-size: 2rem;
    font-weight: 500;
  }
  .feature_child-academy .group__item__comment__text__title__label {
    font-size: 1.5rem;
    margin: 0 15px 0 0;
  }
  /* ============================================================ */
  /* feature_piano */
  /* ============================================================ */
  .feature_piano__questionnaire {
    font-weight: 500;
  }
  .feature_piano__questionnaire__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
  .feature_piano__questionnaire__ttl .__label {
    background: #FF8843;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2.2rem;
    flex: 0 0 40px;
  }
  .feature_piano__questionnaire .row__inner-white {
    padding: 30px 15px;
    border-radius: 0;
  }
  .feature_piano__questionnaire__point .__ttl {
    color: #23B8C5;
    font-size: 2rem;
    font-weight: 500;
  }
  .feature_piano__questionnaire__point__list {
    margin: 20px 0 0 0;
  }
  .feature_piano__questionnaire__point__list__item {
    padding: 0 0 15px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    border-bottom-color: #23B8C5;
  }
  .feature_piano__questionnaire__point__list__item__icon {
    background: #23B8C5;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex: 0 0 60px;
    margin: 0 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.9rem;
    line-height: 1.0;
    color: #fff;
    flex-direction: column;
  }
  .feature_piano__questionnaire__point__list__item__icon .__label {
    font-size: 1.0rem;
  }
  .feature_piano__questionnaire__point__list__item:last-child {
    border-bottom: none;
  }
  .feature_piano .student-list__item {
    margin-bottom: 40px;
  }
  .feature_piano .student-list__item img {
    margin: 0 0 15px;
  }
  .feature_piano .student-list__item__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .feature_piano .student-list__item__title .feature__label {
    margin: 0 15px 0 0;
  }
  /* ============================================================ */
  /* feature_education */
  /* ============================================================ */
  .feature_education .catch01 {
    border-bottom: 1px solid #23B8C5;
    padding: 0 0 10px;
    text-align: left;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  .feature_education .ningengaku {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* feature_recruit */
  /* ============================================================ */
  .feature_recruit .recruit-icon {
    display: flex;
    justify-content: center;
    margin: 0 0 15px;
  }
  .feature_recruit .recruit-icon__item {
    margin: 0 10px;
  }
  .feature_recruit .recruit-icon__item > img {
    width: 100%;
  }
  .feature_recruit .catch01 {
    padding: 0 0 20px;
  }
  /* ============================================================ */
  /* feature_school-life */
  /* ============================================================ */
  .feature_school-life .event-title {
    text-align: center;
    margin: 0 0 20px;
    display: inline-block;
  }
  .feature_school-life .event-title > * {
    max-width: 70%;
  }
  .feature_school-life .event-list__item {
    margin-bottom: 30px;
  }
  .feature_school-life .event-list__item__img {
    border-radius: 10px;
    margin: 0 0 5px;
  }
  .feature_school-life .event-list__item__title {
    display: flex;
    align-items: center;
    line-height: 1.0;
    padding: 5px 0;
    font-weight: 500;
  }
  .feature_school-life .instagram__catch {
    color: #006281;
    font-weight: 500;
    font-size: 2.2rem;
  }
  /* ============================================================ */
  /* feature_piano */
  /* ============================================================ */
  .feature_piano_pianolesson .banner {
    border-top: 1px solid #CEE5E8;
  }
  .feature_piano_pianolesson .dailylife-ttl {
    background: url(/common/img/feature/piano/pianolesson/bg_special01.png) 0 0 repeat;
    padding: 0;
  }
  .feature_piano_pianolesson .catch01 {
    margin: 0 0 30px;
  }
  .feature_piano_pianolesson .flow .box {
    margin: 0 0 50px;
    position: relative;
  }
  .feature_piano_pianolesson .flow .box .catch01 {
    text-align: left;
    margin-bottom: 5px;
  }
  .feature_piano_pianolesson .flow .box .feature__label {
    width: auto;
    padding: 0 20px;
    margin: 0;
  }
  .feature_piano_pianolesson .flow .box::after {
    content: "";
    background: url(/common/img/feature/piano/pianolesson/icon_yajirushi01.svg) no-repeat;
    width: 35px;
    height: 32px;
    bottom: -40px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .feature_piano_pianolesson .flow .box:last-child {
    background: #f9edb5;
    margin-bottom: 0;
  }
  .feature_piano_pianolesson .flow .box:last-child .catch01 {
    color: #303030;
  }
  .feature_piano_pianolesson .flow .box:last-child::after {
    display: none;
  }
  .feature_piano_pianolesson .question {
    background: url(/common/img/feature/piano/pianolesson/ico_pianolesson01_pc.png) no-repeat;
    background-position: 0 10px;
    font-size: 2.0rem;
    text-align: left;
    padding-left: 90px;
    margin: 0 auto 20px;
    line-height: 150%;
    font-weight: 700;
  }
  .feature_piano_pianolesson .question span {
    line-height: 130%;
    font-size: 1.6rem;
  }
  .feature_piano_pianolesson .type {
    margin-bottom: 40px;
  }
  .feature_piano_pianolesson .type .accordion {
    border: none;
    position: relative;
  }
  .feature_piano_pianolesson .type .accordion::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    content: "\f055";
    font-size: 2.2rem;
    font-family: 'icomoon';
    color: #303030;
  }
  .feature_piano_pianolesson .type .accordion.active::after {
    content: "\f056";
    font-family: 'icomoon';
  }
  .feature_piano_pianolesson .type .accordion-hide {
    background: #d0eef7;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
    margin: 10px 0 0;
  }
  .feature_piano_pianolesson .type .accordion-hide .type__student {
    background: #fff;
    padding: 10px 15px;
  }
  .feature_piano_pianolesson .type .accordion-hide .type__student .ttl07 {
    font-size: 1.8rem;
    color: #47b6d9;
    font-weight: bold;
  }
  .feature_piano_pianolesson .type .accordion-hide .type__student .ttl07:before {
    content: url(/common/img/special/pianolesson/ico_pianolesson03_sp.png);
    margin-right: 5px;
    top: 2px;
    position: relative;
  }
  .feature_piano_pianolesson .type .accordion-hide .type__teacher .float__item {
    width: 17%;
    margin-bottom: 0px;
  }
  .feature_piano_pianolesson .beginner .accordion {
    background: #f9ed81;
  }
  .feature_piano_pianolesson .blank .accordion {
    background: #74ccd9;
  }
  .feature_piano_pianolesson .senior .accordion {
    background: #ffc4ca;
  }
  .feature_piano_pianolesson .messeage__item {
    padding: 10px 20px 20px;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(odd) {
    background: #e8f4f7;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(odd) .messeage__item__text {
    border-bottom: 2px solid #fff;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(even) {
    background: #fff;
  }
  .feature_piano_pianolesson .messeage__item:nth-child(even) .messeage__item__text {
    border-bottom: 2px solid #e8f4f7;
  }
  .feature_piano_pianolesson .messeage__item__text {
    padding-bottom: 5px;
    line-height: 250%;
    display: inline;
    font-size: 1.4rem;
    font-weight: 500;
  }
  /* ============================================================ */
  /* feature_recruit_conection */
  /* ============================================================ */
  .feature_recruit_conection .more-support {
    background: #FFFCEF;
  }
  .feature_recruit_conection .more-support__inner__body {
    padding: 30px 20px;
  }
  .feature_recruit_conection .more-support__inner__body__img {
    text-align: center;
  }
  .feature_recruit_conection .banner {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* admission */
  /* ============================================================ */
  .admission .box__head {
    background: #006281;
    color: #fff;
    padding: 7px 15px;
  }
  .admission .ad-block {
    margin: 0 0 30px;
  }
  .admission .ttl09__small {
    font-size: 1.5rem;
    padding-left: 10px;
  }
  .admission .ad-flow {
    margin: 50px 0 30px 0;
  }
  .admission .ad-flow__title {
    margin: 0 0 25px;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
  }
  .admission .ad-flow__title__num {
    color: #23B8C5;
    font-size: 2.2rem;
    padding: 0 5px 0 0;
  }
  .admission .ad-flow__btn {
    margin: 30px 0 0 0;
  }
  .admission .ad-flow__btn .btn {
    width: 90%;
  }
  .admission .admission-ttl01 {
    background: #006281;
    color: #fff;
    font-size: 1.8rem;
    padding: 7px 15px;
    margin: 0 0 20px;
    font-weight: 500;
  }
  .admission .table--tuition thead th {
    background: #006281;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
  }
  .admission .table--tuition thead th.season {
    border-bottom: 1px dashed #fff !important;
  }
  .admission .table--tuition thead th.date {
    border-top: none;
  }
  .admission .table--tuition thead th.bunkatsu-season {
    border-bottom: none;
  }
  .admission .table--tuition tbody th, .admission .table--tuition tbody td {
    border: 1px solid #DFDFDF;
  }
  .admission .table--tuition tbody th .note, .admission .table--tuition tbody td .note {
    margin-left: 5px;
    font-size: 1.2rem;
  }
  .admission .table--tuition tbody th .note02, .admission .table--tuition tbody td .note02 {
    position: relative;
  }
  .admission .table--tuition tbody th .note02__item, .admission .table--tuition tbody td .note02__item {
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 1.2rem;
  }
  .admission .table--tuition tbody th.item {
    font-weight: 500;
    text-align: center;
  }
  .admission .table--tuition tbody th.item .item__text {
    writing-mode: vertical-rl;
    width: 30px;
    line-height: 30px;
  }
  .admission .table--tuition tbody td {
    text-align: right;
  }
  /* ============================================================ */
  /* admission_index */
  /* ============================================================ */
  #admission_index .department-type {
    margin-bottom: 50px;
  }
  #admission_index .department-type__item__img {
    position: relative;
    padding: 0 0 50px;
    margin-bottom: 20px;
  }
  #admission_index .department-type__item__img__text {
    padding: 15px 30px;
    width: 85%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #admission_index .department-type__item__img__text__title {
    font-size: 2.5rem;
  }
  #admission_index .department-type__item .catch01 {
    font-weight: 400;
    margin: 0 0 20px;
  }
  #admission_index .department-type__item--kodomo .department-type__item__img__text {
    background: rgba(255, 136, 67, 0.9);
  }
  #admission_index .department-type__item--kodomo .catch01 {
    color: #FF8843;
  }
  #admission_index .department-type__item--yoji .department-type__item__img__text {
    background: rgba(255, 96, 133, 0.9);
  }
  #admission_index .department-type__item--yoji .catch01 {
    color: #FF6085;
  }
  #admission_index .ttl07 {
    font-size: 2.2rem;
  }
  #admission_index .ttl07__small {
    font-size: 1.6rem;
    padding: 0 0 0 10px;
  }
  #admission_index .recruitment-idx,
  #admission_index .type-idx,
  #admission_index .tuition-idx {
    margin-bottom: 60px;
  }
  #admission_index .recruitment-idx__item {
    margin: 0 0 60px;
  }
  #admission_index .recruitment-idx__item .box {
    margin: 0 0 30px;
  }
  #admission_index .recruitment-idx__item .box .list {
    margin: 0 0 15px;
  }
  #admission_index .type-idx__ico__item {
    display: flex;
    align-items: center;
  }
  #admission_index .type-idx__ico__item:last-child {
    margin-bottom: 0;
  }
  #admission_index .type-idx__ico__item img {
    max-width: 100px;
    flex: 0 0 100px;
  }
  #admission_index .type-idx__ico__item .btn {
    margin: 0 0 0 20px;
  }
  #admission_index .type-idx__ico__item .btn.btn--md02 {
    width: 60%;
  }
  #admission_index .type-idx__pdf {
    border: 1px solid #23B8C5;
    border-left: 7px solid #23B8C5;
    font-weight: 500;
    font-size: 1.8rem;
    padding: 5px 20px;
    line-height: 1.5;
    margin: 25px 0 0 0;
  }
  #admission_index .type-idx__pdf__dl {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 1.6rem;
    margin: 5px 0 0 0;
  }
  #admission_index .type-idx__pdf__dl .__ico {
    color: #23B8C5;
    margin: 0 0 0 5px;
  }
  #admission_index .tuition-idx__item {
    margin-bottom: 20px;
  }
  #admission_index .tuition-idx__item__box .icon-angle-right {
    margin: 0 0 0 5px;
  }
  #admission_index .support-idx__item {
    margin: 0 0 30px;
  }
  #admission_index .support-idx__item .btn {
    margin: 30px 0 0;
    width: 100%;
  }
  #admission_index .support-idx__item .icon-angle-right {
    margin: 0 0 0 5px;
  }
  /* ============================================================ */
  /* admission_ao,admission_suisen,admission_ippan */
  /* ============================================================ */
  .admission_ao .policy,
  .admission_suisen .policy,
  .admission_ippan .policy {
    margin: 0 0 40px;
  }
  .admission_ao .policy .ttl03,
  .admission_suisen .policy .ttl03,
  .admission_ippan .policy .ttl03 {
    font-size: 2.2rem;
  }
  .admission_ao .policy__item,
  .admission_suisen .policy__item,
  .admission_ippan .policy__item {
    display: flex;
  }
  .admission_ao .policy__item__num,
  .admission_suisen .policy__item__num,
  .admission_ippan .policy__item__num {
    flex: 0 0 30px;
    background: #23B8C5;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    margin: 0 10px 10px 0;
  }
  .admission_ao .table th,
  .admission_suisen .table th,
  .admission_ippan .table th {
    font-weight: 500;
  }
  .admission_ao .ad-lnav,
  .admission_suisen .ad-lnav,
  .admission_ippan .ad-lnav {
    border-top: 1px solid #CEE5E8;
  }
  /* ============================================================ */
  /* admission_support */
  /* ============================================================ */
  .admission_support .challenge-flow {
    margin: 0 0 40px;
  }
  .admission_support .challenge-flow__item {
    margin: 0 0 50px 0;
    width: 100%;
    border: 2px solid #23B8C5;
    color: #23B8C5;
    display: flex;
    align-items: center;
    padding: 15px;
    font-weight: 500;
    position: relative;
    font-size: 1.5rem;
  }
  .admission_support .challenge-flow__item::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -42px;
    content: "\e908";
    font-family: 'icomoon';
    color: #000;
    font-size: 2rem;
  }
  .admission_support .challenge-flow__item:last-child {
    margin: 0;
  }
  .admission_support .challenge-flow__item:last-child::after {
    display: none;
  }
  .admission_support .challenge-flow__item__label {
    font-size: 3.2rem;
    padding: 0 15px 0 0;
    margin: 0 15px 0 0;
    border-right: 2px solid #23B8C5;
  }
  .admission_support .challenge-price .icon-arrow-circle-right {
    margin: 0 10px;
  }
  /* ============================================================ */
  /* admission_tuition */
  /* ============================================================ */
  .admission_tuition .admission-bnr {
    width: 270px;
  }
  .admission_tuition .kyufu-ex {
    display: flex;
  }
  .admission_tuition .kyufu-ex .__label {
    background: #23B8C5;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin: 0 15px 0 0;
  }
  .admission_tuition .lone-flow {
    margin: 0 0 40px;
  }
  .admission_tuition .lone-flow .box__head {
    background: #23B8C5;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 500;
  }
  .admission_tuition .lone-flow .box--border-base {
    border-width: 2px;
    text-align: center;
  }
  .admission_tuition .lone-flow__body {
    margin: 0 0 20px;
  }
  .admission_tuition .lone-flow__body__icon {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .admission_tuition .lone-flow__body__icon img {
    transform: rotate(90deg);
    padding: 10px 0 0;
  }
  /* ============================================================ */
  /* admission_ao_form,admission_ippan_form,admission_suisen_form */
  /* ============================================================ */
  .admission_ao_form .pdf-dl__item,
  .admission_ippan_form .pdf-dl__item,
  .admission_suisen_form .pdf-dl__item {
    margin: 0 0 5px;
  }
  /* ============================================================ */
  /* admission_support */
  /* ============================================================ */
  .admission_support .ad-contact {
    font-weight: 500;
    font-size: 1.8rem;
  }
  .admission_support .ad-contact__tel {
    color: #23B8C5;
  }
  .admission_support .ad-contact__num {
    font-size: 2.4rem;
    color: #303030;
  }
  /* ============================================================ */
  /* admission_graduate */
  /* ============================================================ */
  .admission_graduate .student-list__item {
    border-radius: 15px;
    padding: 20px 25px;
    margin: 0 0 30px;
    background: #EFF9FB;
  }
  .admission_graduate .student-list__item__data__img {
    width: 160px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .admission_graduate .student-list__item__data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .admission_graduate .student-list__item__data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    font-size: 1.5rem;
    background: #23B8C5;
  }
  .admission_graduate .student-list__item__data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
  }
  .admission_graduate .student-list__item__catch {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 20px;
    color: #23B8C5;
  }
  .admission_graduate .row__inner-white {
    margin-bottom: 30px;
  }
  .admission_graduate .ttl01__text {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  .admission_graduate .student-data {
    margin: 0 0 20px;
  }
  .admission_graduate .student-data__img {
    width: 160px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .admission_graduate .student-data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .admission_graduate .student-data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    min-width: 110px;
    line-height: 25px;
    font-size: 1.5rem;
    background: #23B8C5;
  }
  .admission_graduate .student-data__text__name {
    margin: 0 0 10px;
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .admission_graduate .student-data__text__name__label {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0 15px 0 0;
  }
  .admission_graduate .student-data__text__name__type {
    width: 100%;
    color: #484848;
    font-size: 1.5rem;
    font-weight: 400;
  }
  .admission_graduate .student-data__text__catch {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0 0 10px;
    border-bottom: 1px solid #23B8C5;
    color: #23B8C5;
  }
  .admission_graduate .student-data__text .__school {
    font-size: 1.5rem;
  }
  .admission_graduate .box {
    margin: 0 0 40px;
  }
  .admission_graduate .student-block {
    margin: 0 0 50px;
  }
  .admission_graduate .student-block .student-ttl {
    border-bottom: 3px dotted #666666;
    padding: 0 0 15px;
    margin: 0 0 30px;
  }
  .admission_graduate .student-block .question {
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  .admission_graduate .student-block .question__label {
    flex: 0 0 40px;
    margin: 0 15px 0 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.6rem;
    background: #23B8C5;
  }
  /* ============================================================ */
  /* department */
  /* ============================================================ */
  .department .ttl02__small {
    display: block;
    font-size: 2.2rem;
  }
  .department .banner {
    border-top: none;
  }
  .department .comment__img {
    flex: 100%;
    padding: 0;
    text-align: center;
  }
  .department .comment .__img {
    margin: 15px 0 -15px;
    max-width: 50%;
  }
  .department .question {
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  .department .question__label {
    flex: 0 0 40px;
    margin: 0 15px 0 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.6rem;
  }
  .department .question.kodomo .question__label {
    background: #FF8843;
  }
  /* ============================================================ */
  /* department_index */
  /* ============================================================ */
  #department_index .department-type {
    margin-bottom: 50px;
  }
  #department_index .department-type__item__img {
    position: relative;
    padding: 0 0 50px;
    margin-bottom: 20px;
  }
  #department_index .department-type__item__img__text {
    padding: 15px 30px;
    width: 85%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  #department_index .department-type__item__img__text__title {
    font-size: 2.5rem;
  }
  #department_index .department-type__item .catch01 {
    font-weight: 400;
    margin: 0 0 20px;
  }
  #department_index .department-type__item__point {
    box-shadow: 0px 0px 6px 3px #f3f3f3;
    padding: 10px 20px 40px;
  }
  #department_index .department-type__item__point__list {
    margin: 0 0 20px;
  }
  #department_index .department-type__item__point__list__item {
    padding: 15px 0;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
  }
  #department_index .department-type__item__point__list__item__icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex: 0 0 70px;
    margin: 0 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.9rem;
    line-height: 1.0;
    color: #fff;
    flex-direction: column;
  }
}

@media screen and (max-width: 767.499px) and (min-width: 767.5px) and (max-width: 1024px) {
  #department_index .department-type__item__point__list__item__icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin: 0 15px 0 0;
  }
}

@media screen and (max-width: 767.499px) {
  #department_index .department-type__item__point__list__item__icon__label {
    font-size: 1.0rem;
  }
  #department_index .department-type__item__point__list__item:last-child {
    border-bottom: none;
  }
  #department_index .department-type__item--kodomo .department-type__item__img__text {
    background: rgba(255, 136, 67, 0.9);
  }
  #department_index .department-type__item--kodomo .catch01 {
    color: #FF8843;
  }
  #department_index .department-type__item--kodomo .department-type__item__point__list__item {
    border-bottom-color: #FF8843;
  }
  #department_index .department-type__item--kodomo .department-type__item__point__list__item__icon {
    background: #FF8843;
  }
  #department_index .department-type__item--yoji .department-type__item__img__text {
    background: rgba(255, 96, 133, 0.9);
  }
  #department_index .department-type__item--yoji .catch01 {
    color: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item {
    border-bottom-color: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item__icon {
    background: #FF6085;
  }
  #department_index .department-type__item--yoji .department-type__item__point__list__item a {
    color: #FF6085;
  }
  #department_index .triple,
  #department_index .dual {
    margin: 30px 0 0 0;
    padding: 30px 15px;
  }
  #department_index .triple__title,
  #department_index .dual__title {
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
  }
  #department_index .triple__title .__label,
  #department_index .dual__title .__label {
    background: #FF6085;
    border-radius: 18px;
    color: #fff;
    font-size: 1.5rem;
    position: relative;
    height: 35px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 0 0;
    white-space: nowrap;
  }
  #department_index .triple__title .__label::before,
  #department_index .dual__title .__label::before {
    content: "";
    position: absolute;
    bottom: 7px;
    right: -14px;
    border: 6px solid transparent;
    border-left: 18px solid #FF6085;
    transform: rotate(-15deg);
  }
  #department_index .triple .__text,
  #department_index .dual .__text {
    color: #FF6085;
  }
  #department_index .triple .__text .icon-square,
  #department_index .dual .__text .icon-square {
    margin: 0 10px 0 0;
  }
  #department_index .triple {
    margin: 30px 0 40px;
  }
  #department_index .triple .__label {
    background: #FF8843;
  }
  #department_index .triple .__label::before {
    border-left: 18px solid #FF8843;
  }
  #department_index .triple .__text {
    color: #FF8843;
  }
  #department_index .support__ttl {
    background: #7378B7;
    color: #fff;
    font-weight: 500;
    margin: 0 0 30px;
    font-size: 2rem;
    padding: 10px 20px;
  }
  #department_index .support__item {
    margin-bottom: 30px;
  }
  #department_index .support__item img {
    margin: 0 0 15px;
  }
  #department_index .support__ttl02 {
    font-weight: 500;
    font-size: 2rem;
    color: #7378B7;
    margin: 0 0 15px;
    display: flex;
    align-items: center;
    line-height: 1.5;
  }
  #department_index .support__ttl02 .__label {
    background: #7378B7;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 0 10px 0 0;
    flex: 0 0 40px;
  }
  #department_index .support__list {
    border: 1px solid #7378B7;
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    height: 100%;
  }
  #department_index .support__list::after {
    display: block;
    content: "";
    clear: both;
  }
  #department_index .support__list__img {
    float: right;
    margin: 0 0 10px 10px;
    max-width: 30%;
  }
  #department_index .support__list__img img {
    max-width: 100%;
  }
  #department_index .ttl04--index::after {
    border-bottom: 2px solid #7378B7;
  }
  /* ============================================================ */
  /* department_kodomo,.department_yoji */
  /* ============================================================ */
  .department_kodomo .catch01--kodomo,
  .department_yoji .catch01--kodomo {
    color: #FF8843;
  }
  .department_kodomo .catch01--yoji,
  .department_yoji .catch01--yoji {
    color: #FF6085;
  }
  .department_kodomo .ttl04--kodomo::after,
  .department_yoji .ttl04--kodomo::after {
    border-bottom: 2px solid #FF8843;
  }
  .department_kodomo .ttl04--yoji::after,
  .department_yoji .ttl04--yoji::after {
    border-bottom: 2px solid #FF6085;
  }
  .department_kodomo .ttl06--kodomo,
  .department_yoji .ttl06--kodomo {
    border-bottom: 1px solid #FF8843;
  }
  .department_kodomo .ttl06--kodomo:first-letter,
  .department_yoji .ttl06--kodomo:first-letter {
    color: #FF8843;
  }
  .department_kodomo .ttl06--yoji,
  .department_yoji .ttl06--yoji {
    border-bottom: 1px solid #FF6085;
  }
  .department_kodomo .ttl06--yoji:first-letter,
  .department_yoji .ttl06--yoji:first-letter {
    color: #FF6085;
  }
  .department_kodomo .ttl07--kodomo,
  .department_yoji .ttl07--kodomo {
    border-left: 4px solid #FF8843;
  }
  .department_kodomo .ttl07--yoji,
  .department_yoji .ttl07--yoji {
    border-left: 4px solid #FF6085;
  }
  .department_kodomo .department-about,
  .department_yoji .department-about {
    align-items: center;
    margin: 0 0 40px;
  }
  .department_kodomo .department-about__guide,
  .department_yoji .department-about__guide {
    margin-top: 30px;
    flex: 1 1 auto;
  }
  .department_kodomo .department-about__guide__item,
  .department_yoji .department-about__guide__item {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    padding: 15px 0;
    display: flex;
  }
  .department_kodomo .department-about__guide__item:first-child,
  .department_yoji .department-about__guide__item:first-child {
    border-top-width: 1px;
    border-top-style: dotted;
  }
  .department_kodomo .department-about__guide__item__label,
  .department_yoji .department-about__guide__item__label {
    border-right-width: 4px;
    border-right-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    margin-right: 15px;
    flex: 0 0 100px;
    font-weight: 500;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item {
    border-bottom-color: #FF8843;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item:first-child,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item:first-child {
    border-top-color: #FF8843;
  }
  .department_kodomo .department-about__guide--kodomo .department-about__guide__item__label,
  .department_yoji .department-about__guide--kodomo .department-about__guide__item__label {
    border-right-color: #FF8843;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item,
  .department_yoji .department-about__guide--yoji .department-about__guide__item {
    border-bottom-color: #FF6085;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item:first-child,
  .department_yoji .department-about__guide--yoji .department-about__guide__item:first-child {
    border-top-color: #FF6085;
  }
  .department_kodomo .department-about__guide--yoji .department-about__guide__item__label,
  .department_yoji .department-about__guide--yoji .department-about__guide__item__label {
    border-right-color: #FF6085;
  }
  .department_kodomo .department-point__item,
  .department_yoji .department-point__item {
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    font-weight: 500;
  }
  .department_kodomo .department-point__item__title,
  .department_yoji .department-point__item__title {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    font-size: 2.2rem;
    padding: 0 0 5px 0;
    margin: 0 0 10px;
  }
  .department_kodomo .department-point--kodomo .department-point__item,
  .department_yoji .department-point--kodomo .department-point__item {
    background: #FFFCEF;
  }
  .department_kodomo .department-point--kodomo .department-point__item__title,
  .department_yoji .department-point--kodomo .department-point__item__title {
    color: #FF8843;
    border-bottom-color: #FF8843;
  }
  .department_kodomo .department-point--yoji .department-point__item,
  .department_yoji .department-point--yoji .department-point__item {
    background: #FFF6F8;
  }
  .department_kodomo .department-point--yoji .department-point__item__title,
  .department_yoji .department-point--yoji .department-point__item__title {
    color: #FF6085;
    border-bottom-color: #FF6085;
  }
  .department_kodomo .department-shikaku,
  .department_yoji .department-shikaku {
    margin: 0 0 30px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block,
  .department_yoji .department-shikaku .department-shikaku__block {
    margin: 0 0 20px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block .ttl08,
  .department_yoji .department-shikaku .department-shikaku__block .ttl08 {
    margin: 0 0 5px;
  }
  .department_kodomo .department-shikaku .department-shikaku__block:last-child,
  .department_yoji .department-shikaku .department-shikaku__block:last-child {
    margin: 0;
  }
  .department_kodomo .department-shikaku:last-child,
  .department_yoji .department-shikaku:last-child {
    margin: 0;
  }
  .department_kodomo .table--department th, .department_kodomo .table--department td,
  .department_yoji .table--department th,
  .department_yoji .table--department td {
    text-align: center;
  }
  .department_kodomo .table--department thead th,
  .department_yoji .table--department thead th {
    color: #fff;
  }
  .department_kodomo .table--department--kodomo td,
  .department_yoji .table--department--kodomo td {
    background: #FFFCEF;
  }
  .department_kodomo .table--department--kodomo th:first-child,
  .department_yoji .table--department--kodomo th:first-child {
    background: #FFF5C9;
  }
  .department_kodomo .table--department--kodomo thead th,
  .department_yoji .table--department--kodomo thead th {
    background: #FF8843;
  }
  .department_kodomo .table--department--yoji td,
  .department_yoji .table--department--yoji td {
    background: #FFF9FB;
  }
  .department_kodomo .table--department--yoji th:first-child,
  .department_yoji .table--department--yoji th:first-child {
    background: #FFE4EA;
  }
  .department_kodomo .table--department--yoji thead th,
  .department_yoji .table--department--yoji thead th {
    background: #FF6085;
  }
  .department_kodomo .table--department--yoji thead th:first-child,
  .department_yoji .table--department--yoji thead th:first-child {
    background: #FF6085;
  }
  .department_kodomo .department-voice__title,
  .department_yoji .department-voice__title {
    text-align: center;
    margin: 0 0 30px;
  }
  .department_kodomo .department-voice__question,
  .department_yoji .department-voice__question {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 500;
    margin: 0 0 30px;
  }
  .department_kodomo .department-voice__question__label,
  .department_yoji .department-voice__question__label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    font-size: 2rem;
    margin: 0 10px 0 0;
  }
  .department_kodomo .department-voice__question__label--kodomo,
  .department_yoji .department-voice__question__label--kodomo {
    background: #FF8843;
  }
  .department_kodomo .department-voice__question__label--yoji,
  .department_yoji .department-voice__question__label--yoji {
    background: #FF6085;
  }
  .department_kodomo .department-voice__img,
  .department_yoji .department-voice__img {
    max-width: 100%;
    margin: 0 auto 30px;
  }
  .department_kodomo .department-voice__img img,
  .department_yoji .department-voice__img img {
    width: 100%;
  }
  .department_kodomo .department-voice__item,
  .department_yoji .department-voice__item {
    margin-bottom: 20px;
  }
  .department_kodomo .department-voice__item--kodomo,
  .department_yoji .department-voice__item--kodomo {
    background: #FFF5EF;
  }
  .department_kodomo .department-voice__item--yoji,
  .department_yoji .department-voice__item--yoji {
    background: #FFF6F8;
  }
  .department_kodomo .department-voice__item__img,
  .department_yoji .department-voice__item__img {
    margin: 0 0 15px;
    text-align: center;
  }
  .department_kodomo .department-voice__item__img img,
  .department_yoji .department-voice__item__img img {
    width: 138px;
  }
  .department_kodomo .department-voice__item .ttl08,
  .department_yoji .department-voice__item .ttl08 {
    text-align: center;
  }
  .department_kodomo .department-voice__item .ttl08__small,
  .department_yoji .department-voice__item .ttl08__small {
    font-size: 1.5rem;
    padding: 0 10px 0 0;
  }
  .department_kodomo .contents-wrap__inner,
  .department_yoji .contents-wrap__inner {
    margin-top: 30px;
    position: relative;
    padding: 70px 15px 40px;
  }
  .department_kodomo .contents-wrap__inner::before,
  .department_yoji .contents-wrap__inner::before {
    width: 80px;
    height: 100px;
    content: "";
    position: absolute;
    top: -40px;
    left: 20px;
  }
  .department_kodomo .contents-wrap__inner .row__inner-white,
  .department_yoji .contents-wrap__inner .row__inner-white {
    border-radius: 0;
    padding: 40px 15px;
  }
  .department_kodomo .contents-wrap__merit__item,
  .department_yoji .contents-wrap__merit__item {
    margin: 0 0 20px;
    display: table;
  }
  .department_kodomo .contents-wrap__merit__item > *:first-child,
  .department_yoji .contents-wrap__merit__item > *:first-child {
    display: table-cell;
    white-space: nowrap;
    padding: 0 10px 0 0;
  }
  .department_kodomo .contents-wrap__merit__item > *:first-child .__num,
  .department_yoji .contents-wrap__merit__item > *:first-child .__num {
    font-weight: 500;
    color: #fff;
    content: attr(data-text);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
  .department_kodomo .contents-wrap__merit__item .__txt,
  .department_yoji .contents-wrap__merit__item .__txt {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5;
    margin: 0 0 10px;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__inner::before,
  .department_yoji .contents-wrap.dual .contents-wrap__inner::before {
    background: url(/common/img/department/yoji/ico_dual.svg) no-repeat;
    background-size: contain;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__merit .__num,
  .department_yoji .contents-wrap.dual .contents-wrap__merit .__num {
    background: #FF6085;
  }
  .department_kodomo .contents-wrap.dual .contents-wrap__merit .__txt,
  .department_yoji .contents-wrap.dual .contents-wrap__merit .__txt {
    color: #FF6085;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__inner::before,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__inner::before {
    background: url("/common/img/department/kodomo/pic_primaryteacher_01.svg") no-repeat;
    background-size: contain;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__merit .__num,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__merit .__num {
    background: #FF8843;
  }
  .department_kodomo .contents-wrap.primaryteacher .contents-wrap__merit .__txt,
  .department_yoji .contents-wrap.primaryteacher .contents-wrap__merit .__txt {
    color: #FF8843;
  }
  /* ============================================================ */
  /* .department_yoji */
  /* ============================================================ */
  .department_yoji .dual__catch01 {
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
    margin: 0 0 30px;
    text-align: center;
  }
  .department_yoji .dual__about {
    margin: 0 0 20px;
  }
  .department_yoji .dual__about__item {
    width: 100%;
  }
  .department_yoji .dual__about__item:first-child {
    margin: 0 0 20px 0;
  }
  .department_yoji .dual__point {
    color: #FF6085;
    font-weight: 500;
    margin: 0 0 40px;
  }
  .department_yoji .dual__point .icon-square {
    margin: 0 10px 0 0;
  }
  .department_yoji .dual .table--department--yoji th, .department_yoji .dual .table--department--yoji td {
    font-size: 1.5rem;
  }
  .department_yoji .dual .table--department--yoji td.bg-white {
    background: #fff;
  }
  .department_yoji .dual .table--department--yoji td.bg-yellowlight {
    background: #FFE98F;
  }
  .department_yoji .dual__flow {
    margin: 0 0 30px;
  }
  .department_yoji .dual__flow__item {
    margin-bottom: 10px;
  }
  .department_yoji .dual__flow__item:not(:last-child)::after {
    content: "";
    background: url(/common/img/department/yoji/ico_dual_yajirushi.svg);
    background-size: cover;
    width: 40px;
    height: 32px;
    margin: 0 auto;
  }
  .department_yoji .dual__merit__item {
    margin: 0 0 20px;
    display: table;
  }
  .department_yoji .dual__merit__item > *:first-child {
    display: table-cell;
    white-space: nowrap;
    padding: 0 10px 0 0;
  }
  .department_yoji .dual__merit__item > *:first-child .__num {
    font-weight: 500;
    color: #fff;
    content: attr(data-text);
    background: #FF6085;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
  .department_yoji .dual__merit__item .__txt {
    color: #FF6085;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5;
    margin: 0 0 10px;
  }
  .department_yoji .dual__voice-box__img {
    flex: 100%;
    padding: 0;
    text-align: center;
  }
  .department_yoji .dual__voice-box .__img {
    margin: 15px 0 -15px;
    max-width: 80%;
  }
  /* ============================================================ */
  /* department_kodomo */
  /* ============================================================ */
  .department_kodomo .primaryteacher__list__item {
    margin: 0 0 20px;
    text-align: center;
  }
  .department_kodomo .primaryteacher__list__item .__label {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 13px;
    border-radius: 50%;
    background-color: #f7b52c;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
  }
  .department_kodomo .primaryteacher__list__item .__label::after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    border-style: solid;
    border-width: 13px 12.5px 0 12.5px;
    border-color: #f7b52c transparent transparent;
    translate: -50% 100%;
  }
  /* ============================================================ */
  /* department_student */
  /* ============================================================ */
  .department_student .student-list__item {
    border-radius: 15px;
    padding: 20px 25px;
    margin: 0 0 30px;
  }
  .department_student .student-list__item__data {
    display: flex;
    margin: 0 0 20px;
  }
  .department_student .student-list__item__data__img {
    width: 45%;
    padding: 0 15px 0 0;
  }
  .department_student .student-list__item__data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .department_student .student-list__item__data__text {
    width: 55%;
  }
  .department_student .student-list__item__data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    font-size: 1.5rem;
  }
  .department_student .student-list__item__data__text__name {
    font-size: 2.2rem;
    font-weight: 500;
  }
  .department_student .student-list__item__catch {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 20px;
  }
  .department_student .student-list--kodomo .student-list__item {
    background: #FFFCEF;
  }
  .department_student .student-list--kodomo .student-list__item__data__text__label {
    background: #FF8843;
  }
  .department_student .student-list--kodomo .student-list__item__catch {
    color: #FF8843;
  }
  .department_student .student-list--yoji .student-list__item {
    background: #FFF5F6;
  }
  .department_student .student-list--yoji .student-list__item__data__text__label {
    background: #FF6085;
  }
  .department_student .student-list--yoji .student-list__item__catch {
    color: #FF6085;
  }
  /* ============================================================ */
  /* department_student_student */
  /* ============================================================ */
  .department_student_student .row__inner-white {
    margin-bottom: 30px;
  }
  .department_student_student .ttl01__text {
    z-index: -1;
    color: #fff;
    height: 1px;
  }
  .department_student_student .student-data {
    margin: 0 0 20px;
  }
  .department_student_student .student-data__img {
    width: 160px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .department_student_student .student-data__img > img {
    border-radius: 50%;
    width: 100%;
  }
  .department_student_student .student-data__text__label {
    color: #fff;
    padding: 0 5px;
    height: 25px;
    min-width: 110px;
    line-height: 25px;
    font-size: 1.5rem;
  }
  .department_student_student .student-data__text__name {
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }
  .department_student_student .student-data__text__name__school {
    font-size: 1.5rem;
    padding: 0 0 0 10px;
    font-weight: 400;
  }
  .department_student_student .student-data__text__catch {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0 0 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .department_student_student .box {
    margin: 0 0 40px;
  }
  .department_student_student .student-block {
    margin: 0 0 50px;
  }
  .department_student_student .student-block .student-ttl {
    border-bottom: 3px dotted #666666;
    padding: 0 0 15px;
    margin: 0 0 30px;
  }
  .department_student_student .student-block .float__item > img {
    width: 265px;
  }
  .department_student_student .student-block .table--list {
    margin: 0;
  }
  .department_student_student .student-block .table--list th {
    font-weight: 500;
    display: block;
    padding: 10px 15px 0 15px;
    vertical-align: top;
  }
  .department_student_student .student-block .table--list td {
    padding: 5px 15px 10px 15px;
    vertical-align: top;
  }
  .department_student_student .kodomo .student-data__text__label {
    background: #FF8843;
  }
  .department_student_student .kodomo .student-data__text__catch {
    color: #FF8843;
    border-bottom-color: #FF8843;
  }
  .department_student_student .kodomo .question__label {
    background: #FF8843;
  }
  .department_student_student .yoji .student-data__text__label {
    background: #FF6085;
  }
  .department_student_student .yoji .student-data__text__catch {
    color: #FF6085;
    border-bottom-color: #FF6085;
  }
  .department_student_student .yoji .question__label {
    background: #FF6085;
  }
  /* ============================================================ */
  /* guide */
  /* ============================================================ */
  /* ============================================================ */
  /* guide_index */
  /* ============================================================ */
  #guide_index .greeting {
    padding: 0;
  }
  #guide_index .greeting__img {
    width: 100%;
    margin: 0 0 15px;
  }
  #guide_index .greeting .ttl07 {
    font-size: 1.8rem;
    margin: 0 0 20px;
  }
  #guide_index .greeting .ttl07__name {
    font-size: 2.8rem;
  }
  #guide_index .ability-list__item {
    text-align: center;
    position: relative;
    border: 2px dashed #23B8C5;
    border-radius: 10px;
    padding: 40px 20px 30px;
    text-align: center;
    margin: 0 0 50px;
  }
  #guide_index .ability-list__item__label {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -25px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23B8C5;
  }
  #guide_index .ability-list__item__title {
    color: #23B8C5;
    font-size: 2rem;
    margin: 0 0 15px;
  }
  #guide_index .charactor-area {
    align-items: center;
  }
  #guide_index .charactor-area img {
    width: 75%;
  }
  #guide_index .history__item__year {
    display: block;
    font-weight: 500;
  }
  /* ============================================================ */
  /* facility_access */
  /* ============================================================ */
  .facility_access .school-title {
    margin: 0 0 30px;
    line-height: 1.5;
    font-size: 2rem;
  }
  .facility_access .school-title__label {
    font-weight: 400;
    font-size: 1.4rem;
  }
  .facility_access .root {
    margin: 60px 0 30px 0;
  }
  .facility_access .root__list__item {
    width: 100%;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center;
  }
  .facility_access .root__list__item:last-child::after {
    display: none;
  }
  .facility_access .root__list__item::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -60px;
    content: "\f0d7";
    font-family: 'icomoon';
    color: #23B8C5;
    font-size: 4.0rem;
  }
  .facility_access .root__list__item > img {
    width: 70%;
    margin: 0 0 10px;
  }
  .facility_access .root__list__item__text {
    text-align: left;
  }
  .facility_access .root__list__item .ttl08 {
    margin: 0 0 5px;
  }
  .facility_access .row__inner-white {
    border-radius: 0;
  }
  /* ============================================================ */
  /* target.html */
  /* ============================================================ */
  .target .target-main {
    margin: 0 0 30px;
  }
  .target .target-main__visual {
    border-radius: 15px;
  }
  .target .target-main__ttl {
    font-size: 2.2rem;
    color: #000;
    font-weight: 500;
    display: flex;
    height: 120px;
    align-items: center;
    background: url(/common/img/target/bg_text.png) center center no-repeat;
    background-size: contain;
    line-height: 1.6;
  }
  .target .box {
    margin-bottom: 30px;
  }
  .target .box .ttl09__text {
    padding-left: 10px;
    border-left: 5px solid #23B8C5;
  }
  /* ============================================================ */
  /* restart */
  /* ============================================================ */
  .restart .benefit {
    margin-bottom: 40px;
  }
  .restart .benefit__item {
    padding: 10px 10px 20px;
    text-align: center;
    background: #fff;
  }
  .restart .benefit__item__title {
    background: #008C98;
    padding: 5px 0;
    margin: 0 0 20px;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
  }
  .restart .benefit__item__text {
    font-size: 2rem;
  }
  .restart .benefit__item__text__em {
    color: #FF6085;
    font-weight: 500;
  }
  .restart .benefit-flow__item {
    display: flex;
    padding: 10px;
  }
  .restart .benefit-flow__item:nth-child(odd) {
    background: #EFF9FB;
  }
  .restart .benefit-flow__item__label {
    display: block;
    background: #008C98;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    flex: 0 0 30px;
  }
  .restart .benefit-flow__item__em {
    background: #FF6085;
    color: #fff;
    padding: 3px 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  .restart .support__point03-box .__label {
    background: #23B8C5;
    color: #fff;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 90px;
    margin: 0 auto 15px;
    font-size: 2rem;
    font-weight: 500;
  }
  .restart .support__voice-box__img {
    flex: 100%;
    padding: 0;
    text-align: center;
  }
  .restart .support__voice-box .__img {
    margin: 15px 0 -15px;
    max-width: 50%;
  }
  /* ============================================================ */
  /* recruitmentofficer */
  /* ============================================================ */
  .recruitmentofficer .target-main__ttl {
    background-position: center center;
    justify-content: center;
  }
  .recruitmentofficer .box .target-main__ttl,
  .recruitmentofficer .btn-area-ttl {
    height: auto;
    background: none;
    justify-content: center;
    margin: 0 0 20px;
    flex-wrap: wrap;
  }
  .recruitmentofficer .btn-area > * {
    text-align: center;
    margin-bottom: 5px;
  }
  .recruitmentofficer .btn-area > * .btn {
    height: 55px;
  }
}

/* position
------------------------------------------------------------ */
.u-pos-s {
  position: static !important;
}

.u-pos-a {
  position: absolute !important;
}

.u-pos-r {
  position: relative !important;
}

.u-pos-f {
  position: fixed !important;
}

.u-t-a {
  top: auto !important;
}

.u-t-0 {
  top: 0 !important;
}

.u-r-a {
  right: auto !important;
}

.u-r-0 {
  right: 0 !important;
}

.u-b-a {
  bottom: auto !important;
}

.u-b-0 {
  bottom: 0 !important;
}

.u-l-a {
  left: auto !important;
}

.u-l-0 {
  left: 0 !important;
}

.u-z-a {
  z-index: auto !important;
}

.u-z-0 {
  z-index: 0 !important;
}

.u-z-1 {
  z-index: 1 !important;
}

.u-z-2 {
  z-index: 2 !important;
}

.u-z-3 {
  z-index: 3 !important;
}

.u-z-4 {
  z-index: 4 !important;
}

.u-z-5 {
  z-index: 5 !important;
}

.u-z-6 {
  z-index: 6 !important;
}

.u-z-9999 {
  z-index: 9999 !important;
}

@media screen and (max-width: 767px) {
  .u-sp-pos-s {
    position: static !important;
  }
  .u-sp-pos-a {
    position: absolute !important;
  }
  .u-sp-pos-r {
    position: relative !important;
  }
  .u-sp-pos-f {
    position: fixed !important;
  }
  .u-sp-t-a {
    top: auto !important;
  }
  .u-sp-t-0 {
    top: 0 !important;
  }
  .u-sp-r-a {
    right: auto !important;
  }
  .u-sp-r-0 {
    right: 0 !important;
  }
  .u-sp-b-a {
    bottom: auto !important;
  }
  .u-sp-b-0 {
    bottom: 0 !important;
  }
  .u-sp-l-a {
    left: auto !important;
  }
  .u-sp-l-0 {
    left: 0 !important;
  }
  .u-sp-z-a {
    z-index: auto !important;
  }
  .u-sp-z-0 {
    z-index: 0 !important;
  }
  .u-sp-z-1 {
    z-index: 1 !important;
  }
  .u-sp-z-2 {
    z-index: 2 !important;
  }
  .u-sp-z-3 {
    z-index: 3 !important;
  }
  .u-sp-z-4 {
    z-index: 4 !important;
  }
  .u-sp-z-5 {
    z-index: 5 !important;
  }
  .u-sp-z-6 {
    z-index: 6 !important;
  }
  .u-sp-z-9999 {
    z-index: 9999 !important;
  }
}

/* float
------------------------------------------------------------ */
.u-fl-n {
  float: none !important;
}

.u-fl-l {
  float: left !important;
}

.u-fl-r {
  float: right !important;
}

.u-cl-n {
  clear: none !important;
}

.u-cl-l {
  clear: left !important;
}

.u-cl-r {
  clear: right !important;
}

.u-cl-b {
  clear: both !important;
}

@media screen and (max-width: 767px) {
  .u-sp-fl-n {
    float: none !important;
  }
  .u-sp-fl-l {
    float: left !important;
  }
  .u-sp-fl-r {
    float: right !important;
  }
  .u-sp-cl-n {
    clear: none !important;
  }
  .u-sp-cl-l {
    clear: left !important;
  }
  .u-sp-cl-r {
    clear: right !important;
  }
  .u-sp-cl-b {
    clear: both !important;
  }
}

/* display
------------------------------------------------------------ */
.u-d-n {
  display: none !important;
}

.u-d-b {
  display: block !important;
}

.u-d-f {
  display: flex !important;
}

.u-d-if {
  display: inline-flex !important;
}

.u-d-i {
  display: inline !important;
}

.u-d-ib {
  display: inline-block !important;
}

.u-d-li {
  display: list-item !important;
}

.u-d-ri {
  display: run-in !important;
}

.u-d-cp {
  display: compact !important;
}

.u-d-tb {
  display: table !important;
}

.u-d-itb {
  display: inline-table !important;
}

.u-d-tbcp {
  display: table-caption !important;
}

.u-d-tbcl {
  display: table-column !important;
}

.u-d-tbclg {
  display: table-column-group !important;
}

.u-d-tbhg {
  display: table-header-group !important;
}

.u-d-tbfg {
  display: table-footer-group !important;
}

.u-d-tbr {
  display: table-row !important;
}

.u-d-tbrg {
  display: table-row-group !important;
}

.u-d-tbc {
  display: table-cell !important;
}

.u-d-rb {
  display: ruby !important;
}

.u-d-rbb {
  display: ruby-base !important;
}

.u-d-rbbg {
  display: ruby-base-group !important;
}

.u-d-rbt {
  display: ruby-text !important;
}

.u-d-rbtg {
  display: ruby-text-group !important;
}

@media screen and (max-width: 767px) {
  .u-sp-d-n {
    display: none !important;
  }
  .u-sp-d-b {
    display: block !important;
  }
  .u-sp-d-f {
    display: flex !important;
  }
  .u-sp-d-if {
    display: inline-flex !important;
  }
  .u-sp-d-i {
    display: inline !important;
  }
  .u-sp-d-ib {
    display: inline-block !important;
  }
  .u-sp-d-li {
    display: list-item !important;
  }
  .u-sp-d-ri {
    display: run-in !important;
  }
  .u-sp-d-cp {
    display: compact !important;
  }
  .u-sp-d-tb {
    display: table !important;
  }
  .u-sp-d-itb {
    display: inline-table !important;
  }
  .u-sp-d-tbcp {
    display: table-caption !important;
  }
  .u-sp-d-tbcl {
    display: table-column !important;
  }
  .u-sp-d-tbclg {
    display: table-column-group !important;
  }
  .u-sp-d-tbhg {
    display: table-header-group !important;
  }
  .u-sp-d-tbfg {
    display: table-footer-group !important;
  }
  .u-sp-d-tbr {
    display: table-row !important;
  }
  .u-sp-d-tbrg {
    display: table-row-group !important;
  }
  .u-sp-d-tbc {
    display: table-cell !important;
  }
  .u-sp-d-rb {
    display: ruby !important;
  }
  .u-sp-d-rbb {
    display: ruby-base !important;
  }
  .u-sp-d-rbbg {
    display: ruby-base-group !important;
  }
  .u-sp-d-rbt {
    display: ruby-text !important;
  }
  .u-sp-d-rbtg {
    display: ruby-text-group !important;
  }
}

/* overflow
------------------------------------------------------------ */
.u-ov-v {
  overflow: visible !important;
}

.u-ov-h {
  overflow: hidden !important;
}

.u-ov-s {
  overflow: scroll !important;
}

.u-ov-a {
  overflow: auto !important;
}

.u-ovx-v {
  overflow-x: visible !important;
}

.u-ovx-h {
  overflow-x: hidden !important;
}

.u-ovx-s {
  overflow-x: scroll !important;
}

.u-ovx-a {
  overflow-x: auto !important;
}

.u-ovy-v {
  overflow-y: visible !important;
}

.u-ovy-h {
  overflow-y: hidden !important;
}

.u-ovy-s {
  overflow-y: scroll !important;
}

.u-ovy-a {
  overflow-y: auto !important;
}

@media screen and (max-width: 767px) {
  .u-sp-ov-v {
    overflow: visible !important;
  }
  .u-sp-ov-h {
    overflow: hidden !important;
  }
  .u-sp-ov-s {
    overflow: scroll !important;
  }
  .u-sp-ov-a {
    overflow: auto !important;
  }
  .u-sp-ovx-v {
    overflow-x: visible !important;
  }
  .u-sp-ovx-h {
    overflow-x: hidden !important;
  }
  .u-sp-ovx-s {
    overflow-x: scroll !important;
  }
  .u-sp-ovx-a {
    overflow-x: auto !important;
  }
  .u-sp-ovy-v {
    overflow-y: visible !important;
  }
  .u-sp-ovy-h {
    overflow-y: hidden !important;
  }
  .u-sp-ovy-s {
    overflow-y: scroll !important;
  }
  .u-sp-ovy-a {
    overflow-y: auto !important;
  }
}

/* box-sizing
------------------------------------------------------------ */
.u-bxz-cb {
  box-sizing: content-box !important;
}

.u-bxz-bb {
  box-sizing: border-box !important;
}

.u-bxsh-n {
  box-shadow: none !important;
}

@media screen and (max-width: 767px) {
  .u-sp-bxz-cb {
    box-sizing: content-box !important;
  }
  .u-sp-bxz-bb {
    box-sizing: border-box !important;
  }
  .u-sp-bxsh-n {
    box-shadow: none !important;
  }
}

/* border
------------------------------------------------------------ */
.u-ol-n {
  outline: none !important;
}

.u-olw-tn {
  outline-width: thin !important;
}

.u-olw-md {
  outline-width: medium !important;
}

.u-olw-tc {
  outline-width: thick !important;
}

.u-ols-n {
  outline-style: none !important;
}

.u-ols-dt {
  outline-style: dotted !important;
}

.u-ols-ds {
  outline-style: dashed !important;
}

.u-ols-s {
  outline-style: solid !important;
}

.u-ols-db {
  outline-style: double !important;
}

.u-ols-g {
  outline-style: groove !important;
}

.u-ols-r {
  outline-style: ridge !important;
}

.u-ols-i {
  outline-style: inset !important;
}

.u-ols-o {
  outline-style: outset !important;
}

.u-olc-i {
  outline-color: invert !important;
}

.u-bfv-h {
  backface-visibility: hidden !important;
}

.u-bfv-v {
  backface-visibility: visible !important;
}

.u-bd-n {
  border: none !important;
}

.u-bd-0 {
  border: 0 !important;
}

.u-bdcl-c {
  border-collapse: collapse !important;
}

.u-bdcl-s {
  border-collapse: separate !important;
}

.u-bdc-t {
  border-color: transparent !important;
}

.u-bdc-cc {
  border-color: currentColor !important;
}

.u-bdc-white {
  border-color: #ffffff !important;
}

.u-bdc-silver {
  border-color: #dddddd !important;
}

.u-bdc-silver-lighten {
  border-color: #eeeeee !important;
}

.u-bdc-gray {
  border-color: #cccccc !important;
}

.u-bdc-black {
  border-color: #111111 !important;
}

.u-bdc-navy {
  border-color: #001f3f !important;
}

.u-bdc-blue {
  border-color: #0074d9 !important;
}

.u-bdc-aqua {
  border-color: #7fdbff !important;
}

.u-bdc-teal {
  border-color: #39cccc !important;
}

.u-bdc-olive {
  border-color: #3d9970 !important;
}

.u-bdc-green {
  border-color: #2ecc40 !important;
}

.u-bdc-lime {
  border-color: #01ff70 !important;
}

.u-bdc-yellow {
  border-color: #ffdc00 !important;
}

.u-bdc-orange {
  border-color: #ff851b !important;
}

.u-bdc-red {
  border-color: #ff4136 !important;
}

.u-bdc-maroon {
  border-color: #85144b !important;
}

.u-bdc-fuchsia {
  border-color: #f012be !important;
}

.u-bdc-purple {
  border-color: #b10dc9 !important;
}

.u-bdc-primary {
  border-color: #0275d8 !important;
}

.u-bdc-success {
  border-color: #5cb85c !important;
}

.u-bdc-info {
  border-color: #5bc0de !important;
}

.u-bdc-warning {
  border-color: #f0ad4e !important;
}

.u-bdc-danger {
  border-color: #d9534f !important;
}

.u-bdi-n {
  border-image: none !important;
}

.u-bds-n {
  border-style: none !important;
}

.u-bds-h {
  border-style: hidden !important;
}

.u-bds-dt {
  border-style: dotted !important;
}

.u-bds-ds {
  border-style: dashed !important;
}

.u-bds-s {
  border-style: solid !important;
}

.u-bds-db {
  border-style: double !important;
}

.u-bds-w {
  border-style: wave !important;
}

.u-bds-g {
  border-style: groove !important;
}

.u-bds-r {
  border-style: ridge !important;
}

.u-bds-i {
  border-style: inset !important;
}

.u-bds-o {
  border-style: outset !important;
}

.u-bdw-0 {
  border-width: 0 !important;
}

.u-bdw-1 {
  border-width: 1px !important;
}

.u-bdw-2 {
  border-width: 2px !important;
}

.u-bdw-3 {
  border-width: 3px !important;
}

.u-bdw-4 {
  border-width: 4px !important;
}

.u-bdw-5 {
  border-width: 5px !important;
}

.u-bdw-6 {
  border-width: 6px !important;
}

.u-bdtw-0 {
  border-top-width: 0 !important;
}

.u-bdtw-1 {
  border-top-width: 1px !important;
}

.u-bdtw-2 {
  border-top-width: 2px !important;
}

.u-bdtw-3 {
  border-top-width: 3px !important;
}

.u-bdtw-4 {
  border-top-width: 4px !important;
}

.u-bdtw-5 {
  border-top-width: 5px !important;
}

.u-bdtw-6 {
  border-top-width: 6px !important;
}

.u-bdrw-0 {
  border-right-width: 0 !important;
}

.u-bdrw-1 {
  border-right-width: 1px !important;
}

.u-bdrw-2 {
  border-right-width: 2px !important;
}

.u-bdrw-3 {
  border-right-width: 3px !important;
}

.u-bdrw-4 {
  border-right-width: 4px !important;
}

.u-bdrw-5 {
  border-right-width: 5px !important;
}

.u-bdrw-6 {
  border-right-width: 6px !important;
}

.u-bdbw-0 {
  border-bottom-width: 0 !important;
}

.u-bdbw-1 {
  border-bottom-width: 1px !important;
}

.u-bdbw-2 {
  border-bottom-width: 2px !important;
}

.u-bdbw-3 {
  border-bottom-width: 3px !important;
}

.u-bdbw-4 {
  border-bottom-width: 4px !important;
}

.u-bdbw-5 {
  border-bottom-width: 5px !important;
}

.u-bdbw-6 {
  border-bottom-width: 6px !important;
}

.u-bdlw-0 {
  border-left-width: 0 !important;
}

.u-bdlw-1 {
  border-left-width: 1px !important;
}

.u-bdlw-2 {
  border-left-width: 2px !important;
}

.u-bdlw-3 {
  border-left-width: 3px !important;
}

.u-bdlw-4 {
  border-left-width: 4px !important;
}

.u-bdlw-5 {
  border-left-width: 5px !important;
}

.u-bdlw-6 {
  border-left-width: 6px !important;
}

.u-bdt-n {
  border-top: none !important;
}

.u-bdt-0 {
  border-top: 0 !important;
}

.u-bdtc-t {
  border-top-color: transparent !important;
}

.u-bdtc-cc {
  border-top-color: currentColor !important;
}

.u-bdr-n {
  border-right: none !important;
}

.u-bdr-0 {
  border-right: 0 !important;
}

.u-bdrc-t {
  border-right-color: transparent !important;
}

.u-bdrc-cc {
  border-right-color: currentColor !important;
}

.u-bdb-n {
  border-bottom: none !important;
}

.u-bdb-0 {
  border-bottom: 0 !important;
}

.u-bdbc-t {
  border-bottom-color: transparent !important;
}

.u-bdbc-cc {
  border-bottom-color: currentColor !important;
}

.u-bdl-n {
  border-left: none !important;
}

.u-bdl-0 {
  border-left: 0 !important;
}

.u-bdlc-t {
  border-left-color: transparent !important;
}

.u-bdlc-cc {
  border-left-color: currentColor !important;
}

@media screen and (max-width: 767px) {
  .u-sp-ol-n {
    outline: none !important;
  }
  .u-sp-olw-tn {
    outline-width: thin !important;
  }
  .u-sp-olw-md {
    outline-width: medium !important;
  }
  .u-sp-olw-tc {
    outline-width: thick !important;
  }
  .u-sp-ols-n {
    outline-style: none !important;
  }
  .u-sp-ols-dt {
    outline-style: dotted !important;
  }
  .u-sp-ols-ds {
    outline-style: dashed !important;
  }
  .u-sp-ols-s {
    outline-style: solid !important;
  }
  .u-sp-ols-db {
    outline-style: double !important;
  }
  .u-sp-ols-g {
    outline-style: groove !important;
  }
  .u-sp-ols-r {
    outline-style: ridge !important;
  }
  .u-sp-ols-i {
    outline-style: inset !important;
  }
  .u-sp-ols-o {
    outline-style: outset !important;
  }
  .u-sp-olc-i {
    outline-color: invert !important;
  }
  .u-sp-bfv-h {
    backface-visibility: hidden !important;
  }
  .u-sp-bfv-v {
    backface-visibility: visible !important;
  }
  .u-sp-bd-n {
    border: none !important;
  }
  .u-sp-bd-0 {
    border: 0 !important;
  }
  .u-sp-bdcl-c {
    border-collapse: collapse !important;
  }
  .u-sp-bdcl-s {
    border-collapse: separate !important;
  }
  .u-sp-bdc-t {
    border-color: transparent !important;
  }
  .u-sp-bdc-cc {
    border-color: currentColor !important;
  }
  .u-sp-bdc-white {
    border-color: #ffffff !important;
  }
  .u-sp-bdc-silver {
    border-color: #dddddd !important;
  }
  .u-sp-bdc-silver-lighten {
    border-color: #eeeeee !important;
  }
  .u-sp-bdc-gray {
    border-color: #aaaaaa !important;
  }
  .u-sp-bdc-black {
    border-color: #111111 !important;
  }
  .u-sp-bdc-navy {
    border-color: #001f3f !important;
  }
  .u-sp-bdc-blue {
    border-color: #0074d9 !important;
  }
  .u-sp-bdc-aqua {
    border-color: #7fdbff !important;
  }
  .u-sp-bdc-teal {
    border-color: #39cccc !important;
  }
  .u-sp-bdc-olive {
    border-color: #3d9970 !important;
  }
  .u-sp-bdc-green {
    border-color: #2ecc40 !important;
  }
  .u-sp-bdc-lime {
    border-color: #01ff70 !important;
  }
  .u-sp-bdc-yellow {
    border-color: #ffdc00 !important;
  }
  .u-sp-bdc-orange {
    border-color: #ff851b !important;
  }
  .u-sp-bdc-red {
    border-color: #ff4136 !important;
  }
  .u-sp-bdc-maroon {
    border-color: #85144b !important;
  }
  .u-sp-bdc-fuchsia {
    border-color: #f012be !important;
  }
  .u-sp-bdc-purple {
    border-color: #b10dc9 !important;
  }
  .u-sp-bdc-primary {
    border-color: #0275d8 !important;
  }
  .u-sp-bdc-success {
    border-color: #5cb85c !important;
  }
  .u-sp-bdc-info {
    border-color: #5bc0de !important;
  }
  .u-sp-bdc-warning {
    border-color: #f0ad4e !important;
  }
  .u-sp-bdc-danger {
    border-color: #d9534f !important;
  }
  .u-sp-bdi-n {
    border-image: none !important;
  }
  .u-sp-bds-n {
    border-style: none !important;
  }
  .u-sp-bds-h {
    border-style: hidden !important;
  }
  .u-sp-bds-dt {
    border-style: dotted !important;
  }
  .u-sp-bds-ds {
    border-style: dashed !important;
  }
  .u-sp-bds-s {
    border-style: solid !important;
  }
  .u-sp-bds-db {
    border-style: double !important;
  }
  .u-sp-bds-w {
    border-style: wave !important;
  }
  .u-sp-bds-g {
    border-style: groove !important;
  }
  .u-sp-bds-r {
    border-style: ridge !important;
  }
  .u-sp-bds-i {
    border-style: inset !important;
  }
  .u-sp-bds-o {
    border-style: outset !important;
  }
  .u-sp-bdw-0 {
    border-width: 0 !important;
  }
  .u-sp-bdw-1 {
    border-width: 1px !important;
  }
  .u-sp-bdw-2 {
    border-width: 2px !important;
  }
  .u-sp-bdw-3 {
    border-width: 3px !important;
  }
  .u-sp-bdw-4 {
    border-width: 4px !important;
  }
  .u-sp-bdw-5 {
    border-width: 5px !important;
  }
  .u-sp-bdw-6 {
    border-width: 6px !important;
  }
  .u-sp-bdtw-0 {
    border-top-width: 0 !important;
  }
  .u-sp-bdtw-1 {
    border-top-width: 1px !important;
  }
  .u-sp-bdtw-2 {
    border-top-width: 2px !important;
  }
  .u-sp-bdtw-3 {
    border-top-width: 3px !important;
  }
  .u-sp-bdtw-4 {
    border-top-width: 4px !important;
  }
  .u-sp-bdtw-5 {
    border-top-width: 5px !important;
  }
  .u-sp-bdtw-6 {
    border-top-width: 6px !important;
  }
  .u-sp-bdrw-0 {
    border-right-width: 0 !important;
  }
  .u-sp-bdrw-1 {
    border-right-width: 1px !important;
  }
  .u-sp-bdrw-2 {
    border-right-width: 2px !important;
  }
  .u-sp-bdrw-3 {
    border-right-width: 3px !important;
  }
  .u-sp-bdrw-4 {
    border-right-width: 4px !important;
  }
  .u-sp-bdrw-5 {
    border-right-width: 5px !important;
  }
  .u-sp-bdrw-6 {
    border-right-width: 6px !important;
  }
  .u-sp-bdbw-0 {
    border-bottom-width: 0 !important;
  }
  .u-sp-bdbw-1 {
    border-bottom-width: 1px !important;
  }
  .u-sp-bdbw-2 {
    border-bottom-width: 2px !important;
  }
  .u-sp-bdbw-3 {
    border-bottom-width: 3px !important;
  }
  .u-sp-bdbw-4 {
    border-bottom-width: 4px !important;
  }
  .u-sp-bdbw-5 {
    border-bottom-width: 5px !important;
  }
  .u-sp-bdbw-6 {
    border-bottom-width: 6px !important;
  }
  .u-sp-bdlw-0 {
    border-left-width: 0 !important;
  }
  .u-sp-bdlw-1 {
    border-left-width: 1px !important;
  }
  .u-sp-bdlw-2 {
    border-left-width: 2px !important;
  }
  .u-sp-bdlw-3 {
    border-left-width: 3px !important;
  }
  .u-sp-bdlw-4 {
    border-left-width: 4px !important;
  }
  .u-sp-bdlw-5 {
    border-left-width: 5px !important;
  }
  .u-sp-bdlw-6 {
    border-left-width: 6px !important;
  }
  .u-sp-bdt-n {
    border-top: none !important;
  }
  .u-sp-bdt-0 {
    border-top: 0 !important;
  }
  .u-sp-bdtc-t {
    border-top-color: transparent !important;
  }
  .u-sp-bdtc-cc {
    border-top-color: currentColor !important;
  }
  .u-sp-bdr-n {
    border-right: none !important;
  }
  .u-sp-bdr-0 {
    border-right: 0 !important;
  }
  .u-sp-bdrc-t {
    border-right-color: transparent !important;
  }
  .u-sp-bdrc-cc {
    border-right-color: currentColor !important;
  }
  .u-sp-bdb-n {
    border-bottom: none !important;
  }
  .u-sp-bdb-0 {
    border-bottom: 0 !important;
  }
  .u-sp-bdbc-t {
    border-bottom-color: transparent !important;
  }
  .u-sp-bdbc-cc {
    border-bottom-color: currentColor !important;
  }
  .u-sp-bdl-n {
    border-left: none !important;
  }
  .u-sp-bdl-0 {
    border-left: 0 !important;
  }
  .u-sp-bdlc-t {
    border-left-color: transparent !important;
  }
  .u-sp-bdlc-cc {
    border-left-color: currentColor !important;
  }
}

/* 背景色
------------------------------------------------------------ */
.u-bgc-t {
  background-color: transparent !important;
}

.u-bgc-cc {
  background-color: currentColor !important;
}

.u-bgc-white {
  background-color: #ffffff !important;
}

.u-bgc-silver {
  background-color: #dddddd !important;
}

.u-bgc-gray {
  background-color: #aaaaaa !important;
}

.u-bgc-black {
  background-color: #111111 !important;
}

.u-bgc-navy {
  background-color: #001f3f !important;
}

.u-bgc-blue {
  background-color: #0074d9 !important;
}

.u-bgc-aqua {
  background-color: #7fdbff !important;
}

.u-bgc-teal {
  background-color: #73D6CD !important;
}

.u-bgc-olive {
  background-color: #3d9970 !important;
}

.u-bgc-green {
  background-color: #2ecc40 !important;
}

.u-bgc-lime {
  background-color: #01ff70 !important;
}

.u-bgc-yellow {
  background-color: #ffdc00 !important;
}

.u-bgc-orange {
  background-color: #ff851b !important;
}

.u-bgc-red {
  background-color: #ff4136 !important;
}

.u-bgc-maroon {
  background-color: #85144b !important;
}

.u-bgc-fuchsia {
  background-color: #f012be !important;
}

.u-bgc-purple {
  background-color: #b10dc9 !important;
}

.u-bgc-primary {
  background-color: #0275d8 !important;
}

.u-bgc-success {
  background-color: #5cb85c !important;
}

.u-bgc-info {
  background-color: #5bc0de !important;
}

.u-bgc-warning {
  background-color: #f0ad4e !important;
}

.u-bgc-danger {
  background-color: #d9534f !important;
}

.u-bgi-n {
  background-image: none !important;
}

.u-bgr-n {
  background-repeat: no-repeat !important;
}

.u-bgr-x {
  background-repeat: repeat-x !important;
}

.u-bgr-y {
  background-repeat: repeat-y !important;
}

.u-bgr-sp {
  background-repeat: space !important;
}

.u-bgr-rd {
  background-repeat: round !important;
}

.u-bga-f {
  background-attachment: fixed !important;
}

.u-bga-s {
  background-attachment: scroll !important;
}

.u-bgp-t {
  background-position: top !important;
}

.u-bgp-r {
  background-position: right !important;
}

.u-bgp-b {
  background-position: bottom !important;
}

.u-bgp-l {
  background-position: left !important;
}

.u-bgp-c {
  background-position: center !important;
}

.u-bgsz-a {
  background-size: auto !important;
}

.u-bgsz-ct {
  background-size: contain !important;
}

.u-bgsz-cv {
  background-size: cover !important;
}

.u-bg-n {
  background: none !important;
}

.u-c-i {
  color: inherit !important;
}

@media screen and (max-width: 767px) {
  .u-sp-bgc-t {
    background-color: transparent !important;
  }
  .u-sp-bgc-cc {
    background-color: currentColor !important;
  }
  .u-sp-bgc-white {
    background-color: #ffffff !important;
  }
  .u-sp-bgc-silver {
    background-color: #dddddd !important;
  }
  .u-sp-bgc-gray {
    background-color: #aaaaaa !important;
  }
  .u-sp-bgc-black {
    background-color: #111111 !important;
  }
  .u-sp-bgc-navy {
    background-color: #001f3f !important;
  }
  .u-sp-bgc-blue {
    background-color: #0074d9 !important;
  }
  .u-sp-bgc-aqua {
    background-color: #7fdbff !important;
  }
  .u-sp-bgc-teal {
    background-color: #73D6CD !important;
  }
  .u-sp-bgc-olive {
    background-color: #3d9970 !important;
  }
  .u-sp-bgc-green {
    background-color: #2ecc40 !important;
  }
  .u-sp-bgc-lime {
    background-color: #01ff70 !important;
  }
  .u-sp-bgc-yellow {
    background-color: #ffdc00 !important;
  }
  .u-sp-bgc-orange {
    background-color: #ff851b !important;
  }
  .u-sp-bgc-red {
    background-color: #ff4136 !important;
  }
  .u-sp-bgc-maroon {
    background-color: #85144b !important;
  }
  .u-sp-bgc-fuchsia {
    background-color: #f012be !important;
  }
  .u-sp-bgc-purple {
    background-color: #b10dc9 !important;
  }
  .u-sp-bgc-primary {
    background-color: #0275d8 !important;
  }
  .u-sp-bgc-success {
    background-color: #5cb85c !important;
  }
  .u-sp-bgc-info {
    background-color: #5bc0de !important;
  }
  .u-sp-bgc-warning {
    background-color: #f0ad4e !important;
  }
  .u-sp-bgc-danger {
    background-color: #d9534f !important;
  }
  .u-sp-bgi-n {
    background-image: none !important;
  }
  .u-sp-bgr-n {
    background-repeat: no-repeat !important;
  }
  .u-sp-bgr-x {
    background-repeat: repeat-x !important;
  }
  .u-sp-bgr-y {
    background-repeat: repeat-y !important;
  }
  .u-sp-bgr-sp {
    background-repeat: space !important;
  }
  .u-sp-bgr-rd {
    background-repeat: round !important;
  }
  .u-sp-bga-f {
    background-attachment: fixed !important;
  }
  .u-sp-bga-s {
    background-attachment: scroll !important;
  }
  .u-sp-bgp-t {
    background-position: top !important;
  }
  .u-sp-bgp-r {
    background-position: right !important;
  }
  .u-sp-bgp-b {
    background-position: bottom !important;
  }
  .u-sp-bgp-l {
    background-position: left !important;
  }
  .u-sp-bgp-c {
    background-position: center !important;
  }
  .u-sp-bgsz-a {
    background-size: auto !important;
  }
  .u-sp-bgsz-ct {
    background-size: contain !important;
  }
  .u-sp-bgsz-cv {
    background-size: cover !important;
  }
  .u-sp-bg-n {
    background: none !important;
  }
  .u-sp-c-i {
    color: inherit !important;
  }
}

/* table
-------------------------------------------------- */
.u-tbl-a {
  table-layout: auto !important;
}

.u-tbl-f {
  table-layout: fixed !important;
}

@media screen and (max-width: 767px) {
  .u-sp-tbl-a {
    table-layout: auto !important;
  }
  .u-sp-tbl-f {
    table-layout: fixed !important;
  }
}

/* list-style
-------------------------------------------------- */
.u-lis-n {
  list-style: none !important;
}

.u-lisp-i {
  list-style-position: inside !important;
}

.u-lisp-o {
  list-style-position: outside !important;
}

.u-list-n {
  list-style-type: none !important;
}

.u-list-d {
  list-style-type: disc !important;
}

.u-list-c {
  list-style-type: circle !important;
}

.u-list-s {
  list-style-type: square !important;
}

.u-list-dc {
  list-style-type: decimal !important;
}

.u-list-dclz {
  list-style-type: decimal-leading-zero !important;
}

.u-list-lr {
  list-style-type: lower-roman !important;
}

.u-list-ur {
  list-style-type: upper-roman !important;
}

.u-lisi-n {
  list-style-image: none !important;
}

@media screen and (max-width: 767px) {
  .u-sp-lis-n {
    list-style: none !important;
  }
  .u-sp-lisp-i {
    list-style-position: inside !important;
  }
  .u-sp-lisp-o {
    list-style-position: outside !important;
  }
  .u-sp-list-n {
    list-style-type: none !important;
  }
  .u-sp-list-d {
    list-style-type: disc !important;
  }
  .u-sp-list-c {
    list-style-type: circle !important;
  }
  .u-sp-list-s {
    list-style-type: square !important;
  }
  .u-sp-list-dc {
    list-style-type: decimal !important;
  }
  .u-sp-list-dclz {
    list-style-type: decimal-leading-zero !important;
  }
  .u-sp-list-lr {
    list-style-type: lower-roman !important;
  }
  .u-sp-list-ur {
    list-style-type: upper-roman !important;
  }
  .u-sp-lisi-n {
    list-style-image: none !important;
  }
}

/* vertical-align
-------------------------------------------------- */
.u-va-sup {
  vertical-align: super !important;
}

.u-va-t {
  vertical-align: top !important;
}

.u-va-tt {
  vertical-align: text-top !important;
}

.u-va-m {
  vertical-align: middle !important;
}

.u-va-bl {
  vertical-align: baseline !important;
}

.u-va-b {
  vertical-align: bottom !important;
}

.u-va-tb {
  vertical-align: text-bottom !important;
}

.u-va-sub {
  vertical-align: sub !important;
}

@media screen and (max-width: 767px) {
  .u-sp-va-sup {
    vertical-align: super !important;
  }
  .u-sp-va-t {
    vertical-align: top !important;
  }
  .u-sp-va-tt {
    vertical-align: text-top !important;
  }
  .u-sp-va-m {
    vertical-align: middle !important;
  }
  .u-sp-va-bl {
    vertical-align: baseline !important;
  }
  .u-sp-va-b {
    vertical-align: bottom !important;
  }
  .u-sp-va-tb {
    vertical-align: text-bottom !important;
  }
  .u-sp-va-sub {
    vertical-align: sub !important;
  }
}

/* 左右センター
-------------------------------------------------- */
.u-ta-l {
  text-align: left !important;
}

.u-ta-c {
  text-align: center !important;
}

.u-ta-r {
  text-align: right !important;
}

.u-ta-j {
  text-align: justify !important;
}

@media screen and (max-width: 767px) {
  .u-sp-ta-l {
    text-align: left !important;
  }
  .u-sp-ta-c {
    text-align: center !important;
  }
  .u-sp-ta-r {
    text-align: right !important;
  }
  .u-sp-ta-j {
    text-align: justify !important;
  }
}

/* font-size
-------------------------------------------------- */
.u-font-xxxs {
  font-size: 1rem !important;
}

.u-font-xxs {
  font-size: 1.2rem !important;
}

.u-font-xs {
  font-size: 1.3rem !important;
}

.u-font-s {
  font-size: 1.4rem !important;
}

.u-font-m {
  font-size: 1.6rem !important;
}

.u-font-l {
  font-size: 1.8rem !important;
}

.u-font-xl {
  font-size: 2rem !important;
}

.u-font-xxl {
  font-size: 2.2rem !important;
}

.u-font-xxxl {
  font-size: 2.4rem !important;
}

@media screen and (max-width: 767px) {
  .u-sp-font-xxxs {
    font-size: 1rem !important;
  }
  .u-sp-font-xxs {
    font-size: 1.2rem !important;
  }
  .u-sp-font-xs {
    font-size: 1.3rem !important;
  }
  .u-sp-font-s {
    font-size: 1.4rem !important;
  }
  .u-sp-font-m {
    font-size: 1.6rem !important;
  }
  .u-sp-font-l {
    font-size: 1.8rem !important;
  }
  .u-sp-font-xl {
    font-size: 2rem !important;
  }
  .u-sp-font-xxl {
    font-size: 2.2rem !important;
  }
  .u-sp-font-xxxl {
    font-size: 2.4rem !important;
  }
}

/* font-weight
-------------------------------------------------- */
.u-font-bold {
  font-weight: 700 !important;
}

.u-font-medium {
  font-weight: 500 !important;
}

.u-font-normal {
  font-weight: normal !important;
}

@media screen and (max-width: 767px) {
  .u-sp-font-bold {
    font-weight: 700 !important;
  }
  .u-sp-font-medium {
    font-weight: 500 !important;
  }
  .u-sp-font-normal {
    font-weight: normal !important;
  }
}

/* font-color
-------------------------------------------------- */
.u-font-red {
  color: #dd0000 !important;
}

.u-font-pink {
  color: #FF6085 !important;
}

.u-font-base {
  color: #23B8C5 !important;
}

.u-font-orange {
  color: #FF8843 !important;
}

@media screen and (max-width: 767px) {
  .u-sp-font-red {
    color: #dd0000 !important;
  }
  .u-sp-font-pink {
    color: #FF6085 !important;
  }
  .u-sp-font-base {
    color: #23B8C5 !important;
  }
  .u-sp-font-orange {
    color: #FF8843 !important;
  }
}

/* text-decoration
-------------------------------------------------- */
.u-td-n {
  text-decoration: none !important;
}

.u-td-u {
  text-decoration: underline !important;
}

.u-td-o {
  text-decoration: overline !important;
}

.u-td-l {
  text-decoration: line-through !important;
}

@media screen and (max-width: 767px) {
  .u-sp-td-n {
    text-decoration: none !important;
  }
  .u-sp-td-u {
    text-decoration: underline !important;
  }
  .u-sp-td-o {
    text-decoration: overline !important;
  }
  .u-sp-td-l {
    text-decoration: line-through !important;
  }
}

/* line-height
-------------------------------------------------- */
.u-lh-nm {
  line-height: normal !important;
}

.u-lh-i {
  line-height: inherit !important;
}

.u-lh-0 {
  line-height: 0 !important;
}

.u-lh-1 {
  line-height: 1 !important;
}

.u-lh-2 {
  line-height: 2 !important;
}

.u-lh-3 {
  line-height: 3 !important;
}

.u-lh-4 {
  line-height: 4 !important;
}

.u-lh-5 {
  line-height: 5 !important;
}

.u-lh-6 {
  line-height: 6 !important;
}

.u-lh-xs {
  line-height: 1.25 !important;
}

.u-lh-sm {
  line-height: 1.33 !important;
}

.u-lh-md {
  line-height: 1.5 !important;
}

.u-lh-lg {
  line-height: 1.75 !important;
}

.u-lh-xl {
  line-height: 2 !important;
}

@media screen and (max-width: 767px) {
  .u-sp-lh-nm {
    line-height: normal !important;
  }
  .u-sp-lh-i {
    line-height: inherit !important;
  }
  .u-sp-lh-0 {
    line-height: 0 !important;
  }
  .u-sp-lh-1 {
    line-height: 1 !important;
  }
  .u-sp-lh-2 {
    line-height: 2 !important;
  }
  .u-sp-lh-3 {
    line-height: 3 !important;
  }
  .u-sp-lh-4 {
    line-height: 4 !important;
  }
  .u-sp-lh-5 {
    line-height: 5 !important;
  }
  .u-sp-lh-6 {
    line-height: 6 !important;
  }
  .u-sp-lh-xs {
    line-height: 1.25 !important;
  }
  .u-sp-lh-sm {
    line-height: 1.33 !important;
  }
  .u-sp-lh-md {
    line-height: 1.5 !important;
  }
  .u-sp-lh-lg {
    line-height: 1.75 !important;
  }
  .u-sp-lh-xl {
    line-height: 2 !important;
  }
}

/* white-space
-------------------------------------------------- */
.u-whs-nm {
  white-space: normal !important;
}

.u-whs-p {
  white-space: pre !important;
}

.u-whs-nw {
  white-space: nowrap !important;
}

.u-whs-pw {
  white-space: pre-wrap !important;
}

.u-whs-pl {
  white-space: pre-line !important;
}

.u-wob-nm {
  word-break: normal !important;
}

.u-wob-k {
  word-break: keep-all !important;
}

.u-wob-ba {
  word-break: break-all !important;
}

.u-wow-nm {
  word-wrap: normal !important;
}

.u-wow-n {
  word-wrap: none !important;
}

.u-wow-u {
  word-wrap: unrestricted !important;
}

.u-wow-s {
  word-wrap: suppress !important;
}

.u-wow-bw {
  word-wrap: break-word !important;
}

@media screen and (max-width: 767px) {
  .u-sp-whs-nm {
    white-space: normal !important;
  }
  .u-sp-whs-p {
    white-space: pre !important;
  }
  .u-sp-whs-nw {
    white-space: nowrap !important;
  }
  .u-sp-whs-pw {
    white-space: pre-wrap !important;
  }
  .u-sp-whs-pl {
    white-space: pre-line !important;
  }
  .u-sp-wob-nm {
    word-break: normal !important;
  }
  .u-sp-wob-k {
    word-break: keep-all !important;
  }
  .u-sp-wob-ba {
    word-break: break-all !important;
  }
  .u-sp-wow-nm {
    word-wrap: normal !important;
  }
  .u-sp-wow-n {
    word-wrap: none !important;
  }
  .u-sp-wow-u {
    word-wrap: unrestricted !important;
  }
  .u-sp-wow-s {
    word-wrap: suppress !important;
  }
  .u-sp-wow-bw {
    word-wrap: break-word !important;
  }
}

/* cursor
-------------------------------------------------- */
.u-cur-a {
  cursor: auto !important;
}

.u-cur-d {
  cursor: default !important;
}

.u-cur-c {
  cursor: crosshair !important;
}

.u-cur-ha {
  cursor: hand !important;
}

.u-cur-he {
  cursor: help !important;
}

.u-cur-m {
  cursor: move !important;
}

.u-cur-p {
  cursor: pointer !important;
}

.u-cur-t {
  cursor: text !important;
}

@media screen and (max-width: 767px) {
  .u-sp-cur-a {
    cursor: auto !important;
  }
  .u-sp-cur-d {
    cursor: default !important;
  }
  .u-sp-cur-c {
    cursor: crosshair !important;
  }
  .u-sp-cur-ha {
    cursor: hand !important;
  }
  .u-sp-cur-he {
    cursor: help !important;
  }
  .u-sp-cur-m {
    cursor: move !important;
  }
  .u-sp-cur-p {
    cursor: pointer !important;
  }
  .u-sp-cur-t {
    cursor: text !important;
  }
}

/* flex
-------------------------------------------------- */
.u-fxd-r {
  flex-direction: row !important;
}

.u-fxd-rr {
  flex-direction: row-reverse !important;
}

.u-fxd-c {
  flex-direction: column !important;
}

.u-fxd-cr {
  flex-direction: column-reverse !important;
}

.u-fxw-n {
  flex-wrap: nowrap !important;
}

.u-fxw-w {
  flex-wrap: wrap !important;
}

.u-fxw-wr {
  flex-wrap: wrap-reverse !important;
}

.u-jc-fs {
  justify-content: flex-start !important;
}

.u-jc-fe {
  justify-content: flex-end !important;
}

.u-jc-c {
  justify-content: center !important;
}

.u-jc-sb {
  justify-content: space-between !important;
}

.u-jc-sa {
  justify-content: space-around !important;
}

.u-ai-fs {
  align-items: flex-start !important;
}

.u-ai-fe {
  align-items: flex-end !important;
}

.u-ai-c {
  align-items: center !important;
}

.u-ai-b {
  align-items: baseline !important;
}

.u-ai-s {
  align-items: stretch !important;
}

.u-ac-fs {
  align-content: flex-start !important;
}

.u-ac-fe {
  align-content: flex-end !important;
}

.u-ac-c {
  align-content: center !important;
}

.u-ac-sb {
  align-content: space-between !important;
}

.u-ac-sa {
  align-content: space-around !important;
}

.u-ac-s {
  align-content: stretch !important;
}

.u-ord--1 {
  order: -1 !important;
}

.u-ord-0 {
  order: 0 !important;
}

.u-ord-1 {
  order: 1 !important;
}

.u-ord-2 {
  order: 2 !important;
}

.u-ord-3 {
  order: 3 !important;
}

.u-ord-4 {
  order: 4 !important;
}

.u-ord-5 {
  order: 5 !important;
}

.u-ord-6 {
  order: 6 !important;
}

.u-ord-9999 {
  order: 9999 !important;
}

.u-fx-n {
  flex: none !important;
}

.u-fx-1_1_a {
  flex: 1 1 auto !important;
}

.u-fx-1_0_a {
  flex: 1 0 auto !important;
}

.u-fx-0_0_a {
  flex: 0 0 auto !important;
}

.u-fx-0_1_a {
  flex: 0 1 auto !important;
}

.u-fx-1_1_1 {
  flex: 1 1 1px !important;
}

.u-as-a {
  align-self: auto !important;
}

.u-as-fs {
  align-self: flex-start !important;
}

.u-as-fe {
  align-self: flex-end !important;
}

.u-as-c {
  align-self: center !important;
}

.u-as-b {
  align-self: baseline !important;
}

.u-as-s {
  align-self: stretch !important;
}

@media screen and (max-width: 767px) {
  .u-sp-fxd-r {
    flex-direction: row !important;
  }
  .u-sp-fxd-rr {
    flex-direction: row-reverse !important;
  }
  .u-sp-fxd-c {
    flex-direction: column !important;
  }
  .u-sp-fxd-cr {
    flex-direction: column-reverse !important;
  }
  .u-sp-fxw-n {
    flex-wrap: nowrap !important;
  }
  .u-sp-fxw-w {
    flex-wrap: wrap !important;
  }
  .u-sp-fxw-wr {
    flex-wrap: wrap-reverse !important;
  }
  .u-sp-jc-fs {
    justify-content: flex-start !important;
  }
  .u-sp-jc-fe {
    justify-content: flex-end !important;
  }
  .u-sp-jc-c {
    justify-content: center !important;
  }
  .u-sp-jc-sb {
    justify-content: space-between !important;
  }
  .u-sp-jc-sa {
    justify-content: space-around !important;
  }
  .u-sp-ai-fs {
    align-items: flex-start !important;
  }
  .u-sp-ai-fe {
    align-items: flex-end !important;
  }
  .u-sp-ai-c {
    align-items: center !important;
  }
  .u-sp-ai-b {
    align-items: baseline !important;
  }
  .u-sp-ai-s {
    align-items: stretch !important;
  }
  .u-sp-ac-fs {
    align-content: flex-start !important;
  }
  .u-sp-ac-fe {
    align-content: flex-end !important;
  }
  .u-sp-ac-c {
    align-content: center !important;
  }
  .u-sp-ac-sb {
    align-content: space-between !important;
  }
  .u-sp-ac-sa {
    align-content: space-around !important;
  }
  .u-sp-ac-s {
    align-content: stretch !important;
  }
  .u-sp-ord--1 {
    order: -1 !important;
  }
  .u-sp-ord-0 {
    order: 0 !important;
  }
  .u-sp-ord-1 {
    order: 1 !important;
  }
  .u-sp-ord-2 {
    order: 2 !important;
  }
  .u-sp-ord-3 {
    order: 3 !important;
  }
  .u-sp-ord-4 {
    order: 4 !important;
  }
  .u-sp-ord-5 {
    order: 5 !important;
  }
  .u-sp-ord-6 {
    order: 6 !important;
  }
  .u-sp-ord-9999 {
    order: 9999 !important;
  }
  .u-sp-fx-n {
    flex: none !important;
  }
  .u-sp-fx-1_1_a {
    flex: 1 1 auto !important;
  }
  .u-sp-fx-1_0_a {
    flex: 1 0 auto !important;
  }
  .u-sp-fx-0_0_a {
    flex: 0 0 auto !important;
  }
  .u-sp-fx-0_1_a {
    flex: 0 1 auto !important;
  }
  .u-sp-fx-1_1_1 {
    flex: 1 1 1px !important;
  }
  .u-sp-as-a {
    align-self: auto !important;
  }
  .u-sp-as-fs {
    align-self: flex-start !important;
  }
  .u-sp-as-fe {
    align-self: flex-end !important;
  }
  .u-sp-as-c {
    align-self: center !important;
  }
  .u-sp-as-b {
    align-self: baseline !important;
  }
  .u-sp-as-s {
    align-self: stretch !important;
  }
}

/* margin
------------------------------------------------------------ */
.u-m-a {
  margin: auto !important;
}

.u-ma0 {
  margin: 0 !important;
}

.u-mt0 {
  margin-top: 0 !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mt85 {
  margin-top: 85px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mt95 {
  margin-top: 95px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mb0 {
  margin-bottom: 0 !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-mb85 {
  margin-bottom: 85px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-mb95 {
  margin-bottom: 95px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-ml0 {
  margin-left: 0 !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-ml95 {
  margin-left: 95px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

.u-mr0 {
  margin-right: 0 !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-mr95 {
  margin-right: 95px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

@media screen and (max-width: 767px) {
  .u-sp-m-a {
    margin: auto !important;
  }
  .u-sp-ma0 {
    margin: 0 !important;
  }
  .u-sp-mt0 {
    margin-top: 0 !important;
  }
  .u-sp-mt5 {
    margin-top: 5px !important;
  }
  .u-sp-mt10 {
    margin-top: 10px !important;
  }
  .u-sp-mt15 {
    margin-top: 15px !important;
  }
  .u-sp-mt20 {
    margin-top: 20px !important;
  }
  .u-sp-mt25 {
    margin-top: 25px !important;
  }
  .u-sp-mt30 {
    margin-top: 30px !important;
  }
  .u-sp-mt35 {
    margin-top: 35px !important;
  }
  .u-sp-mt40 {
    margin-top: 40px !important;
  }
  .u-sp-mt45 {
    margin-top: 45px !important;
  }
  .u-sp-mt50 {
    margin-top: 50px !important;
  }
  .u-sp-mt55 {
    margin-top: 55px !important;
  }
  .u-sp-mt60 {
    margin-top: 60px !important;
  }
  .u-sp-mt65 {
    margin-top: 65px !important;
  }
  .u-sp-mt70 {
    margin-top: 70px !important;
  }
  .u-sp-mt75 {
    margin-top: 75px !important;
  }
  .u-sp-mt80 {
    margin-top: 80px !important;
  }
  .u-sp-mt85 {
    margin-top: 85px !important;
  }
  .u-sp-mt90 {
    margin-top: 90px !important;
  }
  .u-sp-mt95 {
    margin-top: 95px !important;
  }
  .u-sp-mt100 {
    margin-top: 100px !important;
  }
  .u-sp-mb0 {
    margin-bottom: 0 !important;
  }
  .u-sp-mb5 {
    margin-bottom: 5px !important;
  }
  .u-sp-mb10 {
    margin-bottom: 10px !important;
  }
  .u-sp-mb15 {
    margin-bottom: 15px !important;
  }
  .u-sp-mb20 {
    margin-bottom: 20px !important;
  }
  .u-sp-mb25 {
    margin-bottom: 25px !important;
  }
  .u-sp-mb30 {
    margin-bottom: 30px !important;
  }
  .u-sp-mb35 {
    margin-bottom: 35px !important;
  }
  .u-sp-mb40 {
    margin-bottom: 40px !important;
  }
  .u-sp-mb45 {
    margin-bottom: 45px !important;
  }
  .u-sp-mb50 {
    margin-bottom: 50px !important;
  }
  .u-sp-mb55 {
    margin-bottom: 55px !important;
  }
  .u-sp-mb60 {
    margin-bottom: 60px !important;
  }
  .u-sp-mb65 {
    margin-bottom: 65px !important;
  }
  .u-sp-mb70 {
    margin-bottom: 70px !important;
  }
  .u-sp-mb75 {
    margin-bottom: 75px !important;
  }
  .u-sp-mb80 {
    margin-bottom: 80px !important;
  }
  .u-sp-mb85 {
    margin-bottom: 85px !important;
  }
  .u-sp-mb90 {
    margin-bottom: 90px !important;
  }
  .u-sp-mb95 {
    margin-bottom: 95px !important;
  }
  .u-sp-mb100 {
    margin-bottom: 100px !important;
  }
  .u-sp-ml0 {
    margin-left: 0 !important;
  }
  .u-sp-ml5 {
    margin-left: 5px !important;
  }
  .u-sp-ml10 {
    margin-left: 10px !important;
  }
  .u-sp-ml15 {
    margin-left: 15px !important;
  }
  .u-sp-ml20 {
    margin-left: 20px !important;
  }
  .u-sp-ml25 {
    margin-left: 25px !important;
  }
  .u-sp-ml30 {
    margin-left: 30px !important;
  }
  .u-sp-ml35 {
    margin-left: 35px !important;
  }
  .u-sp-ml40 {
    margin-left: 40px !important;
  }
  .u-sp-ml45 {
    margin-left: 45px !important;
  }
  .u-sp-ml50 {
    margin-left: 50px !important;
  }
  .u-sp-ml55 {
    margin-left: 55px !important;
  }
  .u-sp-ml60 {
    margin-left: 60px !important;
  }
  .u-sp-ml65 {
    margin-left: 65px !important;
  }
  .u-sp-ml70 {
    margin-left: 70px !important;
  }
  .u-sp-ml75 {
    margin-left: 75px !important;
  }
  .u-sp-ml80 {
    margin-left: 80px !important;
  }
  .u-sp-ml85 {
    margin-left: 85px !important;
  }
  .u-sp-ml90 {
    margin-left: 90px !important;
  }
  .u-sp-ml95 {
    margin-left: 95px !important;
  }
  .u-sp-ml100 {
    margin-left: 100px !important;
  }
  .u-sp-mr0 {
    margin-right: 0 !important;
  }
  .u-sp-mr5 {
    margin-right: 5px !important;
  }
  .u-sp-mr10 {
    margin-right: 10px !important;
  }
  .u-sp-mr15 {
    margin-right: 15px !important;
  }
  .u-sp-mr20 {
    margin-right: 20px !important;
  }
  .u-sp-mr25 {
    margin-right: 25px !important;
  }
  .u-sp-mr30 {
    margin-right: 30px !important;
  }
  .u-sp-mr35 {
    margin-right: 35px !important;
  }
  .u-sp-mr40 {
    margin-right: 40px !important;
  }
  .u-sp-mr45 {
    margin-right: 45px !important;
  }
  .u-sp-mr50 {
    margin-right: 50px !important;
  }
  .u-sp-mr55 {
    margin-right: 55px !important;
  }
  .u-sp-mr60 {
    margin-right: 60px !important;
  }
  .u-sp-mr65 {
    margin-right: 65px !important;
  }
  .u-sp-mr70 {
    margin-right: 70px !important;
  }
  .u-sp-mr75 {
    margin-right: 75px !important;
  }
  .u-sp-mr80 {
    margin-right: 80px !important;
  }
  .u-sp-mr85 {
    margin-right: 85px !important;
  }
  .u-sp-mr90 {
    margin-right: 90px !important;
  }
  .u-sp-mr95 {
    margin-right: 95px !important;
  }
  .u-sp-mr100 {
    margin-right: 100px !important;
  }
}

/* padding
------------------------------------------------------------ */
.u-pa0 {
  padding: 0 !important;
}

.u-pa5 {
  padding: 5px !important;
}

.u-pa10 {
  padding: 10px !important;
}

.u-pa15 {
  padding: 15px !important;
}

.u-pa20 {
  padding: 20px !important;
}

.u-pa25 {
  padding: 25px !important;
}

.u-pa30 {
  padding: 30px !important;
}

.u-pa35 {
  padding: 35px !important;
}

.u-pa40 {
  padding: 40px !important;
}

.u-pa45 {
  padding: 45px !important;
}

.u-pa50 {
  padding: 50px !important;
}

.u-pa55 {
  padding: 55px !important;
}

.u-pa60 {
  padding: 60px !important;
}

.u-pa65 {
  padding: 65px !important;
}

.u-pa70 {
  padding: 70px !important;
}

.u-pa75 {
  padding: 75px !important;
}

.u-pa80 {
  padding: 80px !important;
}

.u-pa85 {
  padding: 85px !important;
}

.u-pa90 {
  padding: 90px !important;
}

.u-pa95 {
  padding: 95px !important;
}

.u-pa100 {
  padding: 100px !important;
}

.u-pt0 {
  padding-top: 0 !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pr0 {
  padding-right: 0 !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pr95 {
  padding-right: 95px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

.u-pb0 {
  padding-bottom: 0 !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

.u-pl0 {
  padding-left: 0 !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pl95 {
  padding-left: 95px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

@media screen and (max-width: 767px) {
  .u-sp-pa0 {
    padding: 0 !important;
  }
  .u-sp-pa5 {
    padding: 5px !important;
  }
  .u-sp-pa10 {
    padding: 10px !important;
  }
  .u-sp-pa15 {
    padding: 15px !important;
  }
  .u-sp-pa20 {
    padding: 20px !important;
  }
  .u-sp-pa25 {
    padding: 25px !important;
  }
  .u-sp-pa30 {
    padding: 30px !important;
  }
  .u-sp-pa35 {
    padding: 35px !important;
  }
  .u-sp-pa40 {
    padding: 40px !important;
  }
  .u-sp-pa45 {
    padding: 45px !important;
  }
  .u-sp-pa50 {
    padding: 50px !important;
  }
  .u-sp-pa55 {
    padding: 55px !important;
  }
  .u-sp-pa60 {
    padding: 60px !important;
  }
  .u-sp-pa65 {
    padding: 65px !important;
  }
  .u-sp-pa70 {
    padding: 70px !important;
  }
  .u-sp-pa75 {
    padding: 75px !important;
  }
  .u-sp-pa80 {
    padding: 80px !important;
  }
  .u-sp-pa85 {
    padding: 85px !important;
  }
  .u-sp-pa90 {
    padding: 90px !important;
  }
  .u-sp-pa95 {
    padding: 95px !important;
  }
  .u-sp-pa100 {
    padding: 100px !important;
  }
  .u-sp-pt0 {
    padding-top: 0 !important;
  }
  .u-sp-pt5 {
    padding-top: 5px !important;
  }
  .u-sp-pt10 {
    padding-top: 10px !important;
  }
  .u-sp-pt15 {
    padding-top: 15px !important;
  }
  .u-sp-pt20 {
    padding-top: 20px !important;
  }
  .u-sp-pt25 {
    padding-top: 25px !important;
  }
  .u-sp-pt30 {
    padding-top: 30px !important;
  }
  .u-sp-pt35 {
    padding-top: 35px !important;
  }
  .u-sp-pt40 {
    padding-top: 40px !important;
  }
  .u-sp-pt45 {
    padding-top: 45px !important;
  }
  .u-sp-pt50 {
    padding-top: 50px !important;
  }
  .u-sp-pt55 {
    padding-top: 55px !important;
  }
  .u-sp-pt60 {
    padding-top: 60px !important;
  }
  .u-sp-pt65 {
    padding-top: 65px !important;
  }
  .u-sp-pt70 {
    padding-top: 70px !important;
  }
  .u-sp-pt75 {
    padding-top: 75px !important;
  }
  .u-sp-pt80 {
    padding-top: 80px !important;
  }
  .u-sp-pt85 {
    padding-top: 85px !important;
  }
  .u-sp-pt90 {
    padding-top: 90px !important;
  }
  .u-sp-pt95 {
    padding-top: 95px !important;
  }
  .u-sp-pt100 {
    padding-top: 100px !important;
  }
  .u-sp-pr0 {
    padding-right: 0 !important;
  }
  .u-sp-pr5 {
    padding-right: 5px !important;
  }
  .u-sp-pr10 {
    padding-right: 10px !important;
  }
  .u-sp-pr15 {
    padding-right: 15px !important;
  }
  .u-sp-pr20 {
    padding-right: 20px !important;
  }
  .u-sp-pr25 {
    padding-right: 25px !important;
  }
  .u-sp-pr30 {
    padding-right: 30px !important;
  }
  .u-sp-pr35 {
    padding-right: 35px !important;
  }
  .u-sp-pr40 {
    padding-right: 40px !important;
  }
  .u-sp-pr45 {
    padding-right: 45px !important;
  }
  .u-sp-pr50 {
    padding-right: 50px !important;
  }
  .u-sp-pr55 {
    padding-right: 55px !important;
  }
  .u-sp-pr60 {
    padding-right: 60px !important;
  }
  .u-sp-pr65 {
    padding-right: 65px !important;
  }
  .u-sp-pr70 {
    padding-right: 70px !important;
  }
  .u-sp-pr75 {
    padding-right: 75px !important;
  }
  .u-sp-pr80 {
    padding-right: 80px !important;
  }
  .u-sp-pr85 {
    padding-right: 85px !important;
  }
  .u-sp-pr90 {
    padding-right: 90px !important;
  }
  .u-sp-pr95 {
    padding-right: 95px !important;
  }
  .u-sp-pr100 {
    padding-right: 100px !important;
  }
  .u-sp-pb0 {
    padding-bottom: 0 !important;
  }
  .u-sp-pb5 {
    padding-bottom: 5px !important;
  }
  .u-sp-pb10 {
    padding-bottom: 10px !important;
  }
  .u-sp-pb15 {
    padding-bottom: 15px !important;
  }
  .u-sp-pb20 {
    padding-bottom: 20px !important;
  }
  .u-sp-pb25 {
    padding-bottom: 25px !important;
  }
  .u-sp-pb30 {
    padding-bottom: 30px !important;
  }
  .u-sp-pb35 {
    padding-bottom: 35px !important;
  }
  .u-sp-pb40 {
    padding-bottom: 40px !important;
  }
  .u-sp-pb45 {
    padding-bottom: 45px !important;
  }
  .u-sp-pb50 {
    padding-bottom: 50px !important;
  }
  .u-sp-pb55 {
    padding-bottom: 55px !important;
  }
  .u-sp-pb60 {
    padding-bottom: 60px !important;
  }
  .u-sp-pb65 {
    padding-bottom: 65px !important;
  }
  .u-sp-pb70 {
    padding-bottom: 70px !important;
  }
  .u-sp-pb75 {
    padding-bottom: 75px !important;
  }
  .u-sp-pb80 {
    padding-bottom: 80px !important;
  }
  .u-sp-pb85 {
    padding-bottom: 85px !important;
  }
  .u-sp-pb90 {
    padding-bottom: 90px !important;
  }
  .u-sp-pb95 {
    padding-bottom: 95px !important;
  }
  .u-sp-pb100 {
    padding-bottom: 100px !important;
  }
  .u-sp-pl0 {
    padding-left: 0 !important;
  }
  .u-sp-pl5 {
    padding-left: 5px !important;
  }
  .u-sp-pl10 {
    padding-left: 10px !important;
  }
  .u-sp-pl15 {
    padding-left: 15px !important;
  }
  .u-sp-pl20 {
    padding-left: 20px !important;
  }
  .u-sp-pl25 {
    padding-left: 25px !important;
  }
  .u-sp-pl30 {
    padding-left: 30px !important;
  }
  .u-sp-pl35 {
    padding-left: 35px !important;
  }
  .u-sp-pl40 {
    padding-left: 40px !important;
  }
  .u-sp-pl45 {
    padding-left: 45px !important;
  }
  .u-sp-pl50 {
    padding-left: 50px !important;
  }
  .u-sp-pl55 {
    padding-left: 55px !important;
  }
  .u-sp-pl60 {
    padding-left: 60px !important;
  }
  .u-sp-pl65 {
    padding-left: 65px !important;
  }
  .u-sp-pl70 {
    padding-left: 70px !important;
  }
  .u-sp-pl75 {
    padding-left: 75px !important;
  }
  .u-sp-pl80 {
    padding-left: 80px !important;
  }
  .u-sp-pl85 {
    padding-left: 85px !important;
  }
  .u-sp-pl90 {
    padding-left: 90px !important;
  }
  .u-sp-pl95 {
    padding-left: 95px !important;
  }
  .u-sp-pl100 {
    padding-left: 100px !important;
  }
}

/* 幅-px指定
------------------------------------------------------------ */
.u-pc-wd-a {
  width: auto !important;
}

.u-pc-wd0 {
  width: 0px !important;
}

.u-pc-wd5 {
  width: 5px !important;
}

.u-pc-wd10 {
  width: 10px !important;
}

.u-pc-wd15 {
  width: 15px !important;
}

.u-pc-wd20 {
  width: 20px !important;
}

.u-pc-wd25 {
  width: 25px !important;
}

.u-pc-wd30 {
  width: 30px !important;
}

.u-pc-wd35 {
  width: 35px !important;
}

.u-pc-wd40 {
  width: 40px !important;
}

.u-pc-wd45 {
  width: 45px !important;
}

.u-pc-wd50 {
  width: 50px !important;
}

.u-pc-wd55 {
  width: 55px !important;
}

.u-pc-wd60 {
  width: 60px !important;
}

.u-pc-wd65 {
  width: 65px !important;
}

.u-pc-wd70 {
  width: 70px !important;
}

.u-pc-wd75 {
  width: 75px !important;
}

.u-pc-wd80 {
  width: 80px !important;
}

.u-pc-wd85 {
  width: 85px !important;
}

.u-pc-wd90 {
  width: 90px !important;
}

.u-pc-wd95 {
  width: 95px !important;
}

.u-pc-wd100 {
  width: 100px !important;
}

.u-pc-wd105 {
  width: 105px !important;
}

.u-pc-wd110 {
  width: 110px !important;
}

.u-pc-wd115 {
  width: 115px !important;
}

.u-pc-wd120 {
  width: 120px !important;
}

.u-pc-wd125 {
  width: 125px !important;
}

.u-pc-wd130 {
  width: 130px !important;
}

.u-pc-wd135 {
  width: 135px !important;
}

.u-pc-wd140 {
  width: 140px !important;
}

.u-pc-wd145 {
  width: 145px !important;
}

.u-pc-wd150 {
  width: 150px !important;
}

.u-pc-wd155 {
  width: 155px !important;
}

.u-pc-wd160 {
  width: 160px !important;
}

.u-pc-wd165 {
  width: 165px !important;
}

.u-pc-wd170 {
  width: 170px !important;
}

.u-pc-wd175 {
  width: 175px !important;
}

.u-pc-wd180 {
  width: 180px !important;
}

.u-pc-wd185 {
  width: 185px !important;
}

.u-pc-wd190 {
  width: 190px !important;
}

.u-pc-wd195 {
  width: 195px !important;
}

.u-pc-wd200 {
  width: 200px !important;
}

.u-pc-wd205 {
  width: 205px !important;
}

.u-pc-wd210 {
  width: 210px !important;
}

.u-pc-wd215 {
  width: 215px !important;
}

.u-pc-wd220 {
  width: 220px !important;
}

.u-pc-wd225 {
  width: 225px !important;
}

.u-pc-wd230 {
  width: 230px !important;
}

.u-pc-wd235 {
  width: 235px !important;
}

.u-pc-wd240 {
  width: 240px !important;
}

.u-pc-wd245 {
  width: 245px !important;
}

.u-pc-wd250 {
  width: 250px !important;
}

.u-pc-wd255 {
  width: 255px !important;
}

.u-pc-wd260 {
  width: 260px !important;
}

.u-pc-wd265 {
  width: 265px !important;
}

.u-pc-wd270 {
  width: 270px !important;
}

.u-pc-wd275 {
  width: 275px !important;
}

.u-pc-wd280 {
  width: 280px !important;
}

.u-pc-wd285 {
  width: 285px !important;
}

.u-pc-wd290 {
  width: 290px !important;
}

.u-pc-wd295 {
  width: 295px !important;
}

.u-pc-wd300 {
  width: 300px !important;
}

.u-pc-wd305 {
  width: 305px !important;
}

.u-pc-wd310 {
  width: 310px !important;
}

.u-pc-wd315 {
  width: 315px !important;
}

.u-pc-wd320 {
  width: 320px !important;
}

.u-pc-wd325 {
  width: 325px !important;
}

.u-pc-wd330 {
  width: 330px !important;
}

.u-pc-wd335 {
  width: 335px !important;
}

.u-pc-wd340 {
  width: 340px !important;
}

.u-pc-wd345 {
  width: 345px !important;
}

.u-pc-wd350 {
  width: 350px !important;
}

.u-pc-wd355 {
  width: 355px !important;
}

.u-pc-wd360 {
  width: 360px !important;
}

.u-pc-wd365 {
  width: 365px !important;
}

.u-pc-wd370 {
  width: 370px !important;
}

.u-pc-wd375 {
  width: 375px !important;
}

.u-pc-wd380 {
  width: 380px !important;
}

.u-pc-wd385 {
  width: 385px !important;
}

.u-pc-wd390 {
  width: 390px !important;
}

.u-pc-wd395 {
  width: 395px !important;
}

.u-pc-wd400 {
  width: 400px !important;
}

.u-pc-wd405 {
  width: 405px !important;
}

.u-pc-wd410 {
  width: 410px !important;
}

.u-pc-wd415 {
  width: 415px !important;
}

.u-pc-wd420 {
  width: 420px !important;
}

.u-pc-wd425 {
  width: 425px !important;
}

.u-pc-wd430 {
  width: 430px !important;
}

.u-pc-wd435 {
  width: 435px !important;
}

.u-pc-wd440 {
  width: 440px !important;
}

.u-pc-wd445 {
  width: 445px !important;
}

.u-pc-wd450 {
  width: 450px !important;
}

.u-pc-wd455 {
  width: 455px !important;
}

.u-pc-wd460 {
  width: 460px !important;
}

.u-pc-wd465 {
  width: 465px !important;
}

.u-pc-wd470 {
  width: 470px !important;
}

.u-pc-wd475 {
  width: 475px !important;
}

.u-pc-wd480 {
  width: 480px !important;
}

.u-pc-wd485 {
  width: 485px !important;
}

.u-pc-wd490 {
  width: 490px !important;
}

.u-pc-wd495 {
  width: 495px !important;
}

.u-pc-wd500 {
  width: 500px !important;
}

.u-pc-wd505 {
  width: 505px !important;
}

.u-pc-wd510 {
  width: 510px !important;
}

.u-pc-wd515 {
  width: 515px !important;
}

.u-pc-wd520 {
  width: 520px !important;
}

.u-pc-wd525 {
  width: 525px !important;
}

.u-pc-wd530 {
  width: 530px !important;
}

.u-pc-wd535 {
  width: 535px !important;
}

.u-pc-wd540 {
  width: 540px !important;
}

.u-pc-wd545 {
  width: 545px !important;
}

.u-pc-wd550 {
  width: 550px !important;
}

.u-pc-wd555 {
  width: 555px !important;
}

.u-pc-wd560 {
  width: 560px !important;
}

.u-pc-wd565 {
  width: 565px !important;
}

.u-pc-wd570 {
  width: 570px !important;
}

.u-pc-wd575 {
  width: 575px !important;
}

.u-pc-wd580 {
  width: 580px !important;
}

.u-pc-wd585 {
  width: 585px !important;
}

.u-pc-wd590 {
  width: 590px !important;
}

.u-pc-wd595 {
  width: 595px !important;
}

.u-pc-wd600 {
  width: 600px !important;
}

.u-pc-wd605 {
  width: 605px !important;
}

.u-pc-wd610 {
  width: 610px !important;
}

.u-pc-wd615 {
  width: 615px !important;
}

.u-pc-wd620 {
  width: 620px !important;
}

.u-pc-wd625 {
  width: 625px !important;
}

.u-pc-wd630 {
  width: 630px !important;
}

.u-pc-wd635 {
  width: 635px !important;
}

.u-pc-wd640 {
  width: 640px !important;
}

.u-pc-wd645 {
  width: 645px !important;
}

.u-pc-wd650 {
  width: 650px !important;
}

.u-pc-wd655 {
  width: 655px !important;
}

.u-pc-wd660 {
  width: 660px !important;
}

.u-pc-wd665 {
  width: 665px !important;
}

.u-pc-wd670 {
  width: 670px !important;
}

.u-pc-wd675 {
  width: 675px !important;
}

.u-pc-wd680 {
  width: 680px !important;
}

.u-pc-wd685 {
  width: 685px !important;
}

.u-pc-wd690 {
  width: 690px !important;
}

.u-pc-wd695 {
  width: 695px !important;
}

.u-pc-wd700 {
  width: 700px !important;
}

.u-pc-wd705 {
  width: 705px !important;
}

.u-pc-wd710 {
  width: 710px !important;
}

.u-pc-wd715 {
  width: 715px !important;
}

.u-pc-wd720 {
  width: 720px !important;
}

.u-pc-wd725 {
  width: 725px !important;
}

.u-pc-wd730 {
  width: 730px !important;
}

.u-pc-wd735 {
  width: 735px !important;
}

.u-pc-wd740 {
  width: 740px !important;
}

.u-pc-wd745 {
  width: 745px !important;
}

.u-pc-wd750 {
  width: 750px !important;
}

.u-pc-wd755 {
  width: 755px !important;
}

.u-pc-wd760 {
  width: 760px !important;
}

.u-pc-wd765 {
  width: 765px !important;
}

.u-pc-wd770 {
  width: 770px !important;
}

.u-pc-wd775 {
  width: 775px !important;
}

.u-pc-wd780 {
  width: 780px !important;
}

.u-pc-wd785 {
  width: 785px !important;
}

.u-pc-wd790 {
  width: 790px !important;
}

.u-pc-wd795 {
  width: 795px !important;
}

.u-pc-wd800 {
  width: 800px !important;
}

.u-pc-wd805 {
  width: 805px !important;
}

.u-pc-wd810 {
  width: 810px !important;
}

.u-pc-wd815 {
  width: 815px !important;
}

.u-pc-wd820 {
  width: 820px !important;
}

.u-pc-wd825 {
  width: 825px !important;
}

.u-pc-wd830 {
  width: 830px !important;
}

.u-pc-wd835 {
  width: 835px !important;
}

.u-pc-wd840 {
  width: 840px !important;
}

.u-pc-wd845 {
  width: 845px !important;
}

.u-pc-wd850 {
  width: 850px !important;
}

.u-pc-wd855 {
  width: 855px !important;
}

.u-pc-wd860 {
  width: 860px !important;
}

.u-pc-wd865 {
  width: 865px !important;
}

.u-pc-wd870 {
  width: 870px !important;
}

.u-pc-wd875 {
  width: 875px !important;
}

.u-pc-wd880 {
  width: 880px !important;
}

.u-pc-wd885 {
  width: 885px !important;
}

.u-pc-wd890 {
  width: 890px !important;
}

.u-pc-wd895 {
  width: 895px !important;
}

.u-pc-wd900 {
  width: 900px !important;
}

.u-pc-wd905 {
  width: 905px !important;
}

.u-pc-wd910 {
  width: 910px !important;
}

.u-pc-wd915 {
  width: 915px !important;
}

.u-pc-wd920 {
  width: 920px !important;
}

.u-pc-wd925 {
  width: 925px !important;
}

.u-pc-wd930 {
  width: 930px !important;
}

.u-pc-wd935 {
  width: 935px !important;
}

.u-pc-wd940 {
  width: 940px !important;
}

.u-pc-wd945 {
  width: 945px !important;
}

.u-pc-wd950 {
  width: 950px !important;
}

.u-pc-wd955 {
  width: 955px !important;
}

.u-pc-wd960 {
  width: 960px !important;
}

.u-pc-wd965 {
  width: 965px !important;
}

.u-pc-wd970 {
  width: 970px !important;
}

.u-pc-wd975 {
  width: 975px !important;
}

.u-pc-wd980 {
  width: 980px !important;
}

.u-pc-wd985 {
  width: 985px !important;
}

.u-pc-wd990 {
  width: 990px !important;
}

.u-pc-wd995 {
  width: 995px !important;
}

.u-pc-wd1000 {
  width: 1000px !important;
}

@media screen and (max-width: 767px) {
  .u-sp-wd-a {
    width: auto !important;
  }
  .u-sp-wd0 {
    width: 0px !important;
  }
  .u-sp-wd5 {
    width: 5px !important;
  }
  .u-sp-wd10 {
    width: 10px !important;
  }
  .u-sp-wd15 {
    width: 15px !important;
  }
  .u-sp-wd20 {
    width: 20px !important;
  }
  .u-sp-wd25 {
    width: 25px !important;
  }
  .u-sp-wd30 {
    width: 30px !important;
  }
  .u-sp-wd35 {
    width: 35px !important;
  }
  .u-sp-wd40 {
    width: 40px !important;
  }
  .u-sp-wd45 {
    width: 45px !important;
  }
  .u-sp-wd50 {
    width: 50px !important;
  }
  .u-sp-wd55 {
    width: 55px !important;
  }
  .u-sp-wd60 {
    width: 60px !important;
  }
  .u-sp-wd65 {
    width: 65px !important;
  }
  .u-sp-wd70 {
    width: 70px !important;
  }
  .u-sp-wd75 {
    width: 75px !important;
  }
  .u-sp-wd80 {
    width: 80px !important;
  }
  .u-sp-wd85 {
    width: 85px !important;
  }
  .u-sp-wd90 {
    width: 90px !important;
  }
  .u-sp-wd95 {
    width: 95px !important;
  }
  .u-sp-wd100 {
    width: 100px !important;
  }
  .u-sp-wd105 {
    width: 105px !important;
  }
  .u-sp-wd110 {
    width: 110px !important;
  }
  .u-sp-wd115 {
    width: 115px !important;
  }
  .u-sp-wd120 {
    width: 120px !important;
  }
  .u-sp-wd125 {
    width: 125px !important;
  }
  .u-sp-wd130 {
    width: 130px !important;
  }
  .u-sp-wd135 {
    width: 135px !important;
  }
  .u-sp-wd140 {
    width: 140px !important;
  }
  .u-sp-wd145 {
    width: 145px !important;
  }
  .u-sp-wd150 {
    width: 150px !important;
  }
  .u-sp-wd155 {
    width: 155px !important;
  }
  .u-sp-wd160 {
    width: 160px !important;
  }
  .u-sp-wd165 {
    width: 165px !important;
  }
  .u-sp-wd170 {
    width: 170px !important;
  }
  .u-sp-wd175 {
    width: 175px !important;
  }
  .u-sp-wd180 {
    width: 180px !important;
  }
  .u-sp-wd185 {
    width: 185px !important;
  }
  .u-sp-wd190 {
    width: 190px !important;
  }
  .u-sp-wd195 {
    width: 195px !important;
  }
  .u-sp-wd200 {
    width: 200px !important;
  }
  .u-sp-wd205 {
    width: 205px !important;
  }
  .u-sp-wd210 {
    width: 210px !important;
  }
  .u-sp-wd215 {
    width: 215px !important;
  }
  .u-sp-wd220 {
    width: 220px !important;
  }
  .u-sp-wd225 {
    width: 225px !important;
  }
  .u-sp-wd230 {
    width: 230px !important;
  }
  .u-sp-wd235 {
    width: 235px !important;
  }
  .u-sp-wd240 {
    width: 240px !important;
  }
  .u-sp-wd245 {
    width: 245px !important;
  }
  .u-sp-wd250 {
    width: 250px !important;
  }
  .u-sp-wd255 {
    width: 255px !important;
  }
  .u-sp-wd260 {
    width: 260px !important;
  }
  .u-sp-wd265 {
    width: 265px !important;
  }
  .u-sp-wd270 {
    width: 270px !important;
  }
  .u-sp-wd275 {
    width: 275px !important;
  }
  .u-sp-wd280 {
    width: 280px !important;
  }
  .u-sp-wd285 {
    width: 285px !important;
  }
  .u-sp-wd290 {
    width: 290px !important;
  }
  .u-sp-wd295 {
    width: 295px !important;
  }
  .u-sp-wd300 {
    width: 300px !important;
  }
  .u-sp-wd305 {
    width: 305px !important;
  }
  .u-sp-wd310 {
    width: 310px !important;
  }
  .u-sp-wd315 {
    width: 315px !important;
  }
  .u-sp-wd320 {
    width: 320px !important;
  }
  .u-sp-wd325 {
    width: 325px !important;
  }
  .u-sp-wd330 {
    width: 330px !important;
  }
  .u-sp-wd335 {
    width: 335px !important;
  }
  .u-sp-wd340 {
    width: 340px !important;
  }
  .u-sp-wd345 {
    width: 345px !important;
  }
  .u-sp-wd350 {
    width: 350px !important;
  }
  .u-sp-wd355 {
    width: 355px !important;
  }
  .u-sp-wd360 {
    width: 360px !important;
  }
  .u-sp-wd365 {
    width: 365px !important;
  }
  .u-sp-wd370 {
    width: 370px !important;
  }
  .u-sp-wd375 {
    width: 375px !important;
  }
  .u-sp-wd380 {
    width: 380px !important;
  }
  .u-sp-wd385 {
    width: 385px !important;
  }
  .u-sp-wd390 {
    width: 390px !important;
  }
  .u-sp-wd395 {
    width: 395px !important;
  }
  .u-sp-wd400 {
    width: 400px !important;
  }
  .u-sp-wd405 {
    width: 405px !important;
  }
  .u-sp-wd410 {
    width: 410px !important;
  }
  .u-sp-wd415 {
    width: 415px !important;
  }
  .u-sp-wd420 {
    width: 420px !important;
  }
  .u-sp-wd425 {
    width: 425px !important;
  }
  .u-sp-wd430 {
    width: 430px !important;
  }
  .u-sp-wd435 {
    width: 435px !important;
  }
  .u-sp-wd440 {
    width: 440px !important;
  }
  .u-sp-wd445 {
    width: 445px !important;
  }
  .u-sp-wd450 {
    width: 450px !important;
  }
  .u-sp-wd455 {
    width: 455px !important;
  }
  .u-sp-wd460 {
    width: 460px !important;
  }
  .u-sp-wd465 {
    width: 465px !important;
  }
  .u-sp-wd470 {
    width: 470px !important;
  }
  .u-sp-wd475 {
    width: 475px !important;
  }
  .u-sp-wd480 {
    width: 480px !important;
  }
  .u-sp-wd485 {
    width: 485px !important;
  }
  .u-sp-wd490 {
    width: 490px !important;
  }
  .u-sp-wd495 {
    width: 495px !important;
  }
  .u-sp-wd500 {
    width: 500px !important;
  }
  .u-sp-wd505 {
    width: 505px !important;
  }
  .u-sp-wd510 {
    width: 510px !important;
  }
  .u-sp-wd515 {
    width: 515px !important;
  }
  .u-sp-wd520 {
    width: 520px !important;
  }
  .u-sp-wd525 {
    width: 525px !important;
  }
  .u-sp-wd530 {
    width: 530px !important;
  }
  .u-sp-wd535 {
    width: 535px !important;
  }
  .u-sp-wd540 {
    width: 540px !important;
  }
  .u-sp-wd545 {
    width: 545px !important;
  }
  .u-sp-wd550 {
    width: 550px !important;
  }
  .u-sp-wd555 {
    width: 555px !important;
  }
  .u-sp-wd560 {
    width: 560px !important;
  }
  .u-sp-wd565 {
    width: 565px !important;
  }
  .u-sp-wd570 {
    width: 570px !important;
  }
  .u-sp-wd575 {
    width: 575px !important;
  }
  .u-sp-wd580 {
    width: 580px !important;
  }
  .u-sp-wd585 {
    width: 585px !important;
  }
  .u-sp-wd590 {
    width: 590px !important;
  }
  .u-sp-wd595 {
    width: 595px !important;
  }
  .u-sp-wd600 {
    width: 600px !important;
  }
  .u-sp-wd605 {
    width: 605px !important;
  }
  .u-sp-wd610 {
    width: 610px !important;
  }
  .u-sp-wd615 {
    width: 615px !important;
  }
  .u-sp-wd620 {
    width: 620px !important;
  }
  .u-sp-wd625 {
    width: 625px !important;
  }
  .u-sp-wd630 {
    width: 630px !important;
  }
  .u-sp-wd635 {
    width: 635px !important;
  }
  .u-sp-wd640 {
    width: 640px !important;
  }
  .u-sp-wd645 {
    width: 645px !important;
  }
  .u-sp-wd650 {
    width: 650px !important;
  }
  .u-sp-wd655 {
    width: 655px !important;
  }
  .u-sp-wd660 {
    width: 660px !important;
  }
  .u-sp-wd665 {
    width: 665px !important;
  }
  .u-sp-wd670 {
    width: 670px !important;
  }
  .u-sp-wd675 {
    width: 675px !important;
  }
  .u-sp-wd680 {
    width: 680px !important;
  }
  .u-sp-wd685 {
    width: 685px !important;
  }
  .u-sp-wd690 {
    width: 690px !important;
  }
  .u-sp-wd695 {
    width: 695px !important;
  }
  .u-sp-wd700 {
    width: 700px !important;
  }
  .u-sp-wd705 {
    width: 705px !important;
  }
  .u-sp-wd710 {
    width: 710px !important;
  }
  .u-sp-wd715 {
    width: 715px !important;
  }
  .u-sp-wd720 {
    width: 720px !important;
  }
  .u-sp-wd725 {
    width: 725px !important;
  }
  .u-sp-wd730 {
    width: 730px !important;
  }
  .u-sp-wd735 {
    width: 735px !important;
  }
  .u-sp-wd740 {
    width: 740px !important;
  }
  .u-sp-wd745 {
    width: 745px !important;
  }
  .u-sp-wd750 {
    width: 750px !important;
  }
  .u-sp-wd755 {
    width: 755px !important;
  }
  .u-sp-wd760 {
    width: 760px !important;
  }
  .u-sp-wd765 {
    width: 765px !important;
  }
  .u-sp-wd770 {
    width: 770px !important;
  }
  .u-sp-wd775 {
    width: 775px !important;
  }
  .u-sp-wd780 {
    width: 780px !important;
  }
  .u-sp-wd785 {
    width: 785px !important;
  }
  .u-sp-wd790 {
    width: 790px !important;
  }
  .u-sp-wd795 {
    width: 795px !important;
  }
  .u-sp-wd800 {
    width: 800px !important;
  }
  .u-sp-wd805 {
    width: 805px !important;
  }
  .u-sp-wd810 {
    width: 810px !important;
  }
  .u-sp-wd815 {
    width: 815px !important;
  }
  .u-sp-wd820 {
    width: 820px !important;
  }
  .u-sp-wd825 {
    width: 825px !important;
  }
  .u-sp-wd830 {
    width: 830px !important;
  }
  .u-sp-wd835 {
    width: 835px !important;
  }
  .u-sp-wd840 {
    width: 840px !important;
  }
  .u-sp-wd845 {
    width: 845px !important;
  }
  .u-sp-wd850 {
    width: 850px !important;
  }
  .u-sp-wd855 {
    width: 855px !important;
  }
  .u-sp-wd860 {
    width: 860px !important;
  }
  .u-sp-wd865 {
    width: 865px !important;
  }
  .u-sp-wd870 {
    width: 870px !important;
  }
  .u-sp-wd875 {
    width: 875px !important;
  }
  .u-sp-wd880 {
    width: 880px !important;
  }
  .u-sp-wd885 {
    width: 885px !important;
  }
  .u-sp-wd890 {
    width: 890px !important;
  }
  .u-sp-wd895 {
    width: 895px !important;
  }
  .u-sp-wd900 {
    width: 900px !important;
  }
  .u-sp-wd905 {
    width: 905px !important;
  }
  .u-sp-wd910 {
    width: 910px !important;
  }
  .u-sp-wd915 {
    width: 915px !important;
  }
  .u-sp-wd920 {
    width: 920px !important;
  }
  .u-sp-wd925 {
    width: 925px !important;
  }
  .u-sp-wd930 {
    width: 930px !important;
  }
  .u-sp-wd935 {
    width: 935px !important;
  }
  .u-sp-wd940 {
    width: 940px !important;
  }
  .u-sp-wd945 {
    width: 945px !important;
  }
  .u-sp-wd950 {
    width: 950px !important;
  }
  .u-sp-wd955 {
    width: 955px !important;
  }
  .u-sp-wd960 {
    width: 960px !important;
  }
  .u-sp-wd965 {
    width: 965px !important;
  }
  .u-sp-wd970 {
    width: 970px !important;
  }
  .u-sp-wd975 {
    width: 975px !important;
  }
  .u-sp-wd980 {
    width: 980px !important;
  }
  .u-sp-wd985 {
    width: 985px !important;
  }
  .u-sp-wd990 {
    width: 990px !important;
  }
  .u-sp-wd995 {
    width: 995px !important;
  }
  .u-sp-wd1000 {
    width: 1000px !important;
  }
}

/* 幅-%指定
------------------------------------------------------------ */
.u-pc-mw0p {
  width: 0% !important;
}

.u-pc-mw5p {
  width: 5% !important;
}

.u-pc-mw10p {
  width: 10% !important;
}

.u-pc-mw15p {
  width: 15% !important;
}

.u-pc-mw20p {
  width: 20% !important;
}

.u-pc-mw25p {
  width: 25% !important;
}

.u-pc-mw30p {
  width: 30% !important;
}

.u-pc-mw35p {
  width: 35% !important;
}

.u-pc-mw40p {
  width: 40% !important;
}

.u-pc-mw45p {
  width: 45% !important;
}

.u-pc-mw50p {
  width: 50% !important;
}

.u-pc-mw55p {
  width: 55% !important;
}

.u-pc-mw60p {
  width: 60% !important;
}

.u-pc-mw65p {
  width: 65% !important;
}

.u-pc-mw70p {
  width: 70% !important;
}

.u-pc-mw75p {
  width: 75% !important;
}

.u-pc-mw80p {
  width: 80% !important;
}

.u-pc-mw85p {
  width: 85% !important;
}

.u-pc-mw90p {
  width: 90% !important;
}

.u-pc-mw95p {
  width: 95% !important;
}

.u-pc-mw100p {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .u-sp-mw0p {
    width: 0% !important;
  }
  .u-sp-mw5p {
    width: 5% !important;
  }
  .u-sp-mw10p {
    width: 10% !important;
  }
  .u-sp-mw15p {
    width: 15% !important;
  }
  .u-sp-mw20p {
    width: 20% !important;
  }
  .u-sp-mw25p {
    width: 25% !important;
  }
  .u-sp-mw30p {
    width: 30% !important;
  }
  .u-sp-mw35p {
    width: 35% !important;
  }
  .u-sp-mw40p {
    width: 40% !important;
  }
  .u-sp-mw45p {
    width: 45% !important;
  }
  .u-sp-mw50p {
    width: 50% !important;
  }
  .u-sp-mw55p {
    width: 55% !important;
  }
  .u-sp-mw60p {
    width: 60% !important;
  }
  .u-sp-mw65p {
    width: 65% !important;
  }
  .u-sp-mw70p {
    width: 70% !important;
  }
  .u-sp-mw75p {
    width: 75% !important;
  }
  .u-sp-mw80p {
    width: 80% !important;
  }
  .u-sp-mw85p {
    width: 85% !important;
  }
  .u-sp-mw90p {
    width: 90% !important;
  }
  .u-sp-mw95p {
    width: 95% !important;
  }
  .u-sp-mw100p {
    width: 100% !important;
  }
}

/* 幅-最大%指定
------------------------------------------------------------ */
.u-pc-maw-n {
  max-width: none !important;
}

.u-pc-maw-1p {
  max-width: 1% !important;
}

.u-pc-maw-10p {
  max-width: 10% !important;
}

.u-pc-maw-20p {
  max-width: 20% !important;
}

.u-pc-maw-25p {
  max-width: 25% !important;
}

.u-pc-maw-30p {
  max-width: 30% !important;
}

.u-pc-maw-33p {
  max-width: 33% !important;
}

.u-pc-maw-40p {
  max-width: 40% !important;
}

.u-pc-maw-50p {
  max-width: 50% !important;
}

.u-pc-maw-60p {
  max-width: 60% !important;
}

.u-pc-maw-66p {
  max-width: 66% !important;
}

.u-pc-maw-70p {
  max-width: 70% !important;
}

.u-pc-maw-75p {
  max-width: 75% !important;
}

.u-pc-maw-80p {
  max-width: 80% !important;
}

.u-pc-maw-90p {
  max-width: 90% !important;
}

.u-pc-maw-100p {
  max-width: 100% !important;
}

@media screen and (max-width: 767px) {
  .u-sp-maw-n {
    max-width: none !important;
  }
  .u-sp-maw-1p {
    max-width: 1% !important;
  }
  .u-sp-maw-10p {
    max-width: 10% !important;
  }
  .u-sp-maw-20p {
    max-width: 20% !important;
  }
  .u-sp-maw-25p {
    max-width: 25% !important;
  }
  .u-sp-maw-30p {
    max-width: 30% !important;
  }
  .u-sp-maw-33p {
    max-width: 33% !important;
  }
  .u-sp-maw-40p {
    max-width: 40% !important;
  }
  .u-sp-maw-50p {
    max-width: 50% !important;
  }
  .u-sp-maw-60p {
    max-width: 60% !important;
  }
  .u-sp-maw-66p {
    max-width: 66% !important;
  }
  .u-sp-maw-70p {
    max-width: 70% !important;
  }
  .u-sp-maw-75p {
    max-width: 75% !important;
  }
  .u-sp-maw-80p {
    max-width: 80% !important;
  }
  .u-sp-maw-90p {
    max-width: 90% !important;
  }
  .u-sp-maw-100p {
    max-width: 100% !important;
  }
}

/* 高さ
------------------------------------------------------------ */
.u-pc-hg-a {
  height: auto !important;
}

.u-pc-hg0 {
  height: 0px !important;
}

.u-pc-hg5 {
  height: 5px !important;
}

.u-pc-hg10 {
  height: 10px !important;
}

.u-pc-hg15 {
  height: 15px !important;
}

.u-pc-hg20 {
  height: 20px !important;
}

.u-pc-hg25 {
  height: 25px !important;
}

.u-pc-hg30 {
  height: 30px !important;
}

.u-pc-hg35 {
  height: 35px !important;
}

.u-pc-hg40 {
  height: 40px !important;
}

.u-pc-hg45 {
  height: 45px !important;
}

.u-pc-hg50 {
  height: 50px !important;
}

.u-pc-hg55 {
  height: 55px !important;
}

.u-pc-hg60 {
  height: 60px !important;
}

.u-pc-hg65 {
  height: 65px !important;
}

.u-pc-hg70 {
  height: 70px !important;
}

.u-pc-hg75 {
  height: 75px !important;
}

.u-pc-hg80 {
  height: 80px !important;
}

.u-pc-hg85 {
  height: 85px !important;
}

.u-pc-hg90 {
  height: 90px !important;
}

.u-pc-hg95 {
  height: 95px !important;
}

.u-pc-hg100 {
  height: 100px !important;
}

.u-pc-hg105 {
  height: 105px !important;
}

.u-pc-hg110 {
  height: 110px !important;
}

.u-pc-hg115 {
  height: 115px !important;
}

.u-pc-hg120 {
  height: 120px !important;
}

.u-pc-hg125 {
  height: 125px !important;
}

.u-pc-hg130 {
  height: 130px !important;
}

.u-pc-hg135 {
  height: 135px !important;
}

.u-pc-hg140 {
  height: 140px !important;
}

.u-pc-hg145 {
  height: 145px !important;
}

.u-pc-hg150 {
  height: 150px !important;
}

.u-pc-hg155 {
  height: 155px !important;
}

.u-pc-hg160 {
  height: 160px !important;
}

.u-pc-hg165 {
  height: 165px !important;
}

.u-pc-hg170 {
  height: 170px !important;
}

.u-pc-hg175 {
  height: 175px !important;
}

.u-pc-hg180 {
  height: 180px !important;
}

.u-pc-hg185 {
  height: 185px !important;
}

.u-pc-hg190 {
  height: 190px !important;
}

.u-pc-hg195 {
  height: 195px !important;
}

.u-pc-hg200 {
  height: 200px !important;
}

.u-pc-hg205 {
  height: 205px !important;
}

.u-pc-hg210 {
  height: 210px !important;
}

.u-pc-hg215 {
  height: 215px !important;
}

.u-pc-hg220 {
  height: 220px !important;
}

.u-pc-hg225 {
  height: 225px !important;
}

.u-pc-hg230 {
  height: 230px !important;
}

.u-pc-hg235 {
  height: 235px !important;
}

.u-pc-hg240 {
  height: 240px !important;
}

.u-pc-hg245 {
  height: 245px !important;
}

.u-pc-hg250 {
  height: 250px !important;
}

.u-pc-hg255 {
  height: 255px !important;
}

.u-pc-hg260 {
  height: 260px !important;
}

.u-pc-hg265 {
  height: 265px !important;
}

.u-pc-hg270 {
  height: 270px !important;
}

.u-pc-hg275 {
  height: 275px !important;
}

.u-pc-hg280 {
  height: 280px !important;
}

.u-pc-hg285 {
  height: 285px !important;
}

.u-pc-hg290 {
  height: 290px !important;
}

.u-pc-hg295 {
  height: 295px !important;
}

.u-pc-hg300 {
  height: 300px !important;
}

.u-pc-hg305 {
  height: 305px !important;
}

.u-pc-hg310 {
  height: 310px !important;
}

.u-pc-hg315 {
  height: 315px !important;
}

.u-pc-hg320 {
  height: 320px !important;
}

.u-pc-hg325 {
  height: 325px !important;
}

.u-pc-hg330 {
  height: 330px !important;
}

.u-pc-hg335 {
  height: 335px !important;
}

.u-pc-hg340 {
  height: 340px !important;
}

.u-pc-hg345 {
  height: 345px !important;
}

.u-pc-hg350 {
  height: 350px !important;
}

.u-pc-hg355 {
  height: 355px !important;
}

.u-pc-hg360 {
  height: 360px !important;
}

.u-pc-hg365 {
  height: 365px !important;
}

.u-pc-hg370 {
  height: 370px !important;
}

.u-pc-hg375 {
  height: 375px !important;
}

.u-pc-hg380 {
  height: 380px !important;
}

.u-pc-hg385 {
  height: 385px !important;
}

.u-pc-hg390 {
  height: 390px !important;
}

.u-pc-hg395 {
  height: 395px !important;
}

.u-pc-hg400 {
  height: 400px !important;
}

.u-pc-hg405 {
  height: 405px !important;
}

.u-pc-hg410 {
  height: 410px !important;
}

.u-pc-hg415 {
  height: 415px !important;
}

.u-pc-hg420 {
  height: 420px !important;
}

.u-pc-hg425 {
  height: 425px !important;
}

.u-pc-hg430 {
  height: 430px !important;
}

.u-pc-hg435 {
  height: 435px !important;
}

.u-pc-hg440 {
  height: 440px !important;
}

.u-pc-hg445 {
  height: 445px !important;
}

.u-pc-hg450 {
  height: 450px !important;
}

.u-pc-hg455 {
  height: 455px !important;
}

.u-pc-hg460 {
  height: 460px !important;
}

.u-pc-hg465 {
  height: 465px !important;
}

.u-pc-hg470 {
  height: 470px !important;
}

.u-pc-hg475 {
  height: 475px !important;
}

.u-pc-hg480 {
  height: 480px !important;
}

.u-pc-hg485 {
  height: 485px !important;
}

.u-pc-hg490 {
  height: 490px !important;
}

.u-pc-hg495 {
  height: 495px !important;
}

.u-pc-hg500 {
  height: 500px !important;
}

.u-pc-hg505 {
  height: 505px !important;
}

.u-pc-hg510 {
  height: 510px !important;
}

.u-pc-hg515 {
  height: 515px !important;
}

.u-pc-hg520 {
  height: 520px !important;
}

.u-pc-hg525 {
  height: 525px !important;
}

.u-pc-hg530 {
  height: 530px !important;
}

.u-pc-hg535 {
  height: 535px !important;
}

.u-pc-hg540 {
  height: 540px !important;
}

.u-pc-hg545 {
  height: 545px !important;
}

.u-pc-hg550 {
  height: 550px !important;
}

.u-pc-hg555 {
  height: 555px !important;
}

.u-pc-hg560 {
  height: 560px !important;
}

.u-pc-hg565 {
  height: 565px !important;
}

.u-pc-hg570 {
  height: 570px !important;
}

.u-pc-hg575 {
  height: 575px !important;
}

.u-pc-hg580 {
  height: 580px !important;
}

.u-pc-hg585 {
  height: 585px !important;
}

.u-pc-hg590 {
  height: 590px !important;
}

.u-pc-hg595 {
  height: 595px !important;
}

.u-pc-hg600 {
  height: 600px !important;
}

.u-pc-hg605 {
  height: 605px !important;
}

.u-pc-hg610 {
  height: 610px !important;
}

.u-pc-hg615 {
  height: 615px !important;
}

.u-pc-hg620 {
  height: 620px !important;
}

.u-pc-hg625 {
  height: 625px !important;
}

.u-pc-hg630 {
  height: 630px !important;
}

.u-pc-hg635 {
  height: 635px !important;
}

.u-pc-hg640 {
  height: 640px !important;
}

.u-pc-hg645 {
  height: 645px !important;
}

.u-pc-hg650 {
  height: 650px !important;
}

.u-pc-hg655 {
  height: 655px !important;
}

.u-pc-hg660 {
  height: 660px !important;
}

.u-pc-hg665 {
  height: 665px !important;
}

.u-pc-hg670 {
  height: 670px !important;
}

.u-pc-hg675 {
  height: 675px !important;
}

.u-pc-hg680 {
  height: 680px !important;
}

.u-pc-hg685 {
  height: 685px !important;
}

.u-pc-hg690 {
  height: 690px !important;
}

.u-pc-hg695 {
  height: 695px !important;
}

.u-pc-hg700 {
  height: 700px !important;
}

.u-pc-hg705 {
  height: 705px !important;
}

.u-pc-hg710 {
  height: 710px !important;
}

.u-pc-hg715 {
  height: 715px !important;
}

.u-pc-hg720 {
  height: 720px !important;
}

.u-pc-hg725 {
  height: 725px !important;
}

.u-pc-hg730 {
  height: 730px !important;
}

.u-pc-hg735 {
  height: 735px !important;
}

.u-pc-hg740 {
  height: 740px !important;
}

.u-pc-hg745 {
  height: 745px !important;
}

.u-pc-hg750 {
  height: 750px !important;
}

.u-pc-hg755 {
  height: 755px !important;
}

.u-pc-hg760 {
  height: 760px !important;
}

.u-pc-hg765 {
  height: 765px !important;
}

.u-pc-hg770 {
  height: 770px !important;
}

.u-pc-hg775 {
  height: 775px !important;
}

.u-pc-hg780 {
  height: 780px !important;
}

.u-pc-hg785 {
  height: 785px !important;
}

.u-pc-hg790 {
  height: 790px !important;
}

.u-pc-hg795 {
  height: 795px !important;
}

.u-pc-hg800 {
  height: 800px !important;
}

.u-pc-hg805 {
  height: 805px !important;
}

.u-pc-hg810 {
  height: 810px !important;
}

.u-pc-hg815 {
  height: 815px !important;
}

.u-pc-hg820 {
  height: 820px !important;
}

.u-pc-hg825 {
  height: 825px !important;
}

.u-pc-hg830 {
  height: 830px !important;
}

.u-pc-hg835 {
  height: 835px !important;
}

.u-pc-hg840 {
  height: 840px !important;
}

.u-pc-hg845 {
  height: 845px !important;
}

.u-pc-hg850 {
  height: 850px !important;
}

.u-pc-hg855 {
  height: 855px !important;
}

.u-pc-hg860 {
  height: 860px !important;
}

.u-pc-hg865 {
  height: 865px !important;
}

.u-pc-hg870 {
  height: 870px !important;
}

.u-pc-hg875 {
  height: 875px !important;
}

.u-pc-hg880 {
  height: 880px !important;
}

.u-pc-hg885 {
  height: 885px !important;
}

.u-pc-hg890 {
  height: 890px !important;
}

.u-pc-hg895 {
  height: 895px !important;
}

.u-pc-hg900 {
  height: 900px !important;
}

.u-pc-hg905 {
  height: 905px !important;
}

.u-pc-hg910 {
  height: 910px !important;
}

.u-pc-hg915 {
  height: 915px !important;
}

.u-pc-hg920 {
  height: 920px !important;
}

.u-pc-hg925 {
  height: 925px !important;
}

.u-pc-hg930 {
  height: 930px !important;
}

.u-pc-hg935 {
  height: 935px !important;
}

.u-pc-hg940 {
  height: 940px !important;
}

.u-pc-hg945 {
  height: 945px !important;
}

.u-pc-hg950 {
  height: 950px !important;
}

.u-pc-hg955 {
  height: 955px !important;
}

.u-pc-hg960 {
  height: 960px !important;
}

.u-pc-hg965 {
  height: 965px !important;
}

.u-pc-hg970 {
  height: 970px !important;
}

.u-pc-hg975 {
  height: 975px !important;
}

.u-pc-hg980 {
  height: 980px !important;
}

.u-pc-hg985 {
  height: 985px !important;
}

.u-pc-hg990 {
  height: 990px !important;
}

.u-pc-hg995 {
  height: 995px !important;
}

.u-pc-hg1000 {
  height: 1000px !important;
}

.u-mah-n {
  max-height: none !important;
}

.u-mah-100p {
  max-height: 100% !important;
}

.u-miw-0 {
  min-width: 0 !important;
}

.u-mih-0 {
  min-height: 0 !important;
}

@media screen and (max-width: 767px) {
  .u-sp-hg-a {
    height: auto !important;
  }
  .u-sp-hg0 {
    height: 0px !important;
  }
  .u-sp-hg5 {
    height: 5px !important;
  }
  .u-sp-hg10 {
    height: 10px !important;
  }
  .u-sp-hg15 {
    height: 15px !important;
  }
  .u-sp-hg20 {
    height: 20px !important;
  }
  .u-sp-hg25 {
    height: 25px !important;
  }
  .u-sp-hg30 {
    height: 30px !important;
  }
  .u-sp-hg35 {
    height: 35px !important;
  }
  .u-sp-hg40 {
    height: 40px !important;
  }
  .u-sp-hg45 {
    height: 45px !important;
  }
  .u-sp-hg50 {
    height: 50px !important;
  }
  .u-sp-hg55 {
    height: 55px !important;
  }
  .u-sp-hg60 {
    height: 60px !important;
  }
  .u-sp-hg65 {
    height: 65px !important;
  }
  .u-sp-hg70 {
    height: 70px !important;
  }
  .u-sp-hg75 {
    height: 75px !important;
  }
  .u-sp-hg80 {
    height: 80px !important;
  }
  .u-sp-hg85 {
    height: 85px !important;
  }
  .u-sp-hg90 {
    height: 90px !important;
  }
  .u-sp-hg95 {
    height: 95px !important;
  }
  .u-sp-hg100 {
    height: 100px !important;
  }
  .u-sp-hg105 {
    height: 105px !important;
  }
  .u-sp-hg110 {
    height: 110px !important;
  }
  .u-sp-hg115 {
    height: 115px !important;
  }
  .u-sp-hg120 {
    height: 120px !important;
  }
  .u-sp-hg125 {
    height: 125px !important;
  }
  .u-sp-hg130 {
    height: 130px !important;
  }
  .u-sp-hg135 {
    height: 135px !important;
  }
  .u-sp-hg140 {
    height: 140px !important;
  }
  .u-sp-hg145 {
    height: 145px !important;
  }
  .u-sp-hg150 {
    height: 150px !important;
  }
  .u-sp-hg155 {
    height: 155px !important;
  }
  .u-sp-hg160 {
    height: 160px !important;
  }
  .u-sp-hg165 {
    height: 165px !important;
  }
  .u-sp-hg170 {
    height: 170px !important;
  }
  .u-sp-hg175 {
    height: 175px !important;
  }
  .u-sp-hg180 {
    height: 180px !important;
  }
  .u-sp-hg185 {
    height: 185px !important;
  }
  .u-sp-hg190 {
    height: 190px !important;
  }
  .u-sp-hg195 {
    height: 195px !important;
  }
  .u-sp-hg200 {
    height: 200px !important;
  }
  .u-sp-hg205 {
    height: 205px !important;
  }
  .u-sp-hg210 {
    height: 210px !important;
  }
  .u-sp-hg215 {
    height: 215px !important;
  }
  .u-sp-hg220 {
    height: 220px !important;
  }
  .u-sp-hg225 {
    height: 225px !important;
  }
  .u-sp-hg230 {
    height: 230px !important;
  }
  .u-sp-hg235 {
    height: 235px !important;
  }
  .u-sp-hg240 {
    height: 240px !important;
  }
  .u-sp-hg245 {
    height: 245px !important;
  }
  .u-sp-hg250 {
    height: 250px !important;
  }
  .u-sp-hg255 {
    height: 255px !important;
  }
  .u-sp-hg260 {
    height: 260px !important;
  }
  .u-sp-hg265 {
    height: 265px !important;
  }
  .u-sp-hg270 {
    height: 270px !important;
  }
  .u-sp-hg275 {
    height: 275px !important;
  }
  .u-sp-hg280 {
    height: 280px !important;
  }
  .u-sp-hg285 {
    height: 285px !important;
  }
  .u-sp-hg290 {
    height: 290px !important;
  }
  .u-sp-hg295 {
    height: 295px !important;
  }
  .u-sp-hg300 {
    height: 300px !important;
  }
  .u-sp-hg305 {
    height: 305px !important;
  }
  .u-sp-hg310 {
    height: 310px !important;
  }
  .u-sp-hg315 {
    height: 315px !important;
  }
  .u-sp-hg320 {
    height: 320px !important;
  }
  .u-sp-hg325 {
    height: 325px !important;
  }
  .u-sp-hg330 {
    height: 330px !important;
  }
  .u-sp-hg335 {
    height: 335px !important;
  }
  .u-sp-hg340 {
    height: 340px !important;
  }
  .u-sp-hg345 {
    height: 345px !important;
  }
  .u-sp-hg350 {
    height: 350px !important;
  }
  .u-sp-hg355 {
    height: 355px !important;
  }
  .u-sp-hg360 {
    height: 360px !important;
  }
  .u-sp-hg365 {
    height: 365px !important;
  }
  .u-sp-hg370 {
    height: 370px !important;
  }
  .u-sp-hg375 {
    height: 375px !important;
  }
  .u-sp-hg380 {
    height: 380px !important;
  }
  .u-sp-hg385 {
    height: 385px !important;
  }
  .u-sp-hg390 {
    height: 390px !important;
  }
  .u-sp-hg395 {
    height: 395px !important;
  }
  .u-sp-hg400 {
    height: 400px !important;
  }
  .u-sp-hg405 {
    height: 405px !important;
  }
  .u-sp-hg410 {
    height: 410px !important;
  }
  .u-sp-hg415 {
    height: 415px !important;
  }
  .u-sp-hg420 {
    height: 420px !important;
  }
  .u-sp-hg425 {
    height: 425px !important;
  }
  .u-sp-hg430 {
    height: 430px !important;
  }
  .u-sp-hg435 {
    height: 435px !important;
  }
  .u-sp-hg440 {
    height: 440px !important;
  }
  .u-sp-hg445 {
    height: 445px !important;
  }
  .u-sp-hg450 {
    height: 450px !important;
  }
  .u-sp-hg455 {
    height: 455px !important;
  }
  .u-sp-hg460 {
    height: 460px !important;
  }
  .u-sp-hg465 {
    height: 465px !important;
  }
  .u-sp-hg470 {
    height: 470px !important;
  }
  .u-sp-hg475 {
    height: 475px !important;
  }
  .u-sp-hg480 {
    height: 480px !important;
  }
  .u-sp-hg485 {
    height: 485px !important;
  }
  .u-sp-hg490 {
    height: 490px !important;
  }
  .u-sp-hg495 {
    height: 495px !important;
  }
  .u-sp-hg500 {
    height: 500px !important;
  }
  .u-sp-hg505 {
    height: 505px !important;
  }
  .u-sp-hg510 {
    height: 510px !important;
  }
  .u-sp-hg515 {
    height: 515px !important;
  }
  .u-sp-hg520 {
    height: 520px !important;
  }
  .u-sp-hg525 {
    height: 525px !important;
  }
  .u-sp-hg530 {
    height: 530px !important;
  }
  .u-sp-hg535 {
    height: 535px !important;
  }
  .u-sp-hg540 {
    height: 540px !important;
  }
  .u-sp-hg545 {
    height: 545px !important;
  }
  .u-sp-hg550 {
    height: 550px !important;
  }
  .u-sp-hg555 {
    height: 555px !important;
  }
  .u-sp-hg560 {
    height: 560px !important;
  }
  .u-sp-hg565 {
    height: 565px !important;
  }
  .u-sp-hg570 {
    height: 570px !important;
  }
  .u-sp-hg575 {
    height: 575px !important;
  }
  .u-sp-hg580 {
    height: 580px !important;
  }
  .u-sp-hg585 {
    height: 585px !important;
  }
  .u-sp-hg590 {
    height: 590px !important;
  }
  .u-sp-hg595 {
    height: 595px !important;
  }
  .u-sp-hg600 {
    height: 600px !important;
  }
  .u-sp-hg605 {
    height: 605px !important;
  }
  .u-sp-hg610 {
    height: 610px !important;
  }
  .u-sp-hg615 {
    height: 615px !important;
  }
  .u-sp-hg620 {
    height: 620px !important;
  }
  .u-sp-hg625 {
    height: 625px !important;
  }
  .u-sp-hg630 {
    height: 630px !important;
  }
  .u-sp-hg635 {
    height: 635px !important;
  }
  .u-sp-hg640 {
    height: 640px !important;
  }
  .u-sp-hg645 {
    height: 645px !important;
  }
  .u-sp-hg650 {
    height: 650px !important;
  }
  .u-sp-hg655 {
    height: 655px !important;
  }
  .u-sp-hg660 {
    height: 660px !important;
  }
  .u-sp-hg665 {
    height: 665px !important;
  }
  .u-sp-hg670 {
    height: 670px !important;
  }
  .u-sp-hg675 {
    height: 675px !important;
  }
  .u-sp-hg680 {
    height: 680px !important;
  }
  .u-sp-hg685 {
    height: 685px !important;
  }
  .u-sp-hg690 {
    height: 690px !important;
  }
  .u-sp-hg695 {
    height: 695px !important;
  }
  .u-sp-hg700 {
    height: 700px !important;
  }
  .u-sp-hg705 {
    height: 705px !important;
  }
  .u-sp-hg710 {
    height: 710px !important;
  }
  .u-sp-hg715 {
    height: 715px !important;
  }
  .u-sp-hg720 {
    height: 720px !important;
  }
  .u-sp-hg725 {
    height: 725px !important;
  }
  .u-sp-hg730 {
    height: 730px !important;
  }
  .u-sp-hg735 {
    height: 735px !important;
  }
  .u-sp-hg740 {
    height: 740px !important;
  }
  .u-sp-hg745 {
    height: 745px !important;
  }
  .u-sp-hg750 {
    height: 750px !important;
  }
  .u-sp-hg755 {
    height: 755px !important;
  }
  .u-sp-hg760 {
    height: 760px !important;
  }
  .u-sp-hg765 {
    height: 765px !important;
  }
  .u-sp-hg770 {
    height: 770px !important;
  }
  .u-sp-hg775 {
    height: 775px !important;
  }
  .u-sp-hg780 {
    height: 780px !important;
  }
  .u-sp-hg785 {
    height: 785px !important;
  }
  .u-sp-hg790 {
    height: 790px !important;
  }
  .u-sp-hg795 {
    height: 795px !important;
  }
  .u-sp-hg800 {
    height: 800px !important;
  }
  .u-sp-hg805 {
    height: 805px !important;
  }
  .u-sp-hg810 {
    height: 810px !important;
  }
  .u-sp-hg815 {
    height: 815px !important;
  }
  .u-sp-hg820 {
    height: 820px !important;
  }
  .u-sp-hg825 {
    height: 825px !important;
  }
  .u-sp-hg830 {
    height: 830px !important;
  }
  .u-sp-hg835 {
    height: 835px !important;
  }
  .u-sp-hg840 {
    height: 840px !important;
  }
  .u-sp-hg845 {
    height: 845px !important;
  }
  .u-sp-hg850 {
    height: 850px !important;
  }
  .u-sp-hg855 {
    height: 855px !important;
  }
  .u-sp-hg860 {
    height: 860px !important;
  }
  .u-sp-hg865 {
    height: 865px !important;
  }
  .u-sp-hg870 {
    height: 870px !important;
  }
  .u-sp-hg875 {
    height: 875px !important;
  }
  .u-sp-hg880 {
    height: 880px !important;
  }
  .u-sp-hg885 {
    height: 885px !important;
  }
  .u-sp-hg890 {
    height: 890px !important;
  }
  .u-sp-hg895 {
    height: 895px !important;
  }
  .u-sp-hg900 {
    height: 900px !important;
  }
  .u-sp-hg905 {
    height: 905px !important;
  }
  .u-sp-hg910 {
    height: 910px !important;
  }
  .u-sp-hg915 {
    height: 915px !important;
  }
  .u-sp-hg920 {
    height: 920px !important;
  }
  .u-sp-hg925 {
    height: 925px !important;
  }
  .u-sp-hg930 {
    height: 930px !important;
  }
  .u-sp-hg935 {
    height: 935px !important;
  }
  .u-sp-hg940 {
    height: 940px !important;
  }
  .u-sp-hg945 {
    height: 945px !important;
  }
  .u-sp-hg950 {
    height: 950px !important;
  }
  .u-sp-hg955 {
    height: 955px !important;
  }
  .u-sp-hg960 {
    height: 960px !important;
  }
  .u-sp-hg965 {
    height: 965px !important;
  }
  .u-sp-hg970 {
    height: 970px !important;
  }
  .u-sp-hg975 {
    height: 975px !important;
  }
  .u-sp-hg980 {
    height: 980px !important;
  }
  .u-sp-hg985 {
    height: 985px !important;
  }
  .u-sp-hg990 {
    height: 990px !important;
  }
  .u-sp-hg995 {
    height: 995px !important;
  }
  .u-sp-hg1000 {
    height: 1000px !important;
  }
  .u-sp-mah-n {
    max-height: none !important;
  }
  .u-sp-mah-100p {
    max-height: 100% !important;
  }
  .u-sp-miw-0 {
    min-width: 0 !important;
  }
  .u-sp-mih-0 {
    min-height: 0 !important;
  }
}

/* マルチカラムレイアウト
------------------------------------------------------------ */
.u-cc-1 {
  column-count: 1 !important;
}

.u-cc-2 {
  column-count: 2 !important;
}

.u-cc-3 {
  column-count: 3 !important;
}

.u-cc-4 {
  column-count: 4 !important;
}

@media screen and (max-width: 767px) {
  .u-sp-cc-1 {
    column-count: 1 !important;
  }
  .u-sp-cc-2 {
    column-count: 2 !important;
  }
  .u-sp-cc-3 {
    column-count: 3 !important;
  }
  .u-sp-cc-4 {
    column-count: 4 !important;
  }
}
